import React from "react";
import { withFormik, FormikProps, Form, FormikErrors } from "formik";
import { IntlShape } from "react-intl";

import { ClickableComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { useRefState } from "@app/util/use-ref-state";
import { platformType, getTranslatedPlatforms } from "@app/constants/platform";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapMerchantToFilterItem, mapToFilterItem } from "@app/api/core/filter/map-filter-item";

import { getFilters, getMerchants } from "@app/modules/deal-detail/components/api-calls";
import { ReactSelect } from "@app/core/react-select/react-select";
import { FormGroup } from "@app/core/form-group/form-group";
import { FormRow } from "@app/core/form-row/form-row";
import { FormField } from "@app/core/form-field/form-field";
import { DuplicateExternalDealFiltersDTO } from "@app/api/generated";

import styles from "./edf-duplicate-bulk-component.module.scss";

interface IFormValues {
  ids: number[];
  platform?: platformType;
  merchants?: FilterItem[];
  categories?: FilterItem[];
}

const InnerForm = (props: IMyFormProps & FormikProps<IFormValues>) => {
  const { touched, errors, values, setFieldValue } = props;
  const platformRef = useRefState(values.platform);
  const translatedPlatforms = getTranslatedPlatforms(props.intl);

  const onCustomChange = (value: any, id: string) => {
    props.handleChange(value);
    setFieldValue(id, value);
  };

  const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.handleChange(e);
    setFieldValue("merchants", []);
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const merchants = await getMerchants(inputValue, platformRef.current);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant)) : []);
  };

  const loadCategories = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const categories = await getFilters(inputValue, "1");
    callback((categories || []).map(mapToFilterItem));
  };

  return (
    <Form>
      <div className={styles.form}>
        <FormGroup>
          <FormRow>
            <FormField>
              <SelectComponent
                id="platform"
                onBlur={props.handleBlur}
                value={values.platform}
                onChange={handlePlatformChange}
                options={translatedPlatforms}
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.platform.label" }),
                  errorMessage: touched.platform && errors.platform
                }}
              />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <ReactSelect
                id="merchants"
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.merchants.label" }),
                  errorMessage: touched.merchants && (errors.merchants as any as string)
                }}
                isMulti
                isClearable
                isAsync
                onBlur={props.handleBlur}
                value={values.merchants}
                onChange={(e) => onCustomChange(e, "merchants")}
                loadOptions={loadMerchants}
                enableCollapsing
              />
            </FormField>
          </FormRow>

          <FormRow>
            <FormField>
              <ReactSelect
                id="categories"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.categories.label" }),
                  errorMessage: touched.categories && errors.categories
                }}
                isMulti
                value={values.categories}
                isClearable
                isAsync
                onBlur={props.handleBlur}
                onChange={(e) => onCustomChange(e, "categories")}
                loadOptions={loadCategories}
                enableCollapsing
              />
            </FormField>
          </FormRow>
        </FormGroup>

        <div className={styles.bottomBar}>
          <ClickableComponent
            variant="primary-inverted"
            title={props.intl.formatMessage({ id: "featuredCategoryDetailForm.button.cancel" })}
            buttonType="button"
            height={48}
            onClick={() => props.onCancel(false)}
          />
          <ClickableComponent
            buttonType="button"
            title={props.intl.formatMessage({ id: "dealForm.button.save" })}
            height={48}
            onClick={props.handleSubmit}
            disabled={props.isLoading}
          />
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  ids: number[];
  platform?: platformType;
  merchants?: FilterItem[];
  categories?: FilterItem[];
  intl: IntlShape;
  isLoading: boolean;
  onCancel: (isSavedCheck: boolean) => void;
  onSubmit: (filters: DuplicateExternalDealFiltersDTO) => void;
}

export const EdfDuplicateBulkForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: ({ ids, platform }) => ({
    ids,
    platform: platform || platformType.Nederland,
    merchants: [],
    categories: []
  }),

  validate: (values: IFormValues, props) => {
    const errors: FormikErrors<IFormValues> = {};

    if (values.merchants && values.merchants.length === 0) {
      errors.merchants = props.intl.formatMessage({ id: "general.form.error.required" }) as any;
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const externalDealFilters = mapValuesToDuplicateExternalDealFilters(values);
    bag.props.onSubmit(externalDealFilters);
  }
})(InnerForm);

const mapValuesToDuplicateExternalDealFilters = (values: IFormValues): DuplicateExternalDealFiltersDTO => {
  const formValues: DuplicateExternalDealFiltersDTO = {
    filterIds: values.ids?.map((filterId) => ({ id: Number(filterId) })),
    platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
    merchants: (values.merchants || []).map((merchant) => ({ id: Number(merchant.value) })),
    categories: (values.categories || []).map((category) => ({ id: Number(category.value) }))
  };

  return formValues;
};
