import React from "react";
import { IntlShape } from "react-intl";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";

import { TextFieldComponent, ClickableComponent } from "@app/core";
import { validateEmail } from "@app/util";

import styles from "./request-password-component.module.scss";

export interface IRequestPasswordFormValues {
  emailAddress: string;
}

interface IOtherProps {
  intl: IntlShape;
}

const InnerForm = (props: IOtherProps & FormikProps<IRequestPasswordFormValues>) => {
  const { touched, errors } = props;

  return (
    <Form>
      <div className={styles.loginForm}>
        <div className={styles.formField}>
          <TextFieldComponent
            label={{
              label: props.intl.formatMessage({ id: "requestPasswordForm.input.email.label" }),
              errorMessage: touched.emailAddress === true && errors.emailAddress
            }}
            placeholder={props.intl.formatMessage({ id: "requestPasswordForm.input.email.placeholder" })}
            value={props.values.emailAddress}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="email"
            id="emailAddress"
            errorMessage={touched.emailAddress === true && errors.emailAddress}
          />
        </div>
      </div>
      <div className={styles.submitButton}>
        <ClickableComponent
          buttonType="submit"
          title={props.intl.formatMessage({ id: "requestPasswordForm.button.send" })}
          fullWidth
          height={40}
        />
      </div>
    </Form>
  );
};

interface IFormProps {
  emailAddress: string;
  intl: IntlShape;
  onSubmit: (values: IRequestPasswordFormValues) => void;
}

export const RequestPasswordFormComponent = withFormik<IFormProps, IRequestPasswordFormValues>({
  mapPropsToValues: (props: IFormProps) => ({
    emailAddress: props.emailAddress
  }),

  validate: (values: IRequestPasswordFormValues, props) => {
    const errors: FormikErrors<IRequestPasswordFormValues> = {};
    if (!values.emailAddress) {
      errors.emailAddress = props.intl.formatMessage({ id: "requestPasswordForm.error.email.required" });
    } else if (!validateEmail(values.emailAddress)) {
      errors.emailAddress = props.intl.formatMessage({ id: "requestPasswordForm.error.email.invalid" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
