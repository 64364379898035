/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LoginDTO
 */
export interface LoginDTO {
  /**
   *
   * @type {string}
   * @memberof LoginDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LoginDTO
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof LoginDTO
   */
  resetToken?: string;
}

export function LoginDTOFromJSON(json: any): LoginDTO {
  return LoginDTOFromJSONTyped(json, false);
}

export function LoginDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    password: json["password"],
    resetToken: !exists(json, "resetToken") ? undefined : json["resetToken"]
  };
}

export function LoginDTOToJSON(value?: LoginDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    resetToken: value.resetToken
  };
}
