export interface IConfig {
  API_URL: string;
}

export const isDevelopment = () => true;

declare global {
  interface Window {
    appConfig: any;
  }
}

// window.appConfig = window.appConfig || {};
const basePath =
  process.env.NODE_ENV !== "production" ? "https://test.blackfridaynederland.nl/api" : `https://${location.host}/api`;

export const getConfig = (): IConfig => ({
  API_URL: basePath
});
