/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProductDealSortDTO
 */
export interface ProductDealSortDTO {
  /**
   *
   * @type {string}
   * @memberof ProductDealSortDTO
   */
  field?: string;
  /**
   *
   * @type {string}
   * @memberof ProductDealSortDTO
   */
  order?: string;
}

export function ProductDealSortDTOFromJSON(json: any): ProductDealSortDTO {
  return ProductDealSortDTOFromJSONTyped(json, false);
}

export function ProductDealSortDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDealSortDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    field: !exists(json, "field") ? undefined : json["field"],
    order: !exists(json, "order") ? undefined : json["order"]
  };
}

export function ProductDealSortDTOToJSON(value?: ProductDealSortDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    field: value.field,
    order: value.order
  };
}
