import * as React from "react";
import ReactSVG from "react-svg";
import clsx from "clsx";

import style from "./icon-component.module.scss";

export interface IIconComponentProps {
  fillColor?: string;
  icon: any;
  size?: string;
  strokeColor?: string;
  strokeWidth?: string;
}

const component: React.FunctionComponent<IIconComponentProps> = (props) => {
  const [iconStyleClassName, setIconStyleClassName] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    const { fillColor, strokeColor, strokeWidth } = props;

    const defaultStyle = "icon-svg";
    const fillClassName = typeof fillColor !== "undefined" ? style[`${defaultStyle}--filled`] : "";
    const strokeWidthClassName = typeof strokeWidth !== "undefined" ? style[`${defaultStyle}--stroke-width`] : "";
    const outlineClassName = typeof strokeColor !== "undefined" ? style[`${defaultStyle}--outline`] : "";

    const className = clsx(style[defaultStyle], fillClassName, outlineClassName, strokeWidthClassName);
    setIconStyleClassName(className);
  }, [props.fillColor, props.strokeColor, props.strokeWidth]);

  return (
    <ReactSVG
      src={props.icon}
      fallback={() => <span>Error!</span>}
      loading={() => <span />}
      renumerateIRIElements
      wrapper="span"
      style={{
        fill: props.fillColor,
        height: props.size,
        strokeWidth: props.strokeWidth,
        width: props.size,
        stroke: props.strokeColor
      }}
      className={iconStyleClassName}
    />
  );
};

const IconComponent = React.memo(component);

export { IconComponent };
