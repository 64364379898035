import { toast } from "react-toastify";

import { handleError } from "@app/util/handle-error";
import {
  BannersExtendedGetStatusEnum,
  BannersApi,
  CreateBannerDTO,
  PutBannerDTO,
  OutBannerDTO
} from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";
import {
  deleteBannerFromList,
  setSelectedBanner,
  setBanners,
  setIsLoadingBanners,
  setTotalResults
} from "@app/redux/reducers/banners";

const getBannerStatus = (value?: number): BannersExtendedGetStatusEnum | undefined => {
  switch (value) {
    case 0:
      return undefined;
    case 1:
      return BannersExtendedGetStatusEnum.INVISIBLE;
    case 2:
      return BannersExtendedGetStatusEnum.VISIBLE;
    case 3:
      return BannersExtendedGetStatusEnum.ALMOST_VISIBLE;
    default:
      return undefined;
  }
};

const getBanners =
  (skip: number, take: number, status?: number, searchQuery?: string, platformId?: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setIsLoadingBanners(true));

      const api = new BannersApi(await getAuthorizedApiConfig());

      const bannerStatus = getBannerStatus(status);

      const result = await api.bannersExtendedGet(take, skip, bannerStatus, searchQuery, platformId);

      dispatch(setBanners(result.banners));
      dispatch(setTotalResults(result.total));
      dispatch(setIsLoadingBanners(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get banners");
    }
  };

const getBanner =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setIsLoadingBanners(true));
      const api = new BannersApi(await getAuthorizedApiConfig());

      const result: OutBannerDTO = await api.bannersBannerIdGet(id);

      dispatch(setSelectedBanner(result));
      dispatch(setIsLoadingBanners(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get banner");
    }
  };

const deleteBanner =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new BannersApi(await getAuthorizedApiConfig());

      const bannerDeleted = await api.bannersBannerIdDelete(id);

      if (bannerDeleted) {
        dispatch(deleteBannerFromList(id));
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to delete banner");
    }
  };

const createNewBanner =
  (platformId: number, name: string): AppThunk =>
  async (dispatch): Promise<OutBannerDTO | undefined> => {
    try {
      const newBanner: CreateBannerDTO = {
        platform: {
          id: platformId,
          name: "",
          googleExperimentId: "",
          wctPlatformId: 0
        },
        name
      };

      const api = new BannersApi(await getAuthorizedApiConfig());

      const createdBanner: OutBannerDTO = await api.bannersPost(newBanner);

      dispatch(setSelectedBanner(createdBanner));

      return createdBanner;
    } catch (error) {
      if ((error as any)?.status === 422) {
        handleError(error, dispatch, "Banner bestaat al.");
      } else {
        handleError(error, dispatch, "Failed to create new banner.");
      }
    }

    return undefined;
  };

const saveBanner =
  (banner: PutBannerDTO, customMessage?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new BannersApi(await getAuthorizedApiConfig());

      const savedBanner = await api.bannersBannerIdPut(banner.id, banner);

      dispatch(setSelectedBanner(savedBanner));

      if (customMessage) {
        toast.success(customMessage);
      } else {
        toast.success("Banner is opgeslagen");
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to save banner");
    }
  };

export const bannersThunks = {
  getBanners,
  getBanner,
  deleteBanner,
  createNewBanner,
  saveBanner
};
