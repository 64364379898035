import React, { forwardRef } from "react";
import clsx from "clsx";

import style from "./checkbox-component.module.scss";

interface ICheckboxComponentProps {
  children?: any;
  disabled?: boolean;
  id?: string;
  name: string;
  onChange: any;
  value: boolean;
  noMargin?: boolean;
  onBlur?<T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
}

const CheckboxComponent = forwardRef((props: ICheckboxComponentProps, ref: any) => {
  const { disabled = false } = props;

  return (
    <div className={clsx(style.checkbox, props.noMargin && style.noMargin)} ref={ref}>
      <label className={style.checkbox__label} htmlFor={props.name}>
        <input
          aria-checked={props.value}
          type="checkbox"
          name={props.name}
          value={props.id}
          className={style.checkbox__input}
          onChange={props.onChange}
          checked={props.value}
          disabled={disabled}
        />
        {props.children}
      </label>
    </div>
  );
});

export { CheckboxComponent };
