import { toast } from "react-toastify";

import { PlatformApi, PlatformDTO } from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { handleError } from "@app/util/handle-error";
import { AppThunk } from "@app/redux/store";
import { setIsLoadingPlatform, setPlatformInfo } from "@app/redux/reducers/platform";

const getPlatform =
  (platformId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new PlatformApi(await getAuthorizedApiConfig());
      dispatch(setIsLoadingPlatform(true));

      const platform = await api.platformsGet(platformId);
      dispatch(setPlatformInfo(platform));
      dispatch(setIsLoadingPlatform(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get platform");
    }
  };

const savePlatform =
  (platform: PlatformDTO): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new PlatformApi(await getAuthorizedApiConfig());

      await api.platformsPlatformIdPut(platform.id, platform);

      dispatch(setPlatformInfo(platform));
      toast.success("Platform wijzigingen zijn opgeslagen");
    } catch (error) {
      handleError(error, dispatch, "Failed to save platform");
    }
  };

export const platformThunks = {
  getPlatform,
  savePlatform
};
