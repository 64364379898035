import { get } from "lodash";

import { ExternalDealFilterMvoDTO, OutFilterDTO, OutMerchantDTO } from "@app/api/generated";

import { FilterItem } from "./filter-item";

export const mapToFilterItem = (filterDto: OutFilterDTO): FilterItem => {
  const result: FilterItem = {
    value: filterDto.id,
    label: filterDto.name
  };

  return result;
};

export const mapAnyToFilterItem = <T extends object | string>(
  x: T,
  valuePath?: string,
  labelPath?: string,
  labelTransform?: (value: string) => string
): FilterItem => {
  const value = valuePath ? get(x, valuePath) : x || "";
  const label = labelPath ? get(x, labelPath) : x || "";

  return {
    value: String(value),
    label: labelTransform?.(String(label)) || String(label)
  };
};

export const mapAnyToFilterItems = <T extends object | string>(
  arr: T[] = [],
  valuePath?: string,
  labelPath?: string,
  labelTransform?: (value: string) => string
): FilterItem[] => arr.map((x) => mapAnyToFilterItem(x, valuePath, labelPath, labelTransform));

export const mapMerchantToFilterItem = (merchantDto: OutMerchantDTO, showPlatform?: boolean): FilterItem => {
  const label =
    !!showPlatform && merchantDto.platform ? `${merchantDto.name} (${merchantDto.platform.name})` : merchantDto.name;

  const result: FilterItem = {
    value: merchantDto.id.toString(),
    label
  };
  return result;
};

export const mapExternalDealFilterToFilterItem = (externalDealFilter: ExternalDealFilterMvoDTO): FilterItem => {
  const id = `(${externalDealFilter.id})`;
  const label = [externalDealFilter.title, id].filter(Boolean).join(" - ");

  const result: FilterItem = {
    value: externalDealFilter.id.toString(),
    label
  };
  return result;
};
