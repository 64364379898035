import React from "react";
import clsx from "clsx";

import styles from "./select-component.module.scss";
import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";

export interface ISelectComponentProps {
  autoWidth?: boolean;
  disabled?: boolean;
  height?: number;
  icon?: string;
  iconSize?: string;
  id?: string;
  label?: IFormLabelComponentProps;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { id: number; name: string }[];
  value?: any;
  width?: number;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
}

const SelectComponent = (props: ISelectComponentProps) => {
  const selectedValue = typeof props.value !== "undefined" && props.value !== null ? props.value : undefined;
  const firstOption = props.options && props.options.find((item) => item.id === 0);
  const maxWidth = firstOption ? firstOption.name.length : 15;

  return (
    <div>
      <div>
        {props.label && <FormLabelComponent {...props.label} />}
        <select
          style={{
            height: props.height ? props.height : 40,
            width: props.autoWidth ? `${maxWidth * 10}px` : "100%"
          }}
          disabled={props.disabled}
          id={props.id}
          className={clsx(styles.select, props.disabled && styles.disabled)}
          aria-label="Select"
          onChange={props.onChange}
          value={selectedValue}
          onBlur={props.onBlur}
        >
          {props.options.map((value, index: number) => (
            <option aria-selected value={value.id} key={index}>
              {value.name}
            </option>
          ))}
        </select>
      </div>
      {props.label?.errorMessage && <span className={styles.errorMessage}>{props.label?.errorMessage}</span>}
    </div>
  );
};

export { SelectComponent };
