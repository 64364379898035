import React from "react";
import { useIntl } from "react-intl";

import { FormItem as FormItemCore, IFormItemProps as IFormItemPropsCore } from "@webbio/components";

import { Icon } from "../icon-webbio/icon";
import labelStyles from "../form-label-webbio/form-label.module.scss";
import styles from "./form-item.module.scss";

export interface IFormItemProps extends IFormItemPropsCore {}

const FormItem = (props: IFormItemProps): JSX.Element => {
  const intl = useIntl();

  const textOptional = intl.formatMessage({
    id: "formLabel.optional"
  });

  return (
    <FormItemCore
      {...props}
      propStyles={styles}
      labelStyles={labelStyles}
      tooltipHoverItem={<Icon reactIcon="MdInfoOutline" size="14px" />}
      customIndicatorText={
        props.customIndicatorText ? props.customIndicatorText : props.showOptionalDefault ? textOptional : undefined
      }
    />
  );
};

export { FormItem };
