/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GenericIdDTO,
  GenericIdDTOFromJSON,
  GenericIdDTOFromJSONTyped,
  GenericIdDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface DuplicateExternalDealFiltersDTO
 */
export interface DuplicateExternalDealFiltersDTO {
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof DuplicateExternalDealFiltersDTO
   */
  filterIds: Array<GenericIdDTO>;
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof DuplicateExternalDealFiltersDTO
   */
  merchants: Array<GenericIdDTO>;
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof DuplicateExternalDealFiltersDTO
   */
  categories?: Array<GenericIdDTO>;
  /**
   *
   * @type {PlatformDTO}
   * @memberof DuplicateExternalDealFiltersDTO
   */
  platform: PlatformDTO;
}

export function DuplicateExternalDealFiltersDTOFromJSON(json: any): DuplicateExternalDealFiltersDTO {
  return DuplicateExternalDealFiltersDTOFromJSONTyped(json, false);
}

export function DuplicateExternalDealFiltersDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DuplicateExternalDealFiltersDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    filterIds: (json["filterIds"] as Array<any>).map(GenericIdDTOFromJSON),
    merchants: (json["merchants"] as Array<any>).map(GenericIdDTOFromJSON),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(GenericIdDTOFromJSON),
    platform: PlatformDTOFromJSON(json["platform"])
  };
}

export function DuplicateExternalDealFiltersDTOToJSON(value?: DuplicateExternalDealFiltersDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    filterIds: (value.filterIds as Array<any>).map(GenericIdDTOToJSON),
    merchants: (value.merchants as Array<any>).map(GenericIdDTOToJSON),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(GenericIdDTOToJSON),
    platform: PlatformDTOToJSON(value.platform)
  };
}
