import { STYLES } from "@app/styles/style-variables";
import { LightenDarkenColor } from "@app/util/lighten-darken-color";
import { StylesConfig } from "react-select";

type MyOptionType = {
  label: string;
  value: string;
};

type IsMulti = boolean;

export const getReactSelectCustomStyles = (
  inputHasMaxHeight?: boolean,
  hasError?: boolean
): StylesConfig<MyOptionType, IsMulti> => ({
  loadingIndicator: (provided) => ({
    ...provided,
    ">span": {
      backgroundColor: STYLES.COLOR_TINT_GREY_80
    }
  }),
  loadingMessage: (provided) => ({
    ...provided,
    color: STYLES.FONT_COLOR_FADED
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: STYLES.FONT_COLOR_FADED
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? STYLES.FONT_COLOR_FADED : STYLES.FONT_COLOR_MAIN
  }),
  placeholder: (provided) => ({
    ...provided,
    color: STYLES.FONT_COLOR_PLACEHOLDER
  }),
  menu: (provided) => ({
    ...provided,
    border: STYLES.GLOBAL_BORDER_STYLE,
    boxShadow: "none",
    borderRadius: STYLES.GLOBAL_BORDER_RADIUS,
    color: STYLES.FONT_COLOR_MAIN
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? STYLES.COLOR_BACKGROUND : "transparent",
    fontSize: "14px",
    color: isFocused ? STYLES.FONT_COLOR_MAIN : STYLES.FONT_COLOR_FADED,
    ":active": {
      backgroundColor: LightenDarkenColor(STYLES.COLOR_BACKGROUND, -10)
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: inputHasMaxHeight ? "38px" : "auto",
    overflow: inputHasMaxHeight ? "auto" : "hidden"
  }),
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? STYLES.COLOR_TINT_GREY_89 : provided.backgroundColor,
    color: isDisabled ? STYLES.FONT_COLOR_FADED : STYLES.FONT_COLOR_MAIN,
    minHeight: "40px",
    borderRadius: STYLES.GLOBAL_BORDER_RADIUS,
    fontSize: "15px",
    borderColor: hasError
      ? STYLES.COLOR_INFO_DANGER
      : isFocused
      ? STYLES.FONT_COLOR_MAIN
      : STYLES.GLOBAL_BORDER_DARK_COLOR,
    boxShadow: "none",
    ":hover": {
      borderColor: isFocused ? STYLES.FONT_COLOR_MAIN : STYLES.FONT_COLOR_PLACEHOLDER
    }
  }),
  clearIndicator: (provided, { isFocused }) => ({
    ...provided,
    color: isFocused ? "#b9b9b9" : "#e6e6e6",
    ":hover": {
      color: STYLES.FONT_COLOR_MAIN
    }
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    background: STYLES.GLOBAL_BORDER_DARK_COLOR
  }),
  dropdownIndicator: (provided, { isFocused }) => ({
    ...provided,
    color: isFocused ? "#b9b9b9" : "#e6e6e6",
    ":hover": {
      color: STYLES.FONT_COLOR_MAIN
    }
  }),
  multiValue: (styles) => ({
    ...styles,
    background: LightenDarkenColor("#f0f0f0", -3),
    borderRadius: "50px"
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: "12px",
    color: STYLES.FONT_COLOR_MAIN
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#777777",
    borderRadius: "0 50px 50px 0",
    ":hover": {
      backgroundColor: LightenDarkenColor("#f0f0f0", -20),
      color: STYLES.FONT_COLOR_MAIN
    }
  })
});
