import { MutableRefObject, useEffect, useRef } from "react";

// This hook can be useful if state has to be passed down to callback functions
export const useRefState = <T>(initialValue: T): MutableRefObject<T> => {
  const ref = useRef(initialValue);

  useEffect(() => {
    ref.current = initialValue;
  }, [initialValue]);

  return ref;
};
