import clsx from "clsx";
import React from "react";

import styles from "./form-field.module.scss";

export interface IFormFieldProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

const FormField = ({ children, className, ...rest }: IFormFieldProps): JSX.Element => {
  return (
    <div className={clsx(styles.formField, className)} {...rest}>
      {children}
    </div>
  );
};

export { FormField };
