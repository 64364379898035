import { IFilterType } from "@app/constants/filter";
import { platformType } from "@app/constants/platform";

export class Merchant {
  public buyInPosition?: number;
  public campaigns?: ICampaignType[];
  public eCPC?: number;
  public editStatus: editStatusType;
  public features?: string;
  public filters?: IFilter[];
  public headerImage?: {
    alt?: string;
    title?: string;
    url?: string;
  };
  public id: string;
  public inOverview: boolean;
  public logoImage?: {
    alt?: string;
    title?: string;
    url?: string;
  };
  public name: string;
  public platform: platformType;
  public seo?: {
    alternateHTML?: string;
    description?: string;
    pageText?: string;
    title?: string;
  };
  public status: storeStatusType;
  public trackingUrl?: string;
  public url?: string;
}

export enum storeStatusType {
  "live" = 1,
  "offline" = 2
}

export type storeStatusType2 = {
  id: number;
  name: string;
};

export interface ICampaignType {
  couponCode?: string;
  description?: string;
  endDate?: string | null;
  excerpt?: string;
  featuredFilter?: IFilter | null;
  id?: number;
  isFeatured: boolean;
  name: string;
  startDate?: string | null;
  startDatePublish?: string | null;
  startDateStatusShow?: string | null;
  status?: ICampaignStatus;
  trackingUrl?: string;
  url?: string;
}

export type IFilter = {
  id: number;
  name: string;
  parent?: IFilter;
  type?: IFilterType;
  usageByMerchant?: string;
};

export type ICampaignStatus = {
  name: "UNKNOWN" | "ABOUT_TO_START" | "ONGOING" | "ABOUT_TO_EXPIRE" | "EXPIRED";
  percentage: number;
  startInAmountOfDays: number;
};

export const storeStatus: storeStatusType2[] = [
  { id: 1, name: "global.status.live" },
  { id: 2, name: "global.status.offline" }
];

export enum editStatusType {
  "Gepubliceerd" = 1,
  "Bewerkt door winkeleigenaar" = 2
}

export const editStatus: storeStatusType2[] = [
  { id: 1, name: "global.editStatus.published" },
  { id: 2, name: "global.editStatus.merchant" }
];
