import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { Tooltip } from "../tooltip/tooltip.component";
import { ResourceTextComponent } from "..";
import styles from "./form-label-component.module.scss";

export interface IFormLabelComponentProps {
  className?: string;
  errorMessage?: string | false;
  isOptionalField?: boolean;
  label?: string;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
}

export type TooltipPosition = "end" | "start";

const FormLabelComponent = (props: IFormLabelComponentProps) => {
  const [justifyContentStyle, setJustifyContentStyle] = useState<string>("");

  useEffect(() => {
    setJustifyContentStyle(props.tooltipPosition === "end" || !props.tooltipPosition ? "space-between" : "start");
  }, [props.tooltipPosition]);

  if (!props.tooltip && !props.label && !props.isOptionalField) {
    return <></>;
  }

  return (
    <div className={clsx(styles.topBar, props.className)} style={{ justifyContent: justifyContentStyle }}>
      {props.label && (
        <div className={clsx(styles.label, props.errorMessage && styles.errorMessage)}>
          {props.label}
          {props.isOptionalField && (
            <span className={styles.isOptional}>
              <ResourceTextComponent resourceKey="formLabel.optional" />
            </span>
          )}
        </div>
      )}
      {props.tooltip && (
        <div className={styles.tooltip}>
          <Tooltip tooltip={props.tooltip} />
        </div>
      )}
    </div>
  );
};

export { FormLabelComponent };
