export const dateFormatList = (initDateFormatOption: string) => [
  initDateFormatOption,
  "dd MM yyyy - HH:mm",
  "dd MMM yyyy - HH:mm",
  "dd MMMM yyyy - HH:mm",
  "dd - HH:mm",
  "dd MM - HH:mm",
  "dd MMM - HH:mm",
  "dd MMMM - HH:mm",
  "dd-MM-yyyy - HH:mm",
  "dd-MMM-yyyy - HH:mm",
  "dd-MMMM-yyyy - HH:mm",
  "dd-MM - HH:mm",
  "dd-MMM - HH:mm",
  "dd-MMMM - HH:mm",
  "dd/MM/yyyy - HH:mm",
  "dd/MMM/yyyy - HH:mm",
  "dd/MMMM/yyyy - HH:mm",
  "dd/MM - HH:mm",
  "dd/MMM - HH:mm",
  "dd/MMMM - HH:mm",
  "dd MM yy - HH:mm",
  "dd MMM yy - HH:mm",
  "dd MMMM yy - HH:mm",
  "dd-MM-yy - HH:mm",
  "dd-MMM-yy - HH:mm",
  "dd-MMMM-yy - HH:mm",
  "dd/MM/yy - HH:mm",
  "dd/MMM/yy - HH:mm",
  "dd/MMMM/yy - HH:mm",
  "dd MM yyyy - HHmm",
  "dd MMM yyyy - HHmm",
  "dd MMMM yyyy - HHmm",
  "dd - HHmm",
  "dd MM - HHmm",
  "dd MMM - HHmm",
  "dd MMMM - HHmm",
  "dd-MM-yyyy - HHmm",
  "dd-MMM-yyyy - HHmm",
  "dd-MMMM-yyyy - HHmm",
  "dd-MM - HHmm",
  "dd-MMM - HHmm",
  "dd-MMMM - HHmm",
  "dd/MM/yyyy - HHmm",
  "dd/MMM/yyyy - HHmm",
  "dd/MMMM/yyyy - HHmm",
  "dd/MM - HHmm",
  "dd/MMM - HHmm",
  "dd/MMMM - HHmm",
  "dd MM yy - HHmm",
  "dd MMM yy - HHmm",
  "dd MMMM yy - HHmm",
  "dd-MM-yy - HHmm",
  "dd-MMM-yy - HHmm",
  "dd-MMMM-yy - HHmm",
  "dd/MM/yy - HHmm",
  "dd/MMM/yy - HHmm",
  "dd/MMMM/yy - HHmm",
  "dd MM yyyy HHmm",
  "dd MMM yyyy HHmm",
  "dd MMMM yyyy HHmm",
  "dd HHmm",
  "dd MM HHmm",
  "dd MMM HHmm",
  "dd MMMM HHmm",
  "dd-MM-yyyy HHmm",
  "dd-MMM-yyyy HHmm",
  "dd-MMMM-yyyy HHmm",
  "dd-MM HHmm",
  "dd-MMM HHmm",
  "dd-MMMM HHmm",
  "dd/MM/yyyy HHmm",
  "dd/MMM/yyyy HHmm",
  "dd/MMMM/yyyy HHmm",
  "dd/MM HHmm",
  "dd/MMM HHmm",
  "dd/MMMM HHmm",
  "dd MM yy HHmm",
  "dd MMM yy HHmm",
  "dd MMMM yy HHmm",
  "dd-MM-yy HHmm",
  "dd-MMM-yy HHmm",
  "dd-MMMM-yy HHmm",
  "dd/MM/yy HHmm",
  "dd/MMM/yy HHmm",
  "dd/MMMM/yy HHmm",
  "dd MM yyyy HH:mm",
  "dd MMM yyyy HH:mm",
  "dd MMMM yyyy HH:mm",
  "dd HH:mm",
  "dd MM HH:mm",
  "dd MMM HH:mm",
  "dd MMMM HH:mm",
  "dd-MM-yyyy HH:mm",
  "dd-MMM-yyyy HH:mm",
  "dd-MMMM-yyyy HH:mm",
  "dd-MM HH:mm",
  "dd-MMM HH:mm",
  "dd-MMMM HH:mm",
  "dd/MM/yyyy HH:mm",
  "dd/MMM/yyyy HH:mm",
  "dd/MMMM/yyyy HH:mm",
  "dd/MM HH:mm",
  "dd/MMM HH:mm",
  "dd/MMMM HH:mm",
  "dd MM yy HH:mm",
  "dd MMM yy HH:mm",
  "dd MMMM yy HH:mm",
  "dd-MM-yy HH:mm",
  "dd-MMM-yy HH:mm",
  "dd-MMMM-yy HH:mm",
  "dd/MM/yy HH:mm",
  "dd/MMM/yy HH:mm",
  "dd/MMMM/yy HH:mm",
  "dd MM yyyy",
  "dd MMM yyyy",
  "dd MMMM yyyy ",
  "dd",
  "dd MM",
  "dd MMM",
  "dd MMMM",
  "dd-MM-yyyy",
  "dd-MMM-yyyy",
  "dd-MMMM-yyyy",
  "dd-MM",
  "dd-MMM",
  "dd-MMMM",
  "dd/MM/yyyy",
  "dd/MMM/yyyy",
  "dd/MMMM/yyyy",
  "dd/MM",
  "dd/MMM",
  "dd/MMMM",
  "dd MM yy",
  "dd MMM yy",
  "dd MMMM yy",
  "dd-MM-yy",
  "dd-MMM-yy",
  "dd-MMMM-yy",
  "dd/MM/yy",
  "dd/MMM/yy",
  "dd/MMMM/yy"
];
