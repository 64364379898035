import { toast } from "react-toastify";

import { handleError } from "@app/util/handle-error";
import {
  OutDealDTO,
  DealsApi,
  CreateDealDTO,
  InDealDTO,
  DealArrayDTO,
  DealsExtendedGetCreatedByEnum,
  CreateDealDTOCreatedByEnum,
  ContainsIdDTO
} from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";
import {
  setSelectedDeal,
  setDeals,
  setIsLoadingDeals,
  deleteDealFromList,
  setTotalResults
} from "@app/redux/reducers/deals";
import { FilterItem } from "@app/api/core/filter/filter-item";

import { mapNumberToBooleanString } from "./merchants.thunk";

export interface IDealResult {
  deals: OutDealDTO[];
  totalResults: number;
}

const getDeals =
  (
    skip: number,
    take: number,
    merchantFilters?: FilterItem,
    platformId?: number,
    searchQuery?: string,
    live?: number,
    createdBy?: number
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new DealsApi(await getAuthorizedApiConfig());
      dispatch(setIsLoadingDeals(true));

      const merchantFilter = merchantFilters?.value ? merchantFilters.value.toString() : undefined;

      const resultFromApi = await api.dealsExtendedGet(
        take,
        skip,
        undefined,
        undefined,
        merchantFilter,
        searchQuery,
        undefined,
        mapNumberToCreatedByEnum(createdBy),
        platformId,
        mapNumberToBooleanString(live),
        undefined
      );

      dispatch(setDeals(resultFromApi.deals || []));
      dispatch(setTotalResults(resultFromApi.total));
      dispatch(setIsLoadingDeals(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get deals");
    }
  };

const mapNumberToCreatedByEnum = (selectValue?: number) => {
  switch (selectValue) {
    case 0:
      return undefined;
    case 1:
      return DealsExtendedGetCreatedByEnum.API;
    case 2:
      return DealsExtendedGetCreatedByEnum.DASHBOARD;
    case 3:
      return DealsExtendedGetCreatedByEnum.UNKNOWN;
    default:
      return undefined;
  }
};

const getDeal =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const dealApi = new DealsApi(await getAuthorizedApiConfig());

      const deal = await dealApi.dealsDealIdGet(id);

      dispatch(setSelectedDeal(deal));
    } catch (error) {
      handleError(error, dispatch, "Failed to get deal");
    }
  };

const deleteDeal =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const dealApi = new DealsApi(await getAuthorizedApiConfig());

      const deal = await dealApi.dealsDealIdDelete(id);

      if (deal) {
        dispatch(deleteDealFromList(id));
      }
      toast.success("Deal is verwijderd");
    } catch (error) {
      handleError(error, dispatch, "Failed to delete deal");
      // new FatalError(error.name, error.message, error.stack);
    }
  };

const createNewDeal =
  (
    platformId: number,
    name: string,
    ean: string,
    merchant: ContainsIdDTO,
    live: boolean,
    createdBy: CreateDealDTOCreatedByEnum
  ): AppThunk =>
  async (dispatch): Promise<OutDealDTO | undefined> => {
    try {
      const newDeal: CreateDealDTO = {
        name,
        platform: { id: platformId, name: "", googleExperimentId: "", wctPlatformId: 0 },
        ean,
        merchant,
        live,
        createdBy
      };

      const dealApi = new DealsApi(await getAuthorizedApiConfig());
      const createdDeal = await dealApi.dealsPost(newDeal);

      dispatch(setSelectedDeal(createdDeal));

      return createdDeal;
    } catch (error) {
      if ((error as any)?.status === 422) {
        handleError(error, dispatch, "Winkel met deze naam bestaat al voor dit Platform.");
      } else {
        handleError(error, dispatch, "Failed to create new merchant");
      }
    }

    return undefined;
  };

const saveDeal =
  (deal: InDealDTO, customMessage?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const merchantApi = new DealsApi(await getAuthorizedApiConfig());
      const savedDeal: any = await merchantApi.dealsDealIdPut(deal.id, deal);

      dispatch(setSelectedDeal(savedDeal));

      if (customMessage) {
        toast.success(customMessage);
      } else {
        toast.success("Deal is opgeslagen");
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to save deal");
    }
  };

const deleteBulkDeals =
  (deals: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const merchantApi = new DealsApi(await getAuthorizedApiConfig());

      if (deals) {
        await merchantApi.dealsBulkDelete(deals);
        toast.success("De geselecteerde deals zijn verwijderd");
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to delete deals");
      // new FatalError(error.name, error.message, error.stack);
    }
  };

const editBulkDeals =
  (deals: DealArrayDTO): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const merchantApi = new DealsApi(await getAuthorizedApiConfig());

      if (deals) {
        await merchantApi.dealsBulkPatch(deals);
        toast.success("De geselecteerde deals zijn gewijzigd");
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to edit deals");
      // new FatalError(error.name, error.message, error.stack);
    }
  };

export const dealsThunks = {
  getDeals,
  getDeal,
  deleteDeal,
  createNewDeal,
  saveDeal,
  deleteBulkDeals,
  editBulkDeals
};
