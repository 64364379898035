import React, { useState, useEffect } from "react";
import { IntlShape, useIntl } from "react-intl";

import Plus from "@assets/icons/plus.svg";
import Search from "@assets/icons/search.svg";

import { useDebounce } from "@app/util/use-debounce";
import { ClickableComponent, TextFieldComponent } from "@app/core";
import { ModalComponent } from "@app/core/modal";
import { SelectComponent } from "@app/core/select";
import { FilterTypeDTO } from "@app/api/generated";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { setCurrentSearch, setSelectedFilterType } from "@app/redux/reducers/filters";

import styles from "./filter-filter-bar-component.module.scss";
import { FilterModal } from "../filter-modal";

const FilterFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentSearch, filterTypes, selectedFilterType } = useAppSelector((state) => state.filters);

  const translatedFilterTypes = getTranslatedFilterTypes(intl, filterTypes);
  const [search, setSearch] = useState<string>(currentSearch || "");

  const [isAddFilterOpen, setIsAddFilterOpen] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onTypeChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);

    dispatch(setSelectedFilterType(value || undefined));
  };

  return (
    <div className={styles.storeFilterBar}>
      <span className={styles.title}>
        <h1>{intl.formatMessage({ id: "filterFilterBar.title" })}</h1>
      </span>

      <div className={styles.push}>
        <div className={styles.searchField}>
          <TextFieldComponent
            height={40}
            placeholder={intl.formatMessage({ id: "filterFilterBar.input.search.placeholder" })}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            id="search"
            icon={Search}
            width={318}
          />
        </div>

        <div className={styles.selectDiv}>
          <SelectComponent onChange={onTypeChange} value={selectedFilterType} options={translatedFilterTypes} />
        </div>

        <span className={styles.addMerchant}>
          <ClickableComponent
            title={intl.formatMessage({ id: "filterFilterBar.button.add" })}
            height={40}
            icon={Plus}
            iconSize="20px"
            iconFillColor="#ffffff"
            iconPosition="left"
            onClick={() => setIsAddFilterOpen(true)}
          />
        </span>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "filterFilterBar.modal.addFilter.title" })}
        isModalOpen={isAddFilterOpen}
        onCloseModal={() => setIsAddFilterOpen(false)}
        variant="big"
      >
        <FilterModal onCancel={() => setIsAddFilterOpen(false)} onSaveDone={() => setIsAddFilterOpen(false)} />
      </ModalComponent>
    </div>
  );
};

export { FilterFilterBar };

const getTranslatedFilterTypes = (intl: IntlShape, types?: FilterTypeDTO[]): FilterTypeDTO[] => {
  const translatedFilterTypes: FilterTypeDTO[] = [
    { id: 0, name: intl.formatMessage({ id: "filterFilterBar.input.filterTypes.all" }) }
  ];

  types?.forEach((type: FilterTypeDTO) => {
    const translatedType = getTranslatedFilterType(intl, type);
    if (translatedType) {
      translatedFilterTypes.push(translatedType);
    }
  });

  return translatedFilterTypes;
};

export const getTranslatedFilterType = (intl: IntlShape, type?: FilterTypeDTO): FilterTypeDTO | undefined => {
  switch (type?.name) {
    case "category":
      return {
        id: type.id,
        name: intl.formatMessage({ id: "filterOverview.selectFilterTypeCategory" })
      };

    case "brand":
      return {
        id: type.id,
        name: intl.formatMessage({ id: "filterOverview.selectFilterTypeBrand" })
      };

    case "label":
      return {
        id: type.id,
        name: intl.formatMessage({ id: "filterOverview.selectFilterTypeLabel" })
      };
    default:
      return undefined;
  }
};
