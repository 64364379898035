import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { AuthorizationContainer } from "@app/modules";
import { login } from "@app/authorization/authorization-api";
import { useAppDispatch } from "@app/redux/store";
import { setEmailAddress } from "@app/redux/reducers/authorization";
import { ROUTES } from "@app/constants/routes";

import { LoginFormComponent, ILoginFormValues } from "./login.form.component";
import styles from "./login-component.module.scss";

const Login = () => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [showError, setShowError] = useState<boolean>(false);

  const navigateToRequestPassword = (emailAddress: string) => {
    dispatch(setEmailAddress(emailAddress));
    history(ROUTES.REQUEST_PASSWORD);
  };

  return (
    <AuthorizationContainer>
      <div className={styles.loginHeader}>
        <h1>{intl.formatMessage({ id: "login.headerText" })}</h1>
      </div>
      {showError && <div className={styles.error}>{intl.formatMessage({ id: "login.errorText" })}</div>}
      <LoginFormComponent
        intl={intl}
        onSubmit={async (values: ILoginFormValues) => {
          const loginSucceeded = await login(values.emailAddress, values.password);

          if (loginSucceeded) {
            history(ROUTES.STORE_OVERVIEW);
          } else {
            setShowError(true);
          }
        }}
        onNavigateToRequestPassword={navigateToRequestPassword}
      />
    </AuthorizationContainer>
  );
};

export { Login };
