import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";
import styles from "./modal-dialog-component.module.scss";
import { Role, ModalVariant } from ".";

export interface IModalDialogComponentProps {
  ariaLabel: string;
  background: string;
  children: JSX.Element;
  modalRef: React.RefObject<HTMLDivElement>;
  onClickAway: (e: any) => void;
  onClose: () => void;
  onKeyDown: ({ key }: KeyboardEvent) => void;
  role: Role;
  title?: string;
  variant: ModalVariant;
  width?: number;
}

const ModalDialogComponent = (props: IModalDialogComponentProps) => {
  useEffect(() => {
    window.addEventListener("keydown", props.onKeyDown);
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "auto";
      if (!!window) {
        window.removeEventListener("keydown", props.onKeyDown);
      }
    };
  }, []);

  const modalSize = getModalSize(props.variant);
  const maxHeight = props.variant === "full-screen" ? { maxHeight: "initial" } : "";
  const padding = props.variant === "full-screen" ? { padding: "0" } : "";

  if (typeof document !== "undefined") {
    return ReactDOM.createPortal(
      <FocusLock>
        <div
          className={styles["c-modal-cover"]}
          role={props.role}
          aria-label={props.ariaLabel}
          aria-modal="true"
          tabIndex={0}
          onMouseDown={(e): any => {
            props.onClickAway(e);
          }}
        >
          {props.variant === "full-screen" && (
            <button
              className={`${styles["c-modal__close"]} ${styles.closeBackground}`}
              aria-labelledby="close-modal"
              onClick={props.onClose}
              type="button"
            >
              <svg id="close-modal" className={styles["c-modal__close-icon"]} viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </svg>
            </button>
          )}
          <div
            className={styles["c-modal"]}
            style={{
              background: props.background,
              width: modalSize,
              ...maxHeight,
              overflow: "initial"
            }}
            ref={props.modalRef}
          >
            {props.variant !== "full-screen" && (
              <div role="button" className={styles.closeBackground} onClick={props.onClose}>
                <button type="button" className={styles["c-modal__close"]} aria-labelledby="close-modal">
                  <svg id="close-modal" className={styles["c-modal__close-icon"]} viewBox="0 0 40 40">
                    <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
                  </svg>
                </button>
              </div>
            )}

            <div className={styles["c-modal__body"]} style={{ ...padding }}>
              {props.title && <h2>{props.title}</h2>}
              <div className={styles.modalContent}>{props.children ? props.children : ""}</div>
            </div>
          </div>
        </div>
      </FocusLock>,
      document.body
    );
  }

  return null;
};

export { ModalDialogComponent };

const getModalSize = (variant: ModalVariant): string => {
  switch (variant) {
    case "small":
      return "600px";
    case "big":
      return "50%";
    case "full-screen":
      return "80%";
    default:
      return "600px";
  }
};
