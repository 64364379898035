import { toast } from "react-toastify";

import { FilterTypesApi, FilterTypeDTO, OutFilterDTO, FiltersApi, FilterLabelDTO } from "@app/api/generated";
import { handleError } from "@app/util/handle-error";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";
import {
  deleteFilterFromList,
  setFilters,
  setFilterTypes,
  setIsLoadingFilters,
  setTotalResults
} from "@app/redux/reducers/filters";

export interface IFilterTypeResult {
  filterTypes: FilterTypeDTO[];
}

export interface IFiltersResult {
  filters: OutFilterDTO[];
  totalResults: number;
}

const getFilters =
  (skip: number, take: number, searchQuery?: string, typeIds?: string, exactSearch?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setIsLoadingFilters(true));
      const filtersApi = new FiltersApi(await getAuthorizedApiConfig());

      const filtersResponse = await filtersApi.filtersExtendedGet(
        skip,
        take,
        String(Boolean(exactSearch)),
        searchQuery,
        typeIds
      );

      dispatch(setFilters(filtersResponse.filters || []));
      dispatch(setTotalResults(filtersResponse.total));
      dispatch(setIsLoadingFilters(false));
    } catch (error) {
      handleError(error, dispatch, "Failed retreiving filters.");
    }
  };

const getFilterTypes =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const filterTypesApi = new FilterTypesApi(await getAuthorizedApiConfig());

      const filterTypes = await filterTypesApi.filterTypesGet();

      dispatch(setFilterTypes(filterTypes));
    } catch (error) {
      handleError(error, dispatch, "Failed retreiving filter types.");
    }
  };

const deleteFilter =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const filterApi = new FiltersApi(await getAuthorizedApiConfig());
      const filter = await filterApi.filtersFilterIdDelete(id);

      if (filter) {
        dispatch(deleteFilterFromList(id));
      }
    } catch (error) {
      handleError(error, dispatch, "Failed deleting filter.");
    }
  };

const createFilter =
  (name: string, filterType: any, label?: FilterLabelDTO): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const filterApi = new FiltersApi(await getAuthorizedApiConfig());
      await filterApi.filtersPost({
        name,
        type: {
          id: Number(filterType),
          name: "name"
        },
        label
      });
      toast.success("Filter succesvol aangemaakt");
    } catch (error) {
      handleError(error, dispatch, "Failed creating filter.");
    }
  };

const saveFilter =
  (id: number, name: string, filterType: any, label?: FilterLabelDTO): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const filterApi = new FiltersApi(await getAuthorizedApiConfig());
      await filterApi.filtersFilterIdPut(id, {
        id,
        name,
        type: {
          id: Number(filterType),
          name: "name"
        },
        label
      });
      toast.success("Filter succesvol opgeslagen");
    } catch (error) {
      handleError(error, dispatch, "Failed creating filter.");
    }
  };

export const filterThunks = {
  getFilterTypes,
  getFilters,
  deleteFilter,
  createFilter,
  saveFilter
};
