/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InCreateMerchantDTO,
  InCreateMerchantDTOFromJSON,
  InCreateMerchantDTOToJSON,
  InDetailedMerchantDTO,
  InDetailedMerchantDTOFromJSON,
  InDetailedMerchantDTOToJSON,
  MerchantListResponseDTO,
  MerchantListResponseDTOFromJSON,
  MerchantListResponseDTOToJSON,
  OutDetailedMerchantDTO,
  OutDetailedMerchantDTOFromJSON,
  OutDetailedMerchantDTOToJSON
} from "../models";

export interface MerchantsBulkGetRequest {
  platformId: number;
  labelFilter?: string;
  ids?: string;
}

export interface MerchantsCountGetRequest {
  platformId: number;
  status?: MerchantsCountGetStatusEnum;
  filters?: string;
}

export interface MerchantsExtendedGetRequest {
  take: number;
  skip: number;
  searchQuery?: string;
  editFilter?: MerchantsExtendedGetEditFilterEnum;
  approved?: string;
  live?: string;
  platformId?: number;
}

export interface MerchantsGetRequest {
  take: number;
  skip: number;
  platformId: number;
  status?: MerchantsGetStatusEnum;
  filters?: string;
}

export interface MerchantsMerchantIdDeleteRequest {
  merchantId: number;
}

export interface MerchantsMerchantIdGetRequest {
  merchantId: number;
}

export interface MerchantsMerchantIdPutRequest {
  merchantId: number;
  inDetailedMerchantDTO: InDetailedMerchantDTO;
}

export interface MerchantsPostRequest {
  inCreateMerchantDTO: InCreateMerchantDTO;
}

export interface MerchantsSlugMerchantSlugGetRequest {
  platformId: number;
  merchantSlug: string;
}

/**
 * no description
 */
export class MerchantsApi extends runtime.BaseAPI {
  /**
   */
  async merchantsBulkGetRaw(
    requestParameters: MerchantsBulkGetRequest
  ): Promise<runtime.ApiResponse<MerchantListResponseDTO>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling merchantsBulkGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.labelFilter !== undefined) {
      queryParameters["labelFilter"] = requestParameters.labelFilter;
    }

    if (requestParameters.ids !== undefined) {
      queryParameters["ids"] = requestParameters.ids;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/merchants/bulk`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async merchantsBulkGet(platformId: number, labelFilter?: string, ids?: string): Promise<MerchantListResponseDTO> {
    const response = await this.merchantsBulkGetRaw({ platformId: platformId, labelFilter: labelFilter, ids: ids });
    return await response.value();
  }

  /**
   */
  async merchantsCountGetRaw(requestParameters: MerchantsCountGetRequest): Promise<runtime.ApiResponse<number>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling merchantsCountGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/merchants/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async merchantsCountGet(platformId: number, status?: MerchantsCountGetStatusEnum, filters?: string): Promise<number> {
    const response = await this.merchantsCountGetRaw({ platformId: platformId, status: status, filters: filters });
    return await response.value();
  }

  /**
   */
  async merchantsExtendedGetRaw(
    requestParameters: MerchantsExtendedGetRequest
  ): Promise<runtime.ApiResponse<MerchantListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling merchantsExtendedGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling merchantsExtendedGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.editFilter !== undefined) {
      queryParameters["editFilter"] = requestParameters.editFilter;
    }

    if (requestParameters.approved !== undefined) {
      queryParameters["approved"] = requestParameters.approved;
    }

    if (requestParameters.live !== undefined) {
      queryParameters["live"] = requestParameters.live;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/merchants/extended`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async merchantsExtendedGet(
    take: number,
    skip: number,
    searchQuery?: string,
    editFilter?: MerchantsExtendedGetEditFilterEnum,
    approved?: string,
    live?: string,
    platformId?: number
  ): Promise<MerchantListResponseDTO> {
    const response = await this.merchantsExtendedGetRaw({
      take: take,
      skip: skip,
      searchQuery: searchQuery,
      editFilter: editFilter,
      approved: approved,
      live: live,
      platformId: platformId
    });
    return await response.value();
  }

  /**
   */
  async merchantsGetRaw(requestParameters: MerchantsGetRequest): Promise<runtime.ApiResponse<MerchantListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling merchantsGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling merchantsGet."
      );
    }

    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling merchantsGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/merchants`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async merchantsGet(
    take: number,
    skip: number,
    platformId: number,
    status?: MerchantsGetStatusEnum,
    filters?: string
  ): Promise<MerchantListResponseDTO> {
    const response = await this.merchantsGetRaw({
      take: take,
      skip: skip,
      platformId: platformId,
      status: status,
      filters: filters
    });
    return await response.value();
  }

  /**
   */
  async merchantsMerchantIdDeleteRaw(
    requestParameters: MerchantsMerchantIdDeleteRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
      throw new runtime.RequiredError(
        "merchantId",
        "Required parameter requestParameters.merchantId was null or undefined when calling merchantsMerchantIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/merchants/{merchantId}`.replace(
        `{${"merchantId"}}`,
        encodeURIComponent(String(requestParameters.merchantId))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async merchantsMerchantIdDelete(merchantId: number): Promise<boolean> {
    const response = await this.merchantsMerchantIdDeleteRaw({ merchantId: merchantId });
    return await response.value();
  }

  /**
   */
  async merchantsMerchantIdGetRaw(
    requestParameters: MerchantsMerchantIdGetRequest
  ): Promise<runtime.ApiResponse<OutDetailedMerchantDTO>> {
    if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
      throw new runtime.RequiredError(
        "merchantId",
        "Required parameter requestParameters.merchantId was null or undefined when calling merchantsMerchantIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/merchants/{merchantId}`.replace(
        `{${"merchantId"}}`,
        encodeURIComponent(String(requestParameters.merchantId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDetailedMerchantDTOFromJSON(jsonValue));
  }

  /**
   */
  async merchantsMerchantIdGet(merchantId: number): Promise<OutDetailedMerchantDTO> {
    const response = await this.merchantsMerchantIdGetRaw({ merchantId: merchantId });
    return await response.value();
  }

  /**
   */
  async merchantsMerchantIdPutRaw(
    requestParameters: MerchantsMerchantIdPutRequest
  ): Promise<runtime.ApiResponse<OutDetailedMerchantDTO>> {
    if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
      throw new runtime.RequiredError(
        "merchantId",
        "Required parameter requestParameters.merchantId was null or undefined when calling merchantsMerchantIdPut."
      );
    }

    if (requestParameters.inDetailedMerchantDTO === null || requestParameters.inDetailedMerchantDTO === undefined) {
      throw new runtime.RequiredError(
        "inDetailedMerchantDTO",
        "Required parameter requestParameters.inDetailedMerchantDTO was null or undefined when calling merchantsMerchantIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/merchants/{merchantId}`.replace(
        `{${"merchantId"}}`,
        encodeURIComponent(String(requestParameters.merchantId))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: InDetailedMerchantDTOToJSON(requestParameters.inDetailedMerchantDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDetailedMerchantDTOFromJSON(jsonValue));
  }

  /**
   */
  async merchantsMerchantIdPut(
    merchantId: number,
    inDetailedMerchantDTO: InDetailedMerchantDTO
  ): Promise<OutDetailedMerchantDTO> {
    const response = await this.merchantsMerchantIdPutRaw({
      merchantId: merchantId,
      inDetailedMerchantDTO: inDetailedMerchantDTO
    });
    return await response.value();
  }

  /**
   */
  async merchantsPostRaw(
    requestParameters: MerchantsPostRequest
  ): Promise<runtime.ApiResponse<OutDetailedMerchantDTO>> {
    if (requestParameters.inCreateMerchantDTO === null || requestParameters.inCreateMerchantDTO === undefined) {
      throw new runtime.RequiredError(
        "inCreateMerchantDTO",
        "Required parameter requestParameters.inCreateMerchantDTO was null or undefined when calling merchantsPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/merchants`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: InCreateMerchantDTOToJSON(requestParameters.inCreateMerchantDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDetailedMerchantDTOFromJSON(jsonValue));
  }

  /**
   */
  async merchantsPost(inCreateMerchantDTO: InCreateMerchantDTO): Promise<OutDetailedMerchantDTO> {
    const response = await this.merchantsPostRaw({ inCreateMerchantDTO: inCreateMerchantDTO });
    return await response.value();
  }

  /**
   */
  async merchantsSlugMerchantSlugGetRaw(
    requestParameters: MerchantsSlugMerchantSlugGetRequest
  ): Promise<runtime.ApiResponse<OutDetailedMerchantDTO>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling merchantsSlugMerchantSlugGet."
      );
    }

    if (requestParameters.merchantSlug === null || requestParameters.merchantSlug === undefined) {
      throw new runtime.RequiredError(
        "merchantSlug",
        "Required parameter requestParameters.merchantSlug was null or undefined when calling merchantsSlugMerchantSlugGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/merchants/slug/{merchantSlug}`.replace(
        `{${"merchantSlug"}}`,
        encodeURIComponent(String(requestParameters.merchantSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDetailedMerchantDTOFromJSON(jsonValue));
  }

  /**
   */
  async merchantsSlugMerchantSlugGet(platformId: number, merchantSlug: string): Promise<OutDetailedMerchantDTO> {
    const response = await this.merchantsSlugMerchantSlugGetRaw({ platformId: platformId, merchantSlug: merchantSlug });
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum MerchantsCountGetStatusEnum {
  ABOUT_TO_START = "ABOUT_TO_START",
  ONGOING = "ONGOING",
  ABOUT_TO_EXPIRE = "ABOUT_TO_EXPIRE",
  EXPIRED = "EXPIRED"
}
/**
 * @export
 * @enum {string}
 */
export enum MerchantsExtendedGetEditFilterEnum {
  approved = "approved",
  merchant = "merchant"
}
/**
 * @export
 * @enum {string}
 */
export enum MerchantsGetStatusEnum {
  ABOUT_TO_START = "ABOUT_TO_START",
  ONGOING = "ONGOING",
  ABOUT_TO_EXPIRE = "ABOUT_TO_EXPIRE",
  EXPIRED = "EXPIRED"
}
