import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { useIntl } from "react-intl";

import { usePrevious } from "@app/util";
import { FilterItem } from "@app/api/core/filter/filter-item";

import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";
import { getReactSelectCustomStyles } from "../react-select/react-select-custom-styles";
import styles from "./multi-select-component.module.scss";

interface IMultiSelectComponentProps {
  defaultOptions?: FilterItem[] | null;
  enableCollapsing?: boolean;
  errorMessage?: string;
  id: string;
  isMulti?: boolean;
  label?: IFormLabelComponentProps;
  loadOptions?: (inputValue: string, callback: any) => void;
  onChange: (value: FilterItem[] | null, id: string) => void;
  placeholder?: string | true;
  type: string;
  value?: FilterItem[] | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const component = (props: IMultiSelectComponentProps) => {
  const intl = useIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [inputHasMaxHeight, setInputHasMaxHeight] = useState<boolean>(props.enableCollapsing || false);
  const prevValue = usePrevious(props.value);
  const customStyles = getReactSelectCustomStyles(inputHasMaxHeight);

  useEffect(() => {
    if (props.value === prevValue) {
      return undefined;
    }

    setIsMenuOpen(false);
  }, [props.value, props.defaultOptions]);

  const setChangeId = (value: any) => {
    if (Array.isArray(value)) {
      props.onChange(value, props.id);
    } else {
      props.onChange(value ? [value] : null, props.id);
    }
  };

  const loadSuggestions = useCallback(
    debounce((query, callback) => {
      try {
        props.loadOptions?.(query, callback);
      } catch {
        callback([]);
      }
    }, 500),
    []
  );

  const onInputChanged = (value: any) => {
    if (value.length > 0) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  };

  const onClickElement = () => {
    setInputHasMaxHeight(!inputHasMaxHeight);
  };

  return (
    <div>
      {props.label && <FormLabelComponent {...props.label} />}
      <div className={styles.multiSelectWrapper}>
        <AsyncSelect
          value={props.value || null}
          onChange={setChangeId}
          id={props.id}
          isMulti={props.isMulti}
          isClearable
          onInputChange={onInputChanged}
          isSearchable
          name={props.id}
          defaultOptions={!props.defaultOptions ? false : props.defaultOptions}
          loadOptions={loadSuggestions}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuIsOpen={isMenuOpen}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          inputId={props.id}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
        />
        {props.enableCollapsing && (
          <span className={styles.showAllFilters} role="button" onClick={onClickElement}>
            {intl.formatMessage(
              {
                id: inputHasMaxHeight ? "general.combobox.showMore" : "general.combobox.showLess"
              },
              { amount: Array.isArray(props.value) && props.value.length }
            )}
          </span>
        )}
      </div>
      {props.label?.errorMessage && <span className={styles.errorMessage}>{props.label?.errorMessage}</span>}
    </div>
  );
};

const areEqual = (prevProps: IMultiSelectComponentProps, nextProps: IMultiSelectComponentProps) => {
  if (!Array.isArray(nextProps.value) || !Array.isArray(prevProps.value)) {
    return false;
  }

  if (prevProps.value.length !== nextProps.value.length) {
    return false;
  }

  if (Array.isArray(nextProps.value) && Array.isArray(prevProps.value)) {
    for (let i = 0; i < nextProps.value.length; i += 1) {
      if (nextProps.value[i].value !== prevProps.value[i].value) {
        return false;
      }
    }
  }

  if (prevProps.label?.errorMessage !== nextProps.label?.errorMessage) {
    return false;
  }

  return true;
};

const MultiSelectComponent = React.memo(component, areEqual);
export { MultiSelectComponent };
