/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { UserRoleDTO, UserRoleDTOFromJSON, UserRoleDTOFromJSONTyped, UserRoleDTOToJSON } from "./";

/**
 *
 * @export
 * @interface OutUserDTO
 */
export interface OutUserDTO {
  /**
   *
   * @type {UserRoleDTO}
   * @memberof OutUserDTO
   */
  role: UserRoleDTO;
  /**
   *
   * @type {string}
   * @memberof OutUserDTO
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof OutUserDTO
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OutUserDTO
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof OutUserDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof OutUserDTO
   */
  phone?: string;
  /**
   *
   * @type {number}
   * @memberof OutUserDTO
   */
  id: number;
}

export function OutUserDTOFromJSON(json: any): OutUserDTO {
  return OutUserDTOFromJSONTyped(json, false);
}

export function OutUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutUserDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: UserRoleDTOFromJSON(json["role"]),
    company: !exists(json, "company") ? undefined : json["company"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    email: json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    id: json["id"]
  };
}

export function OutUserDTOToJSON(value?: OutUserDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: UserRoleDTOToJSON(value.role),
    company: value.company,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    phone: value.phone,
    id: value.id
  };
}
