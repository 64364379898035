import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutExternalDealFilterDTO } from "@app/api/generated";
import { FilterItem } from "@app/api/core/filter/filter-item";

export interface IFiltersState {
  externalDealFilters?: OutExternalDealFilterDTO[];
  selectedExternalDealFilter?: OutExternalDealFilterDTO;
  currentPlatform?: number;
  currentSearch?: string;
  currentStatus?: number;
  currentMerchantFilter?: FilterItem;
  totalPages: number;
  currentPage: number;
  totalResults: number;
  isLoading?: boolean;
}

const initialState: IFiltersState = {
  externalDealFilters: [],
  currentPage: 1,
  totalPages: 1,
  totalResults: 0
};

export const slice = createSlice({
  name: "externalDealFilters",
  initialState,
  reducers: {
    setExternalDealFilters: (state, action: PayloadAction<OutExternalDealFilterDTO[]>) => {
      state.externalDealFilters = action.payload;
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedExternalDealFilter: (state, action: PayloadAction<OutExternalDealFilterDTO | undefined>) => {
      state.selectedExternalDealFilter = action.payload;
    },
    deleteExternalDealFilterFromList: (state, action: PayloadAction<number>) => {
      state.externalDealFilters = state.externalDealFilters?.filter((filter) => filter.id !== action.payload);
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setCurrentStatus: (state, action: PayloadAction<number | undefined>) => {
      state.currentStatus = action.payload;
    },
    setCurrentPlatform: (state, action: PayloadAction<number | undefined>) => {
      state.currentPlatform = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    },
    setCurrentMerchantFilter: (state, action: PayloadAction<FilterItem | undefined>) => {
      state.currentMerchantFilter = action.payload;
    }
  }
});

export const externalDealFiltersActions = slice.actions;

export default slice.reducer;
