import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IntlShape, useIntl } from "react-intl";
import clsx from "clsx";
import { shallowEqual } from "react-redux";

import Bin from "@assets/icons/bin.svg";
import Edit from "@assets/icons/edit.svg";
import { Pagination } from "@app/core/pagination";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { OutFeaturedCategoriesDTO } from "@app/api/generated";
import { Table } from "@app/components/table/table";
import { Column } from "@webbio/components";
import { IconComponent, ResourceTextComponent } from "@app/core";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { useAppSelector, useAppDispatch } from "@app/redux/store";
import { featuredCategoriesThunk } from "@app/redux/thunks/featured-categories.thunk";
import { setCurrentPage } from "@app/redux/reducers/featured-categories";
import { usePrevious } from "@app/util";
import { platformTranslationKeys } from "@app/constants/platform";
import { CategoryFilterBar } from "@app/modules/featured-category-filter-bar/featured-category-filter-bar.component";
import { ROUTES } from "@app/constants/routes";

import tableStyles from "./table-styling-component.module.scss";
import styles from "./featured-category-overview-component.module.scss";

const TAKE = 18;

const FeaturedCategoryOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    featuredCategories,
    isLoadingFeaturedCategories,
    totalResults,
    currentMerchant,
    currentPlatform,
    currentSearch,
    currentPage
  } = useAppSelector((state) => state.featuredCategories, shallowEqual);
  const prevCurrentMerchant = usePrevious(currentMerchant);

  const history = useNavigate();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDeleteFeaturedCategoryOpen, setIsDeleteFeaturedCategoryOpen] = useState<boolean>(false);
  const [featuredCategoryToDelete, setFeaturedCategoryToDelete] = useState<OutFeaturedCategoriesDTO | undefined>(
    undefined
  );

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  useEffect(() => {
    getFeaturedCategories(1);
  }, [currentPlatform, currentSearch]);

  useEffect(() => {
    if (prevCurrentMerchant?.value !== currentMerchant?.value) {
      getFeaturedCategories(1);
    }
  }, [currentMerchant]);

  const onDeleteFeaturedCategory = (featuredCategory?: OutFeaturedCategoriesDTO) => {
    if (featuredCategory) {
      setFeaturedCategoryToDelete(featuredCategory);
      setIsDeleteFeaturedCategoryOpen(true);
    }
  };

  const onDeleteConfirmed = async () => {
    if (featuredCategoryToDelete) {
      await dispatch(featuredCategoriesThunk.deleteFeaturedCategory(featuredCategoryToDelete.id));

      setIsDeleteFeaturedCategoryOpen(false);
    }
  };

  const getFeaturedCategories = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;
    dispatch(
      featuredCategoriesThunk.getFeaturedCategories(skip, TAKE, currentMerchant, currentSearch, currentPlatform)
    );
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onPageChange = (page: number) => {
    getFeaturedCategories(page);
  };

  const onTableRowClick = (featuredCategory: OutFeaturedCategoriesDTO) => {
    history(`${ROUTES.FEATURED_CATEGORY_OVERVIEW}/${featuredCategory.id}`);
  };

  return (
    <div className={styles.featuredCategoriesOverview}>
      <div className={styles.container}>
        <CategoryFilterBar />

        <div className={styles.overview}>
          {featuredCategories && featuredCategories.length > 0 ? (
            <Table dataSource={featuredCategories || []} isLoading={isLoadingFeaturedCategories}>
              <Column
                title={getColumns(intl).id.title}
                field={getColumns(intl).id.field}
                id={getColumns(intl).id.id}
                onTdClick={onTableRowClick}
                cell={(name): JSX.Element => {
                  return (
                    <span className={clsx(tableStyles.id)} title={name}>
                      {name || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).slug.title}
                field={getColumns(intl).slug.field}
                id={getColumns(intl).slug.id}
                onTdClick={onTableRowClick}
                cell={(name): JSX.Element => {
                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.slug)} title={name}>
                      {name || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).title.title}
                field={getColumns(intl).title.field}
                id={getColumns(intl).title.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const featuredCategory = featuredCategories?.[idx as number];

                  return (
                    <span className={clsx(tableStyles.title)} title={featuredCategory.categoryTitle?.toString()}>
                      {featuredCategory.categoryTitle || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).store.title}
                field={getColumns(intl).store.field}
                id={getColumns(intl).store.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const featuredCategory = featuredCategories?.[idx as number];

                  return (
                    <span
                      className={clsx(tableStyles.truncate, tableStyles.store)}
                      title={featuredCategory.merchant.name.toString()}
                    >
                      {featuredCategory.merchant.name}
                    </span>
                  );
                }}
              />
              <Column
                title={getColumns(intl).trackingUrl.title}
                field={getColumns(intl).trackingUrl.field}
                id={getColumns(intl).trackingUrl.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const featuredCategory = featuredCategories?.[idx as number];

                  return (
                    <span
                      className={clsx(tableStyles.truncate, tableStyles.trackingUrl)}
                      title={featuredCategory.trackingUrl?.toString()}
                    >
                      {featuredCategory.trackingUrl}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).platform.title}
                field={getColumns(intl).platform.field}
                id={getColumns(intl).platform.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const featuredCategory = featuredCategories?.[idx as number];
                  const platformString =
                    featuredCategory &&
                    featuredCategory.platform &&
                    platformTranslationKeys.find((platformItem) => platformItem.id === featuredCategory.platform.id);

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.platform)}>
                      {platformString ? intl.formatMessage({ id: platformString.name }) : "-"}
                    </span>
                  );
                }}
              />

              <Column
                title=""
                field="id"
                cell={(_, idx) => {
                  const featuredCategory = featuredCategories?.[idx as number];

                  return (
                    <div className={clsx(tableStyles.push, styles.push)}>
                      <Link
                        to={`${ROUTES.FEATURED_CATEGORY_OVERVIEW}/${featuredCategory.id}`}
                        className={clsx(styles.smallButton, styles.editButton)}
                      >
                        <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                      </Link>
                      <span
                        role="button"
                        className={clsx(styles.smallButton, styles.removeButton)}
                        onClick={() => onDeleteFeaturedCategory(featuredCategory)}
                      >
                        <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                      </span>
                    </div>
                  );
                }}
              />
            </Table>
          ) : isLoadingFeaturedCategories ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>
              <ResourceTextComponent resourceKey="categoriesOverviewComponent.table.noResults" />
            </span>
          )}
          <div className={styles.pagination}>
            <Pagination currentPage={currentPage} totalItems={totalPages} changePage={onPageChange} />
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "categoriesOverviewComponent.leavePageConfirmation" })}
        isModalOpen={isDeleteFeaturedCategoryOpen}
        onCloseModal={() => {
          setIsDeleteFeaturedCategoryOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteFeaturedCategoryOpen(false);
          }}
          onDelete={onDeleteConfirmed}
        />
      </ModalComponent>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getColumns = (intl: IntlShape) => ({
  id: {
    id: "id",
    title: intl.formatMessage({ id: "categoriesOverviewComponent.tableHeaderId" }),
    field: "id"
  },
  slug: {
    id: "slug",
    title: intl.formatMessage({ id: "categoriesOverviewComponent.tableHeaderSlug" }),
    field: "slug"
  },
  title: {
    id: "title",
    title: intl.formatMessage({ id: "categoriesOverviewComponent.tableHeaderTitle" }),
    field: "title"
  },
  store: {
    id: "store",
    title: intl.formatMessage({ id: "categoriesOverviewComponent.tableHeaderStore" }),
    field: "store"
  },
  trackingUrl: {
    id: "trackingUrl",
    title: intl.formatMessage({ id: "categoriesOverviewComponent.tableHeaderTrackingUrl" }),
    field: "trackingUrl"
  },
  platform: {
    id: "platform",
    title: intl.formatMessage({ id: "categoriesOverviewComponent.tableHeaderPlatform" }),
    field: "platform"
  }
});

export { FeaturedCategoryOverview };
