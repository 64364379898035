/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { GenericIdDTO, GenericIdDTOFromJSON, GenericIdDTOFromJSONTyped, GenericIdDTOToJSON } from "./";

/**
 *
 * @export
 * @interface PatchManyExternalDealFiltersLiveDTO
 */
export interface PatchManyExternalDealFiltersLiveDTO {
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof PatchManyExternalDealFiltersLiveDTO
   */
  filterIds: Array<GenericIdDTO>;
  /**
   *
   * @type {boolean}
   * @memberof PatchManyExternalDealFiltersLiveDTO
   */
  live: boolean;
}

export function PatchManyExternalDealFiltersLiveDTOFromJSON(json: any): PatchManyExternalDealFiltersLiveDTO {
  return PatchManyExternalDealFiltersLiveDTOFromJSONTyped(json, false);
}

export function PatchManyExternalDealFiltersLiveDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchManyExternalDealFiltersLiveDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    filterIds: (json["filterIds"] as Array<any>).map(GenericIdDTOFromJSON),
    live: json["live"]
  };
}

export function PatchManyExternalDealFiltersLiveDTOToJSON(value?: PatchManyExternalDealFiltersLiveDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    filterIds: (value.filterIds as Array<any>).map(GenericIdDTOToJSON),
    live: value.live
  };
}
