import React, { useState } from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent, ClickableComponent, ResourceTextComponent } from "@app/core";
import { validateEmail } from "@app/util";

import styles from "./login-component.module.scss";

export interface ILoginFormValues {
  emailAddress: string;
  password: string;
}

interface IOtherProps {
  intl: IntlShape;
  onNavigateToRequestPassword: (emailAddress: string) => void;
}

const InnerForm = (props: IOtherProps & FormikProps<ILoginFormValues>) => {
  const { touched, errors } = props;
  const [emailAddress, setEmailAddress] = useState<string>("");

  const emailChanged = (event: any) => {
    setEmailAddress(event.target.value);
    props.handleChange(event);
  };

  return (
    <Form>
      <div className={styles.loginForm}>
        <div className={styles.formField}>
          <TextFieldComponent
            label={{
              label: props.intl.formatMessage({ id: "loginForm.email.label" }),
              errorMessage: touched.emailAddress === true && errors.emailAddress
            }}
            errorMessage={props.touched.emailAddress && props.errors.emailAddress}
            placeholder={props.intl.formatMessage({ id: "loginForm.email.placeholder" })}
            value={props.values.emailAddress}
            onChange={emailChanged}
            onBlur={props.handleBlur}
            type="email"
            id="emailAddress"
          />
        </div>
        <div className={styles.formField}>
          <div className={styles.forgotPassword}>
            <a
              role="link"
              onClick={() => {
                props.onNavigateToRequestPassword(emailAddress);
              }}
            >
              <ResourceTextComponent resourceKey="loginForm.forgotPassword" />
            </a>
          </div>
          <TextFieldComponent
            label={{
              label: props.intl.formatMessage({ id: "loginForm.password.label" }),
              errorMessage: touched.password === true && errors.password
            }}
            placeholder={props.intl.formatMessage({ id: "loginForm.email.placeholder" })}
            value={props.values.password}
            errorMessage={props.touched.password && props.errors.password}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="password"
            id="password"
          />
        </div>
      </div>
      <div className={styles.submitButton}>
        <ClickableComponent
          buttonType="submit"
          title={props.intl.formatMessage({ id: "loginForm.button.submit" })}
          fullWidth
          height={40}
        />
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  onSubmit: (values: ILoginFormValues) => void;
}

export const LoginFormComponent = withFormik<IFormProps & IOtherProps, ILoginFormValues>({
  mapPropsToValues: () => ({
    emailAddress: "",
    password: ""
  }),

  validate: (values: ILoginFormValues, props) => {
    const errors: FormikErrors<ILoginFormValues> = {};
    if (!values.emailAddress) {
      errors.emailAddress = props.intl.formatMessage({ id: "loginForm.error.email.required" });
    } else if (!validateEmail(values.emailAddress)) {
      errors.emailAddress = props.intl.formatMessage({ id: "loginForm.error.email.invalid" });
    }
    if (!values.password) {
      errors.password = props.intl.formatMessage({ id: "loginForm.error.password.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
