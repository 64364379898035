/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TokenDTO
 */
export interface TokenDTO {
  /**
   *
   * @type {string}
   * @memberof TokenDTO
   */
  refreshToken: string;
  /**
   *
   * @type {string}
   * @memberof TokenDTO
   */
  accessToken?: string;
  /**
   *
   * @type {string}
   * @memberof TokenDTO
   */
  resetToken?: string;
}

export function TokenDTOFromJSON(json: any): TokenDTO {
  return TokenDTOFromJSONTyped(json, false);
}

export function TokenDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    refreshToken: json["refreshToken"],
    accessToken: !exists(json, "accessToken") ? undefined : json["accessToken"],
    resetToken: !exists(json, "resetToken") ? undefined : json["resetToken"]
  };
}

export function TokenDTOToJSON(value?: TokenDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    refreshToken: value.refreshToken,
    accessToken: value.accessToken,
    resetToken: value.resetToken
  };
}
