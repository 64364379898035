import React from "react";
import { withFormik, FormikProps, Form, isInteger } from "formik";
import { IntlShape } from "react-intl";

import { ClickableComponent, TextFieldComponent, ResourceTextComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { ImageUpload } from "@app/core/image-upload/image-upload.component";
import { OutBannerDTO, ImageDTO, PlatformDTO, PutBannerDTO } from "@app/api/generated";
import { MultiSelectComponent } from "@app/core/multi-select/multi-select.component";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapToFilterItem } from "@app/api/core/filter/map-filter-item";
import { platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { DatePickerComponent } from "@app/core/date-picker";

import { getFilters } from "./api-calls";
import styles from "./banner-form.module.scss";

interface IFormValues {
  bannerImage?: ImageDTO;
  brands?: FilterItem[];
  categories?: FilterItem[];
  cta?: string;
  description?: string;
  endDate?: string | null;
  id: number;
  label?: string;
  logoImage?: ImageDTO;
  name: string;
  platform: PlatformDTO;
  slug: string;
  startDate?: string | null;
  status?: number;
  url?: string;
}

interface IOtherProps {
  intl: IntlShape;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
}

const InnerForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { touched, errors } = props;

  const onCustomChange = (value: any, id: string) => {
    props.handleChange(value);
    props.onEdit();
    props.setFieldValue(id, value);
  };

  const loadCategories = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const filters = await getFilters(inputValue, "1;");
    callback(filters ? filters.map(mapToFilterItem) : []);
  };

  const loadBrands = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const filters = await getFilters(inputValue, "3;");
    callback(filters ? filters.map(mapToFilterItem) : []);
  };

  const handleChange = (values: any) => {
    props.handleChange(values);
    props.onEdit();
  };

  const onDatePickerChange = (date: Date | null, id: string) => {
    let newValue: Date | null | string = date;

    if (newValue) {
      newValue = newValue.toISOString();
    }

    props.handleChange(newValue);
    props.setFieldValue(id, newValue);
  };

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: props.intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="bannerForm.dataBlock.platform" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <SelectComponent
                  height={40}
                  value={props.values.platform.id}
                  onChange={handleChange}
                  options={translatedPlatforms}
                  id="platform"
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.platform.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.platform.tooltip" })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="bannerForm.dataBlock.bannerImage" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <ImageUpload
                  id="bannerImage"
                  onChange={onCustomChange}
                  image={props.values.bannerImage}
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.bannerImage.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.bannerImage.tooltip" })
                  }}
                />
              </div>
              <div className={styles.fieldsContainer}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    placeholder={props.intl.formatMessage({ id: "bannerForm.input.bannerImage.title.placeholder" })}
                    value={props.values.bannerImage && props.values.bannerImage.title}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    disabled={!props.values.bannerImage}
                    type="text"
                    id="bannerImage.title"
                    label={{
                      label: props.intl.formatMessage({ id: "bannerForm.input.bannerImage.title.label" }),
                      tooltip: props.intl.formatMessage({ id: "bannerForm.input.bannerImage.title.tooltip" })
                    }}
                  />
                </div>
                <div className={styles.formField}>
                  <TextFieldComponent
                    placeholder={props.intl.formatMessage({ id: "bannerForm.input.bannerImage.alt.placeholder" })}
                    value={props.values.bannerImage && props.values.bannerImage.alt}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    type="text"
                    id="bannerImage.alt"
                    disabled={!props.values.bannerImage}
                    label={{
                      label: props.intl.formatMessage({ id: "bannerForm.input.bannerImage.alt.label" }),
                      tooltip: props.intl.formatMessage({ id: "bannerForm.input.bannerImage.alt.tooltip" })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="bannerForm.dataBlock.generalInformation" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <MultiSelectComponent
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.categories.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.categories.tooltip" })
                  }}
                  isMulti
                  value={props.values.categories}
                  onChange={onCustomChange}
                  type="array"
                  id="categories"
                  loadOptions={loadCategories}
                  enableCollapsing
                />
              </div>
              <div className={styles.formField}>
                <MultiSelectComponent
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.brands.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.brands.tooltip" })
                  }}
                  isMulti
                  value={props.values.brands}
                  onChange={onCustomChange}
                  type="array"
                  id="brands"
                  loadOptions={loadBrands}
                  enableCollapsing
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.label}
                  onChange={handleChange}
                  id="label"
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.label.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.label.tooltip" }),
                    isOptionalField: true
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.name}
                  onChange={handleChange}
                  id="name"
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.name.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.name.tooltip" })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={102}
                  value={props.values.description}
                  onChange={handleChange}
                  id="description"
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.description.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.description.tooltip" })
                  }}
                  isTextArea
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="bannerForm.dataBlock.button" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.cta}
                  onChange={handleChange}
                  id="cta"
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.cta.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.cta.tooltip" })
                  }}
                />
              </div>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.url}
                  onChange={handleChange}
                  id="url"
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.url.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.url.tooltip" })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="bannerForm.dataBlock.logo" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <ImageUpload
                  id="logoImage"
                  onChange={onCustomChange}
                  image={props.values.logoImage}
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.logoImage.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.logoImage.tooltip" }),
                    isOptionalField: true
                  }}
                />
              </div>
              <div className={styles.fieldsContainer}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    value={props.values.logoImage && props.values.logoImage.title}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    disabled={!props.values.logoImage}
                    type="text"
                    id="logoImage.title"
                    label={{
                      label: props.intl.formatMessage({ id: "bannerForm.input.logoImage.title.label" }),
                      tooltip: props.intl.formatMessage({ id: "bannerForm.input.logoImage.title.tooltip" })
                    }}
                  />
                </div>
                <div className={styles.formField}>
                  <TextFieldComponent
                    value={props.values.logoImage && props.values.logoImage.alt}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    disabled={!props.values.logoImage}
                    type="text"
                    id="logoImage.alt"
                    label={{
                      label: props.intl.formatMessage({ id: "bannerForm.input.logoImage.alt.label" }),
                      tooltip: props.intl.formatMessage({ id: "bannerForm.input.logoImage.alt.tooltip" })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="bannerForm.dataBlock.schedule" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <DatePickerComponent
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.startDate.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.startDate.tooltip" })
                  }}
                  value={props.values.startDate}
                  onChange={onDatePickerChange}
                  includeTime
                  id="startDate"
                  placeholder
                  errorMessage={touched.startDate && errors.startDate}
                  disableAutoComplete
                />
              </div>
              <div className={styles.formField}>
                <DatePickerComponent
                  label={{
                    label: props.intl.formatMessage({ id: "bannerForm.input.endDate.label" }),
                    tooltip: props.intl.formatMessage({ id: "bannerForm.input.endDate.tooltip" })
                  }}
                  value={props.values.endDate}
                  onChange={onDatePickerChange}
                  includeTime
                  id="endDate"
                  placeholder
                  errorMessage={touched.endDate && errors.endDate}
                  disableAutoComplete
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomBar}>
          <div className={styles.actions}>
            <ClickableComponent
              variant="primary-inverted"
              title={props.intl.formatMessage({ id: "bannerForm.button.cancel" })}
              buttonType="button"
              height={48}
              onClick={() => {
                props.onCancel(false);
              }}
            />
            <ClickableComponent
              buttonType="button"
              title={props.intl.formatMessage({ id: "bannerForm.button.save" })}
              height={48}
              onClick={() => {
                props.handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  banner: OutBannerDTO;
  intl: IntlShape;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  onSubmit: (values: PutBannerDTO) => void;
}

export const BannerForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: (props) => {
    return {
      platform: props.banner.platform,
      description: props.banner.description,
      categories: props.banner.categories?.map(mapToFilterItem),
      brands: props.banner.brands?.map(mapToFilterItem),
      cta: props.banner.cta,
      label: props.banner.label,
      bannerImage: props.banner.bannerImage,
      logoImage: props.banner.logoImage,
      url: props.banner.url,
      id: props.banner.id,
      name: props.banner.name,
      slug: props.banner.slug,
      startDate: props.banner.startDate ? props.banner.startDate : null,
      endDate: props.banner.endDate ? props.banner.endDate : null
    };
  },

  validate: (values: IFormValues, bag) => {
    const midnightTimeIsoDate = "23:00:00";
    const midnightTimeError = bag.intl.formatMessage({ id: "campaignForm.errors.midnightTimeError" });
    const errors: any = {};

    if (values.startDate && values.endDate) {
      const startDate = new Date(values.startDate);
      const endDate = new Date(values.endDate);
      const today = new Date();

      if (endDate < startDate) {
        errors.startDate = bag.intl.formatMessage({ id: "campaignForm.errors.startDateAfterEndDate" });
      }
      if (endDate < today) {
        errors.endDate = bag.intl.formatMessage({ id: "campaignForm.errors.endDateAfterToday" });
      }
    }

    if (values.startDate && values.startDate.includes(midnightTimeIsoDate)) {
      errors.startDate = midnightTimeError;
    }

    if (values.endDate && values.endDate.includes(midnightTimeIsoDate)) {
      errors.endDate = midnightTimeError;
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const banner = mapValuesToBanner(values);
    bag.props.onSubmit(banner);
  }
})(InnerForm);

const mapValuesToBanner = (values: IFormValues): PutBannerDTO => {
  const newBanner: PutBannerDTO = {
    platform: isInteger(values.platform)
      ? { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 }
      : values.platform,
    description: values.description,
    categories: values.categories?.map((filterItem) => ({
      id: Number(filterItem.value)
    })),
    brands: values.brands?.map((filterItem) => ({
      id: Number(filterItem.value)
    })),
    cta: values.cta,
    label: values.label,
    bannerImage: values.bannerImage,
    logoImage: values.logoImage,
    url: values.url,
    id: values.id,
    name: values.name,
    startDate: values.startDate ? values.startDate : undefined,
    endDate: values.endDate ? values.endDate : undefined
  };

  return newBanner;
};
