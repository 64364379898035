import { Configuration } from "@app/api/generated";
import { getAccessToken } from "@app/authorization/authorization-api";
import { getConfig } from "./config";

export const getAuthorizedApiConfig = async () => {
  const accessToken = await getAccessToken();

  return new Configuration({
    headers: {
      "x-access-token": accessToken || ""
    },
    basePath: getConfig().API_URL
  });
};

export const getUnAuthorizedApiConfig = () => {
  return new Configuration({
    basePath: getConfig().API_URL
  });
};
