import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";

import Bin from "@assets/icons/bin.svg";
import Edit from "@assets/icons/edit.svg";

import { Pagination } from "@app/core/pagination";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { OutExternalDealFilterDTO, OutMerchantDTO, PlatformDTO } from "@app/api/generated";
import { ClickableComponent, IconComponent, LabelComponent, ResourceTextComponent } from "@app/core";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { Column } from "@webbio/components";
import { Table } from "@app/components/table/table";
import { ROUTES } from "@app/constants/routes";
import { useDateFnsFormat } from "@app/util/use-date-fns-format";
import { platformTranslationKeys } from "@app/constants/platform";
import { usePrevious } from "@app/util";
import { externalDealFiltersThunk } from "@app/redux/thunks/external-deal-filters.thunk";
import { externalDealFiltersActions } from "@app/redux/reducers/external-deal-filters";
import { CheckboxComponent } from "@app/core/checkbox";

import { ExternalDealFilterFilterBar } from "./external-deal-filter-filter-bar/external-deal-filter-filter-bar.component";
import { EdfEditBulkModal } from "../external-deal-filter-modal";
import styles from "./external-deal-filter-overview-component.module.scss";
import tableStyles from "./table-styling-component.module.scss";
import { EdfDuplicateBulkModal } from "../external-deal-filter-duplicate-modal";
import { ExternalDealFilterOptionsBar } from "./options-bar/external-deal-filter-options-bar-component";

const TAKE = 30;

export interface SelectedFilter {
  id: number;
  platformId: number;
}

const ExternalDealFilterOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    externalDealFilters,
    isLoading,
    currentPage,
    currentPlatform,
    currentMerchantFilter,
    currentSearch,
    currentStatus,
    totalPages
  } = useAppSelector((state) => state.externalDealFilters);
  const prevCurrentMerchantFilter = usePrevious(currentMerchantFilter);

  const history = useNavigate();
  const [isDeleteFilterOpen, setIsDeleteFilterOpen] = useState<boolean>(false);
  const [isEditBulkOpen, setIsEditBulkOpen] = useState<boolean>(false);
  const [isDuplicateBulkOpen, setIsDuplicateBulkOpen] = useState<boolean>(false);
  const [filterToDelete, setFilterToDelete] = useState<OutExternalDealFilterDTO | undefined>(undefined);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);

  const { formatDate, getTimeDifference, formatDistance } = useDateFnsFormat();

  useEffect(() => {
    getExternalDealFilters(1);
  }, [currentSearch, currentStatus, currentPlatform]);

  useEffect(() => {
    if (prevCurrentMerchantFilter?.value !== currentMerchantFilter?.value) {
      getExternalDealFilters(1);
    }
  }, [currentMerchantFilter]);

  const onDeleteExternalDealFilter = (externalDealFilter: OutExternalDealFilterDTO) => {
    setFilterToDelete(externalDealFilter);
    setIsDeleteFilterOpen(true);
  };

  const onDeleteConfirmed = () => {
    if (filterToDelete) {
      dispatch(externalDealFiltersThunk.deleteExternalDealFilter(filterToDelete.id));
      setIsDeleteFilterOpen(false);
    }
  };

  const onPageChange = (page: number) => {
    getExternalDealFilters(page);
  };

  const getExternalDealFilters = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(
      externalDealFiltersThunk.getExternalDealFilters(
        skip,
        TAKE,
        currentMerchantFilter,
        currentPlatform,
        currentSearch,
        currentStatus
      )
    );
    dispatch(externalDealFiltersActions.setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onTableRowClick = (externalDealFilter: OutExternalDealFilterDTO) => {
    history(`${ROUTES.EXTERNAL_DEAL_FILTERS}/${externalDealFilter.id}`);
  };

  const onSelectRow = (changeEvent: React.ChangeEvent<HTMLInputElement>, filterId: number, platformId: number) => {
    const newSelectedFilters = [...selectedFilters];
    if (changeEvent.target.checked) {
      newSelectedFilters.push({ id: filterId, platformId });
    } else {
      const indexToDelete = newSelectedFilters.findIndex((filter) => filter.id === filterId);
      newSelectedFilters.splice(indexToDelete, 1);
    }
    setSelectedFilters(newSelectedFilters);
  };

  const onSelectAll = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    let newSelectedFilters: SelectedFilter[] = [];
    const newSelectedPages = [...selectedPages];

    if (changeEvent.target.checked) {
      newSelectedFilters = addFiltersToList([...selectedFilters], externalDealFilters ?? []);
      newSelectedPages.push(currentPage);
    } else {
      newSelectedFilters = removeFiltersFromList([...selectedFilters], externalDealFilters ?? []);
      const pageToRemoveIndex = selectedPages.indexOf(currentPage);
      newSelectedPages.splice(pageToRemoveIndex, 1);
    }
    setSelectedFilters(newSelectedFilters);
    setSelectedPages(newSelectedPages);
  };

  return (
    <div className={styles.externalDealFilterOverview}>
      <div className={styles.container}>
        <ExternalDealFilterFilterBar />
        <div className={styles.multiSelectBar}>
          <span className={styles.amountInfo}>{selectedFilters.length} filter(s) geselecteerd.</span>
        </div>
        <ExternalDealFilterOptionsBar
          selectedFilters={selectedFilters}
          onEditStatus={() => getExternalDealFilters()}
          onDuplicate={() => setIsDuplicateBulkOpen(true)}
          onEdit={() => setIsEditBulkOpen(true)}
        />

        <div className={styles.overview}>
          {externalDealFilters && externalDealFilters.length > 0 ? (
            <Table dataSource={externalDealFilters || []} isLoading={isLoading}>
              <Column
                title={
                  <CheckboxComponent
                    name=""
                    onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                      onSelectAll(changeEvent);
                    }}
                    value={selectedPages.includes(currentPage)}
                    noMargin
                  />
                }
                id="selectedFilters"
                field="id"
                cell={(_, idx) => {
                  const externalDealFilter = externalDealFilters?.[idx as number];
                  return (
                    <CheckboxComponent
                      name=""
                      onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                        onSelectRow(changeEvent, externalDealFilter.id, externalDealFilter.platform.id);
                      }}
                      value={selectedFilters.some((filter) => filter.id === externalDealFilter.id)}
                      noMargin
                    />
                  );
                }}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.id" })}
                field="id"
                id="id"
                onTdClick={onTableRowClick}
                cell={(id) => id}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.status" })}
                field="live"
                id="live"
                onTdClick={onTableRowClick}
                maxWidth={80}
                width={80}
                cell={(live) => <LabelComponent type={live ? "live" : "offline"} />}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.title" })}
                field="title"
                id="title"
                onTdClick={onTableRowClick}
                cell={(title) => (
                  <span className={tableStyles.truncate} title={title}>
                    {title || "-"}
                  </span>
                )}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.lastRunStartDate" })}
                field="lastRunStartDate"
                id="lastRunStartDate"
                onTdClick={onTableRowClick}
                cell={(lastRunStartDate: string) => {
                  const date = lastRunStartDate ? formatDate(new Date(lastRunStartDate), "dd-MM-yy HH:mm:ss") : "-";

                  return (
                    <span className={tableStyles.truncate} title={date}>
                      {date}
                    </span>
                  );
                }}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.lastRunDuration" })}
                field="id"
                id="id"
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const externalDealFilter = externalDealFilters?.[idx as number];
                  const lastRunStartDate =
                    externalDealFilter?.lastRunStartDate && new Date(externalDealFilter?.lastRunStartDate);
                  const lastRunEndDate =
                    externalDealFilter?.lastRunEndDate && new Date(externalDealFilter?.lastRunEndDate);

                  if (!lastRunStartDate || !lastRunEndDate) {
                    return <>-</>;
                  }

                  const isInProgress = Boolean(
                    externalDealFilter?.lastRunStartDate &&
                      externalDealFilter?.lastRunEndDate &&
                      externalDealFilter.lastRunStartDate > externalDealFilter?.lastRunEndDate
                  );

                  if (isInProgress) {
                    return (
                      <span className={tableStyles.truncate}>
                        {intl.formatMessage({ id: "externalDealFilters.detail.form.info.runningProgress.placeholder" })}
                      </span>
                    );
                  }

                  const timeDifference = formatDistance(lastRunEndDate, lastRunStartDate);
                  const exactTimeDifference = getTimeDifference(lastRunEndDate, lastRunStartDate);

                  return (
                    <span className={tableStyles.truncate} title={exactTimeDifference}>
                      {timeDifference}
                    </span>
                  );
                }}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.nextRunAtDate" })}
                field="nextRunAtDate"
                id="nextRunAtDate"
                onTdClick={onTableRowClick}
                cell={(nextRunAtDate, idx) => {
                  const externalDealFilter = externalDealFilters?.[idx as number];

                  const date = nextRunAtDate
                    ? formatDate(new Date(nextRunAtDate), "dd-MM-yy HH:mm:ss")
                    : intl.formatMessage({ id: "externalDealFilters.overview.table.td.nextRunAtDate.default" });

                  const isInProgress = Boolean(
                    externalDealFilter?.lastRunStartDate &&
                      externalDealFilter?.lastRunEndDate &&
                      externalDealFilter.lastRunStartDate > externalDealFilter?.lastRunEndDate
                  );

                  const label = isInProgress ? "-" : date;

                  return (
                    <span className={tableStyles.truncate} title={label}>
                      {label}
                    </span>
                  );
                }}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.runIntervalSeconds" })}
                field="runIntervalSeconds"
                id="runIntervalSeconds"
                maxWidth={80}
                width={80}
                onTdClick={onTableRowClick}
                cell={(runIntervalSeconds: number) => <>{runIntervalSeconds}s</>}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.searchQuery" })}
                field="searchQuery"
                id="searchQuery"
                onTdClick={onTableRowClick}
                cell={(searchQuery: string) => (
                  <span className={tableStyles.truncate} title={searchQuery}>
                    {searchQuery}
                  </span>
                )}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.merchants" })}
                field="merchants"
                id="merchants"
                onTdClick={onTableRowClick}
                cell={(merchants?: OutMerchantDTO[]) => {
                  const combinedMerchants = merchants?.map((merchant) => merchant.name).join(", ");

                  return (
                    <span className={tableStyles.truncate} title={combinedMerchants}>
                      {combinedMerchants}
                    </span>
                  );
                }}
              />
              <Column
                title={intl.formatMessage({ id: "externalDealFilters.overview.table.th.platform" })}
                field="platform"
                id="platform"
                onTdClick={onTableRowClick}
                cell={(platform?: PlatformDTO) => {
                  const translationKey = platformTranslationKeys.find(
                    (platformItem) => platformItem.id === platform?.id
                  );
                  const platformString = translationKey ? intl.formatMessage({ id: translationKey.name }) : "-";

                  return (
                    <span className={tableStyles.truncate} title={platformString}>
                      {platformString}
                    </span>
                  );
                }}
              />
              <Column
                title=""
                field="id"
                cell={(_, idx) => {
                  const externalDealFilter = externalDealFilters?.[idx as number];

                  return (
                    <div className={clsx(tableStyles.push, styles.push)}>
                      <Link
                        to={`${ROUTES.EXTERNAL_DEAL_FILTERS}/${externalDealFilter.id}`}
                        className={clsx(styles.smallButton, styles.editButton)}
                      >
                        <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                      </Link>
                      <span
                        role="button"
                        className={clsx(styles.smallButton, styles.removeButton)}
                        onClick={() => onDeleteExternalDealFilter(externalDealFilter)}
                      >
                        <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                      </span>
                    </div>
                  );
                }}
              />
            </Table>
          ) : isLoading ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>
              <ResourceTextComponent resourceKey="general.table.noResults" />
            </span>
          )}
          <div className={styles.pagination}>
            <Pagination currentPage={currentPage} totalItems={totalPages} changePage={onPageChange} />
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "externalDealFilters.overview.modal.delete.title" })}
        isModalOpen={isDeleteFilterOpen}
        onCloseModal={() => setIsDeleteFilterOpen(false)}
        variant="big"
      >
        <DeleteModal onCancel={() => setIsDeleteFilterOpen(false)} onDelete={onDeleteConfirmed} />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage(
          { id: "externalDealFilters.overview.modal.edit.title" },
          { amount: selectedFilters.length }
        )}
        isModalOpen={isEditBulkOpen}
        onCloseModal={() => {
          setIsEditBulkOpen(false);
        }}
        variant="big"
      >
        <EdfEditBulkModal
          onCancel={() => {
            setIsEditBulkOpen(false);
          }}
          onDone={() => {
            getExternalDealFilters();
            setIsEditBulkOpen(false);
          }}
          selectedFilters={selectedFilters}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "externalDealFilters.modal.duplicate.title" })}
        isModalOpen={isDuplicateBulkOpen}
        onCloseModal={() => {
          setIsDuplicateBulkOpen(false);
        }}
        variant="big"
      >
        <EdfDuplicateBulkModal
          onCancel={() => {
            setIsDuplicateBulkOpen(false);
          }}
          onDone={() => {
            getExternalDealFilters();
            setIsDuplicateBulkOpen(false);
          }}
          selectedFilters={selectedFilters}
        />
      </ModalComponent>
    </div>
  );
};

const addFiltersToList = (currentFilters: SelectedFilter[], filtersToAdd: OutExternalDealFilterDTO[]) => {
  const convertedFiltersToAdd: SelectedFilter[] =
    filtersToAdd.map((filter) => {
      return { id: filter.id, platformId: filter.platform.id };
    }) ?? [];
  const currentIds = new Set(currentFilters.map((filter) => filter.id));

  return [...currentFilters, ...convertedFiltersToAdd.filter((filter) => !currentIds.has(filter.id))];
};

const removeFiltersFromList = (currentFilters: SelectedFilter[], filtersToRemove: OutExternalDealFilterDTO[]) => {
  const filtersToRemoveIds = new Set(filtersToRemove.map((filter) => filter.id));

  return currentFilters.filter((filter) => !filtersToRemoveIds.has(filter.id));
};

export { ExternalDealFilterOverview };
