import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { AuthorizationContainer } from "@app/modules";
import { validatePassword } from "@app/util";
import { ClickableComponent, ResourceTextComponent } from "@app/core";
import { validateToken, resetPassword } from "@app/authorization/authorization-api";

import { ResetPasswordFormComponent, IResetPasswordFormValues } from "./reset-password.form";
import styles from "./reset-password-component.module.scss";

const ResetPassword = () => {
  const intl = useIntl();
  const { token } = useParams<{ token: string }>();
  const history = useNavigate();
  const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false);
  const [tokenInvalid, setTokenInvalid] = useState<boolean>(false);

  useEffect(() => {
    validateTokenFromParam();
  }, []);

  const validateTokenFromParam = async () => {
    const tokenValid = await validateToken(token || "");
    setTokenInvalid(!tokenValid);
  };

  const onResetPassword = (password: string) => {
    const isValid = validatePassword(password);

    if (!isValid) {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
      resetPassword(password, token || "");
      history("/login");
    }
  };

  const navigateToRequestPassword = () => {
    history("/request-password");
  };

  return (
    <AuthorizationContainer>
      {tokenInvalid ? (
        <>
          <div className={styles.resetPasswordHeader}>
            <h1>
              <ResourceTextComponent resourceKey="resetPassword.linkExpired.header" />
            </h1>
          </div>
          <div className={styles.resetPasswordDescription}>
            <ResourceTextComponent resourceKey="resetPassword.linkExpired.description" />
          </div>
          <div className={styles.submitButton}>
            <ClickableComponent
              onClick={navigateToRequestPassword}
              title={intl.formatMessage({ id: "resetPassword.resetPassword.button.resetPassword" })}
              fullWidth
              height={40}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.resetPasswordHeader}>
            <h1>
              <ResourceTextComponent resourceKey="resetPassword.newPassword.header" />
            </h1>
          </div>
          {passwordInvalid && (
            <div className={styles.error}>
              <ResourceTextComponent resourceKey="general.formValidation.newPassword.invalid" />
            </div>
          )}
          <ResetPasswordFormComponent
            intl={intl}
            onSubmit={(values: IResetPasswordFormValues) => {
              onResetPassword(values.password);
            }}
          />
        </>
      )}
    </AuthorizationContainer>
  );
};

export { ResetPassword };
