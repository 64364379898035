import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutDealDTO } from "@app/api/generated";
import { FilterItem } from "@app/api/core/filter/filter-item";

export interface IDealsState {
  currentCreatedBy?: number;
  currentMerchant?: FilterItem;
  currentPage: number;
  currentPlatform?: number;
  currentSearch?: string;
  currentStatus?: number;
  deals: OutDealDTO[];
  isLoadingDeals: boolean;
  selectedDeal?: OutDealDTO;
  totalResults: number;
}

const initialState: IDealsState = {
  currentCreatedBy: undefined,
  currentMerchant: undefined,
  currentPage: 1,
  currentPlatform: undefined,
  currentSearch: undefined,
  currentStatus: undefined,
  deals: [],
  isLoadingDeals: false,
  selectedDeal: undefined,
  totalResults: 0
};

export const slice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setDeals: (state, action: PayloadAction<OutDealDTO[]>) => {
      state.deals = action.payload;
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    deleteDealFromList: (state, action: PayloadAction<number>) => {
      state.deals = state.deals.filter((item) => item.id !== action.payload);
    },
    setIsLoadingDeals: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDeals = action.payload;
    },
    setSelectedDeal: (state, action: PayloadAction<OutDealDTO | undefined>) => {
      state.selectedDeal = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentPlatform: (state, action: PayloadAction<number | undefined>) => {
      state.currentPlatform = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    },
    setCurrentStatus: (state, action: PayloadAction<number | undefined>) => {
      state.currentStatus = action.payload;
    },
    setCurrentMerchant: (state, action: PayloadAction<FilterItem | undefined>) => {
      state.currentMerchant = action.payload;
    },
    setCurrentCreatedBy: (state, action: PayloadAction<number | undefined>) => {
      state.currentCreatedBy = action.payload;
    }
  }
});

export const {
  setDeals,
  setTotalResults,
  deleteDealFromList,
  setIsLoadingDeals,
  setSelectedDeal,
  setCurrentPage,
  setCurrentPlatform,
  setCurrentSearch,
  setCurrentStatus,
  setCurrentMerchant,
  setCurrentCreatedBy
} = slice.actions;

export default slice.reducer;
