/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ConfigurationItemDTO
 */
export interface ConfigurationItemDTO {
  /**
   *
   * @type {string}
   * @memberof ConfigurationItemDTO
   */
  field?: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurationItemDTO
   */
  condition?: string;
  /**
   *
   * @type {string}
   * @memberof ConfigurationItemDTO
   */
  value?: string;
}

export function ConfigurationItemDTOFromJSON(json: any): ConfigurationItemDTO {
  return ConfigurationItemDTOFromJSONTyped(json, false);
}

export function ConfigurationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationItemDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    field: !exists(json, "field") ? undefined : json["field"],
    condition: !exists(json, "condition") ? undefined : json["condition"],
    value: !exists(json, "value") ? undefined : json["value"]
  };
}

export function ConfigurationItemDTOToJSON(value?: ConfigurationItemDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    field: value.field,
    condition: value.condition,
    value: value.value
  };
}
