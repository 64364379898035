import { createSlice } from "@reduxjs/toolkit";

export interface ISettingsState {}

const initialState: ISettingsState = {};

export const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetStore: () => {}
  }
});

export const { resetStore } = slice.actions;

export default slice.reducer;
