/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OutMerchantDTO,
  OutMerchantDTOFromJSON,
  OutMerchantDTOFromJSONTyped,
  OutMerchantDTOToJSON,
  UserRoleDTO,
  UserRoleDTOFromJSON,
  UserRoleDTOFromJSONTyped,
  UserRoleDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutDetailUserDTO
 */
export interface OutDetailUserDTO {
  /**
   *
   * @type {UserRoleDTO}
   * @memberof OutDetailUserDTO
   */
  role: UserRoleDTO;
  /**
   *
   * @type {string}
   * @memberof OutDetailUserDTO
   */
  company?: string;
  /**
   *
   * @type {string}
   * @memberof OutDetailUserDTO
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OutDetailUserDTO
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof OutDetailUserDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof OutDetailUserDTO
   */
  phone?: string;
  /**
   *
   * @type {number}
   * @memberof OutDetailUserDTO
   */
  id: number;
  /**
   *
   * @type {Array<OutMerchantDTO>}
   * @memberof OutDetailUserDTO
   */
  merchants: Array<OutMerchantDTO>;
}

export function OutDetailUserDTOFromJSON(json: any): OutDetailUserDTO {
  return OutDetailUserDTOFromJSONTyped(json, false);
}

export function OutDetailUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutDetailUserDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: UserRoleDTOFromJSON(json["role"]),
    company: !exists(json, "company") ? undefined : json["company"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    email: json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    id: json["id"],
    merchants: (json["merchants"] as Array<any>).map(OutMerchantDTOFromJSON)
  };
}

export function OutDetailUserDTOToJSON(value?: OutDetailUserDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: UserRoleDTOToJSON(value.role),
    company: value.company,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    phone: value.phone,
    id: value.id,
    merchants: (value.merchants as Array<any>).map(OutMerchantDTOToJSON)
  };
}
