import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterTypeDTO, OutFilterDTO, PutFilterDTO } from "@app/api/generated";

export interface IFiltersState {
  filters?: OutFilterDTO[];
  filterTypes?: FilterTypeDTO[];
  selectedFilterType?: number;
  isLoadingFilters: boolean;
  totalResults: number;
  currentPage: number;
  currentSearch?: string;
}

const initialState: IFiltersState = {
  filterTypes: [],
  filters: [],
  totalResults: 0,
  isLoadingFilters: false,
  currentPage: 1,
  currentSearch: undefined
};

export const slice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<PutFilterDTO[]>) => {
      state.filters = action.payload;
    },
    deleteFilterFromList: (state, action: PayloadAction<number>) => {
      state.filters = state.filters?.filter((filter) => filter.id !== action.payload);
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    setFilterTypes: (state, action: PayloadAction<FilterTypeDTO[]>) => {
      state.filterTypes = action.payload;
    },
    setSelectedFilterType: (state, action: PayloadAction<number | undefined>) => {
      state.selectedFilterType = action.payload;
    },
    setIsLoadingFilters: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFilters = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    }
  }
});

export const {
  setFilterTypes,
  setFilters,
  setIsLoadingFilters,
  setCurrentPage,
  setCurrentSearch,
  setTotalResults,
  setSelectedFilterType,
  deleteFilterFromList
} = slice.actions;

export default slice.reducer;
