/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FeaturedCategoriesMvoDTO
 */
export interface FeaturedCategoriesMvoDTO {
  /**
   *
   * @type {number}
   * @memberof FeaturedCategoriesMvoDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FeaturedCategoriesMvoDTO
   */
  categoryTitle?: string;
  /**
   *
   * @type {string}
   * @memberof FeaturedCategoriesMvoDTO
   */
  trackingUrl?: string;
  /**
   *
   * @type {string}
   * @memberof FeaturedCategoriesMvoDTO
   */
  slug?: string;
}

export function FeaturedCategoriesMvoDTOFromJSON(json: any): FeaturedCategoriesMvoDTO {
  return FeaturedCategoriesMvoDTOFromJSONTyped(json, false);
}

export function FeaturedCategoriesMvoDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FeaturedCategoriesMvoDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    categoryTitle: !exists(json, "categoryTitle") ? undefined : json["categoryTitle"],
    trackingUrl: !exists(json, "trackingUrl") ? undefined : json["trackingUrl"],
    slug: !exists(json, "slug") ? undefined : json["slug"]
  };
}

export function FeaturedCategoriesMvoDTOToJSON(value?: FeaturedCategoriesMvoDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    categoryTitle: value.categoryTitle,
    trackingUrl: value.trackingUrl,
    slug: value.slug
  };
}
