import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { ClickableComponent } from "@app/core";
import BackIcon from "@assets/icons/back.svg";
import BinIcon from "@assets/icons/bin.svg";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BasicModal } from "@app/modules/basic-modal";
import { InDealDTO } from "@app/api/generated";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { dealsThunks } from "@app/redux/thunks/deals.thunk";
import { setSelectedDeal } from "@app/redux/reducers/deals";
import { ROUTES } from "@app/constants/routes";

import { DealForm } from "./deal.form";
import styles from "./deal-detail-component.module.scss";

const DealDetail = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { selectedDeal } = useAppSelector((state) => state.deals);
  const history = useNavigate();

  const [isSavingDeal, setIsSavingDeal] = useState<boolean>(false);
  const [isDeleteDealOpen, setIsDeleteDealOpen] = useState<boolean>(false);
  const [isEditDealOpen, setIsEditDealOpen] = useState<boolean>(false);
  const [isDealEdited, setIsDealEdited] = useState<boolean>(false);
  const dealEditStateForEventListeners = useRef(isDealEdited);

  useEffect(() => {
    if (!!id) {
      dispatch(dealsThunks.getDeal(Number(id)));
    }

    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", checkReload);

    return () => {
      window.removeEventListener("beforeunload", checkReload);
      dispatch(setSelectedDeal(undefined));
    };
  }, []);

  useEffect(() => {
    dealEditStateForEventListeners.current = isDealEdited;
  }, [isDealEdited]);

  const checkReload = (e: BeforeUnloadEvent) => {
    if (dealEditStateForEventListeners.current) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const onDeleteDeal = () => {
    if (selectedDeal) {
      dispatch(dealsThunks.deleteDeal(selectedDeal.id));

      setTimeout(() => {
        history(ROUTES.DEAL_OVERVIEW);
      }, 1000);
    }
    setIsDeleteDealOpen(false);
  };

  const onDealCancel = (isSavedCheck: boolean) => {
    if (isDealEdited && isSavedCheck) {
      setIsEditDealOpen(true);
    } else {
      setIsEditDealOpen(false);
      cleanEditState();
      history(ROUTES.DEAL_OVERVIEW);
    }
  };

  const onDealSave = async (values: InDealDTO) => {
    setIsSavingDeal(true);
    cleanEditState();
    await dispatch(dealsThunks.saveDeal(values));
    setIsSavingDeal(false);
  };

  const onEdit = () => {
    if (!isDealEdited) {
      setIsDealEdited(true);
    }
  };

  const cleanEditState = () => {
    setIsDealEdited(false);
  };

  return (
    <div className={styles.dealDetail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <div className={styles.topBarLeft}>
            <ClickableComponent
              variant="link-primary"
              title={
                selectedDeal
                  ? selectedDeal.name
                  : intl.formatMessage({
                      id: "dealDetail.button.add"
                    })
              }
              icon={BackIcon}
              iconPosition="left"
              iconSize="24px"
              onClick={() => {
                onDealCancel(true);
              }}
            />
          </div>
          <div className={styles.topBarRight}>
            <ClickableComponent
              variant="tertiary"
              iconStyle="outline"
              title={intl.formatMessage({ id: "dealDetail.button.delete" })}
              buttonType="button"
              icon={BinIcon}
              iconPosition="left"
              iconSize="20px"
              height={40}
              onClick={() => setIsDeleteDealOpen(true)}
            />
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.details}>
          {!selectedDeal ? (
            <SpinningLoader />
          ) : (
            <DealForm
              intl={intl}
              onSubmit={onDealSave}
              onCancel={onDealCancel}
              onEdit={onEdit}
              disableSaveButton={!isDealEdited}
              deal={selectedDeal}
              isLoading={isSavingDeal}
            />
          )}
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "dealDetail.modal.deleteDeal.title" })}
        isModalOpen={isDeleteDealOpen}
        onCloseModal={() => setIsDeleteDealOpen(false)}
        variant="big"
      >
        <DeleteModal onCancel={() => setIsDeleteDealOpen(false)} onDelete={onDeleteDeal} />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "dealDetail.modal.leavePage.title" })}
        isModalOpen={isEditDealOpen}
        onCloseModal={() => setIsEditDealOpen(false)}
        variant="big"
      >
        <BasicModal
          onApprove={() => onDealCancel(false)}
          onCancel={() => setIsEditDealOpen(false)}
          onApproveTitle={intl.formatMessage({ id: "dealDetail.modal.leavePage.approve" })}
          onCancelTitel={intl.formatMessage({ id: "dealDetail.modal.leavePage.cancel" })}
          description={intl.formatMessage({ id: "dealDetail.modal.leavePage.description" })}
        />
      </ModalComponent>
    </div>
  );
};

export { DealDetail };
