import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IntlShape, useIntl } from "react-intl";
import clsx from "clsx";
import { shallowEqual } from "react-redux";
import ReactTooltip from "react-tooltip";

import { DealFilterBar } from "@app/modules/deal-filter-bar/deal-filter-bar.component";
import Bin from "@assets/icons/bin.svg";
import Edit from "@assets/icons/edit.svg";
import { Pagination } from "@app/core/pagination";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { OutDealDTO, OutFilterDTO, OutDealDTOCreatedByEnum, InExternalDealFilterDTO } from "@app/api/generated";
import { Table } from "@app/components/table/table";
import { Column } from "@webbio/components";
import { IconComponent, LabelComponent, ResourceTextComponent } from "@app/core";
import { platformTranslationKeys } from "@app/constants/platform";
import { IDealEditOptions } from "@app/constants/deal";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { useAppSelector, useAppDispatch } from "@app/redux/store";
import { dealsThunks } from "@app/redux/thunks/deals.thunk";
import { setCurrentPage } from "@app/redux/reducers/deals";
import { usePrevious } from "@app/util";
import { useDateFnsFormat } from "@app/util/use-date-fns-format";
import { ROUTES } from "@app/constants/routes";
import { BetterModal } from "@app/core/better-modal/better-modal";

import { DealOverviewOptionsBar } from "./options-bar/deal-overview-options-bar-component";
import styles from "./deal-overview-component.module.scss";
import tableStyles from "./table-styling-component.module.scss";

const TAKE = 18;

const DealOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    deals,
    isLoadingDeals,
    totalResults,
    currentMerchant,
    currentPlatform,
    currentSearch,
    currentStatus,
    currentCreatedBy,
    currentPage
  } = useAppSelector((state) => state.deals, shallowEqual);
  const prevCurrentMerchant = usePrevious(currentMerchant);
  const { formatDate } = useDateFnsFormat();

  const history = useNavigate();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDeleteDealOpen, setIsDeleteDealOpen] = useState<boolean>(false);
  const [isDeleteBulkDealsOpen, setIsDeleteBulkDealsOpen] = useState<boolean>(false);
  const [dealToDelete, setDealToDelete] = useState<OutDealDTO | undefined>(undefined);
  const [bulkDealsToDelete, setBulkDealsToDelete] = useState<string | undefined>(undefined);
  const [selectedInTable, setSelectedInTable] = useState<number[]>([]);
  const [selectedEDFS, setSelectedEDFS] = useState<InExternalDealFilterDTO[]>();

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  useEffect(() => {
    getDeals(1);
  }, [currentPlatform, currentSearch, currentStatus, currentCreatedBy]);

  useEffect(() => {
    if (prevCurrentMerchant?.value !== currentMerchant?.value) {
      getDeals(1);
    }
  }, [currentMerchant]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [deals]);

  const onDeleteDeal = (deal?: OutDealDTO, bulkDeals?: string) => {
    if (deal) {
      setDealToDelete(deal);
      setIsDeleteDealOpen(true);
    } else if (bulkDeals) {
      setBulkDealsToDelete(bulkDeals);
      setIsDeleteBulkDealsOpen(true);
    }
  };

  const onDeleteConfirmed = async () => {
    if (dealToDelete) {
      await dispatch(dealsThunks.deleteDeal(dealToDelete.id));

      setIsDeleteDealOpen(false);
    } else if (bulkDealsToDelete) {
      await dispatch(dealsThunks.deleteBulkDeals(bulkDealsToDelete));

      setIsDeleteBulkDealsOpen(false);
      setSelectedInTable([]);
      getDeals();
    }
  };

  const getDeals = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(
      dealsThunks.getDeals(skip, TAKE, currentMerchant, currentPlatform, currentSearch, currentStatus, currentCreatedBy)
    );
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onPageChange = (page: number) => {
    getDeals(page);
    setSelectedInTable([]);
  };

  const handleActionRemove = async (): Promise<void> => {
    let payload = "";

    selectedInTable.forEach((id, index) => {
      if (deals && selectedInTable.length === index + 1) {
        payload += `${deals[id].id}`;
      } else if (deals) {
        payload += `${deals[id].id};`;
      }
    });
    onDeleteDeal(undefined, payload);
  };

  const handleActionEdit = async (changes: IDealEditOptions): Promise<void> => {
    const liveStatus = changes.status === 1;

    const dealsToEdit =
      deals &&
      (selectedInTable
        .map((index) => {
          return {
            ...deals[index],
            live: liveStatus
          };
        })
        .filter(Boolean) as OutDealDTO[]);

    if (dealsToEdit) {
      await dispatch(dealsThunks.editBulkDeals({ deals: dealsToEdit }));
    }

    setSelectedInTable([]);
    getDeals();
  };

  const handelTableSelect = async (items: number[]): Promise<void> => {
    setSelectedInTable([...items]);
  };

  const getBrandsString = (brands: OutFilterDTO[] | undefined) => {
    let brandNames: any = [];
    if (brands && brands.length > 0) {
      brands.map((brand) => {
        brandNames = [...brandNames, brand.name];
      });

      return brandNames.join(", ");
    }

    return "-";
  };

  const getCategoriesString = (categories: OutFilterDTO[] | undefined) => {
    let categoryNames: any = [];
    if (categories && categories.length > 0) {
      categories.map((category) => {
        categoryNames = [...categoryNames, category.name];
      });

      return categoryNames.join(", ");
    }

    return "-";
  };

  const onTableRowClick = (deal: OutDealDTO) => {
    history(`/deal-detail/${deal.id}`);
  };

  return (
    <div className={styles.dealOverview}>
      <div className={styles.container}>
        <DealFilterBar />
        <DealOverviewOptionsBar onEdit={handleActionEdit} onRemove={handleActionRemove} isSelected={selectedInTable} />
        <div className={styles.overview}>
          {deals && deals.length > 0 ? (
            <Table dataSource={deals || []} isLoading={isLoadingDeals} onSelect={handelTableSelect}>
              <Column
                title={getColumns(intl).status.title}
                field={getColumns(intl).status.field}
                id={getColumns(intl).status.id}
                onTdClick={onTableRowClick}
                cell={(live): JSX.Element => {
                  return (
                    <span className={clsx(tableStyles.status)}>
                      <LabelComponent type={live ? "live" : "offline"} />
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).name.title}
                field={getColumns(intl).name.field}
                id={getColumns(intl).name.id}
                onTdClick={onTableRowClick}
                cell={(name): JSX.Element => {
                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.name)} title={name}>
                      {name || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).store.title}
                field={getColumns(intl).store.field}
                id={getColumns(intl).store.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];

                  return (
                    <span
                      className={clsx(tableStyles.truncate, tableStyles.store)}
                      title={deal.merchant && deal.merchant?.toString()}
                    >
                      {deal.merchant?.name || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).brand.title}
                field={getColumns(intl).brand.field}
                id={getColumns(intl).brand.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.brand)} title={deal.brands?.toString()}>
                      {getBrandsString(deal.brands)}
                    </span>
                  );
                }}
              />
              <Column
                title={getColumns(intl).category.title}
                field={getColumns(intl).category.field}
                id={getColumns(intl).category.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];

                  return (
                    <span
                      className={clsx(tableStyles.truncate, tableStyles.category)}
                      title={deal.categories?.toString()}
                    >
                      {getCategoriesString(deal.categories)}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).sponsored.title}
                field={getColumns(intl).sponsored.field}
                id={getColumns(intl).sponsored.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.sponsored)}>
                      {deal.sponsoredPosition || "-"}
                    </span>
                  );
                }}
              />
              <Column
                title={getColumns(intl).lastSyncMoment.title}
                field={getColumns(intl).lastSyncMoment.field}
                id={getColumns(intl).lastSyncMoment.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.lastSyncMoment)}>
                      {deal.lastSyncMoment ? formatDate(deal.lastSyncMoment, "eeeeee. dd MMM yyyy HH:mm") : "-"}
                    </span>
                  );
                }}
              />
              <Column
                title={getColumns(intl).lastEdited.title}
                field={getColumns(intl).lastEdited.field}
                id={getColumns(intl).lastEdited.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.lastEdited)}>
                      {deal.updatedAtByUser ? formatDate(deal.updatedAtByUser, "eeeeee. dd MMM yyyy HH:mm") : "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).platform.title}
                field={getColumns(intl).platform.field}
                id={getColumns(intl).platform.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];
                  const platformString =
                    deal &&
                    deal.platform &&
                    platformTranslationKeys.find((platformItem) => platformItem.id === deal.platform.id);

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.platform)}>
                      {platformString ? intl.formatMessage({ id: platformString.name }) : "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getColumns(intl).addedBy.title}
                field={getColumns(intl).addedBy.field}
                id={getColumns(intl).addedBy.id}
                onTdClick={onTableRowClick}
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];
                  const createdBy = (value: OutDealDTOCreatedByEnum) => {
                    switch (value) {
                      case OutDealDTOCreatedByEnum.API:
                        return intl.formatMessage({ id: "global.createdBy.api" });
                      case OutDealDTOCreatedByEnum.DASHBOARD:
                        return intl.formatMessage({ id: "global.createdBy.dashboard" });
                      case OutDealDTOCreatedByEnum.UNKNOWN:
                        return intl.formatMessage({ id: "global.createdBy.unknown" });
                      default:
                        return intl.formatMessage({ id: "global.createdBy.unknown" });
                    }
                  };

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.addedBy)}>
                      {createdBy(deal.createdBy as any)}
                    </span>
                  );
                }}
              />
              <Column
                title={getColumns(intl).edf.title}
                field={getColumns(intl).edf.field}
                id={getColumns(intl).edf.id}
                minWidth={64}
                onTdClick={(row: OutDealDTO) => {
                  if (!row.externalDealFilters || row.externalDealFilters.length === 0) {
                    onTableRowClick(row);
                  }
                }}
                cell={(edfs: InExternalDealFilterDTO[]) => {
                  if (!edfs || edfs.length === 0) {
                    return <></>;
                  }

                  const edf = edfs[0];

                  return (
                    <div className={tableStyles.edf}>
                      {edfs.length === 1 ? (
                        <Link
                          className={tableStyles.truncate}
                          to={`${ROUTES.EXTERNAL_DEAL_FILTERS}/${edf.id}`}
                          data-tip={edf.title}
                          data-for="BlackFriday"
                        >
                          {edf.id}
                        </Link>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setSelectedEDFS(edfs)}
                          data-tip={intl.formatMessage({ id: "dealOverviewComponent.table.edf.multiple.tooltip" })}
                          data-for="BlackFriday"
                        >
                          <span className={tableStyles.truncate}>{edfs.map((x) => x.id).join(", ")}</span>
                        </button>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                title=""
                field="id"
                cell={(_, idx) => {
                  const deal = deals?.[idx as number];

                  return (
                    <div className={clsx(tableStyles.push, styles.push)}>
                      <Link to={`/deal-detail/${deal.id}`} className={clsx(styles.smallButton, styles.editButton)}>
                        <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                      </Link>
                      <span
                        role="button"
                        className={clsx(styles.smallButton, styles.removeButton)}
                        onClick={() => onDeleteDeal(deal, undefined)}
                      >
                        <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                      </span>
                    </div>
                  );
                }}
              />
            </Table>
          ) : isLoadingDeals ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>
              <ResourceTextComponent resourceKey="dealOverviewComponent.table.noResults" />
            </span>
          )}
          <div className={styles.pagination}>
            <Pagination currentPage={currentPage} totalItems={totalPages} changePage={onPageChange} />
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "dealOverviewComponent.leavePageConfirmation" })}
        isModalOpen={isDeleteDealOpen}
        onCloseModal={() => {
          setIsDeleteDealOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteDealOpen(false);
          }}
          onDelete={onDeleteConfirmed}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "dealOverviewComponent.leavePageConfirmation.bulkDeals" })}
        isModalOpen={isDeleteBulkDealsOpen}
        onCloseModal={() => {
          setIsDeleteBulkDealsOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteBulkDealsOpen(false);
          }}
          onDelete={onDeleteConfirmed}
        />
      </ModalComponent>

      <BetterModal isOpen={Boolean(selectedEDFS)} onClose={() => setSelectedEDFS(undefined)} maxWidth={400}>
        <div className={styles.filterModalList}>
          {selectedEDFS?.map((edf) => (
            <Link key={edf.id} to={`${ROUTES.EXTERNAL_DEAL_FILTERS}/${edf.id}`} className={styles.filterModalItem}>
              <span className={styles.idLabel}>{edf.id}</span>
              <span>{edf.title}</span>
            </Link>
          ))}
        </div>
      </BetterModal>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getColumns = (intl: IntlShape) => ({
  status: {
    id: "status",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderStatus" }),
    field: "live"
  },
  name: {
    id: "name",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderName" }),
    field: "name"
  },
  store: {
    id: "store",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderStore" }),
    field: "store"
  },
  brand: {
    id: "brand",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderBrand" }),
    field: "brand"
  },
  category: {
    id: "category",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderCategory" }),
    field: "category"
  },
  sponsored: {
    id: "sponsored",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderSponsored" }),
    field: "sponsored"
  },
  lastSyncMoment: {
    id: "lastSyncMoment",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderLastSyncMoment" }),
    field: "lastSyncMoment"
  },
  lastEdited: {
    id: "lastEdited",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderLastEdited" }),
    field: "lastEdited"
  },
  platform: {
    id: "platform",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderPlatform" }),
    field: "platform"
  },
  addedBy: {
    id: "addedBy",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderAddedBy" }),
    field: "addedBy"
  },
  edf: {
    id: "externalDealFilters",
    title: intl.formatMessage({ id: "dealOverviewComponent.tableHeaderEDF" }),
    field: "externalDealFilters"
  }
});

export { DealOverview };
