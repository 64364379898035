import React, { useState, useEffect } from "react";
import { IntlShape, useIntl } from "react-intl";
import clsx from "clsx";
import { useNavigate, Link } from "react-router-dom";

import Bin from "@assets/icons/bin.svg";
import Edit from "@assets/icons/edit.svg";

import { Pagination } from "@app/core/pagination";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { OutMerchantDTO } from "@app/api/generated";
import { ResourceTextComponent, IconComponent, LabelComponent } from "@app/core";
import { MerchantFilterBar } from "@app/modules/merchant-filter-bar/merchant-filter-bar.component";
import { useAppSelector, useAppDispatch } from "@app/redux/store";
import { merchantThunks } from "@app/redux/thunks/merchants.thunk";
import { setCurrentPage } from "@app/redux/reducers/merchants";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { Table } from "@app/components/table/table";
import { Column } from "@webbio/components";
import { useDateFnsFormat } from "@app/util/use-date-fns-format";
import { platformTranslationKeys } from "@app/constants/platform";

import styles from "./merchant-overview-component.module.scss";
import tableStyles from "./table-styling-component.module.scss";

const TAKE = 18;

const MerchantOverview = () => {
  const {
    merchants,
    currentEditStatus,
    currentPlatform,
    currentSearch,
    currentStatus,
    totalResults,
    currentPage,
    isLoadingMerchants
  } = useAppSelector((state) => state.merchants);
  const { role } = useAppSelector((state) => state.userAccount);
  const intl = useIntl();
  const history = useNavigate();
  const { formatDate } = useDateFnsFormat();

  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDeleteMerchantOpen, setIsDeleteMerchantOpen] = useState<boolean>(false);
  const [merchantToDelete, setMerchantToDelete] = useState<OutMerchantDTO | undefined>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getMerchants(1);
  }, [currentEditStatus, currentPlatform, currentSearch, currentStatus]);

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  const onDeleteMerchant = (merchant: OutMerchantDTO) => {
    setMerchantToDelete(merchant);
    setIsDeleteMerchantOpen(true);
  };

  const onDeleteConfirmed = () => {
    if (merchantToDelete) {
      dispatch(merchantThunks.deleteMerchant(merchantToDelete.id));

      setIsDeleteMerchantOpen(false);
    }
  };

  const onPageChange = (page: number) => {
    getMerchants(page);
  };

  const getMerchants = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(
      merchantThunks.getMerchants(
        skip,
        TAKE,
        currentEditStatus,
        undefined,
        currentStatus,
        currentPlatform,
        currentSearch
      )
    );
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onTableRowClick = (merchant: OutMerchantDTO) => {
    history(`/merchant-detail/${merchant.id}`);
  };

  return (
    <div className={styles.storeOverview}>
      <div className={styles.container}>
        <MerchantFilterBar />
        <div className={styles.overview}>
          {merchants && merchants.length > 0 ? (
            <Table dataSource={merchants || []} isLoading={isLoadingMerchants}>
              <Column
                title={getMerchantColumns(intl).status.title}
                field={getMerchantColumns(intl).status.field}
                id={getMerchantColumns(intl).status.id}
                onTdClick={onTableRowClick}
                maxWidth={80}
                width={80}
                cell={(live): JSX.Element => {
                  return (
                    <span className={clsx(tableStyles.status)}>
                      <LabelComponent type={live ? "live" : "offline"} />
                    </span>
                  );
                }}
              />

              <Column
                title={getMerchantColumns(intl).name.title}
                field={getMerchantColumns(intl).name.field}
                id={getMerchantColumns(intl).name.id}
                onTdClick={onTableRowClick}
                maxWidth={800}
                width={800}
                cell={(name): JSX.Element => {
                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.name)} title={name}>
                      {name || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getMerchantColumns(intl).editStatus.title}
                field={getMerchantColumns(intl).editStatus.field}
                id={getMerchantColumns(intl).editStatus.id}
                onTdClick={onTableRowClick}
                maxWidth={200}
                width={200}
                cell={(lastEdit) => {
                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.edit)}>
                      {lastEdit === "approved"
                        ? intl.formatMessage({ id: "merchantOverviewItem.merchant.published" })
                        : intl.formatMessage({ id: "merchantOverviewItem.merchant.editedByShopOwner" })}
                    </span>
                  );
                }}
              />

              <Column
                title={getMerchantColumns(intl).updatedAtByUser.title}
                field={getMerchantColumns(intl).updatedAtByUser.field}
                id={getMerchantColumns(intl).updatedAtByUser.id}
                onTdClick={onTableRowClick}
                maxWidth={200}
                width={200}
                cell={(updatedAt) => {
                  const updatedAtByUser = updatedAt ? formatDate(updatedAt, "eeeeee. dd MMM yyyy HH:mm") : "";

                  return <span className={clsx(tableStyles.truncate, tableStyles.edit)}>{updatedAtByUser}</span>;
                }}
              />
              <Column
                title={getMerchantColumns(intl).platform.title}
                field={getMerchantColumns(intl).platform.field}
                id={getMerchantColumns(intl).platform.id}
                onTdClick={onTableRowClick}
                maxWidth={90}
                width={90}
                cell={(_, idx) => {
                  const merchant = merchants?.[idx as number];
                  const platformString =
                    merchant?.platform &&
                    platformTranslationKeys.find((platformItem) => platformItem.id === merchant.platform.id);

                  return (
                    <span className={clsx(tableStyles.truncate, tableStyles.platform)}>
                      {platformString ? intl.formatMessage({ id: platformString.name }) : "-"}
                    </span>
                  );
                }}
              />

              <Column
                title=""
                field="id"
                cell={(_, idx) => {
                  const merchant = merchants?.[idx as number];

                  return (
                    <div className={clsx(tableStyles.push, styles.push)}>
                      <Link
                        to={`/merchant-detail/${merchant.id}`}
                        className={clsx(styles.smallButton, styles.editButton)}
                      >
                        <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                      </Link>
                      {role === "admin" && (
                        <span
                          role="button"
                          className={clsx(styles.smallButton, styles.removeButton)}
                          onClick={() => onDeleteMerchant(merchant)}
                        >
                          <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            </Table>
          ) : isLoadingMerchants ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>
              <ResourceTextComponent resourceKey="storeOverview.table.noResults" />
            </span>
          )}
          <div className={styles.pagination}>
            <Pagination currentPage={currentPage} totalItems={totalPages} changePage={onPageChange} />
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "storeOverview.modal.deleteStore.title" })}
        isModalOpen={isDeleteMerchantOpen}
        onCloseModal={() => {
          setIsDeleteMerchantOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteMerchantOpen(false);
          }}
          onDelete={onDeleteConfirmed}
        />
      </ModalComponent>
    </div>
  );
};

export { MerchantOverview };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getMerchantColumns = (intl: IntlShape) => ({
  status: {
    id: "live",
    title: intl.formatMessage({ id: "storeOverview.tableHeader.status" }),
    field: "live"
  },
  name: {
    id: "name",
    title: intl.formatMessage({ id: "storeOverview.tableHeader.store" }),
    field: "name"
  },
  editStatus: {
    id: "lastEdit",
    title: intl.formatMessage({ id: "storeOverview.tableHeader.editStatus" }),
    field: "lastEdit"
  },
  updatedAtByUser: {
    id: "updatedAtByUser",
    title: intl.formatMessage({ id: "storeOverview.tableHeader.editDate" }),
    field: "updatedAtByUser"
  },
  platform: {
    id: "platform",
    title: intl.formatMessage({ id: "storeOverview.tableHeader.platform" }),
    field: "platform"
  }
});
