import React from "react";

import { useAppSelector } from "@app/redux/store";

import { MerchantDetail } from "./merchant-detail.component";

const MerchantDetailWrapper = () => {
  const { isLoading: isLoadingRole } = useAppSelector((state) => state.userAccount);

  if (isLoadingRole) {
    return null;
  }

  return <MerchantDetail />;
};

export { MerchantDetailWrapper };
