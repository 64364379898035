import React, { useState } from "react";
import { useIntl } from "react-intl";

import { FilterLabelDTO, OutFilterDTO } from "@app/api/generated";
import { labelColors, labelIcons } from "@app/api/core/filter/filter-item";
import { useAppDispatch } from "@app/redux/store";
import { filterThunks } from "@app/redux/thunks/filter.thunk";

import { colorToHex, FilterFormComponent, IFilterFormValues } from "./filter.form";

export interface IFilterModalProps {
  filterDto?: OutFilterDTO;
  onCancel: () => void;
  onSaveDone: () => void;
}

const FilterModal = (props: IFilterModalProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitFilter = async (values: IFilterFormValues) => {
    setIsLoading(true);
    if (!props.filterDto) {
      await dispatch(filterThunks.createFilter(values.name, values.filterType, getFilter(values)));
    } else {
      await dispatch(
        filterThunks.saveFilter(props.filterDto.id, values.name, values.filterType, getFilter(values, props.filterDto))
      );
    }

    setIsLoading(false);
    props.onSaveDone();
  };

  return (
    <>
      <FilterFormComponent
        intl={intl}
        onSubmit={submitFilter}
        onCancel={props.onCancel}
        isLoading={isLoading}
        filterDto={props.filterDto}
      />
    </>
  );
};

const getFilter = (values: IFilterFormValues, filterDto?: OutFilterDTO): FilterLabelDTO => {
  const color = labelColors.find((item) => item.id === Number(values.labelColor))?.name || "";
  const hexColor = colorToHex(color);

  return {
    colorHex: hexColor || "",
    icon: labelIcons.find((item) => item.id === Number(values.labelIcon))?.name || "",
    showText: !!values.showText,
    id: filterDto?.label?.id || 0 // There's no InFilterDTO for some reason and this ID is required in the Out, so we have to do this.
  };
};

export { FilterModal };
