import React, { useState } from "react";
import { useIntl } from "react-intl";

import { InUserDTO } from "@app/api/generated";

import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { usersThunks } from "@app/redux/thunks/users.thunk";

import { UserFormComponent } from "./user.form";

export interface IUserModalProps {
  onCancel: () => void;
  onDone: () => void;
}

const UserModal = (props: IUserModalProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { selectedUser } = useAppSelector((state) => state.users);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitUser = async (user: InUserDTO) => {
    setIsLoading(true);

    if (selectedUser?.id !== null) {
      await dispatch(usersThunks.saveUser(user));
    } else {
      await dispatch(usersThunks.createNewUser(user));
    }

    setIsLoading(false);
    props.onDone();
  };

  return (
    <>
      <UserFormComponent
        intl={intl}
        user={selectedUser}
        onSubmit={submitUser}
        onCancel={props.onCancel}
        isLoading={isLoading}
      />
    </>
  );
};

export { UserModal };
