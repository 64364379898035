/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MerchantDTO,
  MerchantDTOFromJSON,
  MerchantDTOFromJSONTyped,
  MerchantDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface CreateFeaturedCategoriesDTO
 */
export interface CreateFeaturedCategoriesDTO {
  /**
   *
   * @type {PlatformDTO}
   * @memberof CreateFeaturedCategoriesDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {MerchantDTO}
   * @memberof CreateFeaturedCategoriesDTO
   */
  merchant: MerchantDTO;
  /**
   *
   * @type {string}
   * @memberof CreateFeaturedCategoriesDTO
   */
  categoryTitle: string;
  /**
   *
   * @type {string}
   * @memberof CreateFeaturedCategoriesDTO
   */
  trackingUrl: string;
  /**
   *
   * @type {string}
   * @memberof CreateFeaturedCategoriesDTO
   */
  slug: string;
}

export function CreateFeaturedCategoriesDTOFromJSON(json: any): CreateFeaturedCategoriesDTO {
  return CreateFeaturedCategoriesDTOFromJSONTyped(json, false);
}

export function CreateFeaturedCategoriesDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateFeaturedCategoriesDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    platform: PlatformDTOFromJSON(json["platform"]),
    merchant: MerchantDTOFromJSON(json["merchant"]),
    categoryTitle: json["categoryTitle"],
    trackingUrl: json["trackingUrl"],
    slug: json["slug"]
  };
}

export function CreateFeaturedCategoriesDTOToJSON(value?: CreateFeaturedCategoriesDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    platform: PlatformDTOToJSON(value.platform),
    merchant: MerchantDTOToJSON(value.merchant),
    categoryTitle: value.categoryTitle,
    trackingUrl: value.trackingUrl,
    slug: value.slug
  };
}
