import React, { useState } from "react";
import { withFormik, FormikProps, Form } from "formik";
import { IntlShape } from "react-intl";

import { ClickableComponent, TextFieldComponent, ResourceTextComponent } from "@app/core";
import { OutDetailUserDTO } from "@app/api/generated";
import { phoneFormValidation } from "@app/util/form-validation-messages";

import styles from "./user-account-form.module.scss";

interface IFormValues {
  company?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

interface IOtherProps {
  intl: IntlShape;
}

const InnerForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { touched, errors } = props;

  const [formIsTouched, setFormIsTouched] = useState(false);

  const handleChange = (values: any) => {
    props.handleChange(values);

    if (!formIsTouched) {
      setFormIsTouched(true);
    }
  };

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <span>
              <ResourceTextComponent resourceKey="userAccountDetail.mainUserAccountInfo.userAccountForm.title" />
            </span>
          </div>

          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.company}
                  onChange={handleChange}
                  errorMessage={touched.company && errors.company}
                  id="company"
                  label={{
                    label: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.company.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.company.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.firstName}
                  onChange={handleChange}
                  errorMessage={touched.firstName && errors.firstName}
                  id="firstName"
                  label={{
                    label: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.firstName.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.firstName.tooltip"
                    })
                  }}
                />
              </div>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.lastName}
                  onChange={handleChange}
                  id="lastName"
                  errorMessage={touched.lastName && errors.lastName}
                  label={{
                    label: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.lastName.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.lastName.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.phone}
                  onChange={handleChange}
                  id="phone"
                  errorMessage={touched.phone && errors.phone}
                  label={{
                    label: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.phone.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.input.phone.tooltip"
                    })
                  }}
                />
              </div>
              <div className={styles.formField} />
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <div className={styles.buttonSubmit}>
                  <ClickableComponent
                    buttonType="submit"
                    title={props.intl.formatMessage({
                      id: "userAccountDetail.mainUserAccountInfo.userAccountForm.submit"
                    })}
                    disabled={!formIsTouched}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  intl: IntlShape;
  onSubmit: (values: any) => void;
  user: OutDetailUserDTO;
}

export const UserAccountForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: (props) => ({
    company: props.user.company || "",
    firstName: props.user.firstName || "",
    lastName: props.user.lastName || "",
    phone: props.user.phone || ""
  }),

  validate: (values: IFormValues, props: IMyFormProps) => {
    const errors: any = {};

    const phoneValidation = phoneFormValidation(values.phone);

    if (phoneValidation) {
      errors.phone = props.intl.formatMessage({
        id: phoneValidation
      });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
