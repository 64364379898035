import React from "react";
import { Link } from "react-router-dom";
import ChevronRight from "@assets/icons/chevron-right.svg";
import ChevronLeft from "@assets/icons/chevron-left.svg";
import style from "./pagination-component.module.scss";
import { calculatePages } from "./calculate-pages/calculate-pages";
import { ClickableComponent } from "../clickable";

export interface IPaginationComponentProps {
  changePage?: (page: number) => void;
  currentPage: number;
  totalItems: number;
}

const Pagination: React.FunctionComponent<IPaginationComponentProps> = (props: IPaginationComponentProps) => {
  const changePage = (page: number) => {
    if (props.changePage) {
      props.changePage(page);
    }
  };
  const nextPage = () => {
    if (props.currentPage < props.totalItems) {
      changePage(props.currentPage + 1);
    }
  };
  const previousPage = () => {
    if (props.currentPage > 1) {
      changePage(props.currentPage - 1);
    }
  };

  const renderPaginationButton = (pages: number) => {
    const pagesIndex = calculatePages(pages, props.currentPage);

    return pagesIndex.map((page) => (
      <li key={page}>
        <Link
          to="#"
          onClick={() => {
            changePage(page);
          }}
          className={
            props.currentPage === page
              ? `${style["pagination-item"]} ${style["pagination-item--current"]}`
              : style["pagination-item"]
          }
        >
          {page}
        </Link>
      </li>
    ));
  };

  return (
    <div>
      {props.totalItems > 1 && (
        <div className={style.pagination}>
          <ClickableComponent
            height={32}
            width={32}
            onClick={previousPage}
            icon={ChevronLeft}
            variant="primary-inverted"
          />
          <ul className={style["pagination-list"]}>{renderPaginationButton(props.totalItems)}</ul>
          <ClickableComponent
            height={32}
            width={32}
            onClick={nextPage}
            icon={ChevronRight}
            variant="primary-inverted"
          />
        </div>
      )}
    </div>
  );
};

export { Pagination };
