import { OutDetailUserDTO } from "@app/api/generated";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserAccountState {
  role: string;
  user: OutDetailUserDTO | undefined;
  userId: number | undefined;
  isLoading?: boolean;
}

const initialState: IUserAccountState = {
  isLoading: true,
  role: "",
  user: undefined,
  userId: undefined
};

export const slice = createSlice({
  name: "userAccount",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<OutDetailUserDTO>) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
      state.isLoading = false;
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
      state.isLoading = false;
    }
  }
});

export const { setUser, setRole, setUserId } = slice.actions;

export default slice.reducer;
