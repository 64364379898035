/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CreateDealDTO,
  CreateDealDTOFromJSON,
  CreateDealDTOToJSON,
  DealArrayDTO,
  DealArrayDTOFromJSON,
  DealArrayDTOToJSON,
  DealMerchantsResponseDTO,
  DealMerchantsResponseDTOFromJSON,
  DealMerchantsResponseDTOToJSON,
  DealsListResponseDTO,
  DealsListResponseDTOFromJSON,
  DealsListResponseDTOToJSON,
  InDealDTO,
  InDealDTOFromJSON,
  InDealDTOToJSON,
  OutDealDTO,
  OutDealDTOFromJSON,
  OutDealDTOToJSON
} from "../models";

export interface DealsBulkDeleteRequest {
  dealIds: string;
}

export interface DealsBulkGetRequest {
  platformId: number;
  ids: string;
  labelFilter?: string;
}

export interface DealsBulkPatchRequest {
  dealArrayDTO: DealArrayDTO;
}

export interface DealsCountGetRequest {
  platformId: number;
  merchantFilters?: string;
  filters?: string;
}

export interface DealsDealIdDeleteRequest {
  dealId: number;
}

export interface DealsDealIdGetRequest {
  dealId: number;
}

export interface DealsDealIdPutRequest {
  dealId: number;
  inDealDTO: InDealDTO;
}

export interface DealsExtendedGetRequest {
  take: number;
  skip: number;
  ean?: string;
  externalDealFilters?: string;
  merchantFilters?: string;
  searchQuery?: string;
  filters?: string;
  createdBy?: DealsExtendedGetCreatedByEnum;
  platformId?: number;
  isLive?: string;
  sponsored?: number;
}

export interface DealsGetRequest {
  take: number;
  skip: number;
  orderByDirection?: string;
  orderBy?: string;
  merchantFilters?: string;
  searchQuery?: string;
  filters?: string;
  sponsored?: number;
  platformId?: number;
  externalDealFilters?: string;
}

export interface DealsMerchantsGetRequest {
  searchQuery?: string;
  dealIds?: string;
  platformId?: number;
  take?: number;
}

export interface DealsPostRequest {
  createDealDTO: CreateDealDTO;
}

/**
 * no description
 */
export class DealsApi extends runtime.BaseAPI {
  /**
   */
  async dealsBulkDeleteRaw(requestParameters: DealsBulkDeleteRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.dealIds === null || requestParameters.dealIds === undefined) {
      throw new runtime.RequiredError(
        "dealIds",
        "Required parameter requestParameters.dealIds was null or undefined when calling dealsBulkDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.dealIds !== undefined) {
      queryParameters["dealIds"] = requestParameters.dealIds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals/bulk`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async dealsBulkDelete(dealIds: string): Promise<void> {
    await this.dealsBulkDeleteRaw({ dealIds: dealIds });
  }

  /**
   */
  async dealsBulkGetRaw(requestParameters: DealsBulkGetRequest): Promise<runtime.ApiResponse<DealsListResponseDTO>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling dealsBulkGet."
      );
    }

    if (requestParameters.ids === null || requestParameters.ids === undefined) {
      throw new runtime.RequiredError(
        "ids",
        "Required parameter requestParameters.ids was null or undefined when calling dealsBulkGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.labelFilter !== undefined) {
      queryParameters["labelFilter"] = requestParameters.labelFilter;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.ids !== undefined) {
      queryParameters["ids"] = requestParameters.ids;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals/bulk`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DealsListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async dealsBulkGet(platformId: number, ids: string, labelFilter?: string): Promise<DealsListResponseDTO> {
    const response = await this.dealsBulkGetRaw({ platformId: platformId, ids: ids, labelFilter: labelFilter });
    return await response.value();
  }

  /**
   */
  async dealsBulkPatchRaw(requestParameters: DealsBulkPatchRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.dealArrayDTO === null || requestParameters.dealArrayDTO === undefined) {
      throw new runtime.RequiredError(
        "dealArrayDTO",
        "Required parameter requestParameters.dealArrayDTO was null or undefined when calling dealsBulkPatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/deals/bulk`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: DealArrayDTOToJSON(requestParameters.dealArrayDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async dealsBulkPatch(dealArrayDTO: DealArrayDTO): Promise<void> {
    await this.dealsBulkPatchRaw({ dealArrayDTO: dealArrayDTO });
  }

  /**
   */
  async dealsCountGetRaw(requestParameters: DealsCountGetRequest): Promise<runtime.ApiResponse<number>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling dealsCountGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.merchantFilters !== undefined) {
      queryParameters["merchantFilters"] = requestParameters.merchantFilters;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async dealsCountGet(platformId: number, merchantFilters?: string, filters?: string): Promise<number> {
    const response = await this.dealsCountGetRaw({
      platformId: platformId,
      merchantFilters: merchantFilters,
      filters: filters
    });
    return await response.value();
  }

  /**
   */
  async dealsDealIdDeleteRaw(requestParameters: DealsDealIdDeleteRequest): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.dealId === null || requestParameters.dealId === undefined) {
      throw new runtime.RequiredError(
        "dealId",
        "Required parameter requestParameters.dealId was null or undefined when calling dealsDealIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals/{dealId}`.replace(`{${"dealId"}}`, encodeURIComponent(String(requestParameters.dealId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async dealsDealIdDelete(dealId: number): Promise<boolean> {
    const response = await this.dealsDealIdDeleteRaw({ dealId: dealId });
    return await response.value();
  }

  /**
   */
  async dealsDealIdGetRaw(requestParameters: DealsDealIdGetRequest): Promise<runtime.ApiResponse<OutDealDTO>> {
    if (requestParameters.dealId === null || requestParameters.dealId === undefined) {
      throw new runtime.RequiredError(
        "dealId",
        "Required parameter requestParameters.dealId was null or undefined when calling dealsDealIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals/{dealId}`.replace(`{${"dealId"}}`, encodeURIComponent(String(requestParameters.dealId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDealDTOFromJSON(jsonValue));
  }

  /**
   */
  async dealsDealIdGet(dealId: number): Promise<OutDealDTO> {
    const response = await this.dealsDealIdGetRaw({ dealId: dealId });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async dealsDealIdPutRaw(requestParameters: DealsDealIdPutRequest): Promise<runtime.ApiResponse<OutDealDTO>> {
    if (requestParameters.dealId === null || requestParameters.dealId === undefined) {
      throw new runtime.RequiredError(
        "dealId",
        "Required parameter requestParameters.dealId was null or undefined when calling dealsDealIdPut."
      );
    }

    if (requestParameters.inDealDTO === null || requestParameters.inDealDTO === undefined) {
      throw new runtime.RequiredError(
        "inDealDTO",
        "Required parameter requestParameters.inDealDTO was null or undefined when calling dealsDealIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/deals/{dealId}`.replace(`{${"dealId"}}`, encodeURIComponent(String(requestParameters.dealId))),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: InDealDTOToJSON(requestParameters.inDealDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDealDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async dealsDealIdPut(dealId: number, inDealDTO: InDealDTO): Promise<OutDealDTO> {
    const response = await this.dealsDealIdPutRaw({ dealId: dealId, inDealDTO: inDealDTO });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async dealsExtendedGetRaw(
    requestParameters: DealsExtendedGetRequest
  ): Promise<runtime.ApiResponse<DealsListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling dealsExtendedGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling dealsExtendedGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.ean !== undefined) {
      queryParameters["ean"] = requestParameters.ean;
    }

    if (requestParameters.externalDealFilters !== undefined) {
      queryParameters["externalDealFilters"] = requestParameters.externalDealFilters;
    }

    if (requestParameters.merchantFilters !== undefined) {
      queryParameters["merchantFilters"] = requestParameters.merchantFilters;
    }

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["createdBy"] = requestParameters.createdBy;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.isLive !== undefined) {
      queryParameters["isLive"] = requestParameters.isLive;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.sponsored !== undefined) {
      queryParameters["sponsored"] = requestParameters.sponsored;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals/extended`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DealsListResponseDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async dealsExtendedGet(
    take: number,
    skip: number,
    ean?: string,
    externalDealFilters?: string,
    merchantFilters?: string,
    searchQuery?: string,
    filters?: string,
    createdBy?: DealsExtendedGetCreatedByEnum,
    platformId?: number,
    isLive?: string,
    sponsored?: number
  ): Promise<DealsListResponseDTO> {
    const response = await this.dealsExtendedGetRaw({
      take: take,
      skip: skip,
      ean: ean,
      externalDealFilters: externalDealFilters,
      merchantFilters: merchantFilters,
      searchQuery: searchQuery,
      filters: filters,
      createdBy: createdBy,
      platformId: platformId,
      isLive: isLive,
      sponsored: sponsored
    });
    return await response.value();
  }

  /**
   */
  async dealsGetRaw(requestParameters: DealsGetRequest): Promise<runtime.ApiResponse<DealsListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling dealsGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling dealsGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.orderByDirection !== undefined) {
      queryParameters["orderByDirection"] = requestParameters.orderByDirection;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["orderBy"] = requestParameters.orderBy;
    }

    if (requestParameters.merchantFilters !== undefined) {
      queryParameters["merchantFilters"] = requestParameters.merchantFilters;
    }

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.sponsored !== undefined) {
      queryParameters["sponsored"] = requestParameters.sponsored;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.externalDealFilters !== undefined) {
      queryParameters["externalDealFilters"] = requestParameters.externalDealFilters;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DealsListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async dealsGet(
    take: number,
    skip: number,
    orderByDirection?: string,
    orderBy?: string,
    merchantFilters?: string,
    searchQuery?: string,
    filters?: string,
    sponsored?: number,
    platformId?: number,
    externalDealFilters?: string
  ): Promise<DealsListResponseDTO> {
    const response = await this.dealsGetRaw({
      take: take,
      skip: skip,
      orderByDirection: orderByDirection,
      orderBy: orderBy,
      merchantFilters: merchantFilters,
      searchQuery: searchQuery,
      filters: filters,
      sponsored: sponsored,
      platformId: platformId,
      externalDealFilters: externalDealFilters
    });
    return await response.value();
  }

  /**
   */
  async dealsMerchantsGetRaw(
    requestParameters: DealsMerchantsGetRequest
  ): Promise<runtime.ApiResponse<DealMerchantsResponseDTO>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.dealIds !== undefined) {
      queryParameters["dealIds"] = requestParameters.dealIds;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/deals/merchants`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DealMerchantsResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async dealsMerchantsGet(
    searchQuery?: string,
    dealIds?: string,
    platformId?: number,
    take?: number
  ): Promise<DealMerchantsResponseDTO> {
    const response = await this.dealsMerchantsGetRaw({
      searchQuery: searchQuery,
      dealIds: dealIds,
      platformId: platformId,
      take: take
    });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async dealsPostRaw(requestParameters: DealsPostRequest): Promise<runtime.ApiResponse<OutDealDTO>> {
    if (requestParameters.createDealDTO === null || requestParameters.createDealDTO === undefined) {
      throw new runtime.RequiredError(
        "createDealDTO",
        "Required parameter requestParameters.createDealDTO was null or undefined when calling dealsPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/deals`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateDealDTOToJSON(requestParameters.createDealDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDealDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async dealsPost(createDealDTO: CreateDealDTO): Promise<OutDealDTO> {
    const response = await this.dealsPostRaw({ createDealDTO: createDealDTO });
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum DealsExtendedGetCreatedByEnum {
  API = "API",
  DASHBOARD = "DASHBOARD",
  UNKNOWN = "UNKNOWN"
}
