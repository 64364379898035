import React, { useEffect, useState } from "react";
import { withFormik, FormikProps, Form, FormikErrors, FieldArray } from "formik";
import { IntlShape } from "react-intl";
import { SingleValue } from "react-select";
import { uniq } from "lodash";

import { ClickableComponent, TextFieldComponent, IconComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import {
  ConfigurationItemDTO,
  CreateExternalDealFilterDTO,
  InExternalDealFilterDTO,
  OutExternalDealFilterDTO
} from "@app/api/generated";
import { useRefState } from "@app/util/use-ref-state";
import { platformType, getTranslatedPlatforms } from "@app/constants/platform";
import { FilterItem } from "@app/api/core/filter/filter-item";
import {
  mapAnyToFilterItem,
  mapAnyToFilterItems,
  mapMerchantToFilterItem,
  mapToFilterItem
} from "@app/api/core/filter/map-filter-item";
import { getTranslatedStatusses } from "@app/constants/status";
import { storeStatusType } from "@app/api/core/merchant/merchant";
import { NumberInput } from "@app/core/number-input/number-input.component";
import { sortOrder, sortOrderToTranslation } from "@app/constants/sort";
import { getFilters, getMerchants } from "@app/modules/deal-detail/components/api-calls";
import IconAdd from "@assets/icons/plus.svg";
import IconDelete from "@assets/icons/remove-circle-outline.svg";
import { externalDealFilterRuleConditions } from "@app/api/core/external-deal-filter/external-deal-filter-rule-conditions";
import { ReactSelect } from "@app/core/react-select/react-select";
import { FormGroup } from "@app/core/form-group/form-group";
import { FormRow } from "@app/core/form-row/form-row";
import { FormField } from "@app/core/form-field/form-field";
import {
  externalDealFilterSortFields,
  ExternalDealFilterSortFieldsEnum
} from "@app/api/core/external-deal-filter/external-deal-filter-sort-field";
import { externalDealFilterConfigurationFields } from "@app/api/core/external-deal-filter/external-deal-filter-configuration-values";
import { validateUrl } from "@app/util";

import styles from "./external-deal-filter-form.module.scss";

const MIN_RUN_INTERVAL_IN_SECONDS = 900;
const DEFAULT_RUN_INTERVAL_IN_SECONDS = 3600;
const MAX_PRODUCT_DEAL_LIMIT = 1000;
const MIN_PRODUCT_DEAL_LIMIT = 1;
const DEFAULT_PRODUCT_DEAL_LIMIT = 5;

interface FormConfigurations extends Omit<ConfigurationItemDTO, "condition" | "field"> {
  condition: FilterItem;
  field: FilterItem;
}

interface IFormValues {
  title: string;
  status: storeStatusType;
  platform: platformType;
  merchants: FilterItem[];
  productDealLimit: number;
  runIntervalSeconds: number;
  productDealSortField?: FilterItem;
  productDealSortOrder?: number;
  searchQuery?: string;
  configurations?: FormConfigurations[];
  categories?: FilterItem[];
  productFeedUrl?: string;
}

const InnerForm = (props: IMyFormProps & FormikProps<IFormValues>) => {
  const { touched, errors, values, setFieldValue, onEdit } = props;
  const [extraProductSortFieldOptions, setExtraProductSortFieldOptions] = useState<string[]>([
    values.productDealSortField?.value
  ]);
  const [extraConfigurationFieldOptions, setExtraConfigurationFieldOptions] = useState<string[]>(
    (values.configurations || [])?.map((x) => x.field?.value)
  );
  const platformRef = useRefState(values.platform);
  const translatedPlatforms = getTranslatedPlatforms(props.intl);
  const translatedStatus = getTranslatedStatusses(props.intl);
  const productSortFieldOptions = getCreatableSelectOptions(
    externalDealFilterSortFields,
    (label) =>
      props.intl.formatMessage({ id: `externalDealFilters.detail.form.productDealSortField.options.${label}` }),
    extraProductSortFieldOptions
  );
  const configurationFieldOptions = getCreatableSelectOptions(
    externalDealFilterConfigurationFields,
    (label) =>
      props.intl.formatMessage({ id: `externalDealFilters.detail.form.configurations.field.options.${label}` }),
    extraConfigurationFieldOptions
  );

  const configurationConditionsOptions = getCreatableSelectOptions(externalDealFilterRuleConditions, (label) =>
    props.intl.formatMessage({ id: `externalDealFilters.rules.condition.${label}` })
  );

  const onCustomChange = (value: any, id: string) => {
    handleChange(value);
    setFieldValue(id, value);
  };

  const handleChange = (value: any) => {
    props.handleChange(value);
    onEdit();
  };

  const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
    setFieldValue("merchants", []);
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const merchants = await getMerchants(inputValue, platformRef.current);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant)) : []);
  };

  const loadCategories = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const categories = await getFilters(inputValue, "1");
    callback((categories || []).map(mapToFilterItem));
  };

  return (
    <Form>
      <div className={styles.form}>
        <FormGroup
          title={props.intl.formatMessage({ id: "externalDealFilters.detail.form.formGroup.statusPlatform.title" })}
        >
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="title"
                onBlur={props.handleBlur}
                value={values.title}
                onChange={handleChange}
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.title.label" }),
                  errorMessage: touched.title && errors.title
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <SelectComponent
                id="status"
                onBlur={props.handleBlur}
                value={values.status}
                onChange={handleChange}
                options={translatedStatus}
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.status.label" }),
                  errorMessage: touched.status && errors.status
                }}
              />
            </FormField>
            <FormField>
              <SelectComponent
                id="platform"
                onBlur={props.handleBlur}
                value={values.platform}
                onChange={handlePlatformChange}
                options={translatedPlatforms}
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.platform.label" }),
                  errorMessage: touched.platform && errors.platform
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="productFeedUrl"
                onBlur={props.handleBlur}
                value={values.productFeedUrl}
                onChange={handleChange}
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.productFeedUrl.label" }),
                  errorMessage: touched.productFeedUrl && errors.productFeedUrl
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="merchants"
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.merchants.label" }),
                  errorMessage: touched.merchants && (errors.merchants as any as string)
                }}
                isMulti
                isClearable
                isAsync
                onBlur={props.handleBlur}
                value={values.merchants}
                onChange={(e) => onCustomChange(e, "merchants")}
                loadOptions={loadMerchants}
                enableCollapsing
              />
            </FormField>
          </FormRow>
        </FormGroup>

        <FormGroup title={props.intl.formatMessage({ id: "externalDealFilters.detail.form.formGroup.general.title" })}>
          <FormRow>
            <FormField>
              <NumberInput
                id="productDealLimit"
                type="number"
                onBlur={props.handleBlur}
                value={values.productDealLimit}
                onChange={onCustomChange}
                minValue={MIN_PRODUCT_DEAL_LIMIT}
                maxValue={MAX_PRODUCT_DEAL_LIMIT}
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.productDealLimit.label" }),
                  errorMessage: touched.productDealLimit && errors.productDealLimit
                }}
              />
            </FormField>
            <FormField>
              <NumberInput
                id="runIntervalSeconds"
                type="number"
                onBlur={props.handleBlur}
                value={values.runIntervalSeconds}
                onChange={onCustomChange}
                label={{
                  label: props.intl.formatMessage({ id: "externalDealFilters.detail.form.runIntervalSeconds.label" }),
                  errorMessage: touched.runIntervalSeconds && errors.runIntervalSeconds
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="productDealSortField"
                label={{
                  label: props.intl.formatMessage({
                    id: "externalDealFilters.detail.form.productDealSortField.label"
                  }),
                  errorMessage: touched.productDealSortField && errors.productDealSortField
                }}
                value={values.productDealSortField}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  const value = e as SingleValue<FilterItem>;
                  if (value?.value) {
                    setExtraProductSortFieldOptions([...(extraProductSortFieldOptions || []), value.value]);
                  }
                  onCustomChange(e, "productDealSortField");
                }}
                options={productSortFieldOptions}
                isCreatable
                useDefaultComponents
              />
            </FormField>
            <FormField>
              <SelectComponent
                id="productDealSortOrder"
                value={values.productDealSortOrder}
                onChange={handleChange}
                onBlur={props.handleBlur}
                options={sortOrderToTranslation(props.intl)}
                label={{
                  label: props.intl.formatMessage({
                    id: "externalDealFilters.detail.form.productDealSortOrder.label"
                  }),
                  errorMessage: touched.productDealSortOrder && errors.productDealSortOrder
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="searchQuery"
                onBlur={props.handleBlur}
                value={values.searchQuery}
                onChange={handleChange}
                label={{
                  label: props.intl.formatMessage({
                    id: "externalDealFilters.detail.form.searchQuery.label"
                  }),
                  errorMessage: touched.searchQuery && errors.searchQuery
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="categories"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.categories.label" }),
                  errorMessage: touched.categories && errors.categories
                }}
                isMulti
                value={values.categories}
                isClearable
                isAsync
                onBlur={props.handleBlur}
                onChange={(e) => onCustomChange(e, "categories")}
                loadOptions={loadCategories}
                enableCollapsing
              />
            </FormField>
          </FormRow>
        </FormGroup>

        <FormGroup
          title={props.intl.formatMessage({ id: "externalDealFilters.detail.form.formGroup.configurationRules.title" })}
        >
          <FormRow>
            <FieldArray
              name="configurations"
              render={(arrayHelpers) => (
                <div className={styles.configurationsWrapper}>
                  {values.configurations?.map((configuration, index) => {
                    const configurationTouched = arrayHelpers.form.touched
                      .configurations as unknown as (ConfigurationItemDTO | null)[];
                    const configurationErrors = arrayHelpers.form.errors
                      .configurations as unknown as (ConfigurationItemDTO | null)[];

                    const fieldError = configurationTouched?.[index]?.field && configurationErrors?.[index]?.field;
                    const conditionError =
                      configurationTouched?.[index]?.condition && configurationErrors?.[index]?.condition;
                    const valueError = configurationTouched?.[index]?.value && configurationErrors?.[index]?.value;

                    return (
                      <FormRow key={index} className={styles.configurationFormRow}>
                        <FormField>
                          <ReactSelect
                            id={`configurations.${index}.field`}
                            label={{
                              label:
                                index === 0
                                  ? props.intl.formatMessage({
                                      id: "externalDealFilters.detail.form.configurations.field.label"
                                    })
                                  : undefined,
                              errorMessage: fieldError
                            }}
                            useDefaultComponents
                            value={configuration.field}
                            onChange={(e) => {
                              const value = e as SingleValue<FilterItem>;
                              if (value?.value) {
                                setExtraConfigurationFieldOptions([
                                  ...(extraConfigurationFieldOptions || []),
                                  value.value
                                ]);
                              }
                              onCustomChange(e, `configurations.${index}.field`);
                            }}
                            options={configurationFieldOptions}
                            onBlur={props.handleBlur}
                            isCreatable
                            isClearable
                          />
                        </FormField>
                        <FormField>
                          <ReactSelect
                            id={`configurations.${index}.condition`}
                            label={{
                              label:
                                index === 0
                                  ? props.intl.formatMessage({
                                      id: "externalDealFilters.detail.form.configurations.condition.label"
                                    })
                                  : undefined,
                              errorMessage: conditionError
                            }}
                            useDefaultComponents
                            value={configuration.condition}
                            onChange={(e) => onCustomChange(e, `configurations.${index}.condition`)}
                            options={configurationConditionsOptions}
                            onBlur={props.handleBlur}
                          />
                        </FormField>
                        <FormField>
                          <TextFieldComponent
                            id={`configurations.${index}.value`}
                            label={{
                              label:
                                index === 0
                                  ? props.intl.formatMessage({
                                      id: "externalDealFilters.detail.form.configurations.value.label"
                                    })
                                  : undefined,
                              errorMessage: valueError
                            }}
                            onBlur={props.handleBlur}
                            value={configuration.value}
                            onChange={handleChange}
                          />
                        </FormField>
                        {index !== 0 ? (
                          <button
                            type="button"
                            className={styles.removeRow}
                            style={{ marginBottom: fieldError || conditionError || valueError ? "24px" : "0" }}
                            onClick={() => {
                              arrayHelpers.remove(index);
                              onEdit();
                            }}
                            title={props.intl.formatMessage({
                              id: "externalDealFilters.detail.form.configurations.remove"
                            })}
                            aria-label={props.intl.formatMessage({
                              id: "externalDealFilters.detail.form.configurations.remove"
                            })}
                          >
                            <IconComponent icon={IconDelete} size="20px" />
                          </button>
                        ) : (
                          <span style={{ width: "40px", height: "40px" }} />
                        )}
                      </FormRow>
                    );
                  })}

                  <button
                    className={styles.addRow}
                    type="button"
                    onClick={() => {
                      arrayHelpers.insert(values.configurations?.length || 0, {
                        field: "",
                        condition: "",
                        value: ""
                      });
                      onEdit();
                    }}
                  >
                    <IconComponent icon={IconAdd} size="20px" />
                    {props.intl.formatMessage({ id: "externalDealFilters.detail.form.configurations.add" })}
                  </button>
                </div>
              )}
            />
          </FormRow>
        </FormGroup>

        <div className={styles.bottomBar}>
          <div className={styles.actions}>
            <ClickableComponent
              variant="primary-inverted"
              title={props.intl.formatMessage({ id: "featuredCategoryDetailForm.button.cancel" })}
              buttonType="button"
              height={48}
              onClick={() => props.onCancel(false)}
            />
            <ClickableComponent
              buttonType="button"
              title={props.intl.formatMessage({ id: "dealForm.button.save" })}
              height={48}
              onClick={props.handleSubmit}
              disabled={props.disableSaveButton}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  externalDealFilter?: OutExternalDealFilterDTO;
  disableSaveButton: boolean;
  intl: IntlShape;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  onSubmit: (values: InExternalDealFilterDTO | CreateExternalDealFilterDTO) => void;
}

export const ExternalDealFilterDetailForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: ({ externalDealFilter, intl }) => ({
    title: externalDealFilter?.title || "",
    status: externalDealFilter?.live ? storeStatusType.live : storeStatusType.offline,
    platform: externalDealFilter?.platform.id || platformType.Nederland,
    merchants: (externalDealFilter?.merchants || []).map((merchant) => mapMerchantToFilterItem(merchant, false)),
    productDealLimit: externalDealFilter?.productDealLimit || DEFAULT_PRODUCT_DEAL_LIMIT,
    runIntervalSeconds: externalDealFilter?.runIntervalSeconds || DEFAULT_RUN_INTERVAL_IN_SECONDS,
    productDealSortField: getCreatableSelectValue(
      externalDealFilterSortFields,
      externalDealFilter?.productDealSort.field || ExternalDealFilterSortFieldsEnum.PRICE,
      (label) => intl.formatMessage({ id: `externalDealFilters.detail.form.productDealSortField.options.${label}` })
    ),
    productDealSortOrder:
      sortOrder.find((sort) => sort.name === externalDealFilter?.productDealSort.order)?.id || sortOrder[0]?.id,
    searchQuery: externalDealFilter?.searchQuery,
    configurations: configurationsToFormConfigurations(intl, externalDealFilter?.configurations),
    categories: (externalDealFilter?.categories || []).map(mapToFilterItem),
    productFeedUrl: externalDealFilter?.productFeedUrl
  }),

  validate: (values: IFormValues, props) => {
    const errors: FormikErrors<IFormValues> = {};

    if (!values.platform) {
      errors.platform = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.title) {
      errors.title = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.merchants || values.merchants.length === 0) {
      errors.merchants = props.intl.formatMessage({ id: "general.form.error.required" }) as any;
    }

    if (!values.productDealLimit) {
      errors.productDealLimit = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (values.productDealLimit < MIN_PRODUCT_DEAL_LIMIT || values.productDealLimit > MAX_PRODUCT_DEAL_LIMIT) {
      errors.productDealLimit = props.intl.formatMessage(
        {
          id: "externalDealFilters.detail.form.productDealLimit.errors.restriction"
        },
        { min: MIN_PRODUCT_DEAL_LIMIT, max: MAX_PRODUCT_DEAL_LIMIT }
      );
    }

    if (!values.runIntervalSeconds) {
      errors.runIntervalSeconds = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.runIntervalSeconds || values.runIntervalSeconds < MIN_RUN_INTERVAL_IN_SECONDS) {
      errors.runIntervalSeconds = props.intl.formatMessage(
        { id: "externalDealFilters.detail.form.runIntervalSeconds.errors.restriction" },
        { min: MIN_RUN_INTERVAL_IN_SECONDS }
      );
    }

    if (!values.searchQuery) {
      errors.searchQuery = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.categories || values.categories.length === 0) {
      errors.categories = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (values.productFeedUrl && !validateUrl(values.productFeedUrl)) {
      errors.productFeedUrl = props.intl.formatMessage({
        id: "externalDealFilters.detail.form.productFeedUrl.errors.restriction"
      });
    }

    if (values.configurations && values.configurations?.length > 0) {
      const configurationsErrors = values.configurations.map((configuration) => {
        if (!configuration.condition || !configuration.field || !configuration.value) {
          return {
            value: !configuration.value ? props.intl.formatMessage({ id: "general.form.error.required" }) : "",
            condition: !configuration.condition ? props.intl.formatMessage({ id: "general.form.error.required" }) : "",
            field: !configuration.field ? props.intl.formatMessage({ id: "general.form.error.required" }) : ""
          };
        }

        return undefined;
      });

      const cleaned = configurationsErrors.filter(Boolean);

      if (cleaned && cleaned.length > 0) {
        // configurationsErrors actually is an array of objects, but this formik version is weird with error objects
        errors.configurations = configurationsErrors as unknown as string;
      }
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const externalDealFilter = mapValuesToExternalDealFilter(values, bag.props.externalDealFilter);
    bag.props.onSubmit(externalDealFilter);
  }
})(InnerForm);

const mapValuesToExternalDealFilter = (
  values: IFormValues,
  externalDealFilter?: InExternalDealFilterDTO
): InExternalDealFilterDTO | CreateExternalDealFilterDTO => {
  const order = sortOrder.find((sort) => sort.id === Number(values.productDealSortOrder))?.name || "";

  const formValues: CreateExternalDealFilterDTO = {
    title: values.title,
    // eslint-disable-next-line eqeqeq
    live: values.status == storeStatusType.live,
    platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
    merchants: values.merchants?.map((merchant) => ({ id: Number(merchant.value) })),
    productDealLimit: values.productDealLimit,
    runIntervalSeconds: values.runIntervalSeconds,
    productDealSort: { field: values.productDealSortField?.value || "", order },
    searchQuery: values.searchQuery,
    configurations: formConfigurationsToConfigurations(values.configurations),
    categories: (values.categories || []).map((category) => ({ id: Number(category.value) })),
    productFeedUrl: values.productFeedUrl,
    // This can actually be null, but back-end cannot deal with multiple types yet
    nextRunAtDate: null as any
  };

  if (externalDealFilter?.id) {
    const savedExternalDealFilter: InExternalDealFilterDTO = { ...externalDealFilter, ...formValues };

    return savedExternalDealFilter;
  }

  return formValues;
};

const configurationsToFormConfigurations = (
  intl: IntlShape,
  configurations?: ConfigurationItemDTO[]
): FormConfigurations[] => {
  if (!configurations || configurations.length === 0) {
    return [{ value: "", field: "" as any, condition: "" as any }];
  }

  return (configurations || []).map((configuration) => ({
    ...configuration,
    field: getCreatableSelectValue(externalDealFilterConfigurationFields, configuration.field, (label) =>
      intl.formatMessage({ id: `externalDealFilters.detail.form.configurations.field.options.${label}` })
    ),
    condition: getCreatableSelectValue(externalDealFilterRuleConditions, configuration.condition, (label) =>
      intl.formatMessage({ id: `externalDealFilters.rules.condition.${label}` })
    )
  }));
};

const formConfigurationsToConfigurations = (formConfigurations?: FormConfigurations[]): ConfigurationItemDTO[] =>
  (formConfigurations || []).map((formConfiguration) => ({
    condition: formConfiguration.condition.value,
    field: formConfiguration.field.value,
    value: formConfiguration.value
  }));

const getCreatableSelectValue = (
  allOptions: string[],
  field?: string,
  translationTransformer?: (label: string) => string
): FilterItem =>
  mapAnyToFilterItem(field || "", undefined, undefined, (label) => {
    const exists = allOptions.find((x) => x === field);

    if (exists && translationTransformer) {
      return translationTransformer(label);
    }

    return label;
  });

const getCreatableSelectOptions = (
  options: string[],
  translationTransformer: (label: string) => string,
  extraValues?: string[]
): FilterItem[] => {
  const extraNonExistentValues =
    uniq(extraValues)?.filter((value) => !options.find((option) => option === value)) || [];
  const allOptions = [...options, ...extraNonExistentValues].filter(Boolean) as string[];

  return mapAnyToFilterItems(allOptions, undefined, undefined, (label) => {
    const exists = options.find((x) => x === label);

    if (exists) {
      return translationTransformer(label);
    }

    return label;
  });
};
