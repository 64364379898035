import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";

import PlusIcon from "@assets/icons/plus.svg";
import Bin from "@assets/icons/bin.svg";
import { ImageDTO } from "@app/api/generated";

import { IconComponent } from "../icon";
import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";
import { uploadImage } from "./upload-image-api";
import { ImageComponent } from "../image/image.component";
import styles from "./image-upload-component.module.scss";

export interface IImageUploadProps {
  id: string;
  image?: ImageDTO;
  label?: IFormLabelComponentProps;
  onChange: (value: any | null, id: string) => void;
  fullWidth?: boolean;
}

const ImageUpload = (props: IImageUploadProps) => {
  const [image, setImage] = useState<ImageDTO | undefined>(undefined);

  useEffect(() => {
    setImage(props.image);
  }, [props.image]);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (acceptedFiles[0].type.includes("image")) {
      const imageDTO: ImageDTO | undefined = await uploadImage(acceptedFiles[0]);

      if (imageDTO) {
        setImage(imageDTO);
      }

      props.onChange(imageDTO, props.id);
    }
  }, []);

  const onRemove = (e: any) => {
    if (props.onChange) {
      props.onChange(undefined, props.id);
    }

    e.stopPropagation();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={styles.imageUploadContainer}>
      {props.image && (
        <span role="button" className={styles.removeIcon} onClick={onRemove}>
          <span className={styles.icon}>
            <IconComponent icon={Bin} size="20px" strokeColor="black" />
          </span>
        </span>
      )}
      {props.label && <FormLabelComponent {...props.label} />}
      <div
        {...getRootProps()}
        className={clsx(styles.imageUpload, isDragActive && styles.dragActive, props.fullWidth && styles.fullWidth)}
      >
        <input {...getInputProps()} multiple={false} accept="image/*" />
        {!isDragActive && (
          <div className={styles.icon}>
            {!image ? (
              <IconComponent icon={PlusIcon} size="20px" strokeColor="black" fillColor="black" />
            ) : (
              <ImageComponent width="376px" height="131px" src={image.url} isBlocking />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { ImageUpload };
