import { FilterItem } from "@app/api/core/filter/filter-item";
import { MerchantsApi, FiltersApi, ExternalDealFiltersApi } from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";

const externalDealFiltersFiltersToJsonString = (
  searchQuery?: string,
  merchantId?: number,
  platformId?: number,
  status?: boolean
): string =>
  JSON.stringify({
    searchQuery,
    platformId,
    merchantId,
    status
  });

export const getMerchants = async (searchQuery: string, platformId?: number) => {
  try {
    if (!searchQuery) {
      return [];
    }
    const merchantsApi = new MerchantsApi(await getAuthorizedApiConfig());

    const allMerchants = await merchantsApi.merchantsExtendedGet(
      50,
      0,
      searchQuery,
      undefined,
      undefined,
      undefined,
      platformId
    );

    return allMerchants.merchants;
  } catch (error) {
    // TODO: Error logging
  }
};

export const getFilters = async (searchQuery: string, typeIds: string) => {
  try {
    if (!searchQuery) {
      return [];
    }

    const filterApi = new FiltersApi(await getAuthorizedApiConfig());
    const allFilters = await filterApi.filtersGet(searchQuery, 50, typeIds, undefined, undefined, undefined, undefined);

    return allFilters.filters;
  } catch (error) {
    // TODO: Error logging
  }
};

export const getExternalDealFilters = async (
  skip: number,
  take: number,
  merchantFilter?: FilterItem | null,
  platformId?: number,
  searchQuery?: string,
  status?: boolean
) => {
  try {
    if (!merchantFilter && platformId) {
      return [];
    }

    const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());

    const filterQuery = externalDealFiltersFiltersToJsonString(searchQuery, merchantFilter?.value, platformId, status);

    const resultFromApi = await api.externalDealFiltersGet(filterQuery, take, skip);

    return resultFromApi.externalDealFilters;
  } catch (error) {
    // TODO: Error logging
  }
};
