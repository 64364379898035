import { LOCALES } from "@app/constants";

const nlTranslations = require("./nl-nl.json");
const enTranslations = require("./en-gb.json");

export const getMessagesForLocale = (locale: string) => {
  switch (locale) {
    case LOCALES.NL:
      return nlTranslations;
    case LOCALES.EN:
      return enTranslations;
    default:
      return nlTranslations;
  }
};
