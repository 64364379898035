import { toast } from "react-toastify";
import { handleError } from "@app/util/handle-error";
import { UsersApi, ChangePasswordDTO, InUserDTO, OutDetailUserDTO } from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import JWTToken from "@app/authorization/jwt-token";
import { setNewPassword, logout } from "@app/authorization/authorization-api";
import { AppThunk } from "@app/redux/store";
import { setRole, setUser, setUserId } from "@app/redux/reducers/user-account";

export const getRole =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const role = JWTToken.getRole();
      dispatch(setRole(role));
    } catch {}
  };

const getUserId =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const userId = JWTToken.getUserId();
      dispatch(setUserId(userId));
    } catch {}
  };

const getUserAccount =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const userId = getState().userAccount.userId;

      if (userId) {
        const usersApi = new UsersApi(await getAuthorizedApiConfig());
        const user = await usersApi.usersUserIdGet(userId);
        dispatch(setUser(user));
      }
    } catch (error) {
      const errorMessage = getState().locales.messages["general.systemNotifications.userAccount.getUserAccount.error"];
      handleError(error, dispatch, errorMessage);
    }
  };

const deleteUserAccount =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const userId = getState().userAccount.userId;
      if (userId) {
        const usersApi = new UsersApi(await getAuthorizedApiConfig());
        await usersApi.usersUserIdDelete(userId);

        logout(dispatch);
      }
    } catch (error) {
      const errorMessage =
        getState().locales.messages["general.systemNotifications.userAccount.deleteUserAccount.error"];
      handleError(error, dispatch, errorMessage);
    }
  };

const updateUserAccount =
  (newUserProps: InUserDTO, customMessage?: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const userId = getState().userAccount.userId;

      if (userId) {
        const usersApi = new UsersApi(await getAuthorizedApiConfig());
        const user = await usersApi.usersUserIdGet(userId);
        const inNewUser: InUserDTO = { ...user, ...newUserProps };
        const newUser: OutDetailUserDTO = await usersApi.usersUserIdPut(userId, inNewUser);

        dispatch(setUser(newUser));

        if (customMessage) {
          toast.success(customMessage);
        } else {
          const successMessage =
            getState().locales.messages["general.systemNotifications.userAccount.updateUserAccount.success"];
          toast.success(successMessage);
        }
      }
    } catch (error) {
      const errorMessage =
        getState().locales.messages["general.systemNotifications.userAccount.updateUserAccount.error"];
      handleError(error, dispatch, errorMessage);
    }
  };

const updateUserAccountPassword =
  (passwords: ChangePasswordDTO, customMessage?: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      await setNewPassword({ ...passwords });

      if (customMessage) {
        toast.success(customMessage);
      } else {
        const successMessage =
          getState().locales.messages["general.systemNotifications.userAccount.updateUserAccountPassword.success"];
        toast.success(successMessage);
      }
    } catch (error) {
      const errorMessage =
        getState().locales.messages["general.systemNotifications.userAccount.updateUserAccountPassword.error"];
      handleError(error, dispatch, errorMessage, true);
    }
  };

const updateUserAccountEmail =
  (email: string, password: string, customMessage?: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const userId = getState().userAccount.userId;
      const user = getState().userAccount.user;

      if (user && userId) {
        const userEmailDTO = {
          email,
          emailConfirmation: email,
          password
        };
        const usersApi = new UsersApi(await getAuthorizedApiConfig());
        await usersApi.usersUserIdEmailPatch(userId, userEmailDTO);

        dispatch(setUser({ ...user, email }));

        if (customMessage) {
          toast.success(customMessage);
        } else {
          const successMessage =
            getState().locales.messages["general.systemNotifications.userAccount.updateUserEmail.success"];
          toast.success(successMessage);
        }
      }
    } catch (error) {
      const errorMessage = getState().locales.messages["general.systemNotifications.userAccount.updateUserEmail.error"];
      handleError(error, dispatch, errorMessage, true);
    }
  };

export const userAccountThunks = {
  getRole,
  getUserId,
  getUserAccount,
  deleteUserAccount,
  updateUserAccount,
  updateUserAccountPassword,
  updateUserAccountEmail
};
