import { toast } from "react-toastify";

import { handleError } from "@app/util/handle-error";
import {
  OutFeaturedCategoriesDTO,
  FeaturedCategoriesApi,
  InFeaturedCategoriesDTO,
  CreateFeaturedCategoriesDTO
} from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";
import { FilterItem } from "@app/api/core/filter/filter-item";

import {
  deleteFeaturedCategoryFromList,
  setFeaturedCategories,
  setIsLoadingFeaturedCategories,
  setSelectedFeaturedCategory,
  setAddFeaturedCategoryToList,
  setTotalResults
} from "../reducers/featured-categories";

export interface IFeaturedCategoriesResult {
  featuredCategories: OutFeaturedCategoriesDTO[];
  totalResults: number;
}

export enum FeaturedCategoriesEnum {
  searchQuery = "searchQuery",
  platformId = "platformId",
  merchantId = "merchantId"
}

export type FeaturedCategoriesFiltersType = {
  [key in FeaturedCategoriesEnum]?: string;
};

const featuredCategoriesFiltersToJsonString = (
  searchQuery?: string,
  merchantId?: number,
  platformId?: number
): string => {
  if (!searchQuery && !merchantId && !platformId) return "";

  return JSON.stringify({
    searchQuery: searchQuery || undefined,
    platformId: platformId || undefined,
    merchantId: merchantId || undefined
  });
};

const getFeaturedCategories =
  (skip: number, take: number, merchantFilter?: FilterItem, searchQuery?: string, platformId?: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new FeaturedCategoriesApi(await getAuthorizedApiConfig());
      dispatch(setIsLoadingFeaturedCategories(true));

      const merchantId = merchantFilter?.value ? merchantFilter.value.toString() : undefined;

      const resultFromApi = await api.featuredCategoriesGet(
        take,
        skip,
        featuredCategoriesFiltersToJsonString(searchQuery, merchantId, platformId)
      );

      dispatch(setFeaturedCategories(resultFromApi.featuredCategories || []));
      dispatch(setTotalResults(resultFromApi.total));
      dispatch(setIsLoadingFeaturedCategories(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get FeaturedCategories");
    }
  };

const getFeaturedCategory =
  (id: number): AppThunk =>
  async (dispatch): Promise<OutFeaturedCategoriesDTO | undefined> => {
    try {
      const api = new FeaturedCategoriesApi(await getAuthorizedApiConfig());

      const featuredCategory = await api.featuredCategoriesFeaturedCategoryIdGet(id);

      dispatch(setSelectedFeaturedCategory(featuredCategory));
      return featuredCategory;
    } catch (error) {
      handleError(error, dispatch, "Failed to get FeaturedCategory");
      return undefined;
    }
  };

const deleteFeaturedCategory =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new FeaturedCategoriesApi(await getAuthorizedApiConfig());

      const featuredCategory = await api.featuredCategoriesFeaturedCategoryIdDelete(id);

      if (featuredCategory) {
        dispatch(deleteFeaturedCategoryFromList(id));
      }
      toast.success("Categorie is verwijderd");
    } catch (error) {
      handleError(error, dispatch, "Failed to delete FeaturedCategory");
      // new FatalError(error.name, error.message, error.stack);
    }
  };

const createNewFeaturedCategory =
  (newFeaturedCategory: CreateFeaturedCategoriesDTO): AppThunk =>
  async (dispatch): Promise<OutFeaturedCategoriesDTO | undefined> => {
    try {
      const api = new FeaturedCategoriesApi(await getAuthorizedApiConfig());
      const createdFeaturedCategory = await api.featuredCategoriesPost(newFeaturedCategory);

      dispatch(setSelectedFeaturedCategory(createdFeaturedCategory));
      dispatch(setAddFeaturedCategoryToList(createdFeaturedCategory));

      toast.success("Categorie is toegevoegd");

      return createdFeaturedCategory;
    } catch (error) {
      if ((error as any)?.status === 409) {
        handleError(error, dispatch, "FeaturedCategory with given slug already exists, cannot have duplicates.");
      } else {
        handleError(error, dispatch, "Failed to create new FeaturedCategory");
      }
    }

    return undefined;
  };

const saveFeaturedCategory =
  (featuredCategory: InFeaturedCategoriesDTO, customMessage?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new FeaturedCategoriesApi(await getAuthorizedApiConfig());
      const savedFeaturedCategory: any = await api.featuredCategoriesFeaturedCategoryIdPut(
        featuredCategory.id,
        featuredCategory
      );

      dispatch(setSelectedFeaturedCategory(savedFeaturedCategory));

      if (customMessage) {
        toast.success(customMessage);
      } else {
        toast.success("Categorie is opgeslagen");
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to save FeaturedCategory");
    }
  };

export const featuredCategoriesThunk = {
  getFeaturedCategories,
  getFeaturedCategory,
  deleteFeaturedCategory,
  createNewFeaturedCategory,
  saveFeaturedCategory
};
