import { IconComponent } from "@app/core";
import React from "react";
import CheckmarkIcon from "@assets/icons/checkmark.svg";
import styles from "./language-switcher.module.scss";

interface IProps {
  currentLocale: string;
  locale: string;
  setLocale: (locale: string) => void;
  value?: string;
}

export const LanguageSwitcherDropdownItem = (props: IProps) => {
  const changeLocale = () => {
    props.setLocale(props.locale);
  };

  return (
    <button id={props.locale} className={styles.dropdownItem} type="button" onClick={changeLocale}>
      <span id={props.locale} className={styles.checkmark}>
        {props.locale === props.currentLocale && <IconComponent icon={CheckmarkIcon} size="16px" fillColor="black" />}
      </span>
      <span id={props.locale}>{props.value}</span>
    </button>
  );
};
