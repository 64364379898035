/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ResetPasswordDTO
 */
export interface ResetPasswordDTO {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDTO
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordDTO
   */
  resetToken: string;
}

export function ResetPasswordDTOFromJSON(json: any): ResetPasswordDTO {
  return ResetPasswordDTOFromJSONTyped(json, false);
}

export function ResetPasswordDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    password: json["password"],
    resetToken: json["resetToken"]
  };
}

export function ResetPasswordDTOToJSON(value?: ResetPasswordDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    password: value.password,
    resetToken: value.resetToken
  };
}
