import React, { useState, useEffect } from "react";
import { IntlShape, useIntl } from "react-intl";

import { useDebounce } from "@app/util/use-debounce";
import { TextFieldComponent, ClickableComponent } from "@app/core";
import Plus from "@assets/icons/plus.svg";
import Search from "@assets/icons/search.svg";
import { SelectComponent } from "@app/core/select";
import { userRoles, UserRoleType } from "@app/api/core/users/user";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { setCurrentRole, setCurrentSearch, setSelectedUser } from "@app/redux/reducers/users";
import { OutDetailUserDTO } from "@app/api/generated";

import styles from "./user-filter-bar-component.module.scss";

const UserFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentSearch } = useAppSelector((state) => state.users);

  const translatedRoles = getTranslatedRoles(intl);
  const [search, setSearch] = useState<string>(currentSearch || "");

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onRoleChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);

    dispatch(setCurrentRole(value || undefined));
  };

  return (
    <div className={styles.userFilterBar}>
      <span className={styles.title}>
        <h1>{intl.formatMessage({ id: "userOverview.filterBar.users" })}</h1>
      </span>

      <div className={styles.push}>
        <div className={styles.searchField}>
          <TextFieldComponent
            height={40}
            placeholder={intl.formatMessage({ id: "userOverview.filterBar.search" })}
            value={search}
            onChange={(e: React.ChangeEvent<any>) => {
              setSearch(e.target.value);
            }}
            type="text"
            id="search"
            icon={Search}
            width={318}
          />
        </div>

        <div className={styles.selectDiv}>
          <SelectComponent onChange={onRoleChange} value={undefined} options={translatedRoles} />
        </div>

        <span>
          <ClickableComponent
            title={intl.formatMessage({ id: "userOverview.filterbar.addUser" })}
            height={40}
            icon={Plus}
            iconSize="20px"
            iconFillColor="#ffffff"
            iconPosition="left"
            onClick={() => dispatch(setSelectedUser({ id: null } as any as OutDetailUserDTO))}
          />
        </span>
      </div>
    </div>
  );
};

export { UserFilterBar };

const getTranslatedRoles = (intl: IntlShape): UserRoleType[] => {
  const defaultSelectOption: UserRoleType = {
    id: 0,
    name: intl.formatMessage({ id: "userOverview.filterBar.allRoles" })
  };

  const roles: UserRoleType[] = userRoles.map((untranslatedUserStatus) => ({
    id: untranslatedUserStatus.id,
    name: intl.formatMessage({ id: untranslatedUserStatus.name })
  }));

  return [defaultSelectOption, ...roles];
};
