/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ResetTokenDTO
 */
export interface ResetTokenDTO {
  /**
   *
   * @type {string}
   * @memberof ResetTokenDTO
   */
  resetToken: string;
}

export function ResetTokenDTOFromJSON(json: any): ResetTokenDTO {
  return ResetTokenDTOFromJSONTyped(json, false);
}

export function ResetTokenDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetTokenDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    resetToken: json["resetToken"]
  };
}

export function ResetTokenDTOToJSON(value?: ResetTokenDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    resetToken: value.resetToken
  };
}
