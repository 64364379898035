import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutDetailedMerchantDTO, OutMerchantDTO } from "@app/api/generated";

export interface IMerchantsState {
  currentEditStatus?: number;
  currentPage: number;
  currentPlatform?: number;
  currentSearch?: string;
  currentStatus?: number;
  isLoadingMerchants: boolean;
  merchants: OutMerchantDTO[];
  selectedMerchant?: OutDetailedMerchantDTO;
  totalResults: number;
}

const initialState: IMerchantsState = {
  isLoadingMerchants: true,
  merchants: [],
  selectedMerchant: undefined,
  totalResults: 0,
  currentPage: 1,
  currentSearch: undefined,
  currentPlatform: undefined,
  currentStatus: undefined,
  currentEditStatus: undefined
};

export const slice = createSlice({
  name: "merchants",
  initialState,
  reducers: {
    setMerchants: (state, action: PayloadAction<OutMerchantDTO[]>) => {
      state.merchants = action.payload;
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    setIsLoadingMerchants: (state, action: PayloadAction<boolean>) => {
      state.isLoadingMerchants = action.payload;
    },
    setSelectedMerchant: (state, action: PayloadAction<OutDetailedMerchantDTO | undefined>) => {
      state.selectedMerchant = action.payload;
    },
    deleteMerchantFromList: (state, action: PayloadAction<number>) => {
      state.merchants = state.merchants.filter((item) => item.id !== action.payload);
    },
    updateCampaign: (state, action: PayloadAction<number>) => {
      state.merchants = state.merchants.filter((item) => item.id !== action.payload);
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentStatus: (state, action: PayloadAction<number | undefined>) => {
      state.currentStatus = action.payload;
    },
    setCurrentPlatform: (state, action: PayloadAction<number | undefined>) => {
      state.currentPlatform = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    },
    setCurrentEditStatus: (state, action: PayloadAction<number | undefined>) => {
      state.currentEditStatus = action.payload;
    }
  }
});

export const {
  setMerchants,
  setTotalResults,
  setIsLoadingMerchants,
  setSelectedMerchant,
  deleteMerchantFromList,
  updateCampaign,
  setCurrentPage,
  setCurrentStatus,
  setCurrentPlatform,
  setCurrentSearch,
  setCurrentEditStatus
} = slice.actions;

export default slice.reducer;
