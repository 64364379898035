import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { IconComponent } from "@app/core";
import SettingsIcon from "@assets/icons/settings-gear.svg";
import LogoutIcon from "@assets/icons/logout.svg";
import PersonIcon from "@assets/icons/single-body.svg";
import useOutsideClick from "@app/util/outside-click";

import styles from "./nav-bar-settings-item-component.module.scss";

export interface INavBarSettingsItemProps {
  onLogout: () => void;
  role: string;
}

const NavBarSettingsItem = (props: INavBarSettingsItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useOutsideClick([dropdownRef, buttonRef], () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleDropdownToggle = () => {
    setIsOpen(true);
  };

  return (
    <div className={styles.settings}>
      <button
        type="button"
        onClick={handleDropdownToggle}
        className={clsx(styles.settingsButton, isOpen && styles.active)}
        ref={buttonRef}
      >
        <IconComponent icon={SettingsIcon} size="16px" fillColor="white" />
      </button>
      <div className={styles.settingsDropdown} style={{ display: isOpen ? "initial" : "none" }}>
        <Link to="/my-account" className={styles.dropdownItem}>
          <IconComponent icon={PersonIcon} size="16px" fillColor="#1c1c1c" />
          <span className={styles.text}>{intl.formatMessage({ id: "navbarSettingsItem.userAccount" })}</span>
        </Link>
        {props.role === "admin" && (
          <Link to="/platform-settings" className={styles.dropdownItem}>
            <IconComponent icon={SettingsIcon} size="16px" fillColor="#1c1c1c" />
            <span className={styles.text}>{intl.formatMessage({ id: "navbarSettingsItem.platformSettings" })}</span>
          </Link>
        )}
        <a className={styles.dropdownItem} role="button" onClick={props.onLogout}>
          <IconComponent icon={LogoutIcon} size="16px" fillColor="#1c1c1c" />
          <span className={styles.text}>{intl.formatMessage({ id: "navbarSettingsItem.logout" })}</span>
        </a>
      </div>
    </div>
  );
};

export { NavBarSettingsItem };
