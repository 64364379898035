export const ROUTES = {
  EXTERNAL_DEAL_FILTERS: "/external-deal-filters",
  EXTERNAL_DEAL_FILTERS_NEW: "/external-deal-filters/new",
  FEATURED_CATEGORY_OVERVIEW: "/featured-category-overview",
  DEAL_OVERVIEW: "/deal-overview",
  STORE_OVERVIEW: "/store-overview",
  BANNER_OVERVIEW: "/banner-overview",
  MERCHANT_DETAIL: "/merchant-detail",
  FILTER_OVERVIEW: "/filter-overview",
  BANNER_DETAIL: "/banner-detail",
  DEAL_DETAIL: "/deal-detail",
  USER_OVERVIEW: "/user-overview",
  MY_ACCOUNT: "/my-account",
  PLATFORM_SETTINGS: "/platform-settings",
  LOGIN: "/login",
  REQUEST_PASSWORD: "/request-password",
  RESET_PASSWORD: "/reset-password"
};
