import { toast } from "react-toastify";

import { handleError } from "@app/util/handle-error";
import { UsersApi, InUserDTO, OutDetailUserDTO } from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";
import {
  deleteUserFromList,
  setIsLoadingUsers,
  setSelectedUser,
  setTotalResults,
  setUsers
} from "@app/redux/reducers/users";
import { userRoles } from "@app/api/core/users/user";

const mapNumberToUserRoleFilter = (id?: number): string | undefined =>
  userRoles.find((userRole) => userRole.id === id)?.apiName;

const getUsers =
  (skip: number, take: number, searchQuery?: string, role?: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setIsLoadingUsers(true));
      const usersApi = new UsersApi(await getAuthorizedApiConfig());

      const roleName = mapNumberToUserRoleFilter(role);

      const userResult = await usersApi.usersExtendedGet(skip, take, searchQuery, roleName);

      dispatch(setUsers(userResult.users || []));
      dispatch(setTotalResults(userResult.total));
      dispatch(setIsLoadingUsers(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get users");
    }
  };

const getUser =
  (id: number): AppThunk =>
  async (dispatch): Promise<OutDetailUserDTO | undefined> => {
    try {
      const usersApi = new UsersApi(await getAuthorizedApiConfig());
      const userResult = await usersApi.usersUserIdGet(id);

      dispatch(setSelectedUser(userResult));

      return userResult;
    } catch (error) {
      handleError(error, dispatch, "Failed to get user");
    }

    return undefined;
  };

const deleteUser =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const usersApi = new UsersApi(await getAuthorizedApiConfig());

      const user = await usersApi.usersUserIdDelete(id);

      if (user) {
        dispatch(deleteUserFromList(id));
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to delete user");
    }
  };

const createNewUser =
  (user: InUserDTO): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const usersApi = new UsersApi(await getAuthorizedApiConfig());

      await usersApi.usersPost(user);
      toast.success("Gebruiker is aangemaakt");
    } catch (error) {
      // TODO: handle error and show the correct issue from API.
      handleError(error, dispatch, "Failed to create new user");
    }

    return undefined;
  };

const saveUser =
  (user: InUserDTO): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const usersApi = new UsersApi(await getAuthorizedApiConfig());

      await usersApi.usersUserIdPut(user.id || 0, user);
      toast.success("Gebruiker is opgeslagen");
    } catch (error) {
      handleError(error, dispatch, "Failed to save user");
    }
  };

export const usersThunks = {
  getUsers,
  getUser,
  deleteUser,
  createNewUser,
  saveUser
};
