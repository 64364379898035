import React from "react";
import { useIntl } from "react-intl";

import { OutDetailUserDTO } from "@app/api/generated";
import { ResourceTextComponent } from "@app/core";

import { DeleteUserAccountForm } from "./delete-user-account-form/delete-user-account.form";
import { UserAccountForm } from "./user-account-form/user-account.form";
import styles from "./main-user-account-info-component.module.scss";

export interface IMaiUuserAccountInfoProps {
  onSubmitDeleteUserAccount: (values: any) => void;
  onSubmitEditUserAccount: (values: any) => void;
  user: OutDetailUserDTO;
}

const MainUserAccountInfo = (props: IMaiUuserAccountInfoProps) => {
  const intl = useIntl();
  const generalInfoEmailAdress = intl.formatMessage({ id: "general.infoEmailAdress" });

  return (
    <div className={styles.form}>
      <UserAccountForm intl={intl} onSubmit={props.onSubmitEditUserAccount} user={props.user} />
      <DeleteUserAccountForm intl={intl} onSubmit={props.onSubmitDeleteUserAccount} />

      <div className={styles.bottomBar}>
        <div className={styles.complaints}>
          <span>
            <ResourceTextComponent
              resourceKey="general.forms.contact"
              values={{
                emailAdress: (
                  <a href={`mailto:${generalInfoEmailAdress}`} target="_blank" rel="noopener noreferrer">
                    {generalInfoEmailAdress}
                  </a>
                )
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export { MainUserAccountInfo };
