export enum ExternalDealFilterRuleConditionsEnum {
  EQ = "eq",
  NE = "ne",
  LT = "lt",
  LTE = "lte",
  GT = "gt",
  GTE = "gte",
  CONTAINS = "contains",
  NOT_CONTAINS = "notContains",
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith"
}

export const externalDealFilterRuleConditions: ExternalDealFilterRuleConditionsEnum[] = [
  ExternalDealFilterRuleConditionsEnum.EQ,
  ExternalDealFilterRuleConditionsEnum.NE,
  ExternalDealFilterRuleConditionsEnum.LT,
  ExternalDealFilterRuleConditionsEnum.LTE,
  ExternalDealFilterRuleConditionsEnum.GT,
  ExternalDealFilterRuleConditionsEnum.GTE,
  ExternalDealFilterRuleConditionsEnum.CONTAINS,
  ExternalDealFilterRuleConditionsEnum.NOT_CONTAINS,
  ExternalDealFilterRuleConditionsEnum.STARTS_WITH,
  ExternalDealFilterRuleConditionsEnum.ENDS_WITH
];
