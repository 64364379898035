import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";
import JWTToken from "@app/authorization/jwt-token";

interface IRequireAuthProps {
  children: JSX.Element;
  redirectTo?: string;
}

export const RequireAuth = ({ children, redirectTo = "/login" }: IRequireAuthProps) => {
  const location = useLocation();
  const isAuthenticated = !JWTToken.checkTokenIsExpired("refreshToken");

  return isAuthenticated ? children : <Navigate to={{ pathname: redirectTo }} state={{ from: location }} replace />;
};
