import React, { useState } from "react";
import { IntlShape } from "react-intl";
import { withFormik, FormikProps, Form } from "formik";

import { ClickableComponent, TextFieldComponent, ResourceTextComponent } from "@app/core";
import { PlatformDTO } from "@app/api/generated";
import { DatePickerComponent } from "@app/core/date-picker";

import styles from "./platform-settings-form.module.scss";

export interface IFormValues {
  googleExperimentId?: string;
  googleOptimizeId?: string;
  headerText?: string;
  mailchimpAudienceId?: string;
  navBar: {
    link?: string;
    subTitle?: string;
    title?: string;
  };
  alert: {
    message?: string;
    startDate?: string | null;
    endDate?: string | null;
  };
}

interface IOtherProps {
  intl: IntlShape;
}

const InnerForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { touched, errors } = props;

  const [formIsTouched, setFormIsTouched] = useState(false);

  const handleChange = (values: any) => {
    props.handleChange(values);

    if (!formIsTouched) {
      setFormIsTouched(true);
    }
  };

  const onDatePickerChange = (date: Date | null, id: string) => {
    let newValue: Date | null | string = date;

    if (newValue) {
      newValue = newValue.toISOString();
    }

    props.handleChange(newValue);
    props.setFieldValue(id, newValue);
  };

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <span>
              <ResourceTextComponent resourceKey="platformSettings.form.title" />
            </span>
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.googleExperimentId}
                  onChange={handleChange}
                  errorMessage={touched.googleExperimentId && errors.googleExperimentId}
                  id="googleExperimentId"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.experimentId.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.experimentId.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.googleOptimizeId}
                  onChange={handleChange}
                  errorMessage={touched.googleOptimizeId && errors.googleOptimizeId}
                  id="googleOptimizeId"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.optimizeId.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.optimizeId.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.headerText}
                  onChange={handleChange}
                  errorMessage={touched.headerText && errors.headerText}
                  id="headerText"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.headerText.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.headerText.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.mailchimpAudienceId}
                  onChange={handleChange}
                  errorMessage={touched.mailchimpAudienceId && errors.mailchimpAudienceId}
                  id="mailchimpAudienceId"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.mailchimpAudienceId.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.mailchimpAudienceId.tooltip"
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <span>{props.intl.formatMessage({ id: "platformSettings.navBar.header.text" })}</span>
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.navBar.title}
                  onChange={handleChange}
                  errorMessage={touched.navBar?.title && errors.navBar?.title}
                  id="navBar.title"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.navBar.title.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.navBar.title.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.navBar.subTitle}
                  onChange={handleChange}
                  errorMessage={touched.navBar?.subTitle && errors.navBar?.subTitle}
                  id="navBar.subTitle"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.navBar.subTitle.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.navBar.subTitle.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.navBar.link}
                  onChange={handleChange}
                  errorMessage={touched.navBar?.link && errors.navBar?.link}
                  id="navBar.link"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.navBar.link.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.navBar.link.tooltip"
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <span>{props.intl.formatMessage({ id: "platformSettings.alert.header.text" })}</span>
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.alert.message}
                  onChange={handleChange}
                  errorMessage={touched.alert?.message && errors.alert?.message}
                  id="alert.message"
                  label={{
                    label: props.intl.formatMessage({
                      id: "platformSettings.alert.message.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "platformSettings.alert.message.tooltip"
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <DatePickerComponent
                  label={{
                    label: props.intl.formatMessage({ id: "platformSettings.alert.startDate.label" }),
                    tooltip: props.intl.formatMessage({ id: "platformSettings.alert.startDate.label" })
                  }}
                  value={props.values.alert.startDate}
                  onChange={onDatePickerChange}
                  includeTime
                  id="alert.startDate"
                  placeholder
                  errorMessage={touched.alert?.startDate && errors.alert?.startDate}
                  disableAutoComplete
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <DatePickerComponent
                  label={{
                    label: props.intl.formatMessage({ id: "platformSettings.alert.endDate.label" }),
                    tooltip: props.intl.formatMessage({ id: "platformSettings.alert.endDate.label" })
                  }}
                  value={props.values.alert.endDate}
                  onChange={onDatePickerChange}
                  includeTime
                  id="alert.endDate"
                  placeholder
                  errorMessage={touched.alert?.endDate && errors.alert?.endDate}
                  disableAutoComplete
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomBar}>
        <div className={styles.actions}>
          <ClickableComponent
            buttonType="button"
            title={props.intl.formatMessage({ id: "platformSettings.button.submit" })}
            height={48}
            onClick={() => {
              props.handleSubmit();
            }}
          />
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  intl: IntlShape;
  onSubmit: (values: any) => void;
  platform: PlatformDTO;
}

export const PlatformSettingsForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: (props) => ({
    googleExperimentId: props.platform.googleExperimentId,
    googleOptimizeId: props.platform.googleOptimizeId,
    headerText: props.platform.headerText,
    mailchimpAudienceId: props.platform.mailchimpAudienceId,
    navBar: {
      link: props.platform.navBarLink,
      title: props.platform.navBarTitle,
      subTitle: props.platform.navBarSubTitle
    },
    alert: {
      message: props.platform.alertMessage,
      startDate: props.platform.alertStartDate,
      endDate: props.platform.alertEndDate
    }
  }),
  enableReinitialize: true,

  validate: (values: IFormValues, bag) => {
    const midnightTimeIsoDate = "23:00:00";
    const midnightTimeError = bag.intl.formatMessage({ id: "platformSettings.errors.midnightTimeError" });
    const errors: any = {};

    if (values.alert.startDate && values.alert.endDate) {
      const startDate = new Date(values.alert.startDate);
      const endDate = new Date(values.alert.endDate);
      const today = new Date();

      if (endDate < startDate) {
        errors.alert = { startDate: bag.intl.formatMessage({ id: "platformSettings.errors.startDateAfterEndDate" }) };
      }
      if (endDate < today) {
        errors.alert = { endDate: bag.intl.formatMessage({ id: "platformSettings.errors.endDateAfterToday" }) };
      }
    }

    if (values.alert.startDate && values.alert.startDate.includes(midnightTimeIsoDate)) {
      errors.alert = { startDate: midnightTimeError };
    }

    if (values.alert.endDate && values.alert.endDate.includes(midnightTimeIsoDate)) {
      errors.alert = { endDate: midnightTimeError };
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
