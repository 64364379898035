import React from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { TextFieldComponent, ClickableComponent, ResourceTextComponent } from "@app/core";
import { IntlShape } from "react-intl";

import { SelectComponent } from "@app/core/select";
import { FilterTypeDTO, OutFilterDTO } from "@app/api/generated";
import { CheckboxComponent } from "@app/core/checkbox";
import { labelColors, labelIcons } from "@app/api/core/filter/filter-item";

import { FeaturedFilterLabel } from "./filter-label.component";
import styles from "./filter-modal-component.module.scss";

export interface IFilterFormValues {
  filterType: number;
  labelColor: number;
  labelIcon?: number;
  name: string;
  showText?: boolean;
}

interface IOtherProps {
  filterTypes?: FilterTypeDTO[];
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
}

const InnerForm = (props: IOtherProps & FormikProps<IFilterFormValues>) => {
  const [showLabelFields, setShowLabelFields] = React.useState(false);
  const [labelHexColor, setLabelHexColor] = React.useState<string | undefined>();
  const [labelIcon, setLabelIcon] = React.useState<string | undefined>();

  const { touched, errors } = props;

  React.useEffect(() => {
    if (Number(props.values.filterType) === 2) {
      setShowLabelFields(true);
    } else {
      setShowLabelFields(false);
    }
  }, [props.values.filterType]);

  React.useEffect(() => {
    const hexColor = colorToHex(labelColors.find((item) => item.id === Number(props.values.labelColor))?.name);
    setLabelHexColor(hexColor);
  }, [props.values.labelColor]);

  React.useEffect(() => {
    const newIcon = labelIcons.find((item) => item.id === Number(props.values.labelIcon))?.name;
    setLabelIcon(newIcon);
  }, [props.values.labelIcon]);

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.fieldsContainer}>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                placeholder=""
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="text"
                id="name"
                maxCharacters={45}
                label={{
                  label: props.intl.formatMessage({ id: "createFilterForm.input.name.label" }),
                  tooltip: props.intl.formatMessage({ id: "createFilterForm.input.name.tooltip" }),
                  errorMessage: touched.name === true && errors.name
                }}
              />
            </div>
            <div className={styles.formField}>
              <SelectComponent
                height={40}
                onChange={props.handleChange}
                value={props.values.filterType}
                options={[
                  { id: 1, name: "Categorie" },
                  { id: 2, name: "Label" },
                  { id: 3, name: "Merk" }
                ]}
                id="filterType"
                label={{
                  label: props.intl.formatMessage({ id: "createFilterForm.input.filterType.label" }),
                  tooltip: props.intl.formatMessage({ id: "createFilterForm.input.filterType.tooltip" }),
                  errorMessage: touched.filterType === true && errors.filterType
                }}
              />
            </div>
          </div>
          {showLabelFields && (
            <div>
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <SelectComponent
                    height={40}
                    onChange={props.handleChange}
                    value={props.values.labelIcon}
                    options={labelIcons}
                    id="labelIcon"
                    label={{
                      label: props.intl.formatMessage({ id: "createFilterForm.input.icon.label" }),
                      tooltip: props.intl.formatMessage({ id: "createFilterForm.input.icon.tooltip" }),
                      errorMessage: touched.filterType === true && errors.filterType
                    }}
                  />
                </div>
                <div className={styles.formField}>
                  <SelectComponent
                    height={40}
                    onChange={props.handleChange}
                    value={props.values.labelColor}
                    options={labelColors}
                    id="labelColor"
                    label={{
                      label: props.intl.formatMessage({ id: "createFilterForm.input.color.label" }),
                      tooltip: props.intl.formatMessage({ id: "createFilterForm.input.color.tooltip" }),
                      errorMessage: touched.filterType === true && errors.filterType
                    }}
                  />
                </div>
              </div>
              <div className={styles.labelExampleFields}>
                <div className={styles.fields}>
                  <div className={styles.formField}>
                    <CheckboxComponent
                      name="showText"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={!!props.values.showText}
                    >
                      <ResourceTextComponent resourceKey="createFilterForm.input.showText.name" />
                    </CheckboxComponent>
                  </div>
                  <div className={styles.formField}>
                    <FeaturedFilterLabel
                      colorHex={labelHexColor}
                      icon={labelIcon}
                      showText={props.values.showText}
                      text={props.values.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={styles.buttons}>
            <span className={styles.firstButton}>
              <ClickableComponent
                buttonType="button"
                title={props.intl.formatMessage({ id: "createFilterForm.button.cancel" })}
                onClick={() => {
                  props.onCancel();
                }}
                height={48}
                variant="primary-inverted"
              />
            </span>
            <span>
              <ClickableComponent
                buttonType="submit"
                title={props.intl.formatMessage({ id: "createFilterForm.button.save" })}
                height={48}
                disabled={props.isLoading}
              />
            </span>
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IFormProps {
  filterDto?: OutFilterDTO;
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: IFilterFormValues) => void;
}

export const FilterFormComponent = withFormik<IFormProps, IFilterFormValues>({
  mapPropsToValues: (props) => ({
    name: props.filterDto ? props.filterDto.name : "",
    filterType: props.filterDto ? props.filterDto.type.id : 1,
    labelColor: Number(labelColors.find((item) => item.name === hexToColor(props.filterDto?.label?.colorHex))?.id)
      ? Number(labelColors.find((item) => item.name === hexToColor(props.filterDto?.label?.colorHex))?.id)
      : 1,
    labelIcon: Number(labelIcons.find((item) => item.name === props.filterDto?.label?.icon)?.id)
      ? Number(labelIcons.find((item) => item.name === props.filterDto?.label?.icon)?.id)
      : 1,
    showText: props.filterDto?.label?.showText
  }),

  validate: (values: IFilterFormValues, props) => {
    const errors: FormikErrors<IFilterFormValues> = {};
    if (!values.name) {
      errors.name = props.intl.formatMessage({ id: "createFilterForm.error.name.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);

export const colorToHex = (color?: string): string | undefined => {
  switch (color) {
    case "Rood":
      return "#E10A14";
    case "Paars":
      return "#8000FF";
    case "Roze":
      return "#E400E9";
    case "Blauw":
      return "#304FFE";
    case "Aqua":
      return "#00CE9D";
    case "Groen":
      return "#00C626";
    case "Geel":
      return "#D9B500";
    case "Oranje":
      return "#FF5C00";
    default:
      return "#E10A14";
  }
};

export const hexToColor = (color?: string): string | undefined => {
  switch (color) {
    case "#E10A14":
      return "Rood";
    case "#8000FF":
      return "Paars";
    case "#E400E9":
      return "Roze";
    case "#304FFE":
      return "Blauw";
    case "#00CE9D":
      return "Aqua";
    case "#00C626":
      return "Groen";
    case "#D9B500":
      return "Geel";
    case "#FF5C00":
      return "Oranje";
    default:
      return "#E10A14";
  }
};
