import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { ClickableComponent, TextFieldComponent, ResourceTextComponent } from "@app/core";
import { ModalComponent } from "@app/core/modal";
import Plus from "@assets/icons/plus.svg";
import Search from "@assets/icons/search.svg";
import { SelectComponent } from "@app/core/select";
import { platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { MultiSelectComponent } from "@app/core/multi-select/multi-select.component";
import { useDebounce } from "@app/util/use-debounce";
import { storeStatus, storeStatusType2 } from "@app/api/core/merchant/merchant";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { dealCreatedBySelectValues } from "@app/constants/deal";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import {
  setCurrentCreatedBy,
  setCurrentPlatform,
  setCurrentSearch,
  setCurrentStatus,
  setCurrentMerchant
} from "@app/redux/reducers/deals";

import { CreateDealModal } from "../create-deal-modal";
import { getMerchants } from "../deal-detail/components/api-calls";
import styles from "./deal-filter-bar-component.module.scss";

const DealFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentSearch, currentCreatedBy, currentPlatform, currentMerchant, currentStatus } = useAppSelector(
    (state) => state.deals
  );

  const [search, setSearch] = useState<string>(currentSearch || "");
  const [isAddDealOpen, setIsAddDealOpen] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  const translatedStoreStatus = storeStatus.map((untranslatedStoreStatus) => {
    return {
      id: untranslatedStoreStatus.id,
      name: intl.formatMessage({ id: untranslatedStoreStatus.name })
    } as storeStatusType2;
  });

  const translatedCreatedBy = dealCreatedBySelectValues.map((untranslatedStoreStatus) => {
    return {
      id: untranslatedStoreStatus.id,
      name: intl.formatMessage({ id: untranslatedStoreStatus.name })
    } as storeStatusType2;
  });

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onCreatedByChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentCreatedBy(value || undefined));
  };

  const onStatusChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentStatus(value || undefined));
  };

  const onPlatformChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentPlatform(value || undefined));
  };

  const onMerchantChange = (value: FilterItem[] | null) => {
    const merchant = value && value.length > 0 ? value[0] : undefined;
    dispatch(setCurrentMerchant(merchant));
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const showPlatformInLabels = !currentPlatform;
    const merchants = await getMerchants(inputValue, currentPlatform);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant, showPlatformInLabels)) : []);
  };

  return (
    <div className={styles.dealFilterBar}>
      <span className={styles.title}>
        <h1>
          <ResourceTextComponent resourceKey="dealFilterBarComponent.title" />
        </h1>
      </span>
      <div className={styles.push}>
        <div className={styles.searchField}>
          <TextFieldComponent
            height={40}
            placeholder={intl.formatMessage({ id: "dealFilterBarComponent.searchPlaceholder" })}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            id="search"
            icon={Search}
            width={318}
          />
        </div>
        <div className={styles.selectDiv}>
          <SelectComponent
            onChange={onCreatedByChange}
            value={currentCreatedBy}
            options={translatedCreatedBy}
            autoWidth
          />
        </div>
        <div className={styles.selectDiv}>
          <SelectComponent
            onChange={onStatusChange}
            value={currentStatus}
            options={[
              { id: 0, name: intl.formatMessage({ id: "dealFilterBarComponent.selectAllStatusses" }) },
              ...translatedStoreStatus
            ]}
            autoWidth
          />
        </div>
        <div className={clsx(styles.selectdiv, styles.merchantSelect)}>
          <MultiSelectComponent
            id="merchant"
            type="array"
            loadOptions={loadMerchants}
            placeholder={intl.formatMessage({ id: "dealFilterBarComponent.selectAllMerchants" })}
            onChange={onMerchantChange}
            isMulti={false}
            value={currentMerchant ? [currentMerchant] : undefined}
          />
        </div>
        <div className={styles.selectDiv}>
          <SelectComponent
            onChange={onPlatformChange}
            value={currentPlatform}
            options={[
              { id: 0, name: intl.formatMessage({ id: "dealFilterBarComponent.selectAllPlatforms" }) },
              ...translatedPlatforms
            ]}
            autoWidth
          />
        </div>
        <span className={styles.addDeal}>
          <ClickableComponent
            title={intl.formatMessage({ id: "dealFilterBarComponent.addDeal" })}
            height={40}
            icon={Plus}
            iconSize="20px"
            iconFillColor="#ffffff"
            iconPosition="left"
            onClick={() => {
              setIsAddDealOpen(true);
            }}
          />
        </span>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "dealFilterBarComponent.addDeal" })}
        isModalOpen={isAddDealOpen}
        onCloseModal={() => {
          setIsAddDealOpen(false);
        }}
        variant="big"
      >
        <CreateDealModal onCancel={() => setIsAddDealOpen(false)} />
      </ModalComponent>
    </div>
  );
};

export { DealFilterBar };
