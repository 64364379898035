import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import clsx from "clsx";

import Bin from "@assets/icons/bin.svg";
import Edit from "@assets/icons/edit.svg";

import { Pagination } from "@app/core/pagination";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { ModalComponent } from "@app/core/modal";
import { UserFilterBar } from "@app/modules/user-filter-bar/user-filter-bar.component";
import { OutUserDTO } from "@app/api/generated";
import { UserModal } from "@app/modules/user-modal";
import { ResourceTextComponent } from "@app/core/resource-text";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { setCurrentPage, setSelectedUser } from "@app/redux/reducers/users";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { usersThunks } from "@app/redux/thunks/users.thunk";
import { Column } from "@webbio/components";
import { Table } from "@app/components/table/table";
import { userRoles } from "@app/api/core/users/user";
import { IconComponent } from "@app/core";

import tableStyles from "./table-styling-component.module.scss";
import styles from "./user-overview-component.module.scss";

const TAKE = 18;

const UserOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { users, isLoadingUsers, currentRole, totalResults, currentSearch, currentPage, selectedUser } = useAppSelector(
    (state) => state.users
  );
  const { role } = useAppSelector((state) => state.userAccount);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<OutUserDTO | undefined>(undefined);

  useEffect(() => {
    getUsers(1);
  }, [currentSearch, currentRole]);

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  const onDeleteUser = (user: OutUserDTO) => {
    setUserToDelete(user);
    setIsDeleteUserOpen(true);
  };

  const onEditUser = async (user: OutUserDTO) => {
    if (user?.id) {
      dispatch(usersThunks.getUser(user.id));
    }
  };

  const onDeleteConfirmed = () => {
    if (userToDelete) {
      dispatch(usersThunks.deleteUser(userToDelete.id));
      setIsDeleteUserOpen(false);
      getUsers();
    }
  };

  const onPageChange = (page: number) => {
    getUsers(page);
  };

  const getUsers = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(usersThunks.getUsers(skip, TAKE, currentSearch, currentRole));
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onTableRowClick = (user: OutUserDTO) => {
    onEditUser(user);
  };

  return (
    <div className={styles.userOverview}>
      <div className={styles.container}>
        <UserFilterBar />
        <div className={styles.overview}>
          {users && users.length > 0 ? (
            <Table dataSource={users || []} isLoading={isLoadingUsers}>
              <Column
                title={getUserColumns(intl).company.title}
                field={getUserColumns(intl).company.field}
                id={getUserColumns(intl).company.id}
                onTdClick={onTableRowClick}
                cell={(company): JSX.Element => {
                  return (
                    <span className={tableStyles.truncate} title={company}>
                      {company || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getUserColumns(intl).firstName.title}
                field={getUserColumns(intl).firstName.field}
                id={getUserColumns(intl).firstName.id}
                onTdClick={onTableRowClick}
                cell={(firstName) => {
                  return <span className={tableStyles.truncate}>{firstName || "-"}</span>;
                }}
              />

              <Column
                title={getUserColumns(intl).lastName.title}
                field={getUserColumns(intl).lastName.field}
                id={getUserColumns(intl).lastName.id}
                onTdClick={onTableRowClick}
                cell={(lastName) => {
                  return <span className={tableStyles.truncate}>{lastName || "-"}</span>;
                }}
              />

              <Column
                title={getUserColumns(intl).role.title}
                field={getUserColumns(intl).role.field}
                id={getUserColumns(intl).role.id}
                onTdClick={onTableRowClick}
                cell={(rowRole) => {
                  const intlRole = userRoles.find((r) => r.apiName === rowRole?.name)?.name;

                  return (
                    <span className={tableStyles.truncate}>
                      {intlRole ? intl.formatMessage({ id: intlRole }) : "-"}
                    </span>
                  );
                }}
              />

              <Column
                title=""
                field="id"
                cell={(_, idx) => {
                  const user = users?.[idx as number];

                  return (
                    <div className={clsx(tableStyles.push, styles.push)}>
                      <button
                        className={clsx(styles.smallButton, styles.editButton)}
                        type="button"
                        onClick={() => onEditUser(user)}
                      >
                        <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                      </button>
                      {role === "admin" && (
                        <button
                          className={clsx(styles.smallButton, styles.removeButton)}
                          onClick={() => onDeleteUser(user)}
                          type="button"
                        >
                          <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                        </button>
                      )}
                    </div>
                  );
                }}
              />
            </Table>
          ) : isLoadingUsers ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>
              <ResourceTextComponent resourceKey="userOverview.table.noResults" />
            </span>
          )}
          <div className={styles.pagination}>
            <Pagination currentPage={currentPage} totalItems={totalPages} changePage={onPageChange} />
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "userOverview.confirmDelete" })}
        isModalOpen={isDeleteUserOpen}
        onCloseModal={() => setIsDeleteUserOpen(false)}
        variant="big"
      >
        <DeleteModal onCancel={() => setIsDeleteUserOpen(false)} onDelete={onDeleteConfirmed} />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "userOverview.editUser" })}
        isModalOpen={Boolean(selectedUser)}
        onCloseModal={() => dispatch(setSelectedUser())}
        variant="big"
      >
        <UserModal
          onCancel={() => dispatch(setSelectedUser())}
          onDone={() => {
            dispatch(setSelectedUser());
            getUsers();
          }}
        />
      </ModalComponent>
    </div>
  );
};

export { UserOverview };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getUserColumns = (intl: IntlShape) => ({
  company: {
    id: "company",
    title: intl.formatMessage({ id: "userOverview.column.company" }),
    field: "company"
  },
  firstName: {
    id: "firstName",
    title: intl.formatMessage({ id: "userOverview.column.firstName" }),
    field: "firstName"
  },
  lastName: {
    id: "lastName",
    title: intl.formatMessage({ id: "userOverview.column.lastName" }),
    field: "lastName"
  },
  role: {
    id: "role",
    title: intl.formatMessage({ id: "userOverview.column.role" }),
    field: "role"
  }
});
