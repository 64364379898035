import React from "react";

import { ClickableComponent } from "@app/core";
import styles from "./basic-modal-component.module.scss";

export interface IBasicModalProps {
  description: string;
  onApprove: () => void;
  onApproveTitle: string;
  onCancel: () => void;
  onCancelTitel: string;
}

const BasicModal = (props: IBasicModalProps) => (
  <div className={styles.basicModal}>
    <div className={styles.description}>{props.description}</div>
    <div>
      <div className={styles.buttons}>
        <ClickableComponent
          buttonType="button"
          title={props.onCancelTitel}
          onClick={props.onCancel}
          height={48}
          variant="primary-inverted"
        />

        <ClickableComponent
          buttonType="submit"
          title={props.onApproveTitle}
          onClick={props.onApprove}
          height={48}
          variant="primary-default"
        />
      </div>
    </div>
  </div>
);

export { BasicModal };
