import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { orderBy } from "lodash";
import clsx from "clsx";

import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BannerFilterBar } from "@app/modules/banner-filter-bar/banner-filter-bar.component";
import { ResourceTextComponent, IconComponent, ModalComponent, Pagination } from "@app/core";
import { Table } from "@app/components/table/table";
import { Column, ISortDirection } from "@webbio/components";
import { platformTranslationKeys } from "@app/constants/platform";
import Edit from "@assets/icons/edit.svg";
import Bin from "@assets/icons/bin.svg";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { bannersThunks } from "@app/redux/thunks/banners.thunk";
import { setCurrentPage } from "@app/redux/reducers/banners";
import { BannersExtendedGetStatusEnum, OutBannerDTO } from "@app/api/generated";
import { useDateFnsFormat } from "@app/util/use-date-fns-format";

import { BannerStatusIcon } from "./banner-status-icon/banner-status-icon.component";
import styles from "./banner-overview-component.module.scss";
import tableStyles from "./table-styling-component.module.scss";

const TAKE = 18;

const BannerOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { banners, isLoadingBanners, totalResults, currentStatus, currentSearch, currentPlatform, currentPage } =
    useAppSelector((state) => state.banners);
  const history = useNavigate();
  const { formatDate } = useDateFnsFormat();

  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDeleteBannerOpen, setIsDeleteBannerOpen] = useState<boolean>(false);
  const [bannerToDelete, setBannerToDelete] = useState<OutBannerDTO | undefined>(undefined);
  const [deletedBanner, setDeletedBanner] = useState<OutBannerDTO | undefined>(undefined);
  const [sortedBanners, setSortedBanners] = useState<OutBannerDTO[]>(banners);

  useEffect(() => {
    setSortedBanners(banners);
  }, [banners]);

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  useEffect(() => {
    getBanners(1);
  }, [currentPlatform, currentSearch, currentStatus]);

  const onPageChange = (page: number) => {
    getBanners(page);
  };

  const getBanners = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(bannersThunks.getBanners(skip, TAKE, currentStatus, currentSearch, currentPlatform));
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onDeleteBanner = (banner: OutBannerDTO) => {
    setBannerToDelete(banner);
    setIsDeleteBannerOpen(true);
  };

  const onDeleteConfirmed = () => {
    if (bannerToDelete) {
      setDeletedBanner(bannerToDelete);

      setTimeout(() => {
        dispatch(bannersThunks.deleteBanner(bannerToDelete.id));
      }, 700);
    }
    setIsDeleteBannerOpen(false);
  };

  const handleSort = (columnId?: string, sortDirection?: ISortDirection) => {
    if (columnId === "startDate") {
      if (!sortDirection) {
        setSortedBanners(banners);
      } else {
        const newSortedBanners = orderBy(banners, "startDate", sortDirection.toLocaleLowerCase() as "asc" | "desc");

        setSortedBanners(newSortedBanners);
      }
    }
  };

  const onTableRowClick = (banner: OutBannerDTO) => {
    history(`/banner-detail/${banner.id}`);
  };

  return (
    <div className={styles.bannerOverview}>
      <div className={styles.container}>
        <BannerFilterBar />
        <div className={styles.overview}>
          <div className={styles.legend}>
            <div className={styles.legendItem}>
              <span className={styles.icon}>
                <BannerStatusIcon status={BannersExtendedGetStatusEnum.VISIBLE} />
              </span>
              <span className={styles.text}> = {intl.formatMessage({ id: "global.banner.status.visible" })}</span>
            </div>
            <div className={styles.legendItem}>
              <span className={styles.icon}>
                <BannerStatusIcon status={BannersExtendedGetStatusEnum.ALMOST_VISIBLE} />
              </span>
              <span className={styles.text}> = {intl.formatMessage({ id: "global.banner.status.almostVisible" })}</span>
            </div>
            <div className={styles.legendItem}>
              <span className={styles.icon}>
                <BannerStatusIcon status={BannersExtendedGetStatusEnum.INVISIBLE} />
              </span>
              <span className={styles.text}> = {intl.formatMessage({ id: "global.banner.status.invisible" })}</span>
            </div>
          </div>

          {sortedBanners && sortedBanners.length > 0 ? (
            <Table
              onSort={handleSort}
              defaultSort={{
                column: "startDate",
                direction: undefined
              }}
              dataSource={sortedBanners}
              isLoading={isLoadingBanners}
            >
              <Column
                field="status"
                title={<ResourceTextComponent resourceKey="bannerOverview.tableHeader.status" />}
                id="status"
                isNotSortable
                onTdClick={onTableRowClick}
                cell={(status, index) => {
                  return (
                    <span
                      className={clsx(deletedBanner?.id === sortedBanners[index as number]?.id && styles.isDeleted)}
                    >
                      <BannerStatusIcon status={status} />
                    </span>
                  );
                }}
              />
              <Column
                field="name"
                title={<ResourceTextComponent resourceKey="bannerOverview.tableHeader.title" />}
                id="title"
                isNotSortable
                onTdClick={onTableRowClick}
                cell={(title, index) => {
                  return (
                    <span
                      className={clsx(deletedBanner?.id === sortedBanners[index as number]?.id && styles.isDeleted)}
                    >
                      {title}
                    </span>
                  );
                }}
              />
              <Column
                field="startDate"
                title="Startdatum"
                id="startDate"
                sortField="startDate"
                onTdClick={onTableRowClick}
                cell={(startDate, index) => {
                  const bannerStartDate = startDate ? formatDate(startDate) : "-";

                  return (
                    <span className={deletedBanner?.id === sortedBanners[index as number].id ? styles.isDeleted : ""}>
                      {bannerStartDate}
                    </span>
                  );
                }}
              />
              <Column
                field="endDate"
                title="Einddatum"
                id="endDate"
                onTdClick={onTableRowClick}
                isNotSortable
                cell={(endDate, index) => {
                  const bannerEndDate = endDate ? formatDate(endDate) : "-";

                  return (
                    <span className={deletedBanner?.id === sortedBanners[index as number].id ? styles.isDeleted : ""}>
                      {bannerEndDate}
                    </span>
                  );
                }}
              />
              <Column
                field="platform"
                title="Platform"
                id="platform"
                onTdClick={onTableRowClick}
                isNotSortable
                cell={(platform, index) => {
                  const platformString = platformTranslationKeys.find((pf) => pf.id === platform.id)?.name;
                  const formattedPlatform = intl.formatMessage({
                    id: platformString || "global.platform.unknown"
                  });

                  return (
                    <span
                      className={clsx(deletedBanner?.id === sortedBanners[index as number]?.id && styles.isDeleted)}
                    >
                      {formattedPlatform}
                    </span>
                  );
                }}
              />
              <Column
                title=""
                field="id"
                isNotSortable
                cell={(_, index) => {
                  const banner = sortedBanners[index as number];

                  return (
                    <div className={clsx(tableStyles.tableCell, tableStyles.push, styles.push)}>
                      <Link to={`/banner-detail/${banner.id}`} className={clsx(styles.smallButton, styles.editButton)}>
                        <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                      </Link>
                      <span
                        role="button"
                        className={clsx(styles.smallButton, styles.removeButton)}
                        onClick={() => onDeleteBanner(banner)}
                      >
                        <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                      </span>
                    </div>
                  );
                }}
              />
            </Table>
          ) : isLoadingBanners ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>
              <ResourceTextComponent resourceKey="bannerOverview.table.noResults" />
            </span>
          )}

          <div className={styles.pagination}>
            <Pagination currentPage={currentPage} totalItems={totalPages} changePage={onPageChange} />
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "bannerOverview.modal.deleteBanner.title" })}
        isModalOpen={isDeleteBannerOpen}
        onCloseModal={() => {
          setIsDeleteBannerOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteBannerOpen(false);
          }}
          onDelete={onDeleteConfirmed}
        />
      </ModalComponent>
    </div>
  );
};

export { BannerOverview };
