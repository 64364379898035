import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import Plus from "@assets/icons/plus.svg";
import Search from "@assets/icons/search.svg";
import { useDebounce } from "@app/util/use-debounce";
import { ClickableComponent, TextFieldComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { externalDealFiltersActions } from "@app/redux/reducers/external-deal-filters";
import { getTranslatedPlatforms } from "@app/constants/platform";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { getMerchants } from "@app/modules/deal-detail/components/api-calls";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { getTranslatedStatusses } from "@app/constants/status";
import { ROUTES } from "@app/constants/routes";
import { ReactSelect } from "@app/core/react-select/react-select";

import styles from "./external-deal-filter-filter-bar-component.module.scss";

const ExternalDealFilterFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { currentSearch, currentStatus, currentPlatform, currentMerchantFilter } = useAppSelector(
    (state) => state.externalDealFilters
  );
  const [search, setSearch] = useState<string>(currentSearch || "");
  const debouncedSearchTerm = useDebounce(search, 500);

  const translatedPlatforms = getTranslatedPlatforms(intl);
  const translatedStatusses = getTranslatedStatusses(intl);

  useEffect(() => {
    dispatch(externalDealFiltersActions.setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onPlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    dispatch(externalDealFiltersActions.setCurrentPlatform(value || undefined));
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    dispatch(externalDealFiltersActions.setCurrentStatus(value || undefined));
  };

  const onMerchantChange = (value: any) => {
    const filterValue = value as FilterItem;
    dispatch(externalDealFiltersActions.setCurrentMerchantFilter(filterValue || undefined));
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const showPlatformInLabels = !currentPlatform;
    const merchants = await getMerchants(inputValue, currentPlatform);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant, showPlatformInLabels)) : []);
  };

  return (
    <div className={styles.storeFilterBar}>
      <span className={styles.title}>
        <h1>{intl.formatMessage({ id: "externalDealFilters.overview.title" })}</h1>
      </span>

      <div className={styles.push}>
        <div className={styles.searchField}>
          <TextFieldComponent
            height={40}
            placeholder={intl.formatMessage({ id: "externalDealFilters.overview.filters.search.placeholder" })}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            id="search"
            icon={Search}
            width={318}
          />
        </div>

        <SelectComponent
          onChange={onStatusChange}
          value={currentStatus}
          options={[
            { id: 0, name: intl.formatMessage({ id: "general.select.status.placeholder" }) },
            ...translatedStatusses
          ]}
          autoWidth
        />
        <SelectComponent
          onChange={onPlatformChange}
          value={currentPlatform}
          options={[
            { id: 0, name: intl.formatMessage({ id: "general.select.platforms.placeholder" }) },
            ...translatedPlatforms
          ]}
          autoWidth
        />
        <div className={styles.merchantSelect}>
          <ReactSelect
            id="merchant"
            isClearable
            placeholder={intl.formatMessage({ id: "general.multiselect.merchants.placeholder" })}
            value={currentMerchantFilter}
            onChange={onMerchantChange}
            loadOptions={loadMerchants}
            isAsync
          />
        </div>

        <span>
          <ClickableComponent
            title={intl.formatMessage({ id: "externalDealFilters.overview.button.add" })}
            height={40}
            icon={Plus}
            iconSize="20px"
            iconFillColor="#ffffff"
            iconPosition="left"
            href={ROUTES.EXTERNAL_DEAL_FILTERS_NEW}
          />
        </span>
      </div>
    </div>
  );
};

export { ExternalDealFilterFilterBar };
