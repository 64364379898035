import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { ClickableComponent, TextFieldComponent, ResourceTextComponent } from "@app/core";
import { ModalComponent } from "@app/core/modal";
import Plus from "@assets/icons/plus.svg";
import Search from "@assets/icons/search.svg";
import { SelectComponent } from "@app/core/select";
import { platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { MultiSelectComponent } from "@app/core/multi-select/multi-select.component";
import { useDebounce } from "@app/util/use-debounce";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { setCurrentMerchant, setCurrentPlatform, setCurrentSearch } from "@app/redux/reducers/featured-categories";

import { getMerchants } from "../deal-detail/components/api-calls";
import { CreateCategoryModal } from "../create-featured-category-modal";
import styles from "./featured-category-filter-bar-component.module.scss";

const CategoryFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentSearch, currentPlatform, currentMerchant } = useAppSelector((state) => state.featuredCategories);

  const [search, setSearch] = useState<string>(currentSearch || "");
  const [isAddFeaturedCategoryOpen, setIsAddFeaturedCategoryOpen] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onPlatformChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentPlatform(value || undefined));
  };

  const onMerchantChange = (value: FilterItem[] | null) => {
    const merchant = value && value.length > 0 ? value[0] : undefined;
    dispatch(setCurrentMerchant(merchant));
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const showPlatformInLabels = !currentPlatform;
    const merchants = await getMerchants(inputValue, currentPlatform);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant, showPlatformInLabels)) : []);
  };

  return (
    <div className={styles.categoriesFilterBar}>
      <span className={styles.title}>
        <h1>
          <ResourceTextComponent resourceKey="categoriesFilterBarComponent.title" />
        </h1>
      </span>
      <div className={styles.push}>
        <div className={styles.searchField}>
          <TextFieldComponent
            height={40}
            placeholder={intl.formatMessage({ id: "categoriesFilterBarComponent.searchPlaceholder" })}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            id="search"
            icon={Search}
            width={318}
          />
        </div>
        <div className={clsx(styles.selectdiv, styles.merchantSelect)}>
          <MultiSelectComponent
            id="merchant"
            type="array"
            loadOptions={loadMerchants}
            placeholder={intl.formatMessage({ id: "dealFilterBarComponent.selectAllMerchants" })}
            onChange={onMerchantChange}
            isMulti={false}
            value={currentMerchant ? [currentMerchant] : undefined}
          />
        </div>
        <div className={styles.selectDiv}>
          <SelectComponent
            onChange={onPlatformChange}
            value={currentPlatform}
            options={[
              { id: 0, name: intl.formatMessage({ id: "dealFilterBarComponent.selectAllPlatforms" }) },
              ...translatedPlatforms
            ]}
            autoWidth
          />
        </div>
        <span className={styles.addDeal}>
          <ClickableComponent
            title={intl.formatMessage({ id: "categoriesFilterBarComponent.addCategory" })}
            height={40}
            icon={Plus}
            iconSize="20px"
            iconFillColor="#ffffff"
            iconPosition="left"
            onClick={() => {
              setIsAddFeaturedCategoryOpen(true);
            }}
          />
        </span>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "categoriesFilterBarComponent.addCategory" })}
        isModalOpen={isAddFeaturedCategoryOpen}
        onCloseModal={() => {
          setIsAddFeaturedCategoryOpen(false);
        }}
        variant="big"
      >
        <CreateCategoryModal
          onSubmit={() => setIsAddFeaturedCategoryOpen(false)}
          onCancel={() => setIsAddFeaturedCategoryOpen(false)}
        />
      </ModalComponent>
    </div>
  );
};

export { CategoryFilterBar };
