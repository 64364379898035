import { LOCAL_STORAGE_KEY } from "@app/constants";

export default class JWTToken {
  public static checkTokenIsExpired(tokenType: "refreshToken" | "accessToken") {
    const tokens = getTokens();
    if (!tokens || !tokens[tokenType]) return true;
    const decoded = this.decodeToken(tokens[tokenType]);
    const currentSeconds = new Date().getTime() / 1000;

    return decoded.exp < currentSeconds;
  }

  public static getRole(): string {
    const tokens = getTokens();
    const decoded = this.decodeToken(tokens.accessToken);

    return decoded.role.name;
  }

  public static getUserId(): number {
    const tokens = getTokens();
    const decoded = this.decodeToken(tokens.accessToken);

    return decoded.user;
  }

  public static decodeToken(encodedToken: any) {
    if (encodedToken === false) return;
    const middleSectionUrl = encodedToken.split(".")[1];
    const base64 = middleSectionUrl.replace("-", "+").replace("_", "/");

    return JSON.parse(window.atob(base64));
  }

  public static getToken(tokenType: "refreshToken" | "accessToken") {
    const tokens = getTokens();

    if (!tokens && !tokens[tokenType]) return false;

    return tokens[tokenType];
  }

  public static getAllTokens() {
    const tokens = getTokens();

    if (!tokens) return false;

    return tokens;
  }
}

const getTokens = () => {
  if (!localStorage.getItem(LOCAL_STORAGE_KEY.TOKENS)) return false;

  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.TOKENS) || "");
};
