import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutBannerDTO } from "@app/api/generated";

export interface IBannersState {
  banners: OutBannerDTO[];
  isLoadingBanners: boolean;
  selectedBanner?: OutBannerDTO;
  totalResults: number;
  currentPage: number;
  currentPlatform?: number;
  currentSearch?: string;
  currentStatus?: number;
}

const initialState: IBannersState = {
  isLoadingBanners: false,
  banners: [],
  totalResults: 0,
  selectedBanner: undefined,
  currentPage: 1,
  currentSearch: undefined,
  currentPlatform: undefined,
  currentStatus: undefined
};

export const slice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setBanners: (state, action: PayloadAction<OutBannerDTO[]>) => {
      state.banners = action.payload;
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    deleteBannerFromList: (state, action: PayloadAction<number>) => {
      state.banners = state.banners.filter((item) => item.id !== action.payload);
    },
    setSelectedBanner: (state, action: PayloadAction<OutBannerDTO | undefined>) => {
      state.selectedBanner = action.payload;
    },
    setIsLoadingBanners: (state, action: PayloadAction<boolean>) => {
      state.isLoadingBanners = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentPlatform: (state, action: PayloadAction<number | undefined>) => {
      state.currentPlatform = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    },
    setCurrentStatus: (state, action: PayloadAction<number | undefined>) => {
      state.currentStatus = action.payload;
    }
  }
});

export const {
  setBanners,
  setTotalResults,
  deleteBannerFromList,
  setIsLoadingBanners,
  setSelectedBanner,
  setCurrentPage,
  setCurrentPlatform,
  setCurrentSearch,
  setCurrentStatus
} = slice.actions;

export default slice.reducer;
