/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BannerListResponseDTO,
  BannerListResponseDTOFromJSON,
  BannerListResponseDTOToJSON,
  CreateBannerDTO,
  CreateBannerDTOFromJSON,
  CreateBannerDTOToJSON,
  OutBannerDTO,
  OutBannerDTOFromJSON,
  OutBannerDTOToJSON,
  PutBannerDTO,
  PutBannerDTOFromJSON,
  PutBannerDTOToJSON
} from "../models";

export interface BannersBannerIdDeleteRequest {
  bannerId: number;
}

export interface BannersBannerIdGetRequest {
  bannerId: number;
}

export interface BannersBannerIdPutRequest {
  bannerId: number;
  putBannerDTO: PutBannerDTO;
}

export interface BannersExtendedGetRequest {
  take: number;
  skip: number;
  status?: BannersExtendedGetStatusEnum;
  searchQuery?: string;
  platformId?: number;
  live?: boolean;
}

export interface BannersGetRequest {
  platformId: number;
  returnDefault?: boolean;
  filters?: string;
}

export interface BannersPostRequest {
  createBannerDTO: CreateBannerDTO;
}

export interface BannersSlugBannerSlugGetRequest {
  bannerSlug: string;
}

/**
 * no description
 */
export class BannersApi extends runtime.BaseAPI {
  /**
   */
  async bannersBannerIdDeleteRaw(
    requestParameters: BannersBannerIdDeleteRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.bannerId === null || requestParameters.bannerId === undefined) {
      throw new runtime.RequiredError(
        "bannerId",
        "Required parameter requestParameters.bannerId was null or undefined when calling bannersBannerIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/banners/{bannerId}`.replace(`{${"bannerId"}}`, encodeURIComponent(String(requestParameters.bannerId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async bannersBannerIdDelete(bannerId: number): Promise<boolean> {
    const response = await this.bannersBannerIdDeleteRaw({ bannerId: bannerId });
    return await response.value();
  }

  /**
   */
  async bannersBannerIdGetRaw(
    requestParameters: BannersBannerIdGetRequest
  ): Promise<runtime.ApiResponse<OutBannerDTO>> {
    if (requestParameters.bannerId === null || requestParameters.bannerId === undefined) {
      throw new runtime.RequiredError(
        "bannerId",
        "Required parameter requestParameters.bannerId was null or undefined when calling bannersBannerIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/banners/{bannerId}`.replace(`{${"bannerId"}}`, encodeURIComponent(String(requestParameters.bannerId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutBannerDTOFromJSON(jsonValue));
  }

  /**
   */
  async bannersBannerIdGet(bannerId: number): Promise<OutBannerDTO> {
    const response = await this.bannersBannerIdGetRaw({ bannerId: bannerId });
    return await response.value();
  }

  /**
   */
  async bannersBannerIdPutRaw(
    requestParameters: BannersBannerIdPutRequest
  ): Promise<runtime.ApiResponse<OutBannerDTO>> {
    if (requestParameters.bannerId === null || requestParameters.bannerId === undefined) {
      throw new runtime.RequiredError(
        "bannerId",
        "Required parameter requestParameters.bannerId was null or undefined when calling bannersBannerIdPut."
      );
    }

    if (requestParameters.putBannerDTO === null || requestParameters.putBannerDTO === undefined) {
      throw new runtime.RequiredError(
        "putBannerDTO",
        "Required parameter requestParameters.putBannerDTO was null or undefined when calling bannersBannerIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/banners/{bannerId}`.replace(`{${"bannerId"}}`, encodeURIComponent(String(requestParameters.bannerId))),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: PutBannerDTOToJSON(requestParameters.putBannerDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutBannerDTOFromJSON(jsonValue));
  }

  /**
   */
  async bannersBannerIdPut(bannerId: number, putBannerDTO: PutBannerDTO): Promise<OutBannerDTO> {
    const response = await this.bannersBannerIdPutRaw({ bannerId: bannerId, putBannerDTO: putBannerDTO });
    return await response.value();
  }

  /**
   */
  async bannersExtendedGetRaw(
    requestParameters: BannersExtendedGetRequest
  ): Promise<runtime.ApiResponse<BannerListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling bannersExtendedGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling bannersExtendedGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.live !== undefined) {
      queryParameters["live"] = requestParameters.live;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/banners/extended`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BannerListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async bannersExtendedGet(
    take: number,
    skip: number,
    status?: BannersExtendedGetStatusEnum,
    searchQuery?: string,
    platformId?: number,
    live?: boolean
  ): Promise<BannerListResponseDTO> {
    const response = await this.bannersExtendedGetRaw({
      take: take,
      skip: skip,
      status: status,
      searchQuery: searchQuery,
      platformId: platformId,
      live: live
    });
    return await response.value();
  }

  /**
   */
  async bannersGetRaw(requestParameters: BannersGetRequest): Promise<runtime.ApiResponse<Array<OutBannerDTO>>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling bannersGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.returnDefault !== undefined) {
      queryParameters["returnDefault"] = requestParameters.returnDefault;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/banners`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutBannerDTOFromJSON));
  }

  /**
   */
  async bannersGet(platformId: number, returnDefault?: boolean, filters?: string): Promise<Array<OutBannerDTO>> {
    const response = await this.bannersGetRaw({
      platformId: platformId,
      returnDefault: returnDefault,
      filters: filters
    });
    return await response.value();
  }

  /**
   */
  async bannersPostRaw(requestParameters: BannersPostRequest): Promise<runtime.ApiResponse<OutBannerDTO>> {
    if (requestParameters.createBannerDTO === null || requestParameters.createBannerDTO === undefined) {
      throw new runtime.RequiredError(
        "createBannerDTO",
        "Required parameter requestParameters.createBannerDTO was null or undefined when calling bannersPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/banners`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateBannerDTOToJSON(requestParameters.createBannerDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutBannerDTOFromJSON(jsonValue));
  }

  /**
   */
  async bannersPost(createBannerDTO: CreateBannerDTO): Promise<OutBannerDTO> {
    const response = await this.bannersPostRaw({ createBannerDTO: createBannerDTO });
    return await response.value();
  }

  /**
   */
  async bannersSlugBannerSlugGetRaw(
    requestParameters: BannersSlugBannerSlugGetRequest
  ): Promise<runtime.ApiResponse<OutBannerDTO>> {
    if (requestParameters.bannerSlug === null || requestParameters.bannerSlug === undefined) {
      throw new runtime.RequiredError(
        "bannerSlug",
        "Required parameter requestParameters.bannerSlug was null or undefined when calling bannersSlugBannerSlugGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/banners/slug/{bannerSlug}`.replace(
        `{${"bannerSlug"}}`,
        encodeURIComponent(String(requestParameters.bannerSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutBannerDTOFromJSON(jsonValue));
  }

  /**
   */
  async bannersSlugBannerSlugGet(bannerSlug: string): Promise<OutBannerDTO> {
    const response = await this.bannersSlugBannerSlugGetRaw({ bannerSlug: bannerSlug });
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum BannersExtendedGetStatusEnum {
  VISIBLE = "VISIBLE",
  INVISIBLE = "INVISIBLE",
  ALMOST_VISIBLE = "ALMOST_VISIBLE"
}
