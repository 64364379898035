import { ImageDTO, ImagesApi, Configuration } from "@app/api/generated";
import { toast } from "react-toastify";
import JWTToken from "@app/authorization/jwt-token";
import { getConfig } from "@app/util";

export const uploadImage = async (file: any): Promise<ImageDTO | undefined> => {
  try {
    const api = new ImagesApi(
      new Configuration({
        basePath: getConfig().API_URL,
        headers: {
          "x-access-token": JWTToken.getToken("accessToken")
        }
      })
    );
    const result = await api.imagesPost(file);

    return result;
  } catch (error) {
    toast.error("Image upload failed");
  }

  return undefined;
};
