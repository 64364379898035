import React, { useState } from "react";
import { useIntl } from "react-intl";
import { PatchManyExternalDealFiltersDTO } from "@app/api/generated";
import { externalDealFiltersThunk } from "@app/redux/thunks/external-deal-filters.thunk";
import { useAppDispatch } from "@app/redux";
import { SelectedFilter } from "@app/modules/external-deal-filter-overview";
import { ResourceTextComponent } from "@app/core";
import { EdfEditBulkForm } from "./edf-edit-bulk.form";

export interface IEdfEditBulkModalProps {
  onCancel: () => void;
  onDone: () => void;
  selectedFilters: SelectedFilter[];
}

const EdfEditBulkModal = (props: IEdfEditBulkModalProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const samePlatform =
    props.selectedFilters.length > 0 &&
    props.selectedFilters.every((el) => props.selectedFilters[0].platformId === el.platformId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (filters: PatchManyExternalDealFiltersDTO, type: "add" | "remove") => {
    setIsLoading(true);

    if (type === "add") {
      await dispatch(externalDealFiltersThunk.patchAddRelationsToManyEdfs(filters));
    } else {
      await dispatch(externalDealFiltersThunk.patchRemoveRelationsToManyEdfs(filters));
    }

    setIsLoading(false);
    props.onDone();
  };

  return (
    <>
      {samePlatform ? (
        <EdfEditBulkForm
          intl={intl}
          onCancel={props.onCancel}
          ids={props.selectedFilters.map((filter) => filter.id)}
          onSubmit={onSubmit}
          isLoading={isLoading}
          platform={props.selectedFilters[0].platformId}
        />
      ) : (
        <ResourceTextComponent resourceKey="externalDealFilters.form.patchMany.differentPlatforms.error" />
      )}
    </>
  );
};

export { EdfEditBulkModal };
