export const dealCreatedBySelectValues: { id: number; name: string }[] = [
  { id: 0, name: "global.createdBy.all" },
  { id: 1, name: "global.createdBy.api" },
  { id: 2, name: "global.createdBy.dashboard" },
  { id: 3, name: "global.createdBy.unknown" }
];

export const statusDealOptions: { id: number; name: string }[] = [
  { id: 0, name: "Status" },
  { id: 1, name: "Live" },
  { id: 2, name: "Offline" }
];

export const categoriesDealOptions: { id: number; name: string }[] = [{ id: 0, name: "Categorieën" }];

export const brandsDealOptions: { id: number; name: string }[] = [{ id: 0, name: "Merken" }];

export interface IDealEditOptions {
  status: number | null;
}
