/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CreateExternalDealFilterDTO,
  CreateExternalDealFilterDTOFromJSON,
  CreateExternalDealFilterDTOToJSON,
  DuplicateExternalDealFiltersDTO,
  DuplicateExternalDealFiltersDTOFromJSON,
  DuplicateExternalDealFiltersDTOToJSON,
  ExternalDealFilterListResponseDTO,
  ExternalDealFilterListResponseDTOFromJSON,
  ExternalDealFilterListResponseDTOToJSON,
  ExternalDealFilterListResponseMvoDTO,
  ExternalDealFilterListResponseMvoDTOFromJSON,
  ExternalDealFilterListResponseMvoDTOToJSON,
  ExternalDealFilterMvoDTO,
  ExternalDealFilterMvoDTOFromJSON,
  ExternalDealFilterMvoDTOToJSON,
  InExternalDealFilterDTO,
  InExternalDealFilterDTOFromJSON,
  InExternalDealFilterDTOToJSON,
  OutExternalDealFilterDTO,
  OutExternalDealFilterDTOFromJSON,
  OutExternalDealFilterDTOToJSON,
  PatchManyExternalDealFiltersDTO,
  PatchManyExternalDealFiltersDTOFromJSON,
  PatchManyExternalDealFiltersDTOToJSON,
  PatchManyExternalDealFiltersLiveDTO,
  PatchManyExternalDealFiltersLiveDTOFromJSON,
  PatchManyExternalDealFiltersLiveDTOToJSON
} from "../models";

export interface ExternalDealFiltersDuplicatePostRequest {
  duplicateExternalDealFiltersDTO: DuplicateExternalDealFiltersDTO;
}

export interface ExternalDealFiltersExternalDealFilterIdDeleteRequest {
  externalDealFilterId: number;
}

export interface ExternalDealFiltersExternalDealFilterIdGetRequest {
  externalDealFilterId: number;
}

export interface ExternalDealFiltersExternalDealFilterIdPatchRequest {
  externalDealFilterId: number;
  externalDealFilterMvoDTO: ExternalDealFilterMvoDTO;
}

export interface ExternalDealFiltersExternalDealFilterIdPutRequest {
  externalDealFilterId: number;
  inExternalDealFilterDTO: InExternalDealFilterDTO;
}

export interface ExternalDealFiltersExternalDealFilterIdSetDealsOfflineOrDecoupleByValidityPatchRequest {
  externalDealFilterId: number;
  externalDealFilterMvoDTO: ExternalDealFilterMvoDTO;
}

export interface ExternalDealFiltersGetRequest {
  filterQuery?: string;
  take?: number;
  skip?: number;
}

export interface ExternalDealFiltersPostRequest {
  createExternalDealFilterDTO: CreateExternalDealFilterDTO;
}

export interface ExternalDealFiltersRelationsAddPatchRequest {
  patchManyExternalDealFiltersDTO: PatchManyExternalDealFiltersDTO;
}

export interface ExternalDealFiltersRelationsRemovePatchRequest {
  patchManyExternalDealFiltersDTO: PatchManyExternalDealFiltersDTO;
}

export interface ExternalDealFiltersSetManyEdfOnlineOrOfflinePatchRequest {
  patchManyExternalDealFiltersLiveDTO: PatchManyExternalDealFiltersLiveDTO;
}

/**
 * no description
 */
export class ExternalDealFiltersApi extends runtime.BaseAPI {
  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersDuplicatePostRaw(
    requestParameters: ExternalDealFiltersDuplicatePostRequest
  ): Promise<runtime.ApiResponse<ExternalDealFilterListResponseMvoDTO>> {
    if (
      requestParameters.duplicateExternalDealFiltersDTO === null ||
      requestParameters.duplicateExternalDealFiltersDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "duplicateExternalDealFiltersDTO",
        "Required parameter requestParameters.duplicateExternalDealFiltersDTO was null or undefined when calling externalDealFiltersDuplicatePost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters/duplicate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DuplicateExternalDealFiltersDTOToJSON(requestParameters.duplicateExternalDealFiltersDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExternalDealFilterListResponseMvoDTOFromJSON(jsonValue)
    );
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersDuplicatePost(
    duplicateExternalDealFiltersDTO: DuplicateExternalDealFiltersDTO
  ): Promise<ExternalDealFilterListResponseMvoDTO> {
    const response = await this.externalDealFiltersDuplicatePostRaw({
      duplicateExternalDealFiltersDTO: duplicateExternalDealFiltersDTO
    });
    return await response.value();
  }

  /**
   */
  async externalDealFiltersExternalDealFilterIdDeleteRaw(
    requestParameters: ExternalDealFiltersExternalDealFilterIdDeleteRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.externalDealFilterId === null || requestParameters.externalDealFilterId === undefined) {
      throw new runtime.RequiredError(
        "externalDealFilterId",
        "Required parameter requestParameters.externalDealFilterId was null or undefined when calling externalDealFiltersExternalDealFilterIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/externalDealFilters/{externalDealFilterId}`.replace(
        `{${"externalDealFilterId"}}`,
        encodeURIComponent(String(requestParameters.externalDealFilterId))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async externalDealFiltersExternalDealFilterIdDelete(externalDealFilterId: number): Promise<boolean> {
    const response = await this.externalDealFiltersExternalDealFilterIdDeleteRaw({
      externalDealFilterId: externalDealFilterId
    });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersExternalDealFilterIdGetRaw(
    requestParameters: ExternalDealFiltersExternalDealFilterIdGetRequest
  ): Promise<runtime.ApiResponse<OutExternalDealFilterDTO>> {
    if (requestParameters.externalDealFilterId === null || requestParameters.externalDealFilterId === undefined) {
      throw new runtime.RequiredError(
        "externalDealFilterId",
        "Required parameter requestParameters.externalDealFilterId was null or undefined when calling externalDealFiltersExternalDealFilterIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/externalDealFilters/{externalDealFilterId}`.replace(
        `{${"externalDealFilterId"}}`,
        encodeURIComponent(String(requestParameters.externalDealFilterId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutExternalDealFilterDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersExternalDealFilterIdGet(externalDealFilterId: number): Promise<OutExternalDealFilterDTO> {
    const response = await this.externalDealFiltersExternalDealFilterIdGetRaw({
      externalDealFilterId: externalDealFilterId
    });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersExternalDealFilterIdPatchRaw(
    requestParameters: ExternalDealFiltersExternalDealFilterIdPatchRequest
  ): Promise<runtime.ApiResponse<OutExternalDealFilterDTO>> {
    if (requestParameters.externalDealFilterId === null || requestParameters.externalDealFilterId === undefined) {
      throw new runtime.RequiredError(
        "externalDealFilterId",
        "Required parameter requestParameters.externalDealFilterId was null or undefined when calling externalDealFiltersExternalDealFilterIdPatch."
      );
    }

    if (
      requestParameters.externalDealFilterMvoDTO === null ||
      requestParameters.externalDealFilterMvoDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "externalDealFilterMvoDTO",
        "Required parameter requestParameters.externalDealFilterMvoDTO was null or undefined when calling externalDealFiltersExternalDealFilterIdPatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters/{externalDealFilterId}`.replace(
        `{${"externalDealFilterId"}}`,
        encodeURIComponent(String(requestParameters.externalDealFilterId))
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ExternalDealFilterMvoDTOToJSON(requestParameters.externalDealFilterMvoDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutExternalDealFilterDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersExternalDealFilterIdPatch(
    externalDealFilterId: number,
    externalDealFilterMvoDTO: ExternalDealFilterMvoDTO
  ): Promise<OutExternalDealFilterDTO> {
    const response = await this.externalDealFiltersExternalDealFilterIdPatchRaw({
      externalDealFilterId: externalDealFilterId,
      externalDealFilterMvoDTO: externalDealFilterMvoDTO
    });
    return await response.value();
  }

  /**
   */
  async externalDealFiltersExternalDealFilterIdPutRaw(
    requestParameters: ExternalDealFiltersExternalDealFilterIdPutRequest
  ): Promise<runtime.ApiResponse<OutExternalDealFilterDTO>> {
    if (requestParameters.externalDealFilterId === null || requestParameters.externalDealFilterId === undefined) {
      throw new runtime.RequiredError(
        "externalDealFilterId",
        "Required parameter requestParameters.externalDealFilterId was null or undefined when calling externalDealFiltersExternalDealFilterIdPut."
      );
    }

    if (requestParameters.inExternalDealFilterDTO === null || requestParameters.inExternalDealFilterDTO === undefined) {
      throw new runtime.RequiredError(
        "inExternalDealFilterDTO",
        "Required parameter requestParameters.inExternalDealFilterDTO was null or undefined when calling externalDealFiltersExternalDealFilterIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters/{externalDealFilterId}`.replace(
        `{${"externalDealFilterId"}}`,
        encodeURIComponent(String(requestParameters.externalDealFilterId))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: InExternalDealFilterDTOToJSON(requestParameters.inExternalDealFilterDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutExternalDealFilterDTOFromJSON(jsonValue));
  }

  /**
   */
  async externalDealFiltersExternalDealFilterIdPut(
    externalDealFilterId: number,
    inExternalDealFilterDTO: InExternalDealFilterDTO
  ): Promise<OutExternalDealFilterDTO> {
    const response = await this.externalDealFiltersExternalDealFilterIdPutRaw({
      externalDealFilterId: externalDealFilterId,
      inExternalDealFilterDTO: inExternalDealFilterDTO
    });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersExternalDealFilterIdSetDealsOfflineOrDecoupleByValidityPatchRaw(
    requestParameters: ExternalDealFiltersExternalDealFilterIdSetDealsOfflineOrDecoupleByValidityPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.externalDealFilterId === null || requestParameters.externalDealFilterId === undefined) {
      throw new runtime.RequiredError(
        "externalDealFilterId",
        "Required parameter requestParameters.externalDealFilterId was null or undefined when calling externalDealFiltersExternalDealFilterIdSetDealsOfflineOrDecoupleByValidityPatch."
      );
    }

    if (
      requestParameters.externalDealFilterMvoDTO === null ||
      requestParameters.externalDealFilterMvoDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "externalDealFilterMvoDTO",
        "Required parameter requestParameters.externalDealFilterMvoDTO was null or undefined when calling externalDealFiltersExternalDealFilterIdSetDealsOfflineOrDecoupleByValidityPatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters/{externalDealFilterId}/setDealsOfflineOrDecoupleByValidity`.replace(
        `{${"externalDealFilterId"}}`,
        encodeURIComponent(String(requestParameters.externalDealFilterId))
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: ExternalDealFilterMvoDTOToJSON(requestParameters.externalDealFilterMvoDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersExternalDealFilterIdSetDealsOfflineOrDecoupleByValidityPatch(
    externalDealFilterId: number,
    externalDealFilterMvoDTO: ExternalDealFilterMvoDTO
  ): Promise<void> {
    await this.externalDealFiltersExternalDealFilterIdSetDealsOfflineOrDecoupleByValidityPatchRaw({
      externalDealFilterId: externalDealFilterId,
      externalDealFilterMvoDTO: externalDealFilterMvoDTO
    });
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersGetRaw(
    requestParameters: ExternalDealFiltersGetRequest
  ): Promise<runtime.ApiResponse<ExternalDealFilterListResponseDTO>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.filterQuery !== undefined) {
      queryParameters["filterQuery"] = requestParameters.filterQuery;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/externalDealFilters`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ExternalDealFilterListResponseDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersGet(
    filterQuery?: string,
    take?: number,
    skip?: number
  ): Promise<ExternalDealFilterListResponseDTO> {
    const response = await this.externalDealFiltersGetRaw({ filterQuery: filterQuery, take: take, skip: skip });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersPostRaw(
    requestParameters: ExternalDealFiltersPostRequest
  ): Promise<runtime.ApiResponse<OutExternalDealFilterDTO>> {
    if (
      requestParameters.createExternalDealFilterDTO === null ||
      requestParameters.createExternalDealFilterDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "createExternalDealFilterDTO",
        "Required parameter requestParameters.createExternalDealFilterDTO was null or undefined when calling externalDealFiltersPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateExternalDealFilterDTOToJSON(requestParameters.createExternalDealFilterDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutExternalDealFilterDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersPost(
    createExternalDealFilterDTO: CreateExternalDealFilterDTO
  ): Promise<OutExternalDealFilterDTO> {
    const response = await this.externalDealFiltersPostRaw({
      createExternalDealFilterDTO: createExternalDealFilterDTO
    });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersRelationsAddPatchRaw(
    requestParameters: ExternalDealFiltersRelationsAddPatchRequest
  ): Promise<runtime.ApiResponse<ExternalDealFilterListResponseMvoDTO>> {
    if (
      requestParameters.patchManyExternalDealFiltersDTO === null ||
      requestParameters.patchManyExternalDealFiltersDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "patchManyExternalDealFiltersDTO",
        "Required parameter requestParameters.patchManyExternalDealFiltersDTO was null or undefined when calling externalDealFiltersRelationsAddPatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters/relations/add`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: PatchManyExternalDealFiltersDTOToJSON(requestParameters.patchManyExternalDealFiltersDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExternalDealFilterListResponseMvoDTOFromJSON(jsonValue)
    );
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersRelationsAddPatch(
    patchManyExternalDealFiltersDTO: PatchManyExternalDealFiltersDTO
  ): Promise<ExternalDealFilterListResponseMvoDTO> {
    const response = await this.externalDealFiltersRelationsAddPatchRaw({
      patchManyExternalDealFiltersDTO: patchManyExternalDealFiltersDTO
    });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersRelationsRemovePatchRaw(
    requestParameters: ExternalDealFiltersRelationsRemovePatchRequest
  ): Promise<runtime.ApiResponse<ExternalDealFilterListResponseMvoDTO>> {
    if (
      requestParameters.patchManyExternalDealFiltersDTO === null ||
      requestParameters.patchManyExternalDealFiltersDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "patchManyExternalDealFiltersDTO",
        "Required parameter requestParameters.patchManyExternalDealFiltersDTO was null or undefined when calling externalDealFiltersRelationsRemovePatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters/relations/remove`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: PatchManyExternalDealFiltersDTOToJSON(requestParameters.patchManyExternalDealFiltersDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExternalDealFilterListResponseMvoDTOFromJSON(jsonValue)
    );
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersRelationsRemovePatch(
    patchManyExternalDealFiltersDTO: PatchManyExternalDealFiltersDTO
  ): Promise<ExternalDealFilterListResponseMvoDTO> {
    const response = await this.externalDealFiltersRelationsRemovePatchRaw({
      patchManyExternalDealFiltersDTO: patchManyExternalDealFiltersDTO
    });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersSetManyEdfOnlineOrOfflinePatchRaw(
    requestParameters: ExternalDealFiltersSetManyEdfOnlineOrOfflinePatchRequest
  ): Promise<runtime.ApiResponse<ExternalDealFilterListResponseMvoDTO>> {
    if (
      requestParameters.patchManyExternalDealFiltersLiveDTO === null ||
      requestParameters.patchManyExternalDealFiltersLiveDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "patchManyExternalDealFiltersLiveDTO",
        "Required parameter requestParameters.patchManyExternalDealFiltersLiveDTO was null or undefined when calling externalDealFiltersSetManyEdfOnlineOrOfflinePatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/externalDealFilters/setManyEdfOnlineOrOffline`,
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: PatchManyExternalDealFiltersLiveDTOToJSON(requestParameters.patchManyExternalDealFiltersLiveDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExternalDealFilterListResponseMvoDTOFromJSON(jsonValue)
    );
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async externalDealFiltersSetManyEdfOnlineOrOfflinePatch(
    patchManyExternalDealFiltersLiveDTO: PatchManyExternalDealFiltersLiveDTO
  ): Promise<ExternalDealFilterListResponseMvoDTO> {
    const response = await this.externalDealFiltersSetManyEdfOnlineOrOfflinePatchRaw({
      patchManyExternalDealFiltersLiveDTO: patchManyExternalDealFiltersLiveDTO
    });
    return await response.value();
  }
}
