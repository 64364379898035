import { storeStatus } from "@app/api/core/merchant/merchant";
import { IntlShape } from "react-intl";

import { IFilterType } from "./filter";

export const getTranslatedStatusses = (intl: IntlShape): IFilterType[] =>
  storeStatus.map((untranslatedStoreStatus) => ({
    id: untranslatedStoreStatus.id,
    name: intl.formatMessage({ id: untranslatedStoreStatus.name })
  }));
