import clsx from "clsx";
import { createElement, DetailedHTMLProps, HTMLAttributes } from "react";
import { IconContext, IconType } from "react-icons/lib";
import * as MdIcons from "react-icons/md";
import * as FiIcons from "react-icons/fi";

import styles from "./icon.module.scss";

export interface IIconProps extends IconContext {
  reactIcon?: string;
  customIcon?: JSX.Element;
  wrapperDivProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

export const Icon = ({ customIcon, reactIcon, wrapperDivProps, ...rest }: IIconProps): JSX.Element => {
  if (reactIcon) {
    return <ReactIcon reactIcon={reactIcon} wrapperDivProps={wrapperDivProps} {...rest} />;
  }

  return (
    <div
      {...wrapperDivProps}
      className={clsx(styles.customIcon, wrapperDivProps?.className)}
      style={{ height: rest.size || "auto", width: rest.size || "auto" }}
    >
      {customIcon}
    </div>
  );
};

export interface IReactIconProps extends IIconProps {
  reactIcon: string;
}

const ReactIcon = ({ reactIcon, wrapperDivProps, ...rest }: IReactIconProps): JSX.Element => {
  const allIcons = getCorrectIconLibrary(reactIcon);

  if (!allIcons || !allIcons?.[reactIcon]) {
    return <></>;
  }

  const icon: IconType = allIcons[reactIcon];

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <IconContext.Provider value={{ ...rest }}>
      <div {...wrapperDivProps} className={clsx(styles.icon, wrapperDivProps?.className)}>
        {createElement(icon, { ...rest })}
      </div>
    </IconContext.Provider>
  );
};

const getCorrectIconLibrary = (reactIcon?: string): any => {
  if (!reactIcon) return undefined;

  if (reactIcon?.toLocaleLowerCase().includes("md")) {
    return MdIcons;
  }

  if (reactIcon?.toLocaleLowerCase().includes("fi")) {
    return FiIcons;
  }

  return undefined;
};
