import React from "react";
import { render } from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";

import { Routes } from "@app/routes";
import { configureAppStore } from "@app/redux/store";
import { IntlProviderContainer } from "@app/translations/intl-provider.container";
import { TooltipProvider } from "@app/modules/tooltip-provider/tooltip-provider";

// Create/use the store
// history MUST be passed here if you want syncing between server on initial route
const store = window.store || configureAppStore(window.__PRELOADED_STATE__);

render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <IntlProviderContainer>
        <TooltipProvider />
        <>
          <Routes />
        </>
      </IntlProviderContainer>
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById("app")
);

if (process.env.NODE_ENV === "development") {
  if (module?.hot?.accept) {
    module.hot.accept();
  }

  if (!window.store) {
    window.store = store;
  }
}
