import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutFeaturedCategoriesDTO } from "@app/api/generated";
import { FilterItem } from "@app/api/core/filter/filter-item";

export interface IFeaturedCategoriesState {
  currentMerchant?: FilterItem;
  currentPage: number;
  currentPlatform?: number;
  currentSearch?: string;
  featuredCategories: OutFeaturedCategoriesDTO[];
  isLoadingFeaturedCategories: boolean;
  selectedFeaturedCategory?: OutFeaturedCategoriesDTO;
  totalResults: number;
}

const initialState: IFeaturedCategoriesState = {
  currentMerchant: undefined,
  currentPage: 1,
  currentPlatform: undefined,
  currentSearch: undefined,
  featuredCategories: [],
  isLoadingFeaturedCategories: false,
  selectedFeaturedCategory: undefined,
  totalResults: 0
};

export const slice = createSlice({
  name: "featuredCategories",
  initialState,
  reducers: {
    setFeaturedCategories: (state, action: PayloadAction<OutFeaturedCategoriesDTO[]>) => {
      state.featuredCategories = action.payload;
    },
    setAddFeaturedCategoryToList: (state, action: PayloadAction<OutFeaturedCategoriesDTO>) => {
      state.featuredCategories = [action.payload, ...state.featuredCategories];
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    deleteFeaturedCategoryFromList: (state, action: PayloadAction<number>) => {
      state.featuredCategories = state.featuredCategories.filter((item) => item.id !== action.payload);
    },
    setIsLoadingFeaturedCategories: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFeaturedCategories = action.payload;
    },
    setSelectedFeaturedCategory: (state, action: PayloadAction<OutFeaturedCategoriesDTO | undefined>) => {
      state.selectedFeaturedCategory = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentPlatform: (state, action: PayloadAction<number | undefined>) => {
      state.currentPlatform = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    },
    setCurrentMerchant: (state, action: PayloadAction<FilterItem | undefined>) => {
      state.currentMerchant = action.payload;
    }
  }
});

export const {
  setFeaturedCategories,
  setTotalResults,
  deleteFeaturedCategoryFromList,
  setIsLoadingFeaturedCategories,
  setSelectedFeaturedCategory,
  setCurrentPage,
  setCurrentPlatform,
  setCurrentSearch,
  setCurrentMerchant,
  setAddFeaturedCategoryToList
} = slice.actions;

export default slice.reducer;
