import { LOCALES } from "@app/constants";
import { getMessagesForLocale } from "@app/translations/get-messages";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const nlTranslations = require("@app/translations/nl-nl.json");

export interface ILocalesState {
  locale: string;
  messages: any;
}

const initialState: ILocalesState = {
  locale: LOCALES.NL,
  messages: nlTranslations
};

export const slice = createSlice({
  name: "locales",
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
      state.messages = getMessagesForLocale(action.payload);
    }
  }
});

export const { setLocale } = slice.actions;

export default slice.reducer;
