import * as React from "react";

import { ImageComponent } from "@app/core";
import styles from "./authorization-container-component.module.scss";

export interface IAuthorizationContainerProps {
  children: any;
}

const AuthorizationContainer = (props: IAuthorizationContainerProps) => (
  <div className={styles.container}>
    <div className={styles.parent}>
      <div className={styles.center}>
        <div className={styles.logo}>
          <ImageComponent
            src="https://viper-development-images.s3-eu-west-1.amazonaws.com/blackFriday/logo-bf-nederland.svg"
            isBlocking
            width="184px"
            height="50px"
          />
        </div>
        <div className={styles.loginContainer}>{props.children}</div>
      </div>
    </div>
  </div>
);

export { AuthorizationContainer };
