import clsx from "clsx";

import { Modal as ModalCore, IModalProps as IModalPropsCore } from "@webbio/components";

import { Icon } from "../icon-webbio/icon";
import styles from "./better-modal.module.scss";

export interface IBetterModalProps extends IModalPropsCore {
  isHidden?: boolean;
}

const BetterModal = (props: IBetterModalProps): JSX.Element => {
  return (
    <ModalCore
      propStyles={styles}
      closeIcon={<Icon reactIcon="MdClose" size="24px" />}
      className={clsx(props.className, props.isHidden && styles.hide)}
      {...props}
    >
      {props.children}
    </ModalCore>
  );
};

export { BetterModal };
