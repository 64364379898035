import { useEffect } from "react";

export const useOutsideClick = (ref: any[] | any, callback: any, checkDomOrder?: boolean): void => {
  const handleClick = (e: MouseEvent): void => {
    const refArray = Array.isArray(ref) ? ref : [ref];
    if (refArray.length === 0) return;

    const clickX = e.clientX;
    const clickY = e.clientY;

    let clickedInside = false;

    refArray.forEach((singleRef) => {
      if (singleRef && (singleRef?.current || singleRef?.getBoundingClientRect)) {
        const fixedRef = singleRef?.current || singleRef;
        const refRect: ClientRect = fixedRef.getBoundingClientRect();
        const clickIsOutsideRef =
          clickX < refRect.left || clickX > refRect.right || clickY < refRect.top || clickY > refRect.bottom;

        const targetIsInsideRef = checkDomOrder && fixedRef.contains(e.target);
        if (!clickIsOutsideRef || targetIsInsideRef) {
          clickedInside = true;
        }
      }
    });

    if (!clickedInside) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useOutsideClick;
