/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MerchantDTO,
  MerchantDTOFromJSON,
  MerchantDTOFromJSONTyped,
  MerchantDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface InFeaturedCategoriesDTO
 */
export interface InFeaturedCategoriesDTO {
  /**
   *
   * @type {number}
   * @memberof InFeaturedCategoriesDTO
   */
  id: number;
  /**
   *
   * @type {PlatformDTO}
   * @memberof InFeaturedCategoriesDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof InFeaturedCategoriesDTO
   */
  categoryTitle: string;
  /**
   *
   * @type {string}
   * @memberof InFeaturedCategoriesDTO
   */
  trackingUrl: string;
  /**
   *
   * @type {string}
   * @memberof InFeaturedCategoriesDTO
   */
  slug: string;
  /**
   *
   * @type {MerchantDTO}
   * @memberof InFeaturedCategoriesDTO
   */
  merchant: MerchantDTO;
}

export function InFeaturedCategoriesDTOFromJSON(json: any): InFeaturedCategoriesDTO {
  return InFeaturedCategoriesDTOFromJSONTyped(json, false);
}

export function InFeaturedCategoriesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InFeaturedCategoriesDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    platform: PlatformDTOFromJSON(json["platform"]),
    categoryTitle: json["categoryTitle"],
    trackingUrl: json["trackingUrl"],
    slug: json["slug"],
    merchant: MerchantDTOFromJSON(json["merchant"])
  };
}

export function InFeaturedCategoriesDTOToJSON(value?: InFeaturedCategoriesDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    platform: PlatformDTOToJSON(value.platform),
    categoryTitle: value.categoryTitle,
    trackingUrl: value.trackingUrl,
    slug: value.slug,
    merchant: MerchantDTOToJSON(value.merchant)
  };
}
