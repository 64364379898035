import { LOCAL_STORAGE_KEY } from "@app/constants/local-storage";
import { RootState } from "./store";

export const saveStateToLocalStorage = (state: Partial<RootState>): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY.STATE, serializedState);
  } catch {
    // ignore write errors
  }
};

export const loadStateFromLocalStorage = (): any => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY.STATE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch {
    return undefined;
  }
};
