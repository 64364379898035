import { IFilter } from "../filter";

export class FilterItem {
  public label: string;
  public value: any;
}

export const labelColors: IFilter[] = [
  { id: 1, name: "Rood" },
  { id: 2, name: "Paars" },
  { id: 3, name: "Roze" },
  { id: 4, name: "Blauw" },
  { id: 5, name: "Aqua" },
  { id: 6, name: "Groen" },
  { id: 7, name: "Geel" },
  { id: 8, name: "Oranje" }
];

export const labelIcons: IFilter[] = [
  { id: 1, name: "Hot" },
  { id: 2, name: "Betrouwbaar" },
  { id: 3, name: "Exclusief" },
  { id: 4, name: "Eco" },
  { id: 5, name: "Meest bekeken" },
  { id: 6, name: "Aanrader" },
  { id: 7, name: "Meest verkocht" },
  { id: 8, name: "Hoogste korting" },
  { id: 9, name: "Geen icoon" }
];
