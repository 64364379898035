import React from "react";
import LazyLoad from "react-lazyload";
import ReactSVG from "react-svg";
import { useIntl } from "react-intl";

import IconDefault from "@assets/icons/no-image.svg";
import { IconComponent } from "@app/core/icon";

import styles from "./image-component.module.scss";

export interface IImageComponentProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  errorImage?: string;
  errorMessage?: string | true;
  height?: string;
  isBlocking?: boolean;
  width?: string;
}
const ImageComponent = (
  props: IImageComponentProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
) => {
  const { errorImage, errorMessage, isBlocking, height, width, ...other } = props;
  const intl = useIntl();

  if (isBlocking) {
    return (
      <span className={styles.imageContainer}>
        <img {...other} />
      </span>
    );
  }

  const computedErrorMessage =
    errorMessage === true ? intl.formatMessage({ id: "image.defaultErrorText" }) : errorMessage;
  const computedErrorImage = errorImage || IconDefault;

  if (!other.src) {
    return (
      <div className={styles.placeholder}>
        <IconComponent icon={computedErrorImage} size="32px" />
        {computedErrorMessage && <span className={styles["placeholder-text"]}>{computedErrorMessage}</span>}
      </div>
    );
  }

  return (
    <LazyLoad>
      {other.src.includes("svg") ? (
        <ReactSVG
          fallback={() => <span>Error!</span>}
          loading={() => <span />}
          renumerateIRIElements
          src={other.src}
          style={{
            height,
            width
          }}
          className={styles["svg-class"]}
        />
      ) : (
        <span className={styles.imageContainer}>
          <img {...other} height={props.height} width={props.width} />
        </span>
      )}
    </LazyLoad>
  );
};

export { ImageComponent };
