export const validatePassword = (password: string) => {
  // Deze regex voorkomt spaties in het wachtwoord.
  if (!/^\S*$/i.test(password)) {
    return false;
  }

  // Wachtwoord moet een kleine letter, hoofdletter en getal bevatten. Deze regex laat ook spaties door. En moet tussen de 8 en 20 karakters zijn.
  // eslint-disable-next-line prefer-regex-literals
  const mediumRegex = new RegExp(/^(?=(.*[a-z])+)(?=(.*[A-Z])+)(?=(.*\d)+).{8,20}$/);

  return mediumRegex.test(password);
};
