import { IntlShape } from "react-intl";
import { IFilterType } from "./filter";

export const sortOrder: IFilterType[] = [
  { id: 1, name: "ASC" },
  { id: 2, name: "DESC" }
];

export const sortOrderToTranslation = (intl: IntlShape): IFilterType[] =>
  sortOrder.map((sort) => ({ id: sort.id, name: intl.formatMessage({ id: `general.sortOrder.${sort.name}` }) }));
