import clsx from "clsx";
import React from "react";
import IconHot from "@assets/icons/labels/hot.svg";
import IconAanrader from "@assets/icons/labels/aanrader.svg";
import IconBetrouwbaar from "@assets/icons/labels/betrouwbaar.svg";
import IconEco from "@assets/icons/labels/eco.svg";
import IconExclusief from "@assets/icons/labels/exclusief.svg";
import IconHoogsteKorting from "@assets/icons/labels/hoogste_korting.svg";
import IconMeestBekeken from "@assets/icons/labels/meest_bekeken.svg";
import IconMeestVerkocht from "@assets/icons/labels/meest_verkocht.svg";
import { IconComponent } from "@app/core/icon";
import styles from "./filter-label.module.scss";

interface IFeaturedLabelProps {
  className?: string;
  colorHex?: string;
  icon?: string;
  showText?: boolean;
  text: string;
}

const component = (props: IFeaturedLabelProps) => {
  const icon = mapIconToImport(props.icon ? props.icon : "hot");

  return (
    <div className={styles.filterLabel}>
      <span className={styles.labelTitle}>Label voorbeeld:</span>
      <div
        className={clsx(styles.label, props.className)}
        style={{ backgroundColor: props.colorHex ? props.colorHex : "" }}
      >
        {icon && <IconComponent icon={icon} fillColor="white" size="12px" />}
        {props.showText && <span className={clsx(styles.labelText, !!icon && styles.withIcon)}>{props.text}</span>}
      </div>
    </div>
  );
};

const FeaturedFilterLabel = component;
export { FeaturedFilterLabel };

const mapIconToImport = (iconName: string): string | undefined => {
  switch (iconName) {
    case "Geen icoon":
      return undefined;
    case "Aanrader":
      return IconAanrader;
    case "Betrouwbaar":
      return IconBetrouwbaar;
    case "Eco":
      return IconEco;
    case "Exclusief":
      return IconExclusief;
    case "Hoogste korting":
      return IconHoogsteKorting;
    case "Meest bekeken":
      return IconMeestBekeken;
    case "Meest verkocht":
      return IconMeestVerkocht;
    default:
      return IconHot;
  }
};
