/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  InUserDTO,
  InUserDTOFromJSON,
  InUserDTOToJSON,
  OutDetailUserDTO,
  OutDetailUserDTOFromJSON,
  OutDetailUserDTOToJSON,
  OutUserDTO,
  OutUserDTOFromJSON,
  OutUserDTOToJSON,
  UserEmailDTO,
  UserEmailDTOFromJSON,
  UserEmailDTOToJSON,
  UserListResponseDTO,
  UserListResponseDTOFromJSON,
  UserListResponseDTOToJSON
} from "../models";

export interface UsersExtendedGetRequest {
  skip: number;
  take: number;
  searchQuery?: string;
  role?: string;
}

export interface UsersPostRequest {
  inUserDTO: InUserDTO;
}

export interface UsersUserIdDeleteRequest {
  userId: number;
}

export interface UsersUserIdEmailPatchRequest {
  userId: number;
  userEmailDTO: UserEmailDTO;
}

export interface UsersUserIdGetRequest {
  userId: number;
}

export interface UsersUserIdPutRequest {
  userId: number;
  inUserDTO: InUserDTO;
}

/**
 * no description
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   */
  async usersExtendedGetRaw(
    requestParameters: UsersExtendedGetRequest
  ): Promise<runtime.ApiResponse<UserListResponseDTO>> {
    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling usersExtendedGet."
      );
    }

    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling usersExtendedGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.role !== undefined) {
      queryParameters["role"] = requestParameters.role;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/extended`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async usersExtendedGet(
    skip: number,
    take: number,
    searchQuery?: string,
    role?: string
  ): Promise<UserListResponseDTO> {
    const response = await this.usersExtendedGetRaw({ skip: skip, take: take, searchQuery: searchQuery, role: role });
    return await response.value();
  }

  /**
   */
  async usersPostRaw(requestParameters: UsersPostRequest): Promise<runtime.ApiResponse<OutUserDTO>> {
    if (requestParameters.inUserDTO === null || requestParameters.inUserDTO === undefined) {
      throw new runtime.RequiredError(
        "inUserDTO",
        "Required parameter requestParameters.inUserDTO was null or undefined when calling usersPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/users`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: InUserDTOToJSON(requestParameters.inUserDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutUserDTOFromJSON(jsonValue));
  }

  /**
   */
  async usersPost(inUserDTO: InUserDTO): Promise<OutUserDTO> {
    const response = await this.usersPostRaw({ inUserDTO: inUserDTO });
    return await response.value();
  }

  /**
   */
  async usersUserIdDeleteRaw(requestParameters: UsersUserIdDeleteRequest): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling usersUserIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async usersUserIdDelete(userId: number): Promise<boolean> {
    const response = await this.usersUserIdDeleteRaw({ userId: userId });
    return await response.value();
  }

  /**
   */
  async usersUserIdEmailPatchRaw(
    requestParameters: UsersUserIdEmailPatchRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling usersUserIdEmailPatch."
      );
    }

    if (requestParameters.userEmailDTO === null || requestParameters.userEmailDTO === undefined) {
      throw new runtime.RequiredError(
        "userEmailDTO",
        "Required parameter requestParameters.userEmailDTO was null or undefined when calling usersUserIdEmailPatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/users/{userId}/email`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: UserEmailDTOToJSON(requestParameters.userEmailDTO)
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async usersUserIdEmailPatch(userId: number, userEmailDTO: UserEmailDTO): Promise<boolean> {
    const response = await this.usersUserIdEmailPatchRaw({ userId: userId, userEmailDTO: userEmailDTO });
    return await response.value();
  }

  /**
   */
  async usersUserIdGetRaw(requestParameters: UsersUserIdGetRequest): Promise<runtime.ApiResponse<OutDetailUserDTO>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling usersUserIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDetailUserDTOFromJSON(jsonValue));
  }

  /**
   */
  async usersUserIdGet(userId: number): Promise<OutDetailUserDTO> {
    const response = await this.usersUserIdGetRaw({ userId: userId });
    return await response.value();
  }

  /**
   */
  async usersUserIdPutRaw(requestParameters: UsersUserIdPutRequest): Promise<runtime.ApiResponse<OutDetailUserDTO>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling usersUserIdPut."
      );
    }

    if (requestParameters.inUserDTO === null || requestParameters.inUserDTO === undefined) {
      throw new runtime.RequiredError(
        "inUserDTO",
        "Required parameter requestParameters.inUserDTO was null or undefined when calling usersUserIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: InUserDTOToJSON(requestParameters.inUserDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutDetailUserDTOFromJSON(jsonValue));
  }

  /**
   */
  async usersUserIdPut(userId: number, inUserDTO: InUserDTO): Promise<OutDetailUserDTO> {
    const response = await this.usersUserIdPutRaw({ userId: userId, inUserDTO: inUserDTO });
    return await response.value();
  }
}
