import { validateEmail } from "./validate-email";
import { validatePassword } from ".";

const checkIfEmpty = (value: string) => {
  if (!/\S/i.test(value)) return true;

  return false;
};

export const passwordFormValidation = (password?: string, isRequired?: boolean) => {
  if (isRequired && !password) {
    return "general.formValidation.password.notEmpty";
  }

  return undefined;
};

export const newPasswordFormValidation = (password?: string, isRequired?: boolean) => {
  if (isRequired && !password) {
    return "general.formValidation.password.notEmpty";
  }

  if (password) {
    if (!/^\S*$/i.test(password)) {
      return "general.formValidation.password.noSpaces";
    }
    if (!validatePassword(password)) {
      return "general.formValidation.newPassword.invalid";
    }
  }

  return undefined;
};

export const mailFormValidation = (mail?: string, isRequired?: boolean) => {
  if (isRequired && !mail) {
    return "general.formValidation.email.notEmpty";
  }
  if (mail && !validateEmail(mail)) {
    return "general.formValidation.email.notValid";
  }

  return undefined;
};

export const phoneFormValidation = (phone?: string, isRequired?: boolean) => {
  if (isRequired && !phone) {
    return "general.formValidation.phone.notEmpty";
  }

  if (phone) {
    if (checkIfEmpty(phone)) {
      return "general.formValidation.phone.onlySpaces";
    }
    if (!/^\+?([\d\s]+-?)+[\d\s]+$/i.test(phone)) {
      return "general.formValidation.phone.notValid";
    }
  }

  return undefined;
};
