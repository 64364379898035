import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";

import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";
import { dateFormatList } from "./date-picker-format-list";
import styles from "./date-picker-component.module.scss";

registerLocale("nl", nl);

interface IDatePickerProps {
  disableAutoComplete?: boolean;
  errorMessage?: string | false;
  id: string;
  includeTime?: boolean;
  initDateFormat?: string;
  label: IFormLabelComponentProps;
  onChange: (date: Date | null, id: string) => void;
  placeholder?: string | true;
  value?: string | null;
}

const DatePickerComponent = (props: IDatePickerProps) => {
  const intl = useIntl();
  const { disableAutoComplete = false } = props;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const initDateFormat = props.initDateFormat || "dd MMM yyyy - HH:mm";

  const placeholderText =
    props.placeholder && typeof props.placeholder !== "string"
      ? intl.formatMessage({ id: "datePicker.defaultPlaceholderText" })
      : props.placeholder;

  useEffect(() => {
    setStartDate(props.value ? new Date(props.value) : null);
  }, [props.value]);

  const setChangeId = (date: Date | null) => {
    let newDate = date;
    const hours = date?.getHours();
    const minutes = date?.getMinutes();
    const singleMinute = 60000;
    if (hours === 0 && minutes === 0 && newDate !== null) {
      newDate = new Date(newDate.getTime() + singleMinute);
    }

    props.onChange(newDate, props.id);
  };

  return (
    <div>
      {props.label && <FormLabelComponent {...props.label} />}
      <div className={styles.datePickerWrapper}>
        <DatePicker
          isClearable
          placeholderText={placeholderText}
          className={styles.inputField}
          popperClassName={styles.popperClassName}
          calendarClassName={styles.calendarClassName}
          selected={startDate}
          onChange={setChangeId}
          showTimeInput={props.includeTime}
          dateFormat={dateFormatList(initDateFormat)}
          locale="nl"
          id={props.id}
          autoComplete={`${!disableAutoComplete}`}
          forceShowMonthNavigation
          showDisabledMonthNavigation
        />
      </div>
      {props.errorMessage && <div className={styles.error}>{props.errorMessage}</div>}
    </div>
  );
};

export { DatePickerComponent };
