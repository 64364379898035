import React, { useState } from "react";
import { useIntl } from "react-intl";

import { ResourceTextComponent } from "@app/core";
import { useAppDispatch } from "@app/redux";
import { externalDealFiltersThunk } from "@app/redux/thunks/external-deal-filters.thunk";
import { DuplicateExternalDealFiltersDTO } from "@app/api/generated";

import styles from "./edf-duplicate-bulk-component.module.scss";
import { SelectedFilter } from "../../external-deal-filter-overview";
import { EdfDuplicateBulkForm } from "./edf-duplicate-bulk-form";

export interface IEdfDuplicateBulkModalProps {
  selectedFilters: SelectedFilter[];
  onCancel: () => void;
  onDone: () => void;
}

const EdfDuplicateBulkModal = (props: IEdfDuplicateBulkModalProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (filters: DuplicateExternalDealFiltersDTO) => {
    setIsLoading(true);

    await dispatch(externalDealFiltersThunk.duplicateExternalDealFilters(filters));
    setIsLoading(false);
    props.onDone();
  };

  return (
    <div className={styles.duplicateModal}>
      <div className={styles.description}>
        <ResourceTextComponent
          resourceKey="externalDealFilters.modal.duplicate.description"
          values={{ amount: props.selectedFilters.length }}
        />
      </div>
      <EdfDuplicateBulkForm
        ids={props.selectedFilters.map((filter) => filter.id)}
        intl={intl}
        isLoading={isLoading}
        onCancel={props.onCancel}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export { EdfDuplicateBulkModal };
