import React from "react";
import clsx from "clsx";

import { css } from "@emotion/core";
import { SyncLoader } from "react-spinners";

import styles from "./spinning-loader.module.scss";

export interface ISpinningLoaderProps {
  className?: string;
}

const SpinningLoader = (props: ISpinningLoaderProps) => (
  <div className={clsx(styles.spinningLoader, props.className)}>
    <SyncLoader css={spinnerOverride} size={ClipLoaderSize} color="#e10a14" loading />
  </div>
);

const spinnerOverride = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const ClipLoaderSize = 6;

export { SpinningLoader };
