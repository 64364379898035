import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";

import { OutExternalDealFilterDTO } from "@app/api/generated";
import { FormGroup } from "@app/core/form-group/form-group";
import { FormRow } from "@app/core/form-row/form-row";
import { FormItem } from "@app/core/form-item/form-item";
import { getTimeDifferenceType, useDateFnsFormat } from "@app/util/use-date-fns-format";
import { Icon } from "@app/core/icon-webbio/icon";

import styles from "./external-deal-filter-form-info.module.scss";

interface IExternalDealFilterDetailFormInfoProps {
  externalDealFilter?: OutExternalDealFilterDTO;
}

const ExternalDealFilterDetailFormInfo = ({ externalDealFilter }: IExternalDealFilterDetailFormInfoProps) => {
  const intl = useIntl();
  const { formatDate, getTimeDifference } = useDateFnsFormat();
  const [runIsInProgress, setRunIsInProgress] = useState<boolean>(true);

  const nextRunAtDate = externalDealFilter?.nextRunAtDate
    ? formatDate(new Date(externalDealFilter.nextRunAtDate), "dd MMM yyyy HH:mm:ss")
    : intl.formatMessage({ id: "externalDealFilters.overview.table.td.nextRunAtDate.default" });

  const lastRunStartDate = externalDealFilter?.lastRunStartDate
    ? formatDate(new Date(externalDealFilter.lastRunStartDate), "dd MMM yyyy HH:mm:ss")
    : "-";

  const lastRunEndDate = externalDealFilter?.lastRunEndDate
    ? formatDate(new Date(externalDealFilter.lastRunEndDate), "dd MMM yyyy HH:mm:ss")
    : "-";

  const lastRunDuration = getLastRunDuration(getTimeDifference, externalDealFilter) || "-";

  useEffect(() => {
    const progress = Boolean(
      externalDealFilter?.lastRunStartDate &&
        externalDealFilter?.lastRunEndDate &&
        externalDealFilter.lastRunStartDate > externalDealFilter?.lastRunEndDate
    );

    setRunIsInProgress(progress);
  }, [externalDealFilter]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [lastRunDuration, runIsInProgress]);

  return (
    <div className={styles.form}>
      <FormGroup
        title={<span>{intl.formatMessage({ id: "externalDealFilters.detail.form.info.title" })}</span>}
        marginBottom={0}
        hideSeparator
      >
        <FormRow>
          <FormItem
            id="lastRunStartDate"
            label={intl.formatMessage({
              id: runIsInProgress
                ? "externalDealFilters.detail.form.info.runningStart.label"
                : "externalDealFilters.detail.form.info.lastRunStartDate.label"
            })}
            hasChildLabel
          >
            <span>{lastRunStartDate}</span>
          </FormItem>

          <FormItem
            id="lastRunDuration"
            label={intl.formatMessage({
              id: runIsInProgress
                ? "externalDealFilters.detail.form.info.runningProgress.label"
                : "externalDealFilters.detail.form.info.lastRunDuration.label"
            })}
            hasChildLabel
          >
            {runIsInProgress ? (
              <div className={styles.loader}>
                <Icon reactIcon="FiLoader" size="14px" />
                {intl.formatMessage({ id: "externalDealFilters.detail.form.info.runningProgress.placeholder" })}
              </div>
            ) : (
              <span data-tip={lastRunEndDate} data-for="BlackFriday">
                {lastRunDuration}
              </span>
            )}
          </FormItem>

          <FormItem
            id="nextRunAtDate"
            label={intl.formatMessage({ id: "externalDealFilters.detail.form.info.nextRunAtDate.label" })}
            hasChildLabel
          >
            <div className={styles.formField}>{runIsInProgress ? "-" : nextRunAtDate}</div>
          </FormItem>
        </FormRow>
      </FormGroup>
    </div>
  );
};

export { ExternalDealFilterDetailFormInfo };

const getLastRunDuration = (
  getTimeDifference: getTimeDifferenceType,
  externalDealFilter?: OutExternalDealFilterDTO
) => {
  const lastRunStartDate = externalDealFilter?.lastRunStartDate && new Date(externalDealFilter.lastRunStartDate);
  const lastRunEndDate = externalDealFilter?.lastRunEndDate && new Date(externalDealFilter.lastRunEndDate);

  if (!lastRunStartDate || !lastRunEndDate) {
    return undefined;
  }

  const exactTimeDifference = getTimeDifference(lastRunEndDate, lastRunStartDate, "HH'u.' mm'm.' ss's.'");

  return exactTimeDifference;
};
