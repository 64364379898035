import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

import FunctionalInfo from "@assets/icons/functional-info.svg";

import styles from "./tooltip-component.module.scss";
import { IconComponent } from "../icon";

export interface ITooltipProps {
  tooltip: string;
}

const Tooltip = (props: ITooltipProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <span data-tip={props.tooltip} data-for="BlackFriday" className={styles.tooltip}>
      <IconComponent size="20px" icon={FunctionalInfo} fillColor="#999999" />
    </span>
  );
};

export { Tooltip };
