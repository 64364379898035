import React from "react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";

import styles from "./nav-bar-component.module.scss";

export interface INavBarItemProps {
  link: string;
  text: string;
}

export const NavBarItem = (props: INavBarItemProps) => {
  const location = useLocation();

  const isSelected = () => {
    const currentPath = location.pathname;

    return currentPath.startsWith(props.link);
  };

  return (
    <div className={clsx(styles.menuItem, isSelected() && styles.isSelected)}>
      <Link to={props.link}>
        <span className={clsx(isSelected() && styles.menuItemSelected)}>{props.text}</span>
      </Link>
    </div>
  );
};
