import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuthorizationState {
  emailAddress: string;
}

const initialState: IAuthorizationState = {
  emailAddress: ""
};

export const slice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    }
  }
});

export const { setEmailAddress } = slice.actions;

export default slice.reducer;
