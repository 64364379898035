import { FiltersApi } from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";

export const getFilters = async (searchQuery: string, typeIds: string) => {
  try {
    if (!searchQuery) {
      return [];
    }

    const filterApi = new FiltersApi(await getAuthorizedApiConfig());
    const allFilters = await filterApi.filtersGet(searchQuery, 50, typeIds);

    return allFilters.filters;
  } catch (error) {
    // TODO: Error logging
  }
};
