/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OutFeaturedCategoriesDTO,
  OutFeaturedCategoriesDTOFromJSON,
  OutFeaturedCategoriesDTOFromJSONTyped,
  OutFeaturedCategoriesDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface FeaturedCategoriesListResponseDTO
 */
export interface FeaturedCategoriesListResponseDTO {
  /**
   *
   * @type {number}
   * @memberof FeaturedCategoriesListResponseDTO
   */
  total: number;
  /**
   *
   * @type {Array<OutFeaturedCategoriesDTO>}
   * @memberof FeaturedCategoriesListResponseDTO
   */
  featuredCategories: Array<OutFeaturedCategoriesDTO>;
}

export function FeaturedCategoriesListResponseDTOFromJSON(json: any): FeaturedCategoriesListResponseDTO {
  return FeaturedCategoriesListResponseDTOFromJSONTyped(json, false);
}

export function FeaturedCategoriesListResponseDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FeaturedCategoriesListResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    featuredCategories: (json["featuredCategories"] as Array<any>).map(OutFeaturedCategoriesDTOFromJSON)
  };
}

export function FeaturedCategoriesListResponseDTOToJSON(value?: FeaturedCategoriesListResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    featuredCategories: (value.featuredCategories as Array<any>).map(OutFeaturedCategoriesDTOToJSON)
  };
}
