/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChangePasswordDTO
 */
export interface ChangePasswordDTO {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordDTO
   */
  currentPassword: string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordDTO
   */
  newPassword: string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordDTO
   */
  newPasswordConfirmation: string;
}

export function ChangePasswordDTOFromJSON(json: any): ChangePasswordDTO {
  return ChangePasswordDTOFromJSONTyped(json, false);
}

export function ChangePasswordDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currentPassword: json["currentPassword"],
    newPassword: json["newPassword"],
    newPasswordConfirmation: json["newPasswordConfirmation"]
  };
}

export function ChangePasswordDTOToJSON(value?: ChangePasswordDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    currentPassword: value.currentPassword,
    newPassword: value.newPassword,
    newPasswordConfirmation: value.newPasswordConfirmation
  };
}
