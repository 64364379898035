import React, { useEffect, useState } from "react";
import { withFormik, FormikProps, Form, FormikErrors } from "formik";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";

import { ClickableComponent, TextFieldComponent, ResourceTextComponent, IconComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { OutFeaturedCategoriesDTO } from "@app/api/generated";
import { InFeaturedCategoriesDTO } from "@app/api/generated/models/InFeaturedCategoriesDTO";
import { MultiSelectComponent } from "@app/core/multi-select/multi-select.component";
import { basicSlugify } from "@app/util/slugify";
import { useRefState } from "@app/util/use-ref-state";
import CopyIcon from "@assets/icons/copy.svg";
import { platformType, platformTranslationKeys, platformLocales } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { getMerchants } from "@app/modules/deal-detail/components/api-calls";
import { createOutLink } from "@app/modules/create-featured-category-modal/create-featured-category.form";

import { SlugifyTextField } from "../slugify-text-field/slugify-text-field";
import styles from "./featured-category-form.module.scss";

interface IFormValues {
  id: number;
  platform: platformType;
  categoryTitle: string;
  merchant: FilterItem;
  trackingUrl: string;
  slug: string;
}

interface IOtherProps {
  disableSaveButton: boolean;
  intl: IntlShape;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
}

let outLinkValue = "";

const InnerForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { touched, errors } = props;
  const [outLink, setOutLink] = useState<string>("");
  const platformRef = useRefState(props.values.platform);
  const locale = platformLocales.find((platformItem) => platformItem.id === Number(props.values.platform));

  const onMultiSelectChange = (value: any, id: string) => {
    if (!value) {
      props.handleChange({ label: "", value: "" });
      props.setFieldValue(id, { label: "", value: "" });
      return;
    }

    let newValue;

    if (!!value) {
      if (Array.isArray(value) && value.length > 0) {
        [newValue] = value;
      } else {
        newValue = value;
      }
    }

    props.handleChange(newValue);
    props.setFieldValue(id, newValue);
  };

  const handleChange = (values: any) => {
    props.handleChange(values);
    props.onEdit();
  };

  useEffect(() => {
    setOutLink(props.values.slug);
    // eslint-disable-next-line no-param-reassign
    props.values.slug = trimSlug(props.values.slug);
  }, []);

  useEffect(() => {
    generateOutLink();
  }, [props.values.platform, props.values.slug, props.values.categoryTitle, props.values.merchant]);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: props.intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  const generateOutLink = () => {
    const outLinkUrl = createOutLink(
      props.values.merchant ? basicSlugify(props.values.merchant.label, true) : "",
      props.values.slug ? basicSlugify(props.values.slug, true) : "",
      locale?.name
    );

    setOutLink(outLinkUrl);
    outLinkValue = outLinkUrl;
  };

  const handleSlugChange = (
    e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    disableSlugify?: boolean
  ): void => {
    const value = !e ? props.values.slug : e.currentTarget.value;
    props.setFieldValue("slug", disableSlugify ? value : basicSlugify(value, true));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(outLinkValue);
    toast.success(props.intl.formatMessage({ id: "featuredCategoryDetailForm.copyToClipboard.success" }));
  };

  const trimSlug = (value: string): string => {
    let newValue = value;

    const outLinkUrl = createOutLink(
      props.values.merchant ? basicSlugify(props.values.merchant.label, true) : "",
      "",
      locale?.name
    );

    newValue = newValue.slice(0, -1);
    newValue = newValue.replace(outLinkUrl, "");

    return newValue;
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const merchants = await getMerchants(inputValue, platformRef.current);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant)) : []);
  };

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="featuredCategoryDetailForm.dataBlock.baseInfo" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.categoryTitle}
                  onChange={handleChange}
                  id="categoryTitle"
                  label={{
                    label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.categoryTitle.label" }),
                    tooltip: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.categoryTitle.tooltip" }),
                    errorMessage: touched.categoryTitle === true && errors.categoryTitle
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <MultiSelectComponent
                  label={{
                    label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.merchants.label" }),
                    tooltip: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.merchants.tooltip" }),
                    errorMessage: touched.merchant?.label === true && errors.merchant?.label
                  }}
                  value={props.values.merchant ? [props.values.merchant] : undefined}
                  onChange={onMultiSelectChange}
                  type="array"
                  id="merchant"
                  loadOptions={loadMerchants}
                />
              </div>
              <div className={styles.formField}>
                <SelectComponent
                  height={40}
                  value={props.values.platform}
                  onChange={handleChange}
                  options={translatedPlatforms}
                  id="platform"
                  label={{
                    label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.platform.label" }),
                    tooltip: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.platform.tooltip" }),
                    errorMessage: touched.platform === true && errors.platform
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="featuredCategoryDetailForm.dataBlock.urlInformation" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  height={40}
                  value={props.values.trackingUrl}
                  onChange={handleChange}
                  id="trackingUrl"
                  label={{
                    label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.trackingUrl.label" }),
                    tooltip: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.trackingUrl.tooltip" }),
                    errorMessage: touched.trackingUrl === true && errors.trackingUrl
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <SlugifyTextField
                  onChange={(e) => handleSlugChange(e, true)}
                  value={props.values.slug}
                  type="text"
                  id="slug"
                  fullWidth
                  onGenerateSlugClick={() => handleSlugChange()}
                  onBlur={props.handleBlur}
                  label={{
                    label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.slug.label" }),
                    tooltip: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.slug.tooltip" }),
                    errorMessage: touched.slug === true && errors.slug
                  }}
                  height={40}
                />
              </div>
            </div>
            <div className={styles.slugOutcomeContainer}>
              <div className={styles.slugOutComeClickField} role="button" onClick={() => copyToClipboard()}>
                <div className={styles.slugOutcomeIcon}>
                  <IconComponent icon={CopyIcon} size="13px" />
                </div>
                <span className={styles.slugOutcome}>{outLink}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomBar}>
          <div className={styles.actions}>
            <ClickableComponent
              variant="primary-inverted"
              title={props.intl.formatMessage({ id: "featuredCategoryDetailForm.button.cancel" })}
              buttonType="button"
              height={48}
              onClick={() => {
                props.onCancel(false);
              }}
            />
            <ClickableComponent
              buttonType="button"
              title={props.intl.formatMessage({ id: "dealForm.button.save" })}
              height={48}
              onClick={() => {
                props.handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  featuredCategory: OutFeaturedCategoriesDTO;
  disableSaveButton: boolean;
  intl: IntlShape;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  onSubmit: (values: InFeaturedCategoriesDTO) => void;
}

export const CategoryDetailForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: (props) => ({
    id: props.featuredCategory.id,
    platform: props.featuredCategory.platform.id,
    categoryTitle: props.featuredCategory.categoryTitle,
    merchant: props.featuredCategory.merchant && mapMerchantToFilterItem(props.featuredCategory.merchant, false),
    trackingUrl: props.featuredCategory.trackingUrl,
    slug: props.featuredCategory.slug
  }),

  validate: (values: IFormValues, props) => {
    const errors: FormikErrors<IFormValues> = {};

    if (!values.platform) {
      errors.platform = props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.platform.required" });
    }
    if (!values.trackingUrl) {
      errors.trackingUrl = props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.trackingUrl.required" });
    }
    if (!values.merchant.label) {
      errors.merchant = {
        label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.merchants.required" })
      };
    }
    if (!values.categoryTitle) {
      errors.categoryTitle = props.intl.formatMessage({
        id: "featuredCategoryDetailForm.error.categoryTitle.required"
      });
    }
    if (!values.slug) {
      errors.slug = props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.slug.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const featuredCategory = mapValuesToFeaturedCategory(values);
    bag.props.onSubmit(featuredCategory);
  }
})(InnerForm);

const mapValuesToFeaturedCategory = (values: IFormValues) => {
  const newFeaturedCategory: InFeaturedCategoriesDTO = {
    id: values.id,
    platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
    categoryTitle: values.categoryTitle,
    merchant: values.merchant && { id: Number(values.merchant.value) },
    trackingUrl: values.trackingUrl,
    slug: outLinkValue
  };

  return newFeaturedCategory;
};
