import { toast } from "react-toastify";
import { logout } from "@app/authorization/authorization-api";

export const handleError = (error: any, dispatch: any, errorMessage: string, keepUserLoggedIn?: boolean) => {
  const FORBIDDEN = 403;
  const UNAUTHORIZED = 401;
  if (error && (error.status === FORBIDDEN || error.status === UNAUTHORIZED) && !keepUserLoggedIn) {
    logout(dispatch, true, "Je sessie is verlopen, log alsjeblief opnieuw in.");
  } else {
    toast.error(errorMessage);
  }
};
