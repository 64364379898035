/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ConfigurationItemDTO,
  ConfigurationItemDTOFromJSON,
  ConfigurationItemDTOFromJSONTyped,
  ConfigurationItemDTOToJSON,
  GenericIdDTO,
  GenericIdDTOFromJSON,
  GenericIdDTOFromJSONTyped,
  GenericIdDTOToJSON,
  MerchantDTO,
  MerchantDTOFromJSON,
  MerchantDTOFromJSONTyped,
  MerchantDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON,
  ProductDealSortDTO,
  ProductDealSortDTOFromJSON,
  ProductDealSortDTOFromJSONTyped,
  ProductDealSortDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface InExternalDealFilterDTO
 */
export interface InExternalDealFilterDTO {
  /**
   *
   * @type {number}
   * @memberof InExternalDealFilterDTO
   */
  id: number;
  /**
   *
   * @type {PlatformDTO}
   * @memberof InExternalDealFilterDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof InExternalDealFilterDTO
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof InExternalDealFilterDTO
   */
  productFeedUrl?: string;
  /**
   *
   * @type {string}
   * @memberof InExternalDealFilterDTO
   */
  lastRunStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof InExternalDealFilterDTO
   */
  lastRunEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof InExternalDealFilterDTO
   */
  nextRunAtDate?: string;
  /**
   *
   * @type {number}
   * @memberof InExternalDealFilterDTO
   */
  runIntervalSeconds: number;
  /**
   *
   * @type {string}
   * @memberof InExternalDealFilterDTO
   */
  searchQuery?: string;
  /**
   *
   * @type {boolean}
   * @memberof InExternalDealFilterDTO
   */
  live: boolean;
  /**
   *
   * @type {number}
   * @memberof InExternalDealFilterDTO
   */
  productDealLimit: number;
  /**
   *
   * @type {ProductDealSortDTO}
   * @memberof InExternalDealFilterDTO
   */
  productDealSort: ProductDealSortDTO;
  /**
   *
   * @type {Array<ConfigurationItemDTO>}
   * @memberof InExternalDealFilterDTO
   */
  configurations?: Array<ConfigurationItemDTO>;
  /**
   *
   * @type {Array<MerchantDTO>}
   * @memberof InExternalDealFilterDTO
   */
  merchants: Array<MerchantDTO>;
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof InExternalDealFilterDTO
   */
  categories?: Array<GenericIdDTO>;
}

export function InExternalDealFilterDTOFromJSON(json: any): InExternalDealFilterDTO {
  return InExternalDealFilterDTOFromJSONTyped(json, false);
}

export function InExternalDealFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InExternalDealFilterDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    platform: PlatformDTOFromJSON(json["platform"]),
    title: json["title"],
    productFeedUrl: !exists(json, "productFeedUrl") ? undefined : json["productFeedUrl"],
    lastRunStartDate: !exists(json, "lastRunStartDate") ? undefined : json["lastRunStartDate"],
    lastRunEndDate: !exists(json, "lastRunEndDate") ? undefined : json["lastRunEndDate"],
    nextRunAtDate: !exists(json, "nextRunAtDate") ? undefined : json["nextRunAtDate"],
    runIntervalSeconds: json["runIntervalSeconds"],
    searchQuery: !exists(json, "searchQuery") ? undefined : json["searchQuery"],
    live: json["live"],
    productDealLimit: json["productDealLimit"],
    productDealSort: ProductDealSortDTOFromJSON(json["productDealSort"]),
    configurations: !exists(json, "configurations")
      ? undefined
      : (json["configurations"] as Array<any>).map(ConfigurationItemDTOFromJSON),
    merchants: (json["merchants"] as Array<any>).map(MerchantDTOFromJSON),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(GenericIdDTOFromJSON)
  };
}

export function InExternalDealFilterDTOToJSON(value?: InExternalDealFilterDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    platform: PlatformDTOToJSON(value.platform),
    title: value.title,
    productFeedUrl: value.productFeedUrl,
    lastRunStartDate: value.lastRunStartDate,
    lastRunEndDate: value.lastRunEndDate,
    nextRunAtDate: value.nextRunAtDate,
    runIntervalSeconds: value.runIntervalSeconds,
    searchQuery: value.searchQuery,
    live: value.live,
    productDealLimit: value.productDealLimit,
    productDealSort: ProductDealSortDTOToJSON(value.productDealSort),
    configurations:
      value.configurations === undefined
        ? undefined
        : (value.configurations as Array<any>).map(ConfigurationItemDTOToJSON),
    merchants: (value.merchants as Array<any>).map(MerchantDTOToJSON),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(GenericIdDTOToJSON)
  };
}
