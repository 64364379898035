/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ConfigurationItemDTO,
  ConfigurationItemDTOFromJSON,
  ConfigurationItemDTOFromJSONTyped,
  ConfigurationItemDTOToJSON,
  OutFilterDTO,
  OutFilterDTOFromJSON,
  OutFilterDTOFromJSONTyped,
  OutFilterDTOToJSON,
  OutMerchantDTO,
  OutMerchantDTOFromJSON,
  OutMerchantDTOFromJSONTyped,
  OutMerchantDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON,
  ProductDealSortDTO,
  ProductDealSortDTOFromJSON,
  ProductDealSortDTOFromJSONTyped,
  ProductDealSortDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutExternalDealFilterDTO
 */
export interface OutExternalDealFilterDTO {
  /**
   *
   * @type {number}
   * @memberof OutExternalDealFilterDTO
   */
  id: number;
  /**
   *
   * @type {PlatformDTO}
   * @memberof OutExternalDealFilterDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof OutExternalDealFilterDTO
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OutExternalDealFilterDTO
   */
  productFeedUrl?: string;
  /**
   *
   * @type {string}
   * @memberof OutExternalDealFilterDTO
   */
  lastRunStartDate?: string;
  /**
   *
   * @type {string}
   * @memberof OutExternalDealFilterDTO
   */
  lastRunEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof OutExternalDealFilterDTO
   */
  nextRunAtDate?: string;
  /**
   *
   * @type {number}
   * @memberof OutExternalDealFilterDTO
   */
  runIntervalSeconds: number;
  /**
   *
   * @type {string}
   * @memberof OutExternalDealFilterDTO
   */
  searchQuery?: string;
  /**
   *
   * @type {boolean}
   * @memberof OutExternalDealFilterDTO
   */
  live: boolean;
  /**
   *
   * @type {number}
   * @memberof OutExternalDealFilterDTO
   */
  productDealLimit: number;
  /**
   *
   * @type {ProductDealSortDTO}
   * @memberof OutExternalDealFilterDTO
   */
  productDealSort: ProductDealSortDTO;
  /**
   *
   * @type {Array<ConfigurationItemDTO>}
   * @memberof OutExternalDealFilterDTO
   */
  configurations?: Array<ConfigurationItemDTO>;
  /**
   *
   * @type {Array<OutMerchantDTO>}
   * @memberof OutExternalDealFilterDTO
   */
  merchants: Array<OutMerchantDTO>;
  /**
   *
   * @type {Array<OutFilterDTO>}
   * @memberof OutExternalDealFilterDTO
   */
  categories?: Array<OutFilterDTO>;
}

export function OutExternalDealFilterDTOFromJSON(json: any): OutExternalDealFilterDTO {
  return OutExternalDealFilterDTOFromJSONTyped(json, false);
}

export function OutExternalDealFilterDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OutExternalDealFilterDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    platform: PlatformDTOFromJSON(json["platform"]),
    title: json["title"],
    productFeedUrl: !exists(json, "productFeedUrl") ? undefined : json["productFeedUrl"],
    lastRunStartDate: !exists(json, "lastRunStartDate") ? undefined : json["lastRunStartDate"],
    lastRunEndDate: !exists(json, "lastRunEndDate") ? undefined : json["lastRunEndDate"],
    nextRunAtDate: !exists(json, "nextRunAtDate") ? undefined : json["nextRunAtDate"],
    runIntervalSeconds: json["runIntervalSeconds"],
    searchQuery: !exists(json, "searchQuery") ? undefined : json["searchQuery"],
    live: json["live"],
    productDealLimit: json["productDealLimit"],
    productDealSort: ProductDealSortDTOFromJSON(json["productDealSort"]),
    configurations: !exists(json, "configurations")
      ? undefined
      : (json["configurations"] as Array<any>).map(ConfigurationItemDTOFromJSON),
    merchants: (json["merchants"] as Array<any>).map(OutMerchantDTOFromJSON),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(OutFilterDTOFromJSON)
  };
}

export function OutExternalDealFilterDTOToJSON(value?: OutExternalDealFilterDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    platform: PlatformDTOToJSON(value.platform),
    title: value.title,
    productFeedUrl: value.productFeedUrl,
    lastRunStartDate: value.lastRunStartDate,
    lastRunEndDate: value.lastRunEndDate,
    nextRunAtDate: value.nextRunAtDate,
    runIntervalSeconds: value.runIntervalSeconds,
    searchQuery: value.searchQuery,
    live: value.live,
    productDealLimit: value.productDealLimit,
    productDealSort: ProductDealSortDTOToJSON(value.productDealSort),
    configurations:
      value.configurations === undefined
        ? undefined
        : (value.configurations as Array<any>).map(ConfigurationItemDTOToJSON),
    merchants: (value.merchants as Array<any>).map(OutMerchantDTOToJSON),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(OutFilterDTOToJSON)
  };
}
