/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { ImageDTO, ImageDTOFromJSON, ImageDTOToJSON } from "../models";

export interface ImagesPostRequest {
  file: Blob;
}

/**
 * no description
 */
export class ImagesApi extends runtime.BaseAPI {
  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async imagesPostRaw(requestParameters: ImagesPostRequest): Promise<runtime.ApiResponse<ImageDTO>> {
    if (requestParameters.file === null || requestParameters.file === undefined) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.file was null or undefined when calling imagesPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    const response = await this.request({
      path: `/images`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ImageDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async imagesPost(file: Blob): Promise<ImageDTO> {
    const response = await this.imagesPostRaw({ file: file });
    return await response.value();
  }
}
