import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { IntlShape, useIntl } from "react-intl";

import Bin from "@assets/icons/bin.svg";
import Edit from "@assets/icons/edit.svg";

import { Pagination } from "@app/core/pagination";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { OutFilterDTO } from "@app/api/generated";
import { FilterFilterBar, getTranslatedFilterType } from "@app/modules/filter-filter-bar/filter-filter-bar.component";
import { IconComponent, ResourceTextComponent } from "@app/core";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { filterThunks } from "@app/redux/thunks/filter.thunk";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { setCurrentPage } from "@app/redux/reducers/filters";
import { FilterModal } from "@app/modules/filter-modal";
import { Column } from "@webbio/components";
import { Table } from "@app/components/table/table";

import styles from "./filter-overview-component.module.scss";
import tableStyles from "./table-styling-component.module.scss";

const TAKE = 18;

const FilterOverview = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { filters, isLoadingFilters, filterTypes, totalResults, currentSearch, currentPage, selectedFilterType } =
    useAppSelector((state) => state.filters);
  const { role } = useAppSelector((state) => state.userAccount);

  const [totalPages, setTotalPages] = useState<number>(0);
  const [isDeleteFilterOpen, setIsDeleteFilterOpen] = useState<boolean>(false);
  const [filterToDelete, setFilterToDelete] = useState<OutFilterDTO | undefined>(undefined);

  const [isAddFilterOpen, setIsAddFilterOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<OutFilterDTO | undefined>(undefined);

  useEffect(() => {
    if (!filterTypes || filterTypes.length === 0) {
      dispatch(filterThunks.getFilterTypes());
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTotalPages(totalResults / TAKE);
  }, [totalResults]);

  useEffect(() => {
    getFilters(1);
  }, [currentSearch, selectedFilterType]);

  const onDeleteFilter = (filter: OutFilterDTO) => {
    setFilterToDelete(filter);
    setIsDeleteFilterOpen(true);
  };

  const onEditFilter = (filter: OutFilterDTO) => {
    setSelectedFilter(filter);
    setIsAddFilterOpen(true);
  };

  const onDeleteConfirmed = () => {
    if (filterToDelete) {
      dispatch(filterThunks.deleteFilter(filterToDelete.id));
      setIsDeleteFilterOpen(false);
    }
  };

  const onPageChange = (page: number) => {
    getFilters(page);
  };

  const getFilters = (newPage?: number) => {
    const page = newPage || currentPage;
    const skip = (page - 1) * TAKE;

    dispatch(filterThunks.getFilters(skip, TAKE, currentSearch, String(selectedFilterType || "")));
    dispatch(setCurrentPage(page));

    window.scrollTo(0, 0);
  };

  const onTableRowClick = (filter: OutFilterDTO) => {
    onEditFilter(filter);
  };

  return (
    <div className={styles.filterOverview}>
      <div className={styles.container}>
        <FilterFilterBar />
        <div className={styles.overview}>
          {filters && filters.length > 0 ? (
            <Table dataSource={filters || []} isLoading={isLoadingFilters}>
              <Column
                title={getFilterColumns(intl).name.title}
                field={getFilterColumns(intl).name.field}
                id={getFilterColumns(intl).name.id}
                onTdClick={onTableRowClick}
                maxWidth={800}
                width={800}
                cell={(name): JSX.Element => {
                  return (
                    <span className={tableStyles.truncate} title={name}>
                      {name || "-"}
                    </span>
                  );
                }}
              />

              <Column
                title={getFilterColumns(intl).type.title}
                field={getFilterColumns(intl).type.field}
                id={getFilterColumns(intl).type.id}
                onTdClick={onTableRowClick}
                maxWidth={200}
                width={200}
                cell={(type) => {
                  const translatedFilterType = getTranslatedFilterType(intl, type);

                  return <span className={tableStyles.truncate}>{translatedFilterType?.name || "-"}</span>;
                }}
              />

              <Column
                title=""
                field="id"
                cell={(_, idx) => {
                  const filter = filters?.[idx as number];

                  return (
                    <div className={clsx(tableStyles.push, styles.push)}>
                      <button
                        className={clsx(styles.smallButton, styles.editButton)}
                        type="button"
                        onClick={() => onEditFilter(filter)}
                      >
                        <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                      </button>
                      {role === "admin" && (
                        <button
                          className={clsx(styles.smallButton, styles.removeButton)}
                          type="button"
                          onClick={() => onDeleteFilter(filter)}
                        >
                          <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                        </button>
                      )}
                    </div>
                  );
                }}
              />
            </Table>
          ) : isLoadingFilters ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>
              <ResourceTextComponent resourceKey="filterOverview.table.noResults" />
            </span>
          )}
          <div className={styles.pagination}>
            <Pagination currentPage={currentPage} totalItems={totalPages} changePage={onPageChange} />
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "filterOverview.modal.deleteFilter.title" })}
        isModalOpen={isDeleteFilterOpen}
        onCloseModal={() => setIsDeleteFilterOpen(false)}
        variant="big"
      >
        <DeleteModal onCancel={() => setIsDeleteFilterOpen(false)} onDelete={onDeleteConfirmed} />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "filterFilterBar.modal.editFilter.title" })}
        isModalOpen={isAddFilterOpen}
        onCloseModal={() => {
          setIsAddFilterOpen(false);
        }}
        variant="big"
      >
        <FilterModal
          onCancel={() => {
            setIsAddFilterOpen(false);
            setSelectedFilter(undefined);
          }}
          onSaveDone={() => {
            setIsAddFilterOpen(false);
            setSelectedFilter(undefined);
            getFilters();
          }}
          filterDto={selectedFilter}
        />
      </ModalComponent>
    </div>
  );
};

export { FilterOverview };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getFilterColumns = (intl: IntlShape) => ({
  name: {
    id: "name",
    title: intl.formatMessage({ id: "filterOverview.tableHeader.nameCategory" }),
    field: "name"
  },
  type: {
    id: "type",
    title: intl.formatMessage({ id: "filterOverview.tableHeader.filterType" }),
    field: "type"
  }
});
