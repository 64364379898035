import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { ClickableComponent, ResourceTextComponent } from "@app/core";
import Logout from "@assets/icons/logout.svg";
import { Tab } from "@app/core/tab/tab.component";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { userAccountThunks } from "@app/redux/thunks/user-account.thunk";
import { InUserDTO } from "@app/api/generated";
import { logout } from "@app/authorization/authorization-api";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { LoginDetailsComponent } from "./login-details/login-details.component";

import { MainUserAccountInfo } from "./user-account-form";
import styles from "./user-account-detail-component.module.scss";

const USERACCOUNT_TAB = "Gebruikersaccount";
const LOGIN_TAB = "Inloggegevens";

const UserAccountDetail = () => {
  const [selectedTab, setSelectedTab] = useState<string>(USERACCOUNT_TAB);
  const [isDeleteUserAccountOpen, setIsDeleteUserAccountOpen] = useState<boolean>(false);

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userAccount);

  useEffect(() => {
    dispatch(userAccountThunks.getUserAccount());
  }, []);

  const handleUserAccountSubmit = (newUser: InUserDTO) => {
    dispatch(userAccountThunks.updateUserAccount(newUser));
  };

  const handleNewEmailSubmit = (values: { currentPassword: string; newEmail: string }) => {
    dispatch(userAccountThunks.updateUserAccountEmail(values.newEmail, values.currentPassword));
  };

  const handleNewPasswordSubmit = (values: {
    passwordFormCurrentPassword: string;
    passwordFormNewPassword: string;
    passwordFormNewPasswordConfirmation: string;
  }) => {
    dispatch(
      userAccountThunks.updateUserAccountPassword({
        currentPassword: values.passwordFormCurrentPassword,
        newPassword: values.passwordFormNewPassword,
        newPasswordConfirmation: values.passwordFormNewPasswordConfirmation
      })
    );
  };

  const handleDeleteUserAccount = () => {
    setIsDeleteUserAccountOpen(true);
  };

  const onDeleteConfirmed = () => {
    dispatch(userAccountThunks.deleteUserAccount());

    setIsDeleteUserAccountOpen(false);
  };

  const onChangeTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const onLogout = async () => {
    logout(dispatch);
  };

  return (
    <div className={styles.userAccountDetail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <>
            <div className={styles.topBarLeft}>
              <h2>
                {user ? (
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                ) : (
                  <span>
                    <ResourceTextComponent resourceKey="userAccountDetail.main.namePlaceholder" />
                  </span>
                )}
              </h2>
            </div>
            <div className={styles.topBarRight}>
              {user && (
                <ClickableComponent
                  title={intl.formatMessage({
                    id: "userAccountDetail.main.logout"
                  })}
                  variant="secondary-inverted"
                  icon={Logout}
                  iconPosition="left"
                  iconSize="16px"
                  onClick={onLogout}
                />
              )}
            </div>
          </>
        </div>
      </div>

      <div className={styles.container}>
        <Tab tabs={[USERACCOUNT_TAB, LOGIN_TAB]} selectedTab={selectedTab} onSelect={onChangeTab} />
        <div className={styles.details}>
          <div className={styles.data}>
            {user ? (
              <>
                {selectedTab === USERACCOUNT_TAB && (
                  <MainUserAccountInfo
                    onSubmitEditUserAccount={handleUserAccountSubmit}
                    onSubmitDeleteUserAccount={handleDeleteUserAccount}
                    user={user}
                  />
                )}
                {selectedTab === LOGIN_TAB && (
                  <LoginDetailsComponent
                    user={user}
                    onSubmitNewEmail={handleNewEmailSubmit}
                    onSubmitNewPassword={handleNewPasswordSubmit}
                  />
                )}
              </>
            ) : (
              <SpinningLoader />
            )}
          </div>
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "userAccountDetail.modal.deleteUserAccount" })}
        isModalOpen={isDeleteUserAccountOpen}
        onCloseModal={() => {
          setIsDeleteUserAccountOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteUserAccountOpen(false);
          }}
          onDelete={onDeleteConfirmed}
        />
      </ModalComponent>
    </div>
  );
};

export { UserAccountDetail };
