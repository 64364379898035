import React from "react";
import { useIntl } from "react-intl";

import { OutDetailUserDTO } from "@app/api/generated";
import { ResourceTextComponent } from "@app/core";

import styles from "./login-details-component.module.scss";
import { ChangeEmailForm } from "./change-email-form/change-email.form";
import { ChangePasswordForm } from "./change-password-form/change-password.form";

export interface ILoginDetailsComponentProps {
  onSubmitNewEmail: (values: any) => void;
  onSubmitNewPassword: (values: any) => void;
  user: OutDetailUserDTO;
}

const LoginDetailsComponent = (props: ILoginDetailsComponentProps) => {
  const intl = useIntl();
  const generalInfoEmailAdress = intl.formatMessage({ id: "general.infoEmailAdress" });

  return (
    <div className={styles.form}>
      <ChangeEmailForm intl={intl} onSubmit={props.onSubmitNewEmail} currentEmail={props.user.email} />
      <ChangePasswordForm intl={intl} onSubmit={props.onSubmitNewPassword} />

      <div className={styles.bottomBar}>
        <div className={styles.complaints}>
          <span>
            <ResourceTextComponent
              resourceKey="general.forms.contact"
              values={{
                emailAdress: (
                  <a href={`mailto:${generalInfoEmailAdress}`} target="_blank" rel="noopener noreferrer">
                    {generalInfoEmailAdress}
                  </a>
                )
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export { LoginDetailsComponent };
