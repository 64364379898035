import { handleError } from "@app/util/handle-error";
import {
  CreateExternalDealFilterDTO,
  DuplicateExternalDealFiltersDTO,
  ExternalDealFilterListResponseMvoDTO,
  ExternalDealFiltersApi,
  InExternalDealFilterDTO,
  OutExternalDealFilterDTO,
  PatchManyExternalDealFiltersDTO,
  PatchManyExternalDealFiltersLiveDTO
} from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";
import { FilterItem } from "@app/api/core/filter/filter-item";

import { externalDealFiltersActions } from "../reducers/external-deal-filters";
import { toast } from "react-toastify";

const externalDealFiltersFiltersToJsonString = (
  searchQuery?: string,
  merchantId?: number,
  platformId?: number,
  status?: boolean
): string =>
  JSON.stringify({
    searchQuery,
    platformId,
    merchantId,
    status
  });

const getExternalDealFilters =
  (
    skip: number,
    take: number,
    merchantFilter?: FilterItem,
    platformId?: number,
    searchQuery?: string,
    status?: number
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());
      dispatch(externalDealFiltersActions.setIsLoading(true));

      const filterQuery = externalDealFiltersFiltersToJsonString(
        searchQuery,
        merchantFilter?.value,
        platformId,
        !status ? undefined : status === 1 ? true : false
      );

      const resultFromApi = await api.externalDealFiltersGet(filterQuery, take, skip);

      dispatch(externalDealFiltersActions.setExternalDealFilters(resultFromApi.externalDealFilters || []));
      dispatch(externalDealFiltersActions.setTotalResults(resultFromApi.total));
      dispatch(externalDealFiltersActions.setTotalPages(Math.ceil(resultFromApi.total / take)));
      dispatch(externalDealFiltersActions.setIsLoading(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get external deal filters");
    }
  };

const getExternalDealFilter =
  (id: number): AppThunk =>
  async (dispatch): Promise<OutExternalDealFilterDTO | undefined> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());

      const resultFromApi = await api.externalDealFiltersExternalDealFilterIdGet(id);

      dispatch(externalDealFiltersActions.setSelectedExternalDealFilter(resultFromApi));

      return resultFromApi;
    } catch (error) {
      handleError(error, dispatch, "Failed to get external deal filter");

      return undefined;
    }
  };

const deleteExternalDealFilter =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());

      const isDeleted = await api.externalDealFiltersExternalDealFilterIdDelete(id);

      if (isDeleted) {
        dispatch(externalDealFiltersActions.deleteExternalDealFilterFromList(id));
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to delete external deal filter");
      // new FatalError(error.name, error.message, error.stack);
    }
  };

const createExternalDealFilter =
  (externalDealFilter: CreateExternalDealFilterDTO): AppThunk =>
  async (dispatch): Promise<OutExternalDealFilterDTO | undefined> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());
      const createdExternalDealFilter = await api.externalDealFiltersPost(externalDealFilter);

      dispatch(externalDealFiltersActions.setSelectedExternalDealFilter(createdExternalDealFilter));

      return createdExternalDealFilter;
    } catch (error) {
      handleError(error, dispatch, "Failed to create new external deal filter");
    }

    return undefined;
  };

const duplicateExternalDealFilters =
  (filters: DuplicateExternalDealFiltersDTO): AppThunk =>
  async (dispatch): Promise<ExternalDealFilterListResponseMvoDTO | undefined> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());
      const createdExternalDealFilters = await api.externalDealFiltersDuplicatePost(filters);

      return createdExternalDealFilters;
    } catch (error) {
      handleError(error, dispatch, "Failed to duplicate external deal filters.");
    }

    return undefined;
  };

const saveExternalDealFilter =
  (externalDealFilter: InExternalDealFilterDTO): AppThunk =>
  async (dispatch): Promise<OutExternalDealFilterDTO | undefined> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());
      const savedExternalDealFilter = await api.externalDealFiltersExternalDealFilterIdPut(
        externalDealFilter.id,
        externalDealFilter
      );

      dispatch(externalDealFiltersActions.setSelectedExternalDealFilter(savedExternalDealFilter));

      return savedExternalDealFilter;
    } catch (error) {
      handleError(error, dispatch, "Failed to save external deal filter");
    }
  };

const patchRemoveRelationsToManyEdfs =
  (externalDealFilters: PatchManyExternalDealFiltersDTO): AppThunk =>
  async (dispatch): Promise<ExternalDealFilterListResponseMvoDTO | undefined> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());
      const savedExternalDealFilters = await api.externalDealFiltersRelationsRemovePatch(externalDealFilters);

      toast.success("De filters zijn aangepast.");
      return savedExternalDealFilters;
    } catch (error) {
      handleError(error, dispatch, "Failed to save external deal filter");
    }
  };

const patchAddRelationsToManyEdfs =
  (externalDealFilters: PatchManyExternalDealFiltersDTO): AppThunk =>
  async (dispatch): Promise<ExternalDealFilterListResponseMvoDTO | undefined> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());
      const savedExternalDealFilters = await api.externalDealFiltersRelationsAddPatch(externalDealFilters);

      toast.success("De filters zijn aangepast.");
      return savedExternalDealFilters;
    } catch (error) {
      handleError(error, dispatch, "Failed to save external deal filter");
    }
  };

const patchLiveManyEdfs =
  (externalDealFilters: PatchManyExternalDealFiltersLiveDTO): AppThunk =>
  async (dispatch): Promise<ExternalDealFilterListResponseMvoDTO | undefined> => {
    try {
      const api = new ExternalDealFiltersApi(await getAuthorizedApiConfig());
      const savedExternalDealFilters = await api.externalDealFiltersSetManyEdfOnlineOrOfflinePatch(externalDealFilters);

      toast.success("De filters zijn aangepast.");
      return savedExternalDealFilters;
    } catch (error) {
      handleError(error, dispatch, "Failed to save external deal filter");
    }
  };

export const externalDealFiltersThunk = {
  getExternalDealFilters,
  getExternalDealFilter,
  deleteExternalDealFilter,
  createExternalDealFilter,
  saveExternalDealFilter,
  patchAddRelationsToManyEdfs,
  patchRemoveRelationsToManyEdfs,
  duplicateExternalDealFilters,
  patchLiveManyEdfs
};
