/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EmailDTO
 */
export interface EmailDTO {
  /**
   *
   * @type {string}
   * @memberof EmailDTO
   */
  email: string;
}

export function EmailDTOFromJSON(json: any): EmailDTO {
  return EmailDTOFromJSONTyped(json, false);
}

export function EmailDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"]
  };
}

export function EmailDTOToJSON(value?: EmailDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email
  };
}
