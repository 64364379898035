import React from "react";
import { IntlProvider } from "react-intl";

import { LOCALES } from "@app/constants";
import { useAppSelector } from "@app/redux/store";

import { getMessagesForLocale } from "./get-messages";

export interface IIntlProviderContainerProps {
  children: React.ReactNode;
}

const IntlProviderContainer = (props: IIntlProviderContainerProps) => {
  const { locale, messages } = useAppSelector((state) => state.locales);

  const translations = messages || getMessagesForLocale(locale);

  return (
    <IntlProvider locale={locale} defaultLocale={LOCALES.NL} messages={translations}>
      {props.children}
    </IntlProvider>
  );
};

export { IntlProviderContainer };
