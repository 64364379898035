/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ChangePasswordDTO,
  ChangePasswordDTOFromJSON,
  ChangePasswordDTOToJSON,
  EmailDTO,
  EmailDTOFromJSON,
  EmailDTOToJSON,
  LoginDTO,
  LoginDTOFromJSON,
  LoginDTOToJSON,
  ResetPasswordDTO,
  ResetPasswordDTOFromJSON,
  ResetPasswordDTOToJSON,
  ResetTokenDTO,
  ResetTokenDTOFromJSON,
  ResetTokenDTOToJSON,
  TokenDTO,
  TokenDTOFromJSON,
  TokenDTOToJSON
} from "../models";

export interface AuthAccessTokenPostRequest {
  tokenDTO: TokenDTO;
}

export interface AuthLoginPostRequest {
  loginDTO: LoginDTO;
}

export interface AuthLogoutPostRequest {
  xAccessToken: string;
  tokenDTO: TokenDTO;
}

export interface AuthPasswordResetAllGetRequest {
  token: string;
}

export interface AuthPasswordResetPutRequest {
  resetPasswordDTO: ResetPasswordDTO;
}

export interface AuthPasswordUserUserIdPutRequest {
  userId: number;
  changePasswordDTO: ChangePasswordDTO;
}

export interface AuthResetTokenRequestPostRequest {
  emailDTO: EmailDTO;
}

export interface AuthResetTokenValidatePostRequest {
  resetTokenDTO: ResetTokenDTO;
}

/**
 * no description
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   */
  async authAccessTokenPostRaw(requestParameters: AuthAccessTokenPostRequest): Promise<runtime.ApiResponse<TokenDTO>> {
    if (requestParameters.tokenDTO === null || requestParameters.tokenDTO === undefined) {
      throw new runtime.RequiredError(
        "tokenDTO",
        "Required parameter requestParameters.tokenDTO was null or undefined when calling authAccessTokenPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/accessToken`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenDTOToJSON(requestParameters.tokenDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => TokenDTOFromJSON(jsonValue));
  }

  /**
   */
  async authAccessTokenPost(tokenDTO: TokenDTO): Promise<TokenDTO> {
    const response = await this.authAccessTokenPostRaw({ tokenDTO: tokenDTO });
    return await response.value();
  }

  /**
   */
  async authLoginPostRaw(requestParameters: AuthLoginPostRequest): Promise<runtime.ApiResponse<TokenDTO>> {
    if (requestParameters.loginDTO === null || requestParameters.loginDTO === undefined) {
      throw new runtime.RequiredError(
        "loginDTO",
        "Required parameter requestParameters.loginDTO was null or undefined when calling authLoginPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/login`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: LoginDTOToJSON(requestParameters.loginDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => TokenDTOFromJSON(jsonValue));
  }

  /**
   */
  async authLoginPost(loginDTO: LoginDTO): Promise<TokenDTO> {
    const response = await this.authLoginPostRaw({ loginDTO: loginDTO });
    return await response.value();
  }

  /**
   */
  async authLogoutPostRaw(requestParameters: AuthLogoutPostRequest): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.xAccessToken === null || requestParameters.xAccessToken === undefined) {
      throw new runtime.RequiredError(
        "xAccessToken",
        "Required parameter requestParameters.xAccessToken was null or undefined when calling authLogoutPost."
      );
    }

    if (requestParameters.tokenDTO === null || requestParameters.tokenDTO === undefined) {
      throw new runtime.RequiredError(
        "tokenDTO",
        "Required parameter requestParameters.tokenDTO was null or undefined when calling authLogoutPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xAccessToken !== undefined && requestParameters.xAccessToken !== null) {
      headerParameters["x-access-token"] = String(requestParameters.xAccessToken);
    }

    const response = await this.request({
      path: `/auth/logout`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenDTOToJSON(requestParameters.tokenDTO)
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async authLogoutPost(xAccessToken: string, tokenDTO: TokenDTO): Promise<boolean> {
    const response = await this.authLogoutPostRaw({ xAccessToken: xAccessToken, tokenDTO: tokenDTO });
    return await response.value();
  }

  /**
   */
  async authPasswordResetAllGetRaw(
    requestParameters: AuthPasswordResetAllGetRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.token === null || requestParameters.token === undefined) {
      throw new runtime.RequiredError(
        "token",
        "Required parameter requestParameters.token was null or undefined when calling authPasswordResetAllGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.token !== undefined) {
      queryParameters["token"] = requestParameters.token;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/auth/password/reset/all`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async authPasswordResetAllGet(token: string): Promise<void> {
    await this.authPasswordResetAllGetRaw({ token: token });
  }

  /**
   */
  async authPasswordResetPutRaw(requestParameters: AuthPasswordResetPutRequest): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.resetPasswordDTO === null || requestParameters.resetPasswordDTO === undefined) {
      throw new runtime.RequiredError(
        "resetPasswordDTO",
        "Required parameter requestParameters.resetPasswordDTO was null or undefined when calling authPasswordResetPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/password/reset`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ResetPasswordDTOToJSON(requestParameters.resetPasswordDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async authPasswordResetPut(resetPasswordDTO: ResetPasswordDTO): Promise<void> {
    await this.authPasswordResetPutRaw({ resetPasswordDTO: resetPasswordDTO });
  }

  /**
   */
  async authPasswordUserUserIdPutRaw(
    requestParameters: AuthPasswordUserUserIdPutRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling authPasswordUserUserIdPut."
      );
    }

    if (requestParameters.changePasswordDTO === null || requestParameters.changePasswordDTO === undefined) {
      throw new runtime.RequiredError(
        "changePasswordDTO",
        "Required parameter requestParameters.changePasswordDTO was null or undefined when calling authPasswordUserUserIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/password/user/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(requestParameters.userId))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ChangePasswordDTOToJSON(requestParameters.changePasswordDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async authPasswordUserUserIdPut(userId: number, changePasswordDTO: ChangePasswordDTO): Promise<void> {
    await this.authPasswordUserUserIdPutRaw({ userId: userId, changePasswordDTO: changePasswordDTO });
  }

  /**
   */
  async authResetTokenRequestPostRaw(
    requestParameters: AuthResetTokenRequestPostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.emailDTO === null || requestParameters.emailDTO === undefined) {
      throw new runtime.RequiredError(
        "emailDTO",
        "Required parameter requestParameters.emailDTO was null or undefined when calling authResetTokenRequestPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/resetToken/request`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: EmailDTOToJSON(requestParameters.emailDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async authResetTokenRequestPost(emailDTO: EmailDTO): Promise<void> {
    await this.authResetTokenRequestPostRaw({ emailDTO: emailDTO });
  }

  /**
   */
  async authResetTokenValidatePostRaw(
    requestParameters: AuthResetTokenValidatePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.resetTokenDTO === null || requestParameters.resetTokenDTO === undefined) {
      throw new runtime.RequiredError(
        "resetTokenDTO",
        "Required parameter requestParameters.resetTokenDTO was null or undefined when calling authResetTokenValidatePost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/resetToken/validate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ResetTokenDTOToJSON(requestParameters.resetTokenDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async authResetTokenValidatePost(resetTokenDTO: ResetTokenDTO): Promise<void> {
    await this.authResetTokenValidatePostRaw({ resetTokenDTO: resetTokenDTO });
  }
}
