import { useIntl } from "react-intl";
import { TextFieldComponent, ITextFieldProps } from "@app/core/text-field";
import { IconComponent } from "@app/core/icon";
import RotatingArrowRight from "@assets/icons/rotating-arrow-right.svg";

import styles from "./slugify-text-field.module.scss";

export interface ISlugifyTextFieldProps extends ITextFieldProps {
  onGenerateSlugClick: () => void;
}

export const SlugifyTextField = ({ onGenerateSlugClick, ...rest }: ISlugifyTextFieldProps): JSX.Element => {
  return (
    <div className={styles.slugifyTextField}>
      <TextFieldComponent {...rest} />

      <button
        onClick={onGenerateSlugClick}
        type="button"
        title="Slugify"
        className={styles.generate}
        disabled={rest.disabled}
      >
        <IconComponent icon={RotatingArrowRight} size="14px" />
      </button>
    </div>
  );
};
