export enum ExternalDealFilterConfigurationFieldsEnum {
  BRAND = "brand",
  EAN = "ean",
  PRICE = "price",
  TITLE = "title",
  DESCRIPTION = "description"
}

export const externalDealFilterConfigurationFields: ExternalDealFilterConfigurationFieldsEnum[] = [
  ExternalDealFilterConfigurationFieldsEnum.BRAND,
  ExternalDealFilterConfigurationFieldsEnum.EAN,
  ExternalDealFilterConfigurationFieldsEnum.PRICE,
  ExternalDealFilterConfigurationFieldsEnum.TITLE,
  ExternalDealFilterConfigurationFieldsEnum.DESCRIPTION
];
