import { FormGroup as FormGroupCore, IFormGroupProps as IFormGroupPropsCore } from "@webbio/components";

import styles from "./form-group.module.scss";

export interface IFormGroupProps extends Omit<IFormGroupPropsCore, "title"> {
  hideSeparator?: boolean;
  title?: JSX.Element | string;
}

const FormGroup = ({ title, ...rest }: IFormGroupProps): JSX.Element => {
  const computedTitle = typeof title === "string" ? <span>{title}</span> : title;

  return <FormGroupCore {...rest} title={computedTitle} propStyles={styles} separator={!rest.hideSeparator} />;
};

export { FormGroup };
