import * as React from "react";

import { ModalDialogComponent } from "./modal-dialog.component";

export type ModalVariant = "small" | "big" | "full-screen";
export type Role = "dialog" | "alertdialog";
export interface IModalComponentProps {
  ariaLabel?: string;
  children: JSX.Element;
  isModalOpen: boolean;
  modalBackground?: string;
  onCloseModal: () => void;
  role?: Role;
  title?: string;
  variant: ModalVariant;
}

const ModalComponent = (props: IModalComponentProps) => {
  const modalRef = React.createRef<HTMLDivElement>();
  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Escape") props.onCloseModal();
  };

  const onClickAway = (e: any) => {
    if (modalRef.current && modalRef.current && modalRef.current.contains(e.target)) return;
    props.onCloseModal();
  };

  return (
    <>
      {props.isModalOpen && (
        <ModalDialogComponent
          title={props.title}
          ariaLabel={props.ariaLabel ? props.ariaLabel : "dialog"}
          background={props.modalBackground ? props.modalBackground : "#fff"}
          onClose={props.onCloseModal}
          variant={props.variant}
          role={props.role ? props.role : "dialog"}
          onKeyDown={onKeyDown}
          modalRef={modalRef}
          onClickAway={onClickAway}
        >
          {props.children}
        </ModalDialogComponent>
      )}
    </>
  );
};
export { ModalComponent };
