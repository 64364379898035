export const userRoles: UserRoleType[] = [
  { id: 1, name: "global.userStatus.admin", apiName: "admin" },
  { id: 2, name: "global.userStatus.storeOwner", apiName: "merchant" }
];

export enum UserRoleEnum {
  "Admin" = 1,
  "Winkeleigenaar" = 2
}

export type UserRoleType = {
  id: number;
  name: string;
  apiName?: string;
};
