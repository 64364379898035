import React, { useEffect, useRef } from "react";
import { withFormik, FormikProps, FormikErrors, Form, FieldArray, FieldArrayRenderProps } from "formik";
import { EditorState } from "draft-js";
import { IntlShape } from "react-intl";

import { TextFieldComponent, ClickableComponent, ResourceTextComponent, IconComponent } from "@app/core";
import { DatePickerComponent } from "@app/core/date-picker/date-picker.component";
import { MultiSelectComponent } from "@app/core/multi-select/multi-select.component";
import { OutCampaignDTO, InCampaignDTO } from "@app/api/generated";
import { RichEditor } from "@app/core/rich-editor";
import { mapToFilterItem } from "@app/api/core/filter/map-filter-item";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { CheckboxComponent } from "@app/core/checkbox";
import IconDelete from "@assets/icons/remove-circle-outline.svg";
import IconAdd from "@assets/icons/plus.svg";
import { FormLabelComponent } from "@app/core/form-label";

import { getFilters } from "../api-calls";
import styles from "./campaign-form.module.scss";

const MAX_USPS = 4;
export interface ICampaignFormValues {
  couponCode?: string;
  description?: string;
  endDate?: string | null;
  excerpt?: string;
  featuredFilter?: FilterItem | null;
  id?: number;
  isFeatured: boolean;
  isFeaturedFilterActive: boolean;
  name: string;
  startDate?: string | null;
  startDatePublish?: string | null;
  startDateStatusShow?: string | null;
  subtitle?: string;
  trackingUrl?: string;
  url?: string;
  usps?: string[];
}

interface IOtherProps {
  disableSaveButton: boolean;
  intl: IntlShape;
  merchantId: number;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  role?: string;
  submitRef?: React.RefObject<HTMLButtonElement>;
}

const InnerForm = (props: IOtherProps & FormikProps<ICampaignFormValues>) => {
  const { touched, errors } = props;
  const uspsWrapperRef = useRef<HTMLDivElement>(null);

  const generalAdvertiserEmailAdress = props.intl.formatMessage({ id: "general.advertiserEmailAdress" });

  const onCustomChange = (value: any, id: string) => {
    props.handleChange(value);
    props.onEdit();
    props.setFieldValue(id, value);
  };

  const onMultiSelectChange = (value: any, id: string) => {
    let newValue;
    if (!!value) {
      if (Array.isArray(value) && value.length > 0) {
        [newValue] = value;
      } else {
        newValue = null;
      }
    }

    props.handleChange(newValue);
    props.setFieldValue(id, newValue);
  };

  const onDatePickerChange = (date: Date | null, id: string) => {
    let newValue: Date | null | string = date;

    if (newValue) {
      newValue = newValue.toISOString();
    }

    props.handleChange(newValue);
    props.setFieldValue(id, newValue);
  };

  const loadOptions = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const filters = await getFilters(inputValue, "2");
    callback(filters ? filters.map(mapToFilterItem) : []);
  };

  useEffect(() => {
    props.onEdit();
  }, [props.values]);

  const handleOnOptionKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ): void => {
    const uspsLength = props.values.usps?.length || 0;
    if (e.key === "Enter" && uspsLength > 0) {
      if (index === uspsLength - 1 && uspsLength < MAX_USPS) {
        arrayHelpers.insert(uspsLength, "");
      }
      focusOnLastUspInput();
    }
  };

  const focusOnLastUspInput = (): void => {
    if (uspsWrapperRef.current) {
      const amountOfUsps = uspsWrapperRef.current?.getElementsByTagName("input").length;

      const lastInput = uspsWrapperRef.current?.getElementsByTagName("input")?.[amountOfUsps - 1];

      if (lastInput) {
        lastInput.focus();
      }
    }
  };

  useEffect(() => {
    if ((props.values.usps?.length || 0) > 0) {
      focusOnLastUspInput();
    }
  }, [props.values.usps?.length]);

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="campaignForm.dataBlock.generalInformation" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "campaignForm.input.name.label" }),
                    tooltip: props.intl.formatMessage({ id: "campaignForm.input.name.tooltip" }),
                    errorMessage: touched.name && errors.name
                  }}
                  value={props.values.name}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="text"
                  id="name"
                  errorMessage={touched.name && errors.name}
                />
              </div>
            </div>

            <div className={styles.fields}>
              <div className={styles.formField}>
                <div className={styles.richEditor}>
                  {typeof window === "undefined" ? (
                    ""
                  ) : (
                    <RichEditor
                      onChange={onCustomChange}
                      pageField={props.values.description ? props.values.description : ""}
                      id="description"
                      height={215}
                      maxHeight={400}
                      maxLength={150}
                      label={{
                        label: props.intl.formatMessage({ id: "campaignForm.input.description.label" }),
                        tooltip: props.intl.formatMessage({ id: "campaignForm.input.description.tooltip" })
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "campaignForm.input.excerpt.label" }),
                    tooltip: props.intl.formatMessage({ id: "campaignForm.input.excerpt.tooltip" }),
                    errorMessage: touched.excerpt && errors.excerpt
                  }}
                  value={props.values.excerpt}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  id="excerpt"
                  isTextArea
                  errorMessage={touched.excerpt && errors.excerpt}
                  maxCharacters={40}
                />
              </div>
            </div>

            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "campaignForm.input.couponCode.label" }),
                    tooltip: props.intl.formatMessage({ id: "campaignForm.input.couponCode.tooltip" }),
                    errorMessage: touched.couponCode && errors.couponCode,
                    isOptionalField: true
                  }}
                  value={props.values.couponCode}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  type="text"
                  id="couponCode"
                  errorMessage={touched.couponCode && errors.couponCode}
                />
              </div>
            </div>

            <div className={styles.fields}>
              <div className={styles.formField} ref={uspsWrapperRef}>
                <FieldArray
                  name="usps"
                  render={(arrayHelpers) => {
                    const uspsTouched = arrayHelpers.form.touched.usps as unknown as boolean[];
                    const uspsErrors = arrayHelpers.form.errors.usps as unknown as string[];

                    return (
                      <>
                        <FormLabelComponent
                          label={props.intl.formatMessage({ id: "campaignForm.input.usps.label" })}
                          tooltip={props.intl.formatMessage({ id: "campaignForm.input.usps.tooltip" })}
                          errorMessage={uspsTouched && uspsErrors ? "error" : undefined}
                          isOptionalField
                          className={styles.uspsLabel}
                        />
                        {props.values.usps?.map((usp, index) => {
                          return (
                            <div className={styles.inputWithDelete} key={index}>
                              <TextFieldComponent
                                value={usp}
                                onChange={arrayHelpers.form.handleChange}
                                type="text"
                                id={`usps[${index}]`}
                                onBlur={arrayHelpers.form.handleBlur}
                                onKeyDown={(e) => handleOnOptionKeyDown(e, index, arrayHelpers)}
                                errorMessage={uspsTouched?.[index] && uspsErrors?.[index]}
                              />
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                title={props.intl.formatMessage({ id: "campaignForm.input.usps.remove" })}
                                aria-label={props.intl.formatMessage({ id: "campaignForm.input.usps.remove" })}
                              >
                                <IconComponent icon={IconDelete} size="20px" />
                              </button>
                            </div>
                          );
                        })}
                        <button
                          className={styles.addUspButton}
                          type="button"
                          onClick={() => {
                            if ((props.values.usps?.length || 0) < MAX_USPS) {
                              arrayHelpers.insert(props.values.usps?.length || 0, "");
                            }
                          }}
                          disabled={
                            (props.values.usps?.length || 0) >= MAX_USPS || props.values.usps?.some((usp) => !usp)
                          }
                        >
                          <IconComponent icon={IconAdd} size="20px" />
                          {props.intl.formatMessage({ id: "campaignForm.input.usps.addNew" })}
                        </button>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="campaignForm.dataBlock.dates" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <DatePickerComponent
                  label={{
                    label: props.intl.formatMessage({ id: "campaignForm.input.startDate.label" }),
                    tooltip: props.intl.formatMessage({ id: "campaignForm.input.startDate.tooltip" })
                  }}
                  value={props.values.startDate}
                  onChange={onDatePickerChange}
                  includeTime
                  id="startDate"
                  placeholder
                  errorMessage={touched.startDate && props.errors.startDate}
                  disableAutoComplete
                />
              </div>
              <div className={styles.formField}>
                <DatePickerComponent
                  label={{
                    label: props.intl.formatMessage({ id: "campaignForm.input.endDate.label" }),
                    tooltip: props.intl.formatMessage({ id: "campaignForm.input.endDate.tooltip" })
                  }}
                  value={props.values.endDate}
                  onChange={onDatePickerChange}
                  includeTime
                  id="endDate"
                  placeholder
                  errorMessage={touched.endDate && props.errors.endDate}
                  disableAutoComplete
                />
              </div>
            </div>
            {props.role === "admin" && (
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <DatePickerComponent
                    label={{
                      label: props.intl.formatMessage({ id: "campaignForm.input.startDatePublish.label" }),
                      tooltip: props.intl.formatMessage({ id: "campaignForm.input.startDatePublish.tooltip" })
                    }}
                    value={props.values.startDatePublish}
                    onChange={onDatePickerChange}
                    includeTime
                    id="startDatePublish"
                    placeholder
                    errorMessage={touched.startDatePublish && props.errors.startDatePublish}
                    disableAutoComplete
                  />
                </div>
                <div className={styles.formField}>
                  <DatePickerComponent
                    label={{
                      label: props.intl.formatMessage({ id: "campaignForm.input.startDateStatusShow.label" }),
                      tooltip: props.intl.formatMessage({ id: "campaignForm.input.startDateStatusShow.tooltip" })
                    }}
                    value={props.values.startDateStatusShow}
                    onChange={onDatePickerChange}
                    includeTime
                    id="startDateStatusShow"
                    placeholder
                    errorMessage={touched.startDateStatusShow && props.errors.startDateStatusShow}
                    disableAutoComplete
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="campaignForm.dataBlock.urls" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "campaignForm.input.url.label" }),
                    tooltip: props.intl.formatMessage({ id: "campaignForm.input.url.tooltip" }),
                    errorMessage: touched.url && errors.url
                  }}
                  placeholder=""
                  value={props.values.url}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  id="url"
                  errorMessage={touched.url && errors.url}
                />
              </div>
            </div>
            {props.role === "admin" && (
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    label={{
                      label: props.intl.formatMessage({ id: "campaignForm.input.trackingUrl.label" }),
                      tooltip: props.intl.formatMessage({ id: "campaignForm.input.trackingUrl.tooltip" }),
                      errorMessage: touched.trackingUrl && errors.trackingUrl,
                      isOptionalField: true
                    }}
                    value={props.values.trackingUrl}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    id="trackingUrl"
                    errorMessage={touched.trackingUrl && errors.trackingUrl}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {props.role === "admin" && (
          <div className={styles.dataBlock}>
            <div className={styles.label}>
              <ResourceTextComponent resourceKey="campaignForm.dataBlock.labelsFilters" />
            </div>
            <div className={styles.fieldsContainer}>
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <MultiSelectComponent
                    label={{
                      label: props.intl.formatMessage({ id: "campaignForm.input.featuredFilter.label" }),
                      tooltip: props.intl.formatMessage({ id: "campaignForm.input.featuredFilter.tooltip" }),
                      isOptionalField: true
                    }}
                    isMulti={false}
                    value={props.values.featuredFilter ? [props.values.featuredFilter] : undefined}
                    onChange={onMultiSelectChange}
                    type="array"
                    id="featuredFilter"
                    loadOptions={loadOptions}
                  />
                  <CheckboxComponent
                    name="isFeaturedFilterActive"
                    value={props.values.isFeaturedFilterActive}
                    onChange={props.handleChange}
                  >
                    <ResourceTextComponent resourceKey="campaignForm.dataBlock.labelsFilters.checkbox" />
                  </CheckboxComponent>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.bottomBar}>
        <div className={styles.complaints}>
          <ResourceTextComponent
            resourceKey="campaignForm.complaints"
            values={{
              emailAdress: (
                <a href={`mailto:${generalAdvertiserEmailAdress}`} target="_blank" rel="noopener noreferrer">
                  {generalAdvertiserEmailAdress}
                </a>
              )
            }}
          />
        </div>
        <div className={styles.actions}>
          <ClickableComponent
            variant="primary-inverted"
            title={props.intl.formatMessage({ id: "campaignForm.button.cancel" })}
            buttonType="button"
            height={48}
            onClick={() => props.onCancel(false)}
          />
          <ClickableComponent
            buttonType="button"
            title={props.intl.formatMessage({ id: "campaignForm.button.save" })}
            height={48}
            onClick={() => {
              props.handleSubmit();
            }}
          />
          <button
            style={{ display: "none" }}
            onClick={() => {
              props.handleSubmit();
            }}
            type="button"
            ref={props.submitRef}
            aria-label={props.intl.formatMessage({ id: "campaignForm.button.save" })}
          />
        </div>
      </div>
    </Form>
  );
};

interface IFormProps {
  campaign: OutCampaignDTO;
  disableSaveButton: boolean;
  editorState: EditorState;
  intl: IntlShape;
  merchantId: number;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  onSubmit: (values: InCampaignDTO) => void;
  pageField: string;
  role: string;
  submitRef?: React.RefObject<HTMLButtonElement>;
}

export const CampaignFormComponent = withFormik<IFormProps, ICampaignFormValues>({
  mapPropsToValues: (props: IFormProps) => ({
    couponCode: props.campaign.couponCode,
    description: props.campaign.description,
    endDate: props.campaign.endDate,
    excerpt: props.campaign.excerpt,
    subtitle: props.campaign.subtitle,
    featuredFilter: props.campaign.featuredFilter ? mapToFilterItem(props.campaign.featuredFilter) : undefined,
    isFeaturedFilterActive: props.campaign.isFeaturedFilterActive || false,
    id: props.campaign.id,
    isFeatured: props.campaign.isFeatured,
    name: props.campaign.name,
    startDate: props.campaign.startDate,
    startDatePublish: props.campaign.startDatePublish,
    startDateStatusShow: props.campaign.startDateStatusShow,
    trackingUrl: props.campaign.trackingUrl,
    url: props.campaign.url,
    usps: props.campaign.usps
  }),

  validate: (values: ICampaignFormValues, bag) => {
    const midnightTimeIsoDate = "23:00:00";
    const midnightTimeError = bag.intl.formatMessage({ id: "campaignForm.errors.midnightTimeError" });
    const errors: FormikErrors<ICampaignFormValues> = {};

    if (!values.name) {
      errors.name = bag.intl.formatMessage({ id: "campaignForm.errors.name.required" });
    }

    if (values.startDate && values.endDate) {
      const startDate = new Date(values.startDate);
      const endDate = new Date(values.endDate);
      const today = new Date();

      if (endDate < startDate) {
        errors.startDate = bag.intl.formatMessage({ id: "campaignForm.errors.startDateAfterEndDate" });
      }
      if (endDate < today) {
        errors.endDate = bag.intl.formatMessage({ id: "campaignForm.errors.endDateAfterToday" });
      }
    }

    if (values.startDate && values.startDate.includes(midnightTimeIsoDate)) {
      errors.startDate = midnightTimeError;
    }

    if (values.endDate && values.endDate.includes(midnightTimeIsoDate)) {
      errors.endDate = midnightTimeError;
    }

    if (values.usps?.length && values.usps?.length > 0) {
      const uspErrors = values.usps.map((usp, index) => {
        const uspIsEmpty = !usp;
        const uspIsTooMany = index >= MAX_USPS;
        if (uspIsTooMany) {
          return bag.intl.formatMessage({ id: "campaignForm.errors.usps.tooMany" });
        }
        if (uspIsEmpty) {
          return bag.intl.formatMessage({ id: "campaignForm.errors.usps.required" });
        }

        return "";
      });

      const filteredErrors = uspErrors.filter(Boolean);

      if (filteredErrors && filteredErrors.length > 0) {
        // uspErrors actually is a string array, but this formik version is weird with error objects
        errors.usps = uspErrors as unknown as string;
      }
    }

    if (bag.role !== "admin") {
      if (!values.excerpt || values.excerpt.length === 0) {
        errors.excerpt = bag.intl.formatMessage({ id: "campaignForm.errors.excerpt.required" });
      }
      if (!values.description || values.description.length === 0) {
        errors.description = bag.intl.formatMessage({
          id: "campaignForm.errors.description.required"
        });
      }
      if (!values.startDate || values.startDate.length === 0) {
        errors.startDate = bag.intl.formatMessage({
          id: "campaignForm.errors.startDate.required"
        });
      }
      if (!values.endDate || values.endDate.length === 0) {
        errors.endDate = bag.intl.formatMessage({
          id: "campaignForm.errors.endDate.required"
        });
      }
      if (!values.url || values.url.length === 0) {
        errors.url = bag.intl.formatMessage({
          id: "campaignForm.errors.url.required"
        });
      }
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const campaign = mapValuesToCampaign(values, bag.props.campaign, bag.props.merchantId);
    bag.props.onSubmit(campaign);
  }
})(InnerForm);

const mapValuesToCampaign = (values: ICampaignFormValues, campaign: OutCampaignDTO, merchantId: number) => {
  const newCampaign: InCampaignDTO = {
    couponCode: values.couponCode,
    description: values.description,
    endDate: values.endDate ? values.endDate : undefined,
    excerpt: values.excerpt,
    subtitle: values.subtitle,
    featuredFilter: values.featuredFilter ? { id: values.featuredFilter.value } : undefined, // When generating a new api via swagger make sure the InCampaignDTO has a nullable featuredFilter (add it manually)
    isFeaturedFilterActive: values.isFeaturedFilterActive || false,
    id: campaign.id,
    isFeatured: values.isFeatured,
    name: values.name,
    startDate: values.startDate ? values.startDate : undefined,
    startDatePublish: values.startDatePublish ? values.startDatePublish : undefined,
    startDateStatusShow: values.startDateStatusShow ? values.startDateStatusShow : undefined,
    trackingUrl: values.trackingUrl,
    url: values.url,
    merchantId,
    usps: values.usps
  };

  return newCampaign;
};
