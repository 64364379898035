import React, { useState } from "react";
import { useIntl } from "react-intl";

import { useAppDispatch } from "@app/redux/store";
import { CreateFeaturedCategoriesDTO, OutFeaturedCategoriesDTO } from "@app/api/generated";
import { featuredCategoriesThunk } from "@app/redux/thunks/featured-categories.thunk";

import { CreateCategoryFormComponent } from "./create-featured-category.form";

export interface ICreateDealModalProps {
  onCancel: () => void;
  onSubmit: () => void;
}

const CreateCategoryModal = (props: ICreateDealModalProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createFeaturedCategory = async (featuredCategory?: CreateFeaturedCategoriesDTO) => {
    if (featuredCategory) {
      setIsLoading(true);

      const newFeaturedCategory: OutFeaturedCategoriesDTO = (await dispatch(
        featuredCategoriesThunk.createNewFeaturedCategory(featuredCategory)
      )) as any;

      if (newFeaturedCategory) {
        props.onSubmit();
      }

      setIsLoading(false);
    }
  };

  return (
    <CreateCategoryFormComponent
      intl={intl}
      onSubmit={createFeaturedCategory}
      onCancel={props.onCancel}
      isLoading={isLoading}
    />
  );
};

export { CreateCategoryModal };
