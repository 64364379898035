import * as React from "react";
import ReactTooltip from "react-tooltip";

import styles from "./tooltip-provider.module.scss";

const TooltipProvider = () => {
  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return <ReactTooltip effect="solid" multiline id="BlackFriday" className={styles.tooltip} type="dark" />;
};

export { TooltipProvider };
