import * as React from "react";

import { Table as CoreTable, ITableProps as ICoreTableProps } from "@webbio/components";
import defaultStyles from "./table.module.scss";

export interface ITableProps extends ICoreTableProps {}

const Table = (props: ITableProps): JSX.Element => {
  return <CoreTable {...props} propStyles={defaultStyles} />;
};

export { Table };
