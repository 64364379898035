/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserEmailDTO
 */
export interface UserEmailDTO {
  /**
   *
   * @type {string}
   * @memberof UserEmailDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailDTO
   */
  emailConfirmation: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailDTO
   */
  password: string;
}

export function UserEmailDTOFromJSON(json: any): UserEmailDTO {
  return UserEmailDTOFromJSONTyped(json, false);
}

export function UserEmailDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEmailDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    emailConfirmation: json["emailConfirmation"],
    password: json["password"]
  };
}

export function UserEmailDTOToJSON(value?: UserEmailDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    emailConfirmation: value.emailConfirmation,
    password: value.password
  };
}
