/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OutMerchantDTO,
  OutMerchantDTOFromJSON,
  OutMerchantDTOFromJSONTyped,
  OutMerchantDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutFeaturedCategoriesDTO
 */
export interface OutFeaturedCategoriesDTO {
  /**
   *
   * @type {number}
   * @memberof OutFeaturedCategoriesDTO
   */
  id: number;
  /**
   *
   * @type {PlatformDTO}
   * @memberof OutFeaturedCategoriesDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof OutFeaturedCategoriesDTO
   */
  categoryTitle: string;
  /**
   *
   * @type {string}
   * @memberof OutFeaturedCategoriesDTO
   */
  trackingUrl: string;
  /**
   *
   * @type {string}
   * @memberof OutFeaturedCategoriesDTO
   */
  slug: string;
  /**
   *
   * @type {OutMerchantDTO}
   * @memberof OutFeaturedCategoriesDTO
   */
  merchant: OutMerchantDTO;
}

export function OutFeaturedCategoriesDTOFromJSON(json: any): OutFeaturedCategoriesDTO {
  return OutFeaturedCategoriesDTOFromJSONTyped(json, false);
}

export function OutFeaturedCategoriesDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OutFeaturedCategoriesDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    platform: PlatformDTOFromJSON(json["platform"]),
    categoryTitle: json["categoryTitle"],
    trackingUrl: json["trackingUrl"],
    slug: json["slug"],
    merchant: OutMerchantDTOFromJSON(json["merchant"])
  };
}

export function OutFeaturedCategoriesDTOToJSON(value?: OutFeaturedCategoriesDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    platform: PlatformDTOToJSON(value.platform),
    categoryTitle: value.categoryTitle,
    trackingUrl: value.trackingUrl,
    slug: value.slug,
    merchant: OutMerchantDTOToJSON(value.merchant)
  };
}
