import React, { useState, useEffect, useRef } from "react";
import { EditorState } from "draft-js";
import { useIntl } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { toast } from "react-toastify";

import { ClickableComponent, LabelComponent } from "@app/core";
import BackIcon from "@assets/icons/back.svg";
import BinIcon from "@assets/icons/bin.svg";
import CopyIcon from "@assets/icons/copy.svg";

import { Tab } from "@app/core/tab/tab.component";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BasicModal } from "@app/modules/basic-modal/basic-modal.component";
import { CampaignDTO, InDetailedMerchantDTO, MerchantsApi, OutMerchantDTO } from "@app/api/generated";
import { useAppSelector, useAppDispatch } from "@app/redux/store";
import { merchantThunks } from "@app/redux/thunks/merchants.thunk";
import { setSelectedMerchant } from "@app/redux/reducers/merchants";
import { ROUTES } from "@app/constants/routes";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { getAuthorizedApiConfig } from "@app/util/api-config";

import { MerchantFormComponent } from "./merchant.form";
import { CampaignsOverview } from "./campaigns/campaigns-overview.component";
import { CampaignFormComponent } from "./campaigns/campaign.form";
import styles from "./merchant-detail-component.module.scss";

const MerchantDetail = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const history = useNavigate();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { selectedMerchant } = useAppSelector((state) => state.merchants);
  const { role } = useAppSelector((state) => state.userAccount);

  const storeTab = "merchantDetail.tab.storeInfo";
  const campaignActionsTab = "merchantDetail.tab.actionCampaigns";

  const [isDeleteMerchantOpen, setIsDeleteMerchantOpen] = useState<boolean>(false);
  const [isDeleteCampaignOpen, setIsDeleteCampaignOpen] = useState<boolean>(false);
  const [isEditMerchantOpen, setIsEditMerchantOpen] = useState<boolean>(false);
  const [shouldPublish, setShouldPublish] = useState<boolean>(false);
  const [lastEdit, setLastEdit] = useState<string | undefined>(undefined);
  const [isMerchantEdited, setIsMerchantEdited] = useState<boolean>(false);
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<string>(role === "admin" ? storeTab : campaignActionsTab);
  const [isChangeTab, setIsChangeTab] = useState<string | undefined>(undefined);

  const [isEditingCampaign, setIsEditingCampaign] = useState<CampaignDTO>();
  const [changedCampaigns, setChangedCampaigns] = useState<CampaignDTO[] | undefined>(undefined);
  const [isDeleteCampaignId, setIsDeleteCampaignId] = useState<number | null>(null);
  const [initialEditHasPassed, setInitialEditHasPassed] = useState<boolean>(false);
  const merchantEditStateForEventListeners = useRef(isMerchantEdited);
  const merchantSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const campaignSubmitButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!!id) {
      dispatch(merchantThunks.getMerchant(Number(id)));
    }

    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", checkReload);

    return () => {
      window.removeEventListener("beforeunload", checkReload);
      dispatch(setSelectedMerchant(undefined));
    };
  }, []);

  useEffect(() => {
    merchantEditStateForEventListeners.current = isMerchantEdited;
  }, [isMerchantEdited]);

  const checkReload = (e: BeforeUnloadEvent) => {
    if (merchantEditStateForEventListeners.current) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  useEffect(() => {
    if (selectedMerchant) {
      setLastEdit(selectedMerchant.lastEdit);

      window.scrollTo(0, 0);
    }
  }, [selectedMerchant]);

  const cleanEditState = () => {
    setIsMerchantEdited(false);
    setInitialEditHasPassed(false);
    setIsEditingCampaign(undefined);
    setChangedCampaigns(undefined);
    setIsDeleteCampaignId(null);
  };

  const onTabChange = (currentSelectedtab: string) => {
    if (currentSelectedtab === storeTab || currentSelectedtab === campaignActionsTab) {
      setIsChangeTab(currentSelectedtab);
      if (isMerchantEdited) {
        setIsEditMerchantOpen(true);
      } else {
        setSelectedTab(currentSelectedtab);
        setIsChangeTab(undefined);
        cleanEditState();
      }
    }
  };

  const onCampaignsChangeActive = async (campaigns: CampaignDTO[], publish?: boolean) => {
    if (selectedMerchant) {
      const merchantToSave = { ...selectedMerchant, campaigns: campaigns as CampaignDTO[] };

      await dispatch(
        merchantThunks.saveMerchant(
          mapToInMerchant(merchantToSave),
          publish,
          intl.formatMessage({ id: "merchantDetail.message.campaignSaved" })
        )
      );
    }

    setIsEditingCampaign(undefined);
    setShouldPublish(false);
    setIsMerchantEdited(false);
    setChangedCampaigns(undefined);

    window.scrollTo(0, 0);
  };

  const onCampaignSave = async (campaign: CampaignDTO) => {
    if (selectedMerchant) {
      const filteredCampaigns = selectedMerchant.campaigns?.filter((c) => c.id !== campaign.id) || [];

      const merchantToSave = { ...selectedMerchant, campaigns: [campaign, ...filteredCampaigns] as CampaignDTO[] };

      await dispatch(
        merchantThunks.saveMerchant(
          mapToInMerchant(merchantToSave),
          shouldPublish,
          intl.formatMessage({ id: "merchantDetail.message.campaignSaved" })
        )
      );
    }

    setIsEditingCampaign(undefined);
    setShouldPublish(false);
    setIsMerchantEdited(false);
    setChangedCampaigns(undefined);

    window.scrollTo(0, 0);
  };

  const onDeleteCampaign = async (campaignId: number | null) => {
    if (selectedMerchant) {
      const merchantToSave = { ...selectedMerchant };

      if (merchantToSave.campaigns && merchantToSave.campaigns.length > 0) {
        merchantToSave.campaigns = merchantToSave.campaigns.filter((campaign) => campaign.id !== campaignId);
      }

      await dispatch(
        merchantThunks.saveMerchant(
          mapToInMerchant(merchantToSave),
          true,
          intl.formatMessage({ id: "merchantDetail.message.campaignDeleted" })
        )
      );

      setIsDeleteCampaignOpen(false);
    }
  };

  const onCampaignEditSave = (campaign: CampaignDTO) => {
    if (selectedMerchant) {
      onCampaignSave(campaign);
    }
  };

  const onChangeActiveCampaigns = (campaigns?: CampaignDTO[]) => {
    setChangedCampaigns(campaigns);
    setIsMerchantEdited(true);
  };

  const onGoToEditCampaign = (campaign?: CampaignDTO) => {
    if (campaign) {
      setIsEditingCampaign({ ...campaign });
    }
  };

  const onMerchantSave = async (values: InDetailedMerchantDTO, publish?: boolean) => {
    cleanEditState();

    await dispatch(merchantThunks.saveMerchant(values, publish || shouldPublish));

    setShouldPublish(false);
  };

  const onMerchantCancel = (isSavedCheck: boolean) => {
    if (isMerchantEdited && isSavedCheck) {
      setIsEditMerchantOpen(true);
    } else if (!isChangeTab && !isEditingCampaign) {
      history(ROUTES.STORE_OVERVIEW);
      cleanEditState();
    } else {
      setIsEditMerchantOpen(false);
      if (isChangeTab) {
        setSelectedTab(isChangeTab);
      }
      setIsChangeTab(undefined);
      cleanEditState();
    }
  };

  const onEdit = () => {
    if (!isMerchantEdited && initialEditHasPassed) {
      setIsMerchantEdited(true);
    }

    if (!initialEditHasPassed) {
      setInitialEditHasPassed(true);
    }
  };

  const publishMerchantPlain = () => {
    if (selectedMerchant) {
      onMerchantSave(mapToInMerchant(selectedMerchant), true);
    }
    cleanEditState();
  };

  const onMerchantPublish = (
    typeofPublish: "merchantEdit" | "campaignChange" | "campaignEdit" | "publish" = "publish"
  ) => {
    setShouldPublish(true);
    switch (typeofPublish) {
      case "publish":
        publishMerchantPlain();
        break;
      case "merchantEdit":
        merchantSubmitButtonRef?.current?.click();
        break;
      case "campaignEdit":
        campaignSubmitButtonRef?.current?.click();
        break;
      case "campaignChange":
        if (changedCampaigns) {
          onCampaignsChangeActive(changedCampaigns, true);
        }
        break;
      default:
        publishMerchantPlain();
    }
  };

  const publishButtonEnabled = lastEdit === "merchant" || isMerchantEdited;

  const onDeleteMerchant = () => {
    if (selectedMerchant) {
      dispatch(merchantThunks.deleteMerchant(Number(id)));
      history(ROUTES.STORE_OVERVIEW);
    }
    setIsDeleteMerchantOpen(false);
  };

  const setDeleteCampaignId = (campaignId?: number | null) => {
    if (selectedMerchant && campaignId != null) {
      setIsDeleteCampaignId(campaignId);
      setIsDeleteCampaignOpen(true);
    }
  };

  const getLastEditStatus = (value: string | undefined) => {
    switch (value) {
      case "merchant":
        return intl.formatMessage({ id: "merchantDetail.editStatus.lastEditedByShopOwner" });
      case "approved":
        return intl.formatMessage({ id: "merchantDetail.editStatus.published" });
      case undefined:
        return "";
      default:
        return "";
    }
  };

  const getTabs = () => {
    if (role === "admin") {
      return [storeTab, campaignActionsTab];
    }

    return [campaignActionsTab];
  };

  const duplicateMerchant = async () => {
    try {
      if (selectedMerchant) {
        setIsDuplicating(true);
        const api = new MerchantsApi(await getAuthorizedApiConfig());

        const merchantsWithTitle = await api.merchantsExtendedGet(
          100,
          0,
          `${selectedMerchant.name} Copy`,
          undefined,
          undefined,
          undefined,
          selectedMerchant.platform.id
        );
        const copyNumber = merchantsWithTitle.total + 1;

        const result = await api.merchantsPost({
          name: `${selectedMerchant.name} Copy ${copyNumber}`,
          logoImage: selectedMerchant.logoImage
            ? {
                title: selectedMerchant.logoImage?.title,
                url: selectedMerchant.logoImage?.url ?? "",
                alt: selectedMerchant.logoImage?.alt
              }
            : undefined,
          eCPC: selectedMerchant.eCPC,
          inOverview: selectedMerchant.inOverview,
          platform: selectedMerchant.platform,
          buyInPosition: selectedMerchant.buyInPosition,
          approvalRate: selectedMerchant.approvalRate
        });

        // The merchantsPost call for some reason always sets the filters to undefined,
        // so we need to update the created merchant with the filters
        const updatedMerchantWithFilters = result
          ? await api.merchantsMerchantIdPut(result.id, {
              id: result.id,
              name: result.name,
              platform: result.platform,
              filters: selectedMerchant.filters
            })
          : undefined;

        if (!updatedMerchantWithFilters) {
          throw new Error("Error creating merchant");
        }

        toast.success(intl.formatMessage({ id: "merchantDetail.duplicate.notify.success" }));
        dispatch(setSelectedMerchant());
        history(`${ROUTES.MERCHANT_DETAIL}/${result.id}`);
        dispatch(merchantThunks.getMerchant(result.id));

        setIsDuplicating(false);
      }
    } catch {
      toast.error(intl.formatMessage({ id: "merchantDetail.duplicate.notify.error" }));
    }
  };

  return (
    <div className={styles.merchantDetail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <div className={styles.topBarLeft}>
            <ClickableComponent
              variant="link-primary"
              title={selectedMerchant?.name || ""}
              icon={BackIcon}
              iconPosition="left"
              iconSize="24px"
              onClick={() => onMerchantCancel(true)}
            />

            {selectedMerchant && <LabelComponent type={selectedMerchant?.live ? "live" : "offline"} />}
            <span className={styles.editStatus}>{lastEdit && getLastEditStatus(lastEdit)}</span>
          </div>
          {role === "admin" && (
            <div className={styles.topBarRight}>
              <ClickableComponent
                variant="primary-inverted"
                iconStyle="filled"
                title={intl.formatMessage({ id: "merchantDetail.button.duplicate" })}
                buttonType="button"
                icon={CopyIcon}
                iconPosition="left"
                iconSize="14px"
                height={40}
                onClick={duplicateMerchant}
                disabled={isDuplicating}
              />
              <ClickableComponent
                variant="tertiary"
                title={intl.formatMessage({ id: "merchantDetail.button.deleteStore" })}
                buttonType="button"
                iconStyle="outline"
                icon={BinIcon}
                iconPosition="left"
                iconSize="20px"
                height={40}
                onClick={() => setIsDeleteMerchantOpen(true)}
              />
              <span
                data-tip={
                  publishButtonEnabled ? intl.formatMessage({ id: "merchantDetail.tooltip.editFormToPublish" }) : ""
                }
                data-place="bottom"
                className={styles.tooltip}
              >
                <ClickableComponent
                  variant="primary-default"
                  title={
                    isMerchantEdited
                      ? intl.formatMessage({ id: "merchantDetail.button.saveAndPublish" })
                      : intl.formatMessage({ id: "merchantDetail.button.publish" })
                  }
                  buttonType="button"
                  disabled={!publishButtonEnabled}
                  height={40}
                  onClick={() => {
                    onMerchantPublish(
                      changedCampaigns
                        ? "campaignChange"
                        : isEditingCampaign
                        ? "campaignEdit"
                        : isMerchantEdited
                        ? "merchantEdit"
                        : "publish"
                    );
                  }}
                />
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={styles.container}>
        <>
          <Tab tabs={getTabs()} selectedTab={selectedTab} onSelect={onTabChange} />
          <div className={styles.details}>
            {!selectedMerchant ? (
              <SpinningLoader />
            ) : (
              <>
                {selectedTab === storeTab && (
                  <div className={styles.data}>
                    <MerchantFormComponent
                      intl={intl}
                      onSubmit={onMerchantSave}
                      onCancel={onMerchantCancel}
                      onEdit={onEdit}
                      disableSaveButton={!isMerchantEdited}
                      merchant={selectedMerchant}
                      role={role}
                      submitRef={merchantSubmitButtonRef}
                    />
                  </div>
                )}
                {selectedTab === campaignActionsTab && !isEditingCampaign && (
                  <CampaignsOverview
                    disableSaveButton={isMerchantEdited}
                    onCancel={onMerchantCancel}
                    onSave={onCampaignsChangeActive}
                    onChangeActiveCampaigns={onChangeActiveCampaigns}
                    onClickEditCampaign={onGoToEditCampaign}
                    onClickDeleteCampaign={setDeleteCampaignId}
                    campaigns={selectedMerchant.campaigns}
                    role={role}
                  />
                )}
                {selectedTab === campaignActionsTab && isEditingCampaign && (
                  <CampaignFormComponent
                    intl={intl}
                    onSubmit={onCampaignEditSave}
                    onCancel={onMerchantCancel}
                    onEdit={onEdit}
                    disableSaveButton={isMerchantEdited}
                    campaign={isEditingCampaign}
                    pageField=""
                    editorState={EditorState.createEmpty()}
                    role={role}
                    merchantId={selectedMerchant?.id}
                    submitRef={campaignSubmitButtonRef}
                  />
                )}
              </>
            )}
          </div>
        </>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "merchantDetail.modal.deleteMerchant.title" })}
        isModalOpen={isDeleteMerchantOpen}
        onCloseModal={() => {
          setIsDeleteMerchantOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteMerchantOpen(false);
          }}
          onDelete={onDeleteMerchant}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage(
          { id: "merchantDetail.modal.deleteCampagin.title" },
          { name: `"${selectedMerchant?.campaigns?.find((item) => item.id === isDeleteCampaignId)?.name}"` }
        )}
        isModalOpen={isDeleteCampaignOpen}
        onCloseModal={() => {
          setIsDeleteCampaignOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteCampaignOpen(false);
          }}
          onDelete={() => onDeleteCampaign(isDeleteCampaignId)}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "merchantDetail.modal.leavePage.title" })}
        isModalOpen={isEditMerchantOpen}
        onCloseModal={() => {
          setIsEditMerchantOpen(false);
        }}
        variant="big"
      >
        <BasicModal
          onApprove={() => onMerchantCancel(false)}
          onCancel={() => setIsEditMerchantOpen(false)}
          onApproveTitle={intl.formatMessage({ id: "merchantDetail.modal.leavePage.approve" })}
          onCancelTitel={intl.formatMessage({ id: "merchantDetail.modal.leavePage.cancel" })}
          description={intl.formatMessage({ id: "merchantDetail.modal.leavePage.description" })}
        />
      </ModalComponent>
    </div>
  );
};

const mapToInMerchant = (outMerchant: OutMerchantDTO): InDetailedMerchantDTO => {
  const result: InDetailedMerchantDTO = {
    ...outMerchant,
    campaigns: outMerchant.campaigns?.map((campaign) => ({ ...campaign, merchantId: outMerchant.id }))
  };

  return result;
};

export { MerchantDetail };
