import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { ClickableComponent, IconComponent } from "@app/core";
import Bin from "@assets/icons/bin.svg";
import Edit from "@assets/icons/edit.svg";
import Plus from "@assets/icons/plus.svg";
import { CampaignDTO, CampaignStatusDTO } from "@app/api/generated";
import { useIntl } from "react-intl";
import { CheckboxComponent } from "@app/core/checkbox";
import { Tooltip } from "@app/core/tooltip/tooltip.component";
import { Table } from "@app/components/table/table";
import { Column, ISortDirection } from "@webbio/components";
import { useDateFnsFormat } from "@app/util/use-date-fns-format";

import tableStyles from "./table-styling-component.module.scss";
import styles from "./campaigns-overview-component.module.scss";

export interface ICampaignsOverviewProps {
  campaigns?: CampaignDTO[];
  disableSaveButton: boolean;
  onCancel: (isSavedCheck: boolean) => void;
  onChangeActiveCampaigns: (campaign?: CampaignDTO[]) => void;
  onClickDeleteCampaign: (campaignId?: number) => void;
  onClickEditCampaign: (campaign?: CampaignDTO) => void;
  onSave: (campaign: CampaignDTO[]) => void;
  role: string;
}

const CampaignsOverview = (props: ICampaignsOverviewProps) => {
  const intl = useIntl();
  const { formatDate } = useDateFnsFormat();

  const [allCampaigns, setAllCampaigns] = useState<CampaignDTO[]>(props.campaigns || []);
  const [checkboxEvent, setCheckboxEvent] = useState<boolean>(false);

  useEffect(() => {
    setAllCampaigns(props.campaigns || []);
  }, [props.campaigns]);

  const onSelect = (event: React.FormEvent<HTMLInputElement>) => {
    const selectedCampaignId = Number(event.currentTarget.value);
    changeCampaignIsFeatured(selectedCampaignId);
  };

  const changeCampaignIsFeatured = (id: number) => {
    const newCampaigns = allCampaigns?.map((campaign) =>
      campaign.id === id ? { ...campaign, isFeatured: !campaign.isFeatured } : campaign
    );
    setAllCampaigns(newCampaigns);
    props.onChangeActiveCampaigns(newCampaigns);
  };

  const handleSort = (columnId: string, sortDirection: ISortDirection) => {
    const campaignsToSort: CampaignDTO[] = [...allCampaigns];

    if (columnId === "startDate" && sortDirection === "ASC") {
      const sorted = campaignsToSort?.sort(
        (a, b) => new Date(a.startDate as string).getTime() - new Date(b.startDate as string).getTime()
      );
      setAllCampaigns(sorted);
    }
    if (columnId === "startDate" && sortDirection === "DESC") {
      const sorted = campaignsToSort?.sort(
        (a, b) => new Date(b.startDate as string).getTime() - new Date(a.startDate as string).getTime()
      );
      setAllCampaigns(sorted);
    }
    if (columnId === "endDate" && sortDirection === "ASC") {
      const sorted = campaignsToSort?.sort(
        (a, b) => new Date(a.endDate as string).getTime() - new Date(b.endDate as string).getTime()
      );
      setAllCampaigns(sorted);
    }
    if (columnId === "endDate" && sortDirection === "DESC") {
      const sorted = campaignsToSort?.sort(
        (a, b) => new Date(b.endDate as string).getTime() - new Date(a.endDate as string).getTime()
      );
      setAllCampaigns(sorted);
    }
  };

  const onRowClick = (item: any) => {
    if (!checkboxEvent) {
      props.onClickEditCampaign(item);
    }
  };

  return (
    <div className={styles.campaignsOverview}>
      <div className={styles.campaigns}>
        <Table onChange={onRowClick} onSort={handleSort} dataSource={allCampaigns || []}>
          <Column
            field="id"
            maxWidth={80}
            width={80}
            title={intl.formatMessage({ id: "campaignsOverview.tableHeader.active" })}
            isNotSortable
            cell={(data, idx) => {
              const campaign = allCampaigns?.[idx as number];
              const now = new Date().toISOString();
              const allowSetCampaignActive = !campaign?.endDate || campaign?.endDate >= now;

              return (
                <div className={styles.active}>
                  {!allowSetCampaignActive && (
                    <div className={styles.tooltip}>
                      <Tooltip tooltip={intl.formatMessage({ id: "campaignsOverview.unClickableCampaigns" })} />
                    </div>
                  )}

                  <div
                    onMouseEnter={() => setCheckboxEvent(true)}
                    onMouseLeave={() => setCheckboxEvent(false)}
                    className={styles.checkbox}
                  >
                    <CheckboxComponent
                      disabled={!allowSetCampaignActive}
                      name={`${campaign?.id}`}
                      id={`${campaign?.id}`}
                      // eslint-disable-next-line jsx-a11y/aria-proptypes
                      aria-checked={campaign?.isFeatured}
                      onChange={onSelect}
                      value={campaign?.isFeatured || false}
                    />
                  </div>
                </div>
              );
            }}
          />
          <Column isNotSortable field="name" title={intl.formatMessage({ id: "campaignsOverview.tableHeader.name" })} />
          <Column
            field="startDate"
            title={intl.formatMessage({ id: "campaignsOverview.tableHeader.startDate" })}
            sortField="startDate"
            cell={(startDate) => {
              const campaignStartDate = startDate ? formatDate(startDate) : "-";

              return <>{campaignStartDate}</>;
            }}
          />
          <Column
            minWidth={200}
            field="endDate"
            title={intl.formatMessage({ id: "campaignsOverview.tableHeader.endDate" })}
            sortField="endDate"
            cell={(endDate) => {
              const campaignEndDate = endDate ? formatDate(endDate) : "-";

              return <>{campaignEndDate}</>;
            }}
          />
          <Column
            isNotSortable
            minWidth={200}
            field="editedBy"
            title={intl.formatMessage({ id: "campaignsOverview.tableHeader.editedBy" })}
            cell={(editedBy: CampaignStatusDTO) => {
              const editedByText = editedBy ? `${editedBy?.firstName} ${editedBy?.lastName} (${editedBy?.email})` : "-";

              return <>{editedByText}</>;
            }}
          />
          <Column
            title=""
            field="id"
            isNotSortable
            cell={(_, idx) => {
              const campaign = allCampaigns?.[idx as number];

              return (
                <div className={clsx(tableStyles.push, styles.push)}>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onClickEditCampaign(campaign);
                    }}
                    role="button"
                    className={clsx(styles.smallButton, styles.editButton)}
                  >
                    <IconComponent icon={Edit} strokeColor="#1c1c1c" />
                  </span>
                  {props.role === "admin" && (
                    <span
                      role="button"
                      className={clsx(styles.smallButton, styles.removeButton)}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (typeof campaign?.id === "number") {
                          props.onClickDeleteCampaign(campaign.id);
                        }
                      }}
                    >
                      <IconComponent icon={Bin} strokeColor="#1c1c1c" />
                    </span>
                  )}
                </div>
              );
            }}
          />
        </Table>
        <span className={styles.addCampaignButton}>
          <ClickableComponent
            title={intl.formatMessage({ id: "campaignsOverview.button.addCampaign" })}
            buttonType="button"
            icon={Plus}
            iconPosition="left"
            iconSize="20px"
            height={40}
            onClick={() =>
              props.onClickEditCampaign({
                id: 0,
                name: "",
                isFeatured: true
              })
            }
          />
        </span>
      </div>

      <div className={styles.bottomBar}>
        <div className={styles.actions}>
          <ClickableComponent
            variant="primary-inverted"
            title={intl.formatMessage({ id: "campaignsOverview.button.cancel" })}
            buttonType="button"
            height={48}
            onClick={() => props.onCancel(false)}
          />
          {allCampaigns && (
            <ClickableComponent
              buttonType="button"
              title={intl.formatMessage({ id: "campaignsOverview.button.save" })}
              disabled={!props.disableSaveButton}
              height={48}
              onClick={() => props.onSave(allCampaigns)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { CampaignsOverview };
