import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { AuthorizationContainer } from "@app/modules";
import ChevronLeft from "@assets/icons/chevron-left.svg";
import { ClickableComponent, ResourceTextComponent } from "@app/core";
import { requestNewPassword } from "@app/authorization/authorization-api";

import { useAppSelector } from "@app/redux/store";
import styles from "./request-password-component.module.scss";
import { RequestPasswordFormComponent, IRequestPasswordFormValues } from "./request-password.form";

const RequestPassword = () => {
  const history = useNavigate();
  const intl = useIntl();
  const { emailAddress } = useAppSelector((state) => state.authorization);

  const [mailSent, setMailSent] = useState<boolean>(false);

  const backToLogin = () => {
    history("/login");
  };

  return (
    <AuthorizationContainer>
      {mailSent ? (
        <>
          <div className={styles.requestPasswordHeader}>
            <h1>
              <ResourceTextComponent resourceKey="requestPassword.mailSent.header" />
            </h1>
          </div>
          <div className={styles.requestPasswordDescription}>
            <ResourceTextComponent resourceKey="requestPassword.mailSent.description" />
          </div>
        </>
      ) : (
        <>
          <div className={styles.requestPasswordHeader}>
            <h1>
              <ResourceTextComponent resourceKey="requestPassword.requestPassword.header" />
            </h1>
          </div>
          <div className={styles.requestPasswordDescription}>
            <ResourceTextComponent resourceKey="requestPassword.requestPassword.description" />
          </div>
          <RequestPasswordFormComponent
            intl={intl}
            onSubmit={(values: IRequestPasswordFormValues) => {
              requestNewPassword(values.emailAddress);
              setMailSent(true);
            }}
            emailAddress={emailAddress}
          />
        </>
      )}
      <div className={styles.backToLogin}>
        <ClickableComponent
          fullWidth
          onClick={backToLogin}
          icon={ChevronLeft}
          iconPosition="left"
          variant="primary-inverted"
          title={intl.formatMessage({ id: "requestPassword.button.back" })}
          height={40}
        />
      </div>
    </AuthorizationContainer>
  );
};

export { RequestPassword };
