/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CreateFeaturedCategoriesDTO,
  CreateFeaturedCategoriesDTOFromJSON,
  CreateFeaturedCategoriesDTOToJSON,
  FeaturedCategoriesListResponseDTO,
  FeaturedCategoriesListResponseDTOFromJSON,
  FeaturedCategoriesListResponseDTOToJSON,
  FeaturedCategoriesMvoDTO,
  FeaturedCategoriesMvoDTOFromJSON,
  FeaturedCategoriesMvoDTOToJSON,
  InFeaturedCategoriesDTO,
  InFeaturedCategoriesDTOFromJSON,
  InFeaturedCategoriesDTOToJSON,
  OutFeaturedCategoriesDTO,
  OutFeaturedCategoriesDTOFromJSON,
  OutFeaturedCategoriesDTOToJSON
} from "../models";

export interface FeaturedCategoriesFeaturedCategoryIdDeleteRequest {
  featuredCategoryId: number;
}

export interface FeaturedCategoriesFeaturedCategoryIdGetRequest {
  featuredCategoryId: number;
}

export interface FeaturedCategoriesFeaturedCategoryIdPatchRequest {
  featuredCategoryId: number;
  featuredCategoriesMvoDTO: FeaturedCategoriesMvoDTO;
}

export interface FeaturedCategoriesFeaturedCategoryIdPutRequest {
  featuredCategoryId: number;
  inFeaturedCategoriesDTO: InFeaturedCategoriesDTO;
}

export interface FeaturedCategoriesGetRequest {
  take: number;
  skip: number;
  filterQuery?: string;
}

export interface FeaturedCategoriesPostRequest {
  createFeaturedCategoriesDTO: CreateFeaturedCategoriesDTO;
}

export interface FeaturedCategoriesSlugGetRequest {
  slug: string;
  platformId: number;
}

/**
 * no description
 */
export class FeaturedCategoriesApi extends runtime.BaseAPI {
  /**
   */
  async featuredCategoriesFeaturedCategoryIdDeleteRaw(
    requestParameters: FeaturedCategoriesFeaturedCategoryIdDeleteRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.featuredCategoryId === null || requestParameters.featuredCategoryId === undefined) {
      throw new runtime.RequiredError(
        "featuredCategoryId",
        "Required parameter requestParameters.featuredCategoryId was null or undefined when calling featuredCategoriesFeaturedCategoryIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/featuredCategories/{featuredCategoryId}`.replace(
        `{${"featuredCategoryId"}}`,
        encodeURIComponent(String(requestParameters.featuredCategoryId))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async featuredCategoriesFeaturedCategoryIdDelete(featuredCategoryId: number): Promise<boolean> {
    const response = await this.featuredCategoriesFeaturedCategoryIdDeleteRaw({
      featuredCategoryId: featuredCategoryId
    });
    return await response.value();
  }

  /**
   */
  async featuredCategoriesFeaturedCategoryIdGetRaw(
    requestParameters: FeaturedCategoriesFeaturedCategoryIdGetRequest
  ): Promise<runtime.ApiResponse<OutFeaturedCategoriesDTO>> {
    if (requestParameters.featuredCategoryId === null || requestParameters.featuredCategoryId === undefined) {
      throw new runtime.RequiredError(
        "featuredCategoryId",
        "Required parameter requestParameters.featuredCategoryId was null or undefined when calling featuredCategoriesFeaturedCategoryIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/featuredCategories/{featuredCategoryId}`.replace(
        `{${"featuredCategoryId"}}`,
        encodeURIComponent(String(requestParameters.featuredCategoryId))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutFeaturedCategoriesDTOFromJSON(jsonValue));
  }

  /**
   */
  async featuredCategoriesFeaturedCategoryIdGet(featuredCategoryId: number): Promise<OutFeaturedCategoriesDTO> {
    const response = await this.featuredCategoriesFeaturedCategoryIdGetRaw({ featuredCategoryId: featuredCategoryId });
    return await response.value();
  }

  /**
   */
  async featuredCategoriesFeaturedCategoryIdPatchRaw(
    requestParameters: FeaturedCategoriesFeaturedCategoryIdPatchRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.featuredCategoryId === null || requestParameters.featuredCategoryId === undefined) {
      throw new runtime.RequiredError(
        "featuredCategoryId",
        "Required parameter requestParameters.featuredCategoryId was null or undefined when calling featuredCategoriesFeaturedCategoryIdPatch."
      );
    }

    if (
      requestParameters.featuredCategoriesMvoDTO === null ||
      requestParameters.featuredCategoriesMvoDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "featuredCategoriesMvoDTO",
        "Required parameter requestParameters.featuredCategoriesMvoDTO was null or undefined when calling featuredCategoriesFeaturedCategoryIdPatch."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/featuredCategories/{featuredCategoryId}`.replace(
        `{${"featuredCategoryId"}}`,
        encodeURIComponent(String(requestParameters.featuredCategoryId))
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FeaturedCategoriesMvoDTOToJSON(requestParameters.featuredCategoriesMvoDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async featuredCategoriesFeaturedCategoryIdPatch(
    featuredCategoryId: number,
    featuredCategoriesMvoDTO: FeaturedCategoriesMvoDTO
  ): Promise<void> {
    await this.featuredCategoriesFeaturedCategoryIdPatchRaw({
      featuredCategoryId: featuredCategoryId,
      featuredCategoriesMvoDTO: featuredCategoriesMvoDTO
    });
  }

  /**
   */
  async featuredCategoriesFeaturedCategoryIdPutRaw(
    requestParameters: FeaturedCategoriesFeaturedCategoryIdPutRequest
  ): Promise<runtime.ApiResponse<OutFeaturedCategoriesDTO>> {
    if (requestParameters.featuredCategoryId === null || requestParameters.featuredCategoryId === undefined) {
      throw new runtime.RequiredError(
        "featuredCategoryId",
        "Required parameter requestParameters.featuredCategoryId was null or undefined when calling featuredCategoriesFeaturedCategoryIdPut."
      );
    }

    if (requestParameters.inFeaturedCategoriesDTO === null || requestParameters.inFeaturedCategoriesDTO === undefined) {
      throw new runtime.RequiredError(
        "inFeaturedCategoriesDTO",
        "Required parameter requestParameters.inFeaturedCategoriesDTO was null or undefined when calling featuredCategoriesFeaturedCategoryIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/featuredCategories/{featuredCategoryId}`.replace(
        `{${"featuredCategoryId"}}`,
        encodeURIComponent(String(requestParameters.featuredCategoryId))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: InFeaturedCategoriesDTOToJSON(requestParameters.inFeaturedCategoriesDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutFeaturedCategoriesDTOFromJSON(jsonValue));
  }

  /**
   */
  async featuredCategoriesFeaturedCategoryIdPut(
    featuredCategoryId: number,
    inFeaturedCategoriesDTO: InFeaturedCategoriesDTO
  ): Promise<OutFeaturedCategoriesDTO> {
    const response = await this.featuredCategoriesFeaturedCategoryIdPutRaw({
      featuredCategoryId: featuredCategoryId,
      inFeaturedCategoriesDTO: inFeaturedCategoriesDTO
    });
    return await response.value();
  }

  /**
   */
  async featuredCategoriesGetRaw(
    requestParameters: FeaturedCategoriesGetRequest
  ): Promise<runtime.ApiResponse<FeaturedCategoriesListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling featuredCategoriesGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling featuredCategoriesGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.filterQuery !== undefined) {
      queryParameters["filterQuery"] = requestParameters.filterQuery;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/featuredCategories`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FeaturedCategoriesListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async featuredCategoriesGet(
    take: number,
    skip: number,
    filterQuery?: string
  ): Promise<FeaturedCategoriesListResponseDTO> {
    const response = await this.featuredCategoriesGetRaw({ take: take, skip: skip, filterQuery: filterQuery });
    return await response.value();
  }

  /**
   */
  async featuredCategoriesPostRaw(
    requestParameters: FeaturedCategoriesPostRequest
  ): Promise<runtime.ApiResponse<OutFeaturedCategoriesDTO>> {
    if (
      requestParameters.createFeaturedCategoriesDTO === null ||
      requestParameters.createFeaturedCategoriesDTO === undefined
    ) {
      throw new runtime.RequiredError(
        "createFeaturedCategoriesDTO",
        "Required parameter requestParameters.createFeaturedCategoriesDTO was null or undefined when calling featuredCategoriesPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/featuredCategories`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateFeaturedCategoriesDTOToJSON(requestParameters.createFeaturedCategoriesDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutFeaturedCategoriesDTOFromJSON(jsonValue));
  }

  /**
   */
  async featuredCategoriesPost(
    createFeaturedCategoriesDTO: CreateFeaturedCategoriesDTO
  ): Promise<OutFeaturedCategoriesDTO> {
    const response = await this.featuredCategoriesPostRaw({ createFeaturedCategoriesDTO: createFeaturedCategoriesDTO });
    return await response.value();
  }

  /**
   */
  async featuredCategoriesSlugGetRaw(
    requestParameters: FeaturedCategoriesSlugGetRequest
  ): Promise<runtime.ApiResponse<OutFeaturedCategoriesDTO>> {
    if (requestParameters.slug === null || requestParameters.slug === undefined) {
      throw new runtime.RequiredError(
        "slug",
        "Required parameter requestParameters.slug was null or undefined when calling featuredCategoriesSlugGet."
      );
    }

    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling featuredCategoriesSlugGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.slug !== undefined) {
      queryParameters["slug"] = requestParameters.slug;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/featuredCategories/slug`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutFeaturedCategoriesDTOFromJSON(jsonValue));
  }

  /**
   */
  async featuredCategoriesSlugGet(slug: string, platformId: number): Promise<OutFeaturedCategoriesDTO> {
    const response = await this.featuredCategoriesSlugGetRaw({ slug: slug, platformId: platformId });
    return await response.value();
  }
}
