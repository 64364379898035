import React, { useState } from "react";
import { useIntl } from "react-intl";

import BinIcon from "@assets/icons/bin.svg";
import { ClickableComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { statusDealOptions, IDealEditOptions } from "@app/constants/deal";
// import { categoriesDealOptions, brandsDealOptions } from "@app/constants/deal";
import styles from "./deal-overview-options-bar-component.module.scss";

export interface IDealOverviewOptionsBarProps {
  isSelected: number[];
  onEdit: (changes: IDealEditOptions) => void;
  onRemove: () => void;
}

const DealOverviewOptionsBar = (props: IDealOverviewOptionsBarProps) => {
  const [selectedStatus, setSelectedStatus] = useState<number | null>(0);
  const intl = useIntl();
  // const [selectedCategories, setSelectedCategories] = useState<number | null>(0);
  // const [selectedBrands, setSelectedBrands] = useState<number | null>(0);

  const onStatusChange = (e: React.ChangeEvent<any>) => {
    if (Number(e.target.value) === 0) {
      setSelectedStatus(null);
    }
    setSelectedStatus(Number(e.target.value));
  };

  // const onCategoriesChange = (e: React.ChangeEvent<any>) => {
  //   if (Number(e.target.value) === 0) {
  //     setSelectedCategories(null);
  //   }
  //   setSelectedCategories(Number(e.target.value));
  // };

  // const onBrandsChange = (e: React.ChangeEvent<any>) => {
  //   if (Number(e.target.value) === 0) {
  //     setSelectedBrands(null);
  //   }
  //   setSelectedBrands(Number(e.target.value));
  // };

  return (
    <div className={styles.container}>
      <div className={styles.optionsContainer}>
        <div className={styles.editSection}>
          <div className={styles.selectOptions}>
            <SelectComponent height={40} onChange={onStatusChange} options={statusDealOptions} value={selectedStatus} />
          </div>
          {/* <div className={styles.selectOptions}>
            <SelectComponent
              height={40}
              onChange={onCategoriesChange}
              options={categoriesDealOptions}
              value={selectedCategories}
            />
          </div>
          <div className={styles.selectOptions}>
            <SelectComponent height={40} onChange={onBrandsChange} options={brandsDealOptions} value={selectedBrands} />
          </div> */}
          <ClickableComponent
            buttonType="submit"
            title={intl.formatMessage({ id: "dealOverviewOptionsBarComponent.button.editDeals" })}
            height={40}
            onClick={() => props.onEdit({ status: selectedStatus })}
            disabled={props.isSelected.length === 0}
          />
        </div>
        <ClickableComponent
          buttonType="submit"
          title={intl.formatMessage({ id: "dealOverviewOptionsBarComponent.button.deleteDeals" })}
          height={40}
          onClick={props.onRemove}
          icon={BinIcon}
          iconSize="18px"
          iconPosition="left"
          iconStyle="outline"
          variant="tertiary"
          disabled={props.isSelected.length === 0}
        />
      </div>
    </div>
  );
};

export { DealOverviewOptionsBar };
