import { PlatformDTO } from "@app/api/generated";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPlatformState {
  isLoading?: boolean;
  platform?: PlatformDTO;
}

const initialState: IPlatformState = {
  platform: undefined,
  isLoading: false
};

export const slice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    setPlatformInfo: (state, action: PayloadAction<PlatformDTO | undefined>) => {
      state.platform = action.payload;
    },
    setIsLoadingPlatform: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  }
});

export const { setPlatformInfo, setIsLoadingPlatform } = slice.actions;

export default slice.reducer;
