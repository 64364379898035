import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { Tab } from "@app/core/tab/tab.component";
import { platformTranslationKeys } from "@app/constants/platform";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { platformThunks } from "@app/redux/thunks/platform.thunk";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";

import { PlatformSettingsForm, IFormValues } from "./platform-settings.form";
import styles from "./platform-settings-component.module.scss";

const PlatformSettings = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { isLoading, platform } = useAppSelector((state) => state.platform);
  const [selectedTab, setSelectedTab] = useState<string>("global.platform.netherlands");

  const onSubmit = (values: IFormValues) => {
    if (platform) {
      dispatch(
        platformThunks.savePlatform({
          ...platform,
          googleExperimentId: values.googleExperimentId || "",
          googleOptimizeId: values.googleOptimizeId || "",
          headerText: values.headerText || "",
          mailchimpAudienceId: values.mailchimpAudienceId || "",
          navBarTitle: values.navBar.title,
          navBarSubTitle: values.navBar.subTitle,
          navBarLink: values.navBar.link,
          alertMessage: values.alert.message || "",
          alertStartDate: values.alert.startDate ?? null,
          alertEndDate: values.alert.endDate ?? null
        })
      );
    }
  };

  useEffect(() => {
    const newPlatform = platformTranslationKeys.find((platformToFind) => platformToFind.name === selectedTab);

    dispatch(platformThunks.getPlatform(newPlatform?.id || 1));
  }, [selectedTab]);

  return (
    <div className={styles.platformSettingsDetail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <>
            <div className={styles.topBarLeft}>
              <h2>{intl.formatMessage({ id: selectedTab })}</h2>
            </div>
          </>
        </div>
      </div>
      <div className={styles.container}>
        <Tab
          tabs={platformTranslationKeys.map((item) => item.name)}
          selectedTab={selectedTab}
          onSelect={setSelectedTab}
        />
        <div className={styles.details}>
          <div className={styles.data} style={{ opacity: isLoading ? 0.5 : 1 }}>
            {platform ? (
              <PlatformSettingsForm platform={platform} onSubmit={onSubmit} intl={intl} />
            ) : (
              isLoading && <SpinningLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { PlatformSettings };
