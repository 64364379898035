import { toast } from "react-toastify";

import JWTToken from "@app/authorization/jwt-token";
import { ChangePasswordDTO, AuthApi } from "@app/api/generated";

import { getUnAuthorizedApiConfig, getAuthorizedApiConfig } from "@app/util/api-config";
import { LOCAL_STORAGE_KEY } from "@app/constants";

import { resetStore } from "@app/redux/reducers/settings";
import { ROUTES } from "@app/constants/routes";

export const getAccessToken = async (): Promise<string | false> => {
  const accessToken = JWTToken.getToken("accessToken");
  const isExpired = JWTToken.checkTokenIsExpired("accessToken");

  if (!accessToken || isExpired) {
    const refreshToken = JWTToken.getToken("refreshToken");
    if (!refreshToken) {
      return false;
    }
    const authApi = new AuthApi(getUnAuthorizedApiConfig());

    const result = await authApi.authAccessTokenPost({ refreshToken });

    localStorage.clear();
    localStorage.setItem(LOCAL_STORAGE_KEY.TOKENS, JSON.stringify(result));

    return result.accessToken || result.refreshToken;
  }

  return accessToken;
};

export const login = async (email: string, password: string): Promise<any> => {
  try {
    const authApi = new AuthApi(getUnAuthorizedApiConfig());

    const result = await authApi.authLoginPost({ email, password });

    localStorage.clear();
    localStorage.setItem(LOCAL_STORAGE_KEY.TOKENS, JSON.stringify(result));

    return true;
  } catch {
    return false;
  }
};

export const logout = async (dispatch: any, toastError?: boolean, toastMessage?: string): Promise<boolean> => {
  try {
    const accessToken = JWTToken.getToken("accessToken");
    const tokens = JWTToken.getAllTokens();

    const authApi = new AuthApi(await getAuthorizedApiConfig());
    await authApi.authLogoutPost(accessToken, tokens);

    return true;
  } catch {
    return false;
  } finally {
    let timeoutTime = 200;
    const getToastMessage = toastMessage || "Je bent uitgelogd, we sturen je door naar de login pagina.";

    if (toastError) {
      timeoutTime = 4000;
      toast.error(getToastMessage);
    }

    localStorage.clear();
    dispatch(resetStore());

    setTimeout(() => {
      window.location.href = ROUTES.LOGIN;
    }, timeoutTime);
  }
};

export const setNewPassword = async (passwords: ChangePasswordDTO): Promise<any> => {
  try {
    const userId = JWTToken.getUserId();
    const authApi = new AuthApi(await getAuthorizedApiConfig());
    await authApi.authPasswordUserUserIdPut(userId, passwords);

    return true;
  } catch {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw false;
  }
};

export const requestNewPassword = async (emailAddress: string): Promise<void> => {
  try {
    const authApi = new AuthApi(getUnAuthorizedApiConfig());

    authApi.authResetTokenRequestPost({
      email: emailAddress
    });
  } catch {
    // error logging
  }
};

export const validateToken = async (token: string): Promise<boolean> => {
  try {
    const authApi = new AuthApi(getUnAuthorizedApiConfig());

    await authApi.authResetTokenValidatePost({ resetToken: token });

    return true;
  } catch {
    return false;
  }
};

export const resetPassword = async (newPassword: string, resetToken: string): Promise<boolean> => {
  const authApi = new AuthApi(getUnAuthorizedApiConfig());

  try {
    const result: any = await authApi.authPasswordResetPut({ password: newPassword, resetToken });

    return result.ok;
  } catch {
    return false;
  }
};
