import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { ClickableComponent } from "@app/core";
import BackIcon from "@assets/icons/back.svg";
import BinIcon from "@assets/icons/bin.svg";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BasicModal } from "@app/modules/basic-modal";
import { InFeaturedCategoriesDTO, OutFeaturedCategoriesDTO } from "@app/api/generated";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { featuredCategoriesThunk } from "@app/redux/thunks/featured-categories.thunk";
import { setSelectedFeaturedCategory } from "@app/redux/reducers/featured-categories";
import { ROUTES } from "@app/constants/routes";

import { CategoryDetailForm } from "./form/featured-category.form";
import styles from "./featured-category-detail-component.module.scss";

const FeaturedCategoryDetail = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { selectedFeaturedCategory } = useAppSelector((state) => state.featuredCategories);
  const history = useNavigate();

  const [isDeleteFeaturedCategoryOpen, setIsDeleteFeaturedCategoryOpen] = useState<boolean>(false);
  const [isLeaveWarningOpen, setIsLeaveWarningOpen] = useState<boolean>(false);
  const [isFeaturedCategoryEdited, setIsFeaturedCategoryEdited] = useState<boolean>(false);
  const [initialEditHasPassed, setInitialEditHasPassed] = useState<boolean>(false);
  const featuredCategoryEditStateForEventListeners = useRef(isFeaturedCategoryEdited);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", checkReload);

    return () => {
      window.removeEventListener("beforeunload", checkReload);
      dispatch(setSelectedFeaturedCategory(undefined));
    };
  }, []);

  useEffect(() => {
    fetchFeaturedCategory(id);
  }, [id]);

  const fetchFeaturedCategory = async (categoryId?: string) => {
    if (categoryId !== "new") {
      const result: OutFeaturedCategoriesDTO | undefined = (await dispatch(
        featuredCategoriesThunk.getFeaturedCategory(Number(categoryId))
      )) as any;

      if (!result) {
        history(ROUTES.FEATURED_CATEGORY_OVERVIEW);
      }
    }
  };

  useEffect(() => {
    featuredCategoryEditStateForEventListeners.current = isFeaturedCategoryEdited;
  }, [isFeaturedCategoryEdited]);

  const checkReload = (e: BeforeUnloadEvent) => {
    if (featuredCategoryEditStateForEventListeners.current) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const onDeleteFeaturedCategory = () => {
    onFeaturedCategoryCancel(false);
    if (selectedFeaturedCategory) {
      dispatch(featuredCategoriesThunk.deleteFeaturedCategory(selectedFeaturedCategory.id));

      setTimeout(() => {
        history(ROUTES.FEATURED_CATEGORY_OVERVIEW);
      }, 1000);
    }
    setIsLeaveWarningOpen(false);
  };

  const onFeaturedCategoryCancel = (isSavedCheck: boolean) => {
    if (isFeaturedCategoryEdited && isSavedCheck) {
      setIsLeaveWarningOpen(true);
    } else {
      setIsLeaveWarningOpen(false);
      cleanEditState();
      history(ROUTES.FEATURED_CATEGORY_OVERVIEW);
    }
  };

  const onFeaturedCategorySave = async (values: InFeaturedCategoriesDTO) => {
    cleanEditState();
    dispatch(featuredCategoriesThunk.saveFeaturedCategory(values));
  };

  const onEdit = () => {
    if (!isFeaturedCategoryEdited && initialEditHasPassed) {
      setIsFeaturedCategoryEdited(true);
    }
    if (!initialEditHasPassed) {
      setInitialEditHasPassed(true);
    }
  };

  const cleanEditState = () => {
    setIsLeaveWarningOpen(false);
    setIsFeaturedCategoryEdited(false);
    setInitialEditHasPassed(false);
  };

  return (
    <div className={styles.categoryDetail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <div className={styles.topBarLeft}>
            <ClickableComponent
              variant="link-primary"
              title={
                selectedFeaturedCategory
                  ? selectedFeaturedCategory.categoryTitle
                  : intl.formatMessage({
                      id: "featuredCategoryDetail.button.add"
                    })
              }
              icon={BackIcon}
              iconPosition="left"
              iconSize="24px"
              onClick={() => {
                onFeaturedCategoryCancel(true);
              }}
            />
          </div>
          <div className={styles.topBarRight}>
            <ClickableComponent
              variant="tertiary"
              iconStyle="outline"
              title={intl.formatMessage({ id: "featuredCategoryDetail.button.delete" })}
              buttonType="button"
              icon={BinIcon}
              iconPosition="left"
              iconSize="20px"
              height={40}
              onClick={() => setIsDeleteFeaturedCategoryOpen(true)}
            />
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.details}>
          {!selectedFeaturedCategory ? (
            <SpinningLoader />
          ) : (
            <CategoryDetailForm
              intl={intl}
              onSubmit={onFeaturedCategorySave}
              onCancel={onFeaturedCategoryCancel}
              onEdit={onEdit}
              disableSaveButton={!isFeaturedCategoryEdited}
              featuredCategory={selectedFeaturedCategory}
            />
          )}
        </div>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "featuredCategoryDetail.modal.deleteCategory.title" })}
        isModalOpen={isDeleteFeaturedCategoryOpen}
        onCloseModal={() => {
          setIsDeleteFeaturedCategoryOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteFeaturedCategoryOpen(false);
          }}
          onDelete={onDeleteFeaturedCategory}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "featuredCategoryDetail.modal.leavePage.title" })}
        isModalOpen={isLeaveWarningOpen}
        onCloseModal={() => {
          setIsLeaveWarningOpen(false);
        }}
        variant="big"
      >
        <BasicModal
          onApprove={() => onFeaturedCategoryCancel(false)}
          onCancel={() => setIsLeaveWarningOpen(false)}
          onApproveTitle={intl.formatMessage({ id: "featuredCategoryDetail.modal.leavePage.approve" })}
          onCancelTitel={intl.formatMessage({ id: "featuredCategoryDetail.modal.leavePage.cancel" })}
          description={intl.formatMessage({ id: "featuredCategoryDetail.modal.leavePage.description" })}
        />
      </ModalComponent>
    </div>
  );
};

export { FeaturedCategoryDetail };
