import { trim } from "lodash";
import slugify from "slugify";

export const basicSlugify = (value?: string, allowSlashes?: boolean): string => {
  const slugifyProps = { lower: true, strict: false, locale: "nl" };
  let newValue = trim(value, "/");
  newValue = replacePlus(newValue);
  newValue = decodeURIComponent(newValue);

  if (allowSlashes && newValue.includes("/")) {
    newValue = newValue
      .split("/")
      .map((slug) => slugify(slug, slugifyProps))
      .join("/");
  } else {
    newValue = slugify(newValue, slugifyProps);
  }

  return newValue;
};

const replacePlus = (value?: string): string => {
  const plusRegex = /\+/g;
  const dotRegex = /\./g;

  const replacementChar = "-";

  let newValue = value;

  newValue = (value || "").replace(plusRegex, replacementChar);
  newValue = (value || "").replace(dotRegex, replacementChar);

  return newValue;
};
