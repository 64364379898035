/* eslint-disable */

import React, { useRef } from "react";
import { Editor, EditorState, RichUtils, CompositeDecorator, Entity, DraftBlockType, SelectionState } from "draft-js";
import "./rich-editor-styles.scss";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

// Icons
import editorH1 from "@assets/icons/editor/editor-h1.svg";
import editorH2 from "@assets/icons/editor/editor-h2.svg";
import editorH3 from "@assets/icons/editor/editor-h3.svg";
import editorH4 from "@assets/icons/editor/editor-h4.svg";
import editorH5 from "@assets/icons/editor/editor-h5.svg";
import editorBold from "@assets/icons/editor/editor-bold.svg";
import editorUnderlined from "@assets/icons/editor/editor-underlined.svg";
import editorCode from "@assets/icons/editor/editor-code.svg";
import editorLink from "@assets/icons/editor/editor-link.svg";
import editorItalic from "@assets/icons/editor/editor-italic.svg";
import editorListBulleted from "@assets/icons/editor/editor-list-bulleted.svg";
import editorListNumbered from "@assets/icons/editor/editor-list-numbered.svg";
import ReactTooltip from "react-tooltip";
import { IconComponent } from "../icon";
import styles from "./rich-editor.module.scss";
import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";

export interface IRichEditorProps {
  height: number;
  id: string;
  label?: IFormLabelComponentProps;
  maxHeight: number;
  maxLength?: number;
  onChange: (htmlValue: string, fieldId: string) => void;
  pageField: string;
}

export const RichEditor = (props: IRichEditorProps) => {
  const onChange = (editedState: any) => {
    setEditorState(editedState);

    const currentContent = editedState.getCurrentContent();
    const contentLength = currentContent.getPlainText("").length;
    setCurrentContentLength(contentLength);
  };

  const editorRefContainer = useRef<any>(null);
  const editorRef = useRef<any>(null);

  /** Add link en entity as decorator to the list. */
  const findLinkEntities = (contentBlock: any, callback: any) => {
    contentBlock.findEntityRanges((character: any) => {
      const entityKey = character.getEntity();

      return entityKey !== null && Entity.get(entityKey).getType() === "LINK";
    }, callback);
  };
  /** Link style */
  const Link = (linkProps: any) => {
    const { url } = Entity.get(linkProps.entityKey).getData();

    return (
      <a href={url} style={{ color: "#3b5998", textDecoration: "underline" }}>
        {linkProps.children}
      </a>
    );
  };

  const linkDecorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    }
  ]);

  const [editorState, setEditorState] = React.useState<EditorState>(
    EditorState.createWithContent(stateFromHTML(props.pageField), linkDecorator)
  );
  const [showUrlInput, setShowUrlInput] = React.useState(false);
  const [urlValue, setUrlValue] = React.useState<any>("");
  const [currentContentLength, setCurrentContentLength] = React.useState<number>(0);

  const moveSelectionToEnd = (stateOfEditor: EditorState) => {
    const content = stateOfEditor.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length
    });

    return EditorState.forceSelection(stateOfEditor, selection);
  };

  /**  toggles the text to H1 till 6 and sets list */
  const toggleBlockType = (blockType: DraftBlockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  /**  toggles style of the text */
  const toggleInlineStyle = (inlineStyle: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const checkPlaceholder = () => {
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText() && contentState.getBlockMap().first().getType() !== "unstyled") {
      return "RichEditor-editor RichEditor-hidePlaceholder";
    }

    return "RichEditor-editor";
  };

  React.useEffect(() => {
    setEditorState(EditorState.createWithContent(stateFromHTML(props.pageField), linkDecorator));
  }, []);

  React.useEffect(() => {
    const newRawContent = stateToHTML(editorState.getCurrentContent());
    props.onChange(newRawContent, props.id);
  }, [stateToHTML(editorState.getCurrentContent())]);

  /**  Set the state to show the link form */
  const promptForLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setShowUrlInput(true);
      setUrlValue("");
    }
  };

  const confirmLink = (e: any) => {
    e.preventDefault();
    const entityKey = Entity.create("LINK", "MUTABLE", { url: urlValue });

    setEditorState(RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey));
    setShowUrlInput(false);
    setUrlValue("");
  };

  /**  checks if enter is pressed when enterting the link name */
  const onLinkInputKeyDown = (e: any) => {
    const enter = 13;
    if (e.which === enter) {
      confirmLink(e);
    }
  };
  /** sets url state */
  const onUrlChange = (e: any) => {
    setUrlValue(e.target.value);
  };

  /**  input field for url */
  const inputUrl = () => {
    let urlInput;
    if (showUrlInput) {
      return (urlInput = (
        <div className={styles.urlInputContainer}>
          <input onChange={onUrlChange} style={{}} type="text" value={urlValue} onKeyDown={onLinkInputKeyDown} />
          <button type="button" onMouseDown={confirmLink}>
            Confirm
          </button>
        </div>
      ));
    }
  };

  const handleBeforeInput = (chars: string, editorStateParam: EditorState, eventTimeStamp: number) => {
    if (!props.maxLength) return "not-handled";

    const currentContent = editorStateParam.getCurrentContent();
    const contentLength = currentContent.getPlainText("").length;

    if (contentLength > props.maxLength - 1) {
      return "handled";
    }

    return "not-handled";
  };

  const handlePastedText = (text: string, html: string | undefined, editorStateParam: EditorState) => {
    if (!props.maxLength) return "not-handled";

    const currentContent = editorStateParam.getCurrentContent();
    const contentLength = currentContent.getPlainText("").length;

    if (contentLength + text.length > props.maxLength - 1) {
      return "handled";
    }

    return "not-handled";
  };

  return (
    <div>
      {props.label && <FormLabelComponent {...props.label} />}
      <div
        className={styles["RichEditor-root"]}
        style={{ maxWidth: "100%", minHeight: props.height, maxHeight: props.maxHeight }}
      >
        <span className={styles["RichEditor-control-wrapper"]}>
          <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} linkToggle={promptForLink} />
          <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
        </span>
        <div
          id="editor-container"
          role="div"
          onClick={(e: React.ChangeEvent<any>) => {
            if (e.target.id && e.target.id === editorRefContainer.current.id) {
              editorRef.current.focus();
              setEditorState(moveSelectionToEnd(editorState));
            }
          }}
          className={styles[checkPlaceholder()]}
          style={{ minHeight: props.height, maxHeight: props.maxHeight * 0.75 }}
          ref={editorRefContainer}
        >
          {inputUrl()}
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            onChange={onChange}
            spellCheck
            ref={editorRef}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
          />
        </div>
      </div>
      {props.maxLength && (
        <div className={styles.maxLength}>
          {currentContentLength} / {props.maxLength}
        </div>
      )}
    </div>
  );
};

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};
/**  checks which blockstyles are active */
const getBlockStyle = (block: any) => {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return "";
  }
};

interface IStyleProp {
  active: any;
  icon: any;
  label: any;
  onToggle: any;
  style: any;
}

/**  Stylebuttons are the clickable buttons in the wyswyg module. */
const StyleButton = (props: IStyleProp) => {
  const onToggle = (e: any) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  return (
    <span
      role="toggle"
      className={
        props.active
          ? `${styles["RichEditor-styleButton"]} ${styles["RichEditor-activeButton"]}`
          : styles["RichEditor-styleButton"]
      }
      onMouseDown={onToggle}
    >
      <span data-tip={props.label} className={styles.tooltip}>
        <IconComponent icon={props.icon} fillColor="#1c1c1c" size="20px" />
      </span>
      {/* <ReactTooltip effect="solid" /> */}
    </span>
  );
};

const BLOCK_TYPES = [
  { label: "H1", style: "header-one", icon: editorH1 },
  { label: "H2", style: "header-two", icon: editorH2 },
  { label: "H3", style: "header-three", icon: editorH3 },
  { label: "H4", style: "header-four", icon: editorH4 },
  { label: "H5", style: "header-five", icon: editorH5 },
  { label: "UL", style: "unordered-list-item", icon: editorListBulleted },
  { label: "OL", style: "ordered-list-item", icon: editorListNumbered }
];
/**  Adds the blockstyle controls to the editor */
const BlockStyleControls = (props: any) => {
  const selection = props.editorState.getSelection();
  const blockType = props.editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  return (
    <div className={styles["RichEditor-controls"]}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
      <StyleButton
        key={1}
        active={blockType === "link"}
        label="LINK"
        onToggle={props.linkToggle}
        style="type.style"
        icon={editorLink}
      />
    </div>
  );
};

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD", icon: editorBold },
  { label: "Italic", style: "ITALIC", icon: editorItalic },
  { label: "Underline", style: "UNDERLINE", icon: editorUnderlined },
  { label: "Code", style: "CODE", icon: editorCode }
];
/**  Adds the inline style controls to the editor */
const InlineStyleControls = (props: any) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className={styles["RichEditor-controls"]}>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          icon={type.icon}
        />
      ))}
    </div>
  );
};
