import clsx from "clsx";
import * as React from "react";
import styles from "./label-component.module.scss";

export interface ILabelComponentComponentProps {
  type?: string;
}

const LabelComponent = (props: ILabelComponentComponentProps) => {
  const typeClass = props.type ? props.type.toLowerCase() : "";

  return <div className={clsx(styles.label, styles[typeClass])}>{props.type}</div>;
};

export { LabelComponent };
