/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { FilterTypeDTO, FilterTypeDTOFromJSON, FilterTypeDTOToJSON } from "../models";

/**
 * no description
 */
export class FilterTypesApi extends runtime.BaseAPI {
  /**
   */
  async filterTypesGetRaw(): Promise<runtime.ApiResponse<Array<FilterTypeDTO>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filterTypes`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterTypeDTOFromJSON));
  }

  /**
   */
  async filterTypesGet(): Promise<Array<FilterTypeDTO>> {
    const response = await this.filterTypesGetRaw();
    return await response.value();
  }
}
