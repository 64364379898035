/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ExternalDealFilterMvoDTO,
  ExternalDealFilterMvoDTOFromJSON,
  ExternalDealFilterMvoDTOFromJSONTyped,
  ExternalDealFilterMvoDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface ExternalDealFilterListResponseMvoDTO
 */
export interface ExternalDealFilterListResponseMvoDTO {
  /**
   *
   * @type {Array<ExternalDealFilterMvoDTO>}
   * @memberof ExternalDealFilterListResponseMvoDTO
   */
  externalDealFilters: Array<ExternalDealFilterMvoDTO>;
}

export function ExternalDealFilterListResponseMvoDTOFromJSON(json: any): ExternalDealFilterListResponseMvoDTO {
  return ExternalDealFilterListResponseMvoDTOFromJSONTyped(json, false);
}

export function ExternalDealFilterListResponseMvoDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExternalDealFilterListResponseMvoDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    externalDealFilters: (json["externalDealFilters"] as Array<any>).map(ExternalDealFilterMvoDTOFromJSON)
  };
}

export function ExternalDealFilterListResponseMvoDTOToJSON(value?: ExternalDealFilterListResponseMvoDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    externalDealFilters: (value.externalDealFilters as Array<any>).map(ExternalDealFilterMvoDTOToJSON)
  };
}
