import { useEffect, useState } from "react";
import { nl, enGB } from "date-fns/locale";
import {
  addMinutes,
  addSeconds,
  differenceInSeconds,
  format as formatDateFns,
  formatDistance as formatDistanceDateFns,
  Locale
} from "date-fns";

import { useAppSelector } from "@app/redux";
import { LOCALES } from "@app/constants";

export type formatDistanceType = typeof formatDistanceDateFns;
export type formatDateType = (
  date: Parameters<typeof formatDateFns>[0],
  format?: Parameters<typeof formatDateFns>[1],
  options?: Parameters<typeof formatDateFns>[2]
) => string;

export type getTimeDifferenceType = (
  dateLeft: Date,
  dateRight: Date,
  format?: Parameters<typeof formatDateFns>[1],
  options?: Parameters<typeof formatDateFns>[2]
) => string;

export type formatSecondsToTimeType = (
  seconds: number,
  format: Parameters<typeof formatDateFns>[1],
  options?: Parameters<typeof formatDateFns>[2]
) => string;

export const useDateFnsFormat = () => {
  const { locale } = useAppSelector((state) => state.locales);
  const [dateFnsLocale, setDateFnsLocale] = useState<Locale>(nl);

  useEffect(() => {
    setDateFnsLocale(getDateFnsLocale(locale));
  }, [locale]);

  const formatDistance: formatDistanceType = (date, baseDate, options) =>
    formatDistanceDateFns(date, baseDate, { locale: dateFnsLocale, ...options });

  const formatDate: formatDateType = (
    date: Parameters<typeof formatDateFns>[0],
    format: Parameters<typeof formatDateFns>[1] = "dd MMM yyyy HH:mm",
    options?: Parameters<typeof formatDateFns>[2]
  ) => formatDateFns(new Date(date), format, { locale: dateFnsLocale, ...options });

  const getTimeDifference: getTimeDifferenceType = (
    dateLeft: Date,
    dateRight: Date,
    format: Parameters<typeof formatDateFns>[1] = "HH:mm:ss",
    options?: Parameters<typeof formatDateFns>[2]
  ) => {
    const seconds = differenceInSeconds(dateLeft, dateRight);
    const helperDate = addSecondsToZeroTime(seconds);

    return formatDate(helperDate, format, { locale: dateFnsLocale, ...options });
  };

  const formatSecondsToTime: formatSecondsToTimeType = (
    seconds: number,
    format: Parameters<typeof formatDateFns>[1] = "HH:mm:ss",
    options?: Parameters<typeof formatDateFns>[2]
  ) => {
    const helperDate = addSecondsToZeroTime(seconds);

    return formatDate(helperDate, format, { locale: dateFnsLocale, ...options });
  };

  return {
    formatDate,
    formatDistance,
    getTimeDifference,
    formatSecondsToTime
  };
};

const addSecondsToZeroTime = (seconds: number): Date => {
  const baseDate = getBaseDateWithFixedTimezone();

  return addSeconds(new Date(baseDate), seconds);
};

const getDateFnsLocale = (locale: string): Locale => {
  switch (locale) {
    case LOCALES.NL:
      return nl;
    case LOCALES.EN:
      return enGB;
    default:
      return nl;
  }
};

const getBaseDateWithFixedTimezone = (): Date => {
  const zeroDate = new Date(0);
  const timezoneDiff = zeroDate.getTimezoneOffset();

  return addMinutes(zeroDate, timezoneDiff);
};
