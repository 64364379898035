import { IntlShape } from "react-intl";
import { IFilterType } from "./filter";

export enum platformType {
  "Nederland" = 1,
  "België (NL)" = 2,
  "België (FR)" = 3,
  "Frankrijk" = 4,
  "Verenigd Koninkrijk" = 9,
  "Duitsland" = 10,
  "Zweden" = 11,
  "Spanje" = 12,
  "Italië" = 13
}

export const platformLocales: IFilterType[] = [
  { id: 1, name: "" },
  { id: 2, name: "" },
  { id: 3, name: "fr" },
  { id: 4, name: "fr" },
  { id: 9, name: "uk" },
  { id: 10, name: "de" },
  { id: 11, name: "se" },
  { id: 12, name: "es" },
  { id: 13, name: "it" }
];

export const platformTranslationKeys: IFilterType[] = [
  { id: 1, name: "global.platform.netherlands" },
  { id: 2, name: "global.platform.belgiumNL" },
  { id: 3, name: "global.platform.belgiumFR" },
  { id: 4, name: "global.platform.france" },
  { id: 9, name: "global.platform.unitedKingdom" },
  { id: 10, name: "global.platform.germany" },
  { id: 11, name: "global.platform.sweden" },
  { id: 12, name: "global.platform.spain" },
  { id: 13, name: "global.platform.italy" }
];

export const getTranslatedPlatforms = (intl: IntlShape): IFilterType[] =>
  platformTranslationKeys.map(
    (untranslatedPlatform) =>
      ({
        id: untranslatedPlatform.id,
        name: intl.formatMessage({ id: untranslatedPlatform.name })
      } as IFilterType)
  );
