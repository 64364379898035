import { OutDetailUserDTO, OutUserDTO } from "@app/api/generated";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUsersState {
  isLoadingUsers: boolean;
  totalResults: number;
  users: OutUserDTO[];
  selectedUser?: OutDetailUserDTO;
  currentPage: number;
  currentSearch?: string;
  currentRole?: number;
}

const initialState: IUsersState = {
  isLoadingUsers: false,
  users: [],
  totalResults: 0,
  currentPage: 1
};

export const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<OutUserDTO[]>) => {
      state.users = action.payload;
    },
    deleteUserFromList: (state, action: PayloadAction<number>) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
    setSelectedUser: (state, action: PayloadAction<OutDetailUserDTO | undefined>) => {
      state.selectedUser = action.payload;
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    setIsLoadingUsers: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUsers = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    },
    setCurrentRole: (state, action: PayloadAction<number | undefined>) => {
      state.currentRole = action.payload;
    }
  }
});

export const {
  setUsers,
  setIsLoadingUsers,
  setCurrentPage,
  setTotalResults,
  setCurrentSearch,
  setCurrentRole,
  setSelectedUser,
  deleteUserFromList
} = slice.actions;

export default slice.reducer;
