import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { IconComponent } from "@app/core/icon";

import styles from "./clickable-component.module.scss";

export type ButtonVariant =
  | "primary-default"
  | "primary-brand"
  | "primary-inverted"
  | "secondary"
  | "secondary-inverted"
  | "tertiary"
  | "tertiary-inverted"
  | "link-primary"
  | "link-secondary"
  | "link-tertiary"
  | "link-quaternary";

export type IconStyle = "outline" | "filled" | "outline-fill";

export interface IClickableComponentProps {
  animated?: boolean;
  buttonType?: "submit" | "reset" | "button";
  disabled?: boolean;
  disableSelect?: boolean;
  dynamicSize?: boolean;
  fullWidth?: boolean;
  height?: number;
  href?: string;
  icon?: string;
  iconFillColor?: string;
  iconStrokeColor?: string;
  iconPosition?: "right" | "left";
  iconSize?: string;
  iconStyle?: IconStyle;
  onClick?: () => void;
  target?: "_blank" | "_self";
  title?: string;
  variant?: ButtonVariant;
  width?: number;
  zIndex?: number;
  ref?: React.RefObject<HTMLButtonElement>;
}

const renderIconMargin = (
  margin = "right",
  title: string | undefined,
  icon: string | undefined,
  iconFillColor: string | undefined,
  iconStrokeColor: string | undefined,
  iconSize: string | undefined
) => {
  let style;
  if (title) {
    style = styles[`icon-${margin}`];
  }

  return (
    <span className={style}>
      <IconComponent icon={icon} size={iconSize || "14px"} fillColor={iconFillColor} strokeColor={iconStrokeColor} />
    </span>
  );
};

const renderIconText = (
  icon: string | undefined,
  iconPosition: string | undefined,
  title: string | undefined,
  iconFillColor: string | undefined,
  iconStrokeColor: string | undefined,
  iconSize: string | undefined
) => {
  const showTextOnTheLeft = (icon && iconPosition === "right") || (icon && !iconPosition);
  const showTextOnTheRight = icon && iconPosition === "left";

  return (
    <span className={styles["icon-svg"]}>
      {showTextOnTheLeft && title}
      {icon ? renderIconMargin(iconPosition, title, icon, iconFillColor, iconStrokeColor, iconSize) : title}
      {showTextOnTheRight && title}
    </span>
  );
};

const ClickableComponent = (props: IClickableComponentProps) => {
  const [iconText, setIconText] = React.useState<JSX.Element>(
    renderIconText(
      props.icon,
      props.iconPosition,
      props.title,
      props.iconFillColor,
      props.iconStrokeColor,
      props.iconSize
    )
  );
  useEffect(
    () =>
      setIconText(
        renderIconText(
          props.icon,
          props.iconPosition,
          props.title,
          props.iconFillColor,
          props.iconStrokeColor,
          props.iconSize
        )
      ),
    [props.icon, props.iconPosition, props.title]
  );
  const {
    buttonType,
    disableSelect,
    animated,
    onClick,
    href,
    variant,
    title,
    disabled,
    iconStyle,
    target,
    zIndex,
    ref
  } = props;
  const classModify = variant || "primary-default";
  const buttonFAB = !title ? styles["button--FAB"] : "";
  const animatedIcon = animated ? styles[`button--animated`] : "";
  const noSelect = disableSelect ? styles[`no-select`] : "";
  const iconOutline = iconStyle ? styles[`button--${classModify}--icon-${iconStyle}`] : "";
  const buttonClassName = clsx(
    styles.button,
    styles[`button--${classModify}`],
    props.disabled && styles[`button--${classModify}--disabled`],
    buttonFAB,
    iconOutline,
    animatedIcon,
    noSelect,
    {
      "button--fullWidth": props.fullWidth
    },
    {
      "button__dynamic-size": props.dynamicSize
    }
  );

  const buttonStyle = {
    width: props.fullWidth ? "100%" : props.width,
    height: props.height,
    zIndex
  };

  if (href) {
    const regex = "https://|http://";
    const isExternalLink = href.match(regex) !== null;

    if (isExternalLink) {
      return (
        <a target={target || "_blank"} className={buttonClassName} style={buttonStyle} href={href}>
          {iconText}
        </a>
      );
    }

    return (
      <Link target={target || "_self"} className={buttonClassName} style={buttonStyle} to={href}>
        {iconText}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      style={buttonStyle}
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      name={title}
      className={buttonClassName}
      onClick={onClick}
      ref={ref}
    >
      <span className={styles["icon-svg"]}>{iconText}</span>
    </button>
  );
};

export { ClickableComponent };
