import {
  MerchantsApi,
  InCreateMerchantDTO,
  OutDetailedMerchantDTO,
  InDetailedMerchantDTO,
  MerchantsExtendedGetEditFilterEnum
} from "@app/api/generated";
import { toast } from "react-toastify";
import { handleError } from "@app/util/handle-error";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";
import {
  deleteMerchantFromList,
  setIsLoadingMerchants,
  setSelectedMerchant,
  setMerchants,
  setTotalResults
} from "@app/redux/reducers/merchants";

export const mapNumberToBooleanString = (selectValue?: number): string | undefined => {
  switch (selectValue) {
    case 0:
      return undefined;
    case 1:
      return "true";
    case 2:
      return "false";
    default:
      return undefined;
  }
};

const mapNumberToEditFilter = (selectValue?: number): MerchantsExtendedGetEditFilterEnum | undefined => {
  switch (selectValue) {
    case 0:
      return undefined;
    case 1:
      return MerchantsExtendedGetEditFilterEnum.approved;
    case 2:
      return MerchantsExtendedGetEditFilterEnum.merchant;
    default:
      return undefined;
  }
};

const getMerchants =
  (
    skip: number,
    take: number,
    editFilter?: number,
    approved?: number,
    live?: number,
    platformId?: number,
    searchQuery?: string
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const api = new MerchantsApi(await getAuthorizedApiConfig());

    try {
      dispatch(setIsLoadingMerchants(true));

      const resultFromApi = await api.merchantsExtendedGet(
        take,
        skip,
        searchQuery,
        mapNumberToEditFilter(editFilter),
        mapNumberToBooleanString(approved),
        mapNumberToBooleanString(live),
        platformId
      );

      dispatch(setMerchants(resultFromApi.merchants || []));
      dispatch(setTotalResults(resultFromApi.total));
      dispatch(setIsLoadingMerchants(false));
    } catch (error) {
      handleError(error, dispatch, "Get merchants failed");
    }
  };

const getMerchant =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const merchantApi = new MerchantsApi(await getAuthorizedApiConfig());

      const merchant = await merchantApi.merchantsMerchantIdGet(id);

      dispatch(setSelectedMerchant(merchant));
    } catch (error) {
      handleError(error, dispatch, "Failed to get merchant");
    }
  };

const deleteMerchant =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const merchantApi = new MerchantsApi(await getAuthorizedApiConfig());

      const merchant = await merchantApi.merchantsMerchantIdDelete(id);

      if (merchant) {
        dispatch(deleteMerchantFromList(id));
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to delete merchant");
    }
  };

const createNewMerchant =
  (newMerchant: InCreateMerchantDTO): AppThunk =>
  async (dispatch): Promise<OutDetailedMerchantDTO | undefined> => {
    try {
      const merchantApi = new MerchantsApi(await getAuthorizedApiConfig());
      const createdMerchant = await merchantApi.merchantsPost(newMerchant);

      dispatch(setSelectedMerchant(createdMerchant));

      return createdMerchant;
    } catch (error) {
      if ((error as any)?.status === 422) {
        handleError(error, dispatch, "Winkel met deze naam bestaat al voor dit Platform.");
      } else {
        handleError(error, dispatch, "Failed to create new merchant");
      }
    }

    return undefined;
  };

const saveMerchant =
  (merchant: InDetailedMerchantDTO, publish?: boolean, customMessage?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const merchantApi = new MerchantsApi(await getAuthorizedApiConfig());

      const merchantToSave: InDetailedMerchantDTO = { ...merchant, approved: publish ? true : false };

      const savedMerchant = await merchantApi.merchantsMerchantIdPut(merchant.id, merchantToSave);

      dispatch(setSelectedMerchant(savedMerchant));

      if (customMessage) {
        toast.success(customMessage);
      } else {
        toast.success("Winkel is opgeslagen");
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to save merchant");
      // new FatalError(error.name, error.message, error.stack);
    }
  };

export const merchantThunks = {
  getMerchants,
  getMerchant,
  deleteMerchant,
  createNewMerchant,
  saveMerchant
};
